import { CSSProperties } from 'react';
import { ModalHeader } from 'reactstrap';

enum TabHeaderType {
  Normal = 'transparent',
  Highlight = '#bbd4be',
  Error = '#f9d2c7',
}

type TabHeaderProps =
  | {
      type?: TabHeaderType;
      isModal?: false | undefined;
      children: React.ReactNode | React.ReactNode[];
    }
  | {
      type?: TabHeaderType;
      isModal: true;
      children: React.ReactNode | React.ReactNode[];
      closeModal: () => void;
    };

const styles = (props: TabHeaderProps): CSSProperties => ({
  backgroundColor: props.type || TabHeaderType.Normal,
  position: 'relative',
  ...(props.isModal
    ? { borderRadius: '5px 5px 0 0', lineHeight: 1.25, padding: '15px 15px 10px 15px' }
    : { padding: '15px', lineHeight: 1.25 }),
});

const TabHeader: React.SFC<TabHeaderProps> = (props: TabHeaderProps) =>
  props.isModal ? (
    <ModalHeader style={styles(props)} toggle={props.closeModal}>
      {props.children}
    </ModalHeader>
  ) : (
    <h5 className="modal-title" style={styles(props)}>
      {props.children}
    </h5>
  );

export default TabHeader;
export { TabHeaderType };
