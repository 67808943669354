import { useCallback, useRef, useEffect, useState, useMemo, CSSProperties } from 'react';
import { HangTime } from './HangTime';
import { getNewValuePlaceholder } from './newValuePlaceholder';
import { ModalContainer } from './ContextModal';
import { hangTimesTabPage } from './Pages';
import HangTimeEdit from './HangTimeEdit';
import CalendarTop, { CalendarTopRefHandles } from './CalendarTop';
import CalendarBottomDay, { CalendarBottomScrollableProps, CalendarBottomRefHandles } from './CalendarBottomScrollable';
import CalendarBottomList from './CalendarBottomList';
import { HangTimesTabProps, CalendarViewType } from './HangTimesTab';
import CalendarMiddle, { CalendarMiddleProps } from './CalendarMiddle';
import { useLastInteractedWith } from './LastInteractedWith';
import { useHistory } from 'react-router-dom';
import { useLogIfChanged, useLogUnmount, usePlainDateState, usePlainTimeState } from './useHooks';
import { createContainer } from 'unstated-next';
import { Temporal } from '@js-temporal/polyfill';
import { DEFAULT_TIME } from './dateUtils';

interface CalendarProps {
  initialDate: Temporal.PlainDate;
  initialTime: Temporal.PlainTime; // this time should be in the middle of the view
  view: CalendarViewType;
  hangTimes: HangTime[];
}

export type SelectedDateChangeHandler = (arg: {
  date: Temporal.PlainDate;
  caller: 'top' | 'middle' | 'bottom';
  view?: CalendarViewType;
}) => void;

function useBottomCalendarScrollbarOffset() {
  const [offset, setOffset] = useState(0);
  const result = useMemo(() => ({ offset, setOffset }), [offset]);
  return result;
}
export const BottomCalendarScrollbarOffsetContainer = createContainer(useBottomCalendarScrollbarOffset);

/** Component that simulates the width of a scrollbar if there's currently a
 * scrollbar shown in the bottom calendar list view.
 */
export function BottomCalendarScrollbarSpacer() {
  const scrollbarOffsetContainer = BottomCalendarScrollbarOffsetContainer.useContainer();
  const style: CSSProperties = useMemo(
    () => ({
      width: scrollbarOffsetContainer.offset,
      backgroundColor: '#fff',
      display: 'inline-block',
    }),
    [scrollbarOffsetContainer.offset]
  );
  return <span style={style} />;
}

const Calendar = (props: CalendarProps) => {
  const { initialDate, initialTime, view, hangTimes } = props;
  const [selectedDate, setSelectedDate] = usePlainDateState(initialDate);
  const [selectedBottomDate, setSelectedBottomDate] = usePlainDateState(initialDate);
  const [selectedBottomTime /* setSelectedBottomTime */] = usePlainTimeState(initialTime);
  const [selectedTopDate, setSelectedTopDate] = usePlainDateState(initialDate);
  const calendarTopRef = useRef<CalendarTopRefHandles>(null);
  const calendarBottomRef = useRef<CalendarBottomRefHandles>(null);
  const { addModal } = ModalContainer.useContainer();
  const { lastInteractedWith } = useLastInteractedWith();
  const routerHistory = useHistory();

  useEffect(() => {
    calendarTopRef.current!.setSmoothScrolling(true);
  }, []);

  /**
   * Handler called to change the selected date. Triggered by:
   * - Top calendar when user clicks/taps a new date
   * - Middle calendar when the user switches to a new date, e.g. by clicking the today button
   * - Swiping left or right on the bottom calendar's day view.
   * - Scrolling to a new date in the bottom calendar's list view.
   */
  const onSelectedDateChange = useCallback<SelectedDateChangeHandler>(
    ({ date: newDate, caller, view: newView }) => {
      console.log(`may change to date: ${newDate.toString()} (caller = ${caller})`);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`last interacted with: ${lastInteractedWith.current}`);

      // Scroll the newly-selected date into view. Avoid recursion by ensuring
      // that changes made by the top calendar don't cause the top calendar to
      // change, and changes made by the bottom calendar won't cause the bottom
      // calendar to change.
      if (calendarBottomRef.current && lastInteractedWith.current !== 'bottom') {
        // If the user is changing the selected date NOT via scrolling the
        // bottom calendar, then change the date of the bottom calendar.
        setSelectedBottomDate(newDate);
        calendarBottomRef.current.ensureDateIsVisible(newDate);
        console.log(`setting bottom date: ${newDate.toString()}`);

        // Unlike the uncontrolled bottom calendar, the top calendar is a
        // controlled component, so we'll set its selected date here.
        setSelectedTopDate(newDate);
      }
      if (calendarTopRef.current && lastInteractedWith.current !== 'top') {
        console.log(`setting top date: ${newDate.toString()}`);
        setSelectedTopDate(newDate);
        calendarTopRef.current.ensureDateIsVisible(newDate);
      }

      setSelectedDate(newDate);

      // TODO: The problem is probably a URL mismatch between top-cal selected date, URL, and bottom-cal selected date

      // reset the URL to match the new date, time, and view
      const urlProps: HangTimesTabProps = { date: newDate, time: DEFAULT_TIME, view: newView || view || 'day' };
      const url = hangTimesTabPage.urlBuilder(urlProps);
      console.log(`setting url: ${url}`);
      routerHistory.replace(url);
    },
    [routerHistory, view, lastInteractedWith, setSelectedDate, setSelectedTopDate, setSelectedBottomDate]
  );

  const handleEventClick = useCallback<CalendarBottomScrollableProps['onEventClick']>(
    arg => {
      const { hangTime } = arg;
      const editor = <HangTimeEdit hangTime={hangTime} />;
      addModal(editor);
    },
    [addModal]
  );

  const handleTimeClick = useCallback<CalendarBottomScrollableProps['onTimeClick']>(
    arg => {
      const startTime = arg.date.toZonedDateTime({ plainTime: arg.time, timeZone: Temporal.Now.timeZone() });
      const hangTime = {
        h: getNewValuePlaceholder(),
        startTime,
        minutes: 60,
        tags: [],
        name: null,
        hangId: null,
      };
      const editor = <HangTimeEdit hangTime={hangTime} />;
      addModal(editor);
    },
    [addModal]
  );

  /*
  const handleEventDrop = useCallback<CalendarBottomProps['onEventDrop']>(
    arg => {
      const { hangTime, start, end } = arg;
      if (!isRangeOnSameDate(start, end)) {
        throw new ArgumentError('End date/time must be on the same calendar date as the start date/time.');
      }
      const { date, time } = splitDateTime(start);
      const minutes = differenceInMinutes(end, start);
      const newHangTime: HangTime = { ...hangTime, date, startTime: time, minutes };

      stateContainer.onUpdateHangTime(newHangTime);
    },
    [stateContainer]
  );
*/

  const onViewChange = useCallback<CalendarMiddleProps['onViewChange']>(
    ({ view: newView }) => {
      if (newView !== view) {
        const urlProps: HangTimesTabProps = { date: selectedBottomDate, time: selectedBottomTime, view: newView };
        const url = hangTimesTabPage.urlBuilder(urlProps);
        routerHistory.push(url);
        console.log(`switching to ${view} view by changing URL`);
      }
    },
    [selectedBottomDate, selectedBottomTime, view, routerHistory]
  );

  //  useLogIfChanged('selectedDateNotMemo', selectedDateNotMemo);
  useLogIfChanged('selectedDate (Calendar)', selectedDate);
  useLogIfChanged('selectedBottomDate (Calendar)', selectedBottomDate);
  useLogIfChanged('selectedTopDate (Calendar)', selectedTopDate);
  useLogIfChanged('calendarTopRef (Calendar)', calendarTopRef);
  useLogIfChanged('calendarBottomRef (Calendar)', calendarBottomRef);
  useLogIfChanged('addModal (Calendar)', addModal);
  useLogIfChanged('lastInteractedWith (Calendar)', lastInteractedWith);
  useLogIfChanged('routerHistory (Calendar)', routerHistory);
  useLogIfChanged('lastInteractedWith (Calendar)', lastInteractedWith);
  useLogIfChanged('initialDate (Calendar)', initialDate);
  useLogIfChanged('time (Calendar)', initialTime);
  useLogIfChanged('view (Calendar)', view);
  useLogIfChanged('hangTimes (Calendar)', hangTimes);
  useLogIfChanged('addModal (Calendar)', addModal);
  useLogIfChanged('handleTimeClick (Calendar)', handleTimeClick);
  useLogIfChanged('handleEventClick (Calendar)', handleEventClick);
  useLogIfChanged('onSelectedDateChange (Calendar)', onSelectedDateChange);
  useLogIfChanged('onViewChange (Calendar)', onViewChange);

  useLogUnmount('Calendar');

  return (
    <BottomCalendarScrollbarOffsetContainer.Provider>
      <div className="calendar-container">
        <CalendarTop
          // TODO: Use a key prop to ensure the scroller is re-created if we reset the start date?
          // See https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
          selectedDate={selectedTopDate}
          onDateClick={onSelectedDateChange}
          ref={calendarTopRef}
          isMulti={false}
        />
        <CalendarMiddle
          startDate={selectedDate}
          endDate={selectedDate}
          view={view}
          onSelectedDateChange={onSelectedDateChange}
          onViewChange={onViewChange}
          onTimeClick={handleTimeClick}
        />
        {view === 'day' && (
          <CalendarBottomDay
            selectedDate={selectedBottomDate}
            time={selectedBottomTime}
            onTimeClick={handleTimeClick}
            onEventClick={handleEventClick}
            //        onEventDrop={handleEventDrop}
            hangTimes={hangTimes}
            onSelectedDateChange={onSelectedDateChange}
            ref={calendarBottomRef}
          />
        )}
        {view === 'list' && (
          <CalendarBottomList
            selectedDate={selectedBottomDate}
            onTimeClick={handleTimeClick}
            onEventClick={handleEventClick}
            //        onEventDrop={handleEventDrop}
            hangTimes={hangTimes}
            onSelectedDateChange={onSelectedDateChange}
            ref={calendarBottomRef}
          />
        )}
      </div>
    </BottomCalendarScrollbarOffsetContainer.Provider>
  );
};

export default Calendar;

/*

// if the top calendar is out of sync with the bottom day view,
// then scroll the top to get the bottom date into view
function dayRenderBottom(arg: { view: View; date: Date; allDay?: boolean | undefined; el: HTMLElement }) {
  const { date: bottomDate } = splitDateTime(arg.date);

  if (selectedDate.getTime() !== bottomDate.getTime()) {
    console.log(`topDate: ${selectedDate.toString()}, bottomDate: ${bottomDate.toString()}`);
    setSelectedDate(bottomDate);
  }

  const diffDays = differenceInCalendarDays(bottomDate, startDate);
  if (diffDays < 0 || diffDays > visibleDaysCount) {
    const newStart = startOfWeek(bottomDate);
    setStartDate(newStart);
    log({ newStart, diffDays, visibleDaysCount });
  }
}
*/
/*
// now make sure that the newly selected date is shown in the top calendar. If it's not,
// then scroll the top calendar.
if (selected < visibleStartDate || selected > visibleEndDate) {
  const selectedWeekIndex = getWeekIndex(selected);
  const startWeekIndex = getWeekIndex(visibleStartDate);
  const endWeekIndex = getWeekIndex(visibleEndDate);

  // Normally we'd put the selected date at the top week of the visible date range,
  // but there's a special case where the user is swiping backwards from a week that's
  // only one week lower than the visible range.  In that case, we'll put the selected
  // date on the last row of the visible range.
  const desired =
    selectedWeekIndex === endWeekIndex + 1
      ? { start: startWeekIndex + 1, end: endWeekIndex + 1 }
      : { start: selectedWeekIndex, end: selectedWeekIndex + DEFAULT_WEEKS_TO_SHOW - 1 };

  setDesiredStartDate(getStartDateFromWeekIndex(desired.start));

  export function getStartDateFromWeekIndex(weekIndex: number) {
    return FIRST_SUNDAY_PLAIN_DATE.add({ weeks: weekIndex });
  }


  */
/*
const onSelectedDateChange = useCallback<CalendarMiddleProps['onSelectedDateChange']>(({ date }) => {
  const selected = date;

  // change state which will update props of the bottom calendar
  setSelectedDate(selected);

  // scroll the newly-selected date into view in the top calendar, if needed
  CalendarTopRef.current!.ensureSelectedDateIsVisible();

  // TODO: should we switch to using start date and # of days?
}, []);
*/

/*
const onScrollTopDateRange = useCallback<Exclude<CalendarTopProps['onScrollDateRange'], undefined>>(
  ({ start, end }) => {
    setVisibleStartDate(start);
    setVisibleEndDate(end);
    //      const days = differenceInCalendarDays(end, start);
    //      setVisibleDaysCount(days);
  },
  [setVisibleStartDate, setVisibleEndDate]
);
*/
