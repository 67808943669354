import { Temporal } from '@js-temporal/polyfill';
import { useRef, MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { createContainer } from 'unstated-next';

/** Return value of the useLastInteractedWith hook */
interface UseInteractedWithResult {
  /** Ref to user-defined name of the React component which the user last
   * interacted with, including any of its child elements. Will be null if no
   * tracked components (or children) have been interacted with yet. */
  lastInteractedWith: MutableRefObject<string | null>;

  /** Ref to the date/time that the last interaction was recorded */
  lastInteractionTime: MutableRefObject<Temporal.Instant | null>;
}

/**
 * React Hook to track which HTML elements the user has most recently interacted
 * with using mouse, keyboard, or touch. This hook makes it easier to avoid
 * recursion and infinite loops when one component triggers updates in another
 * component which ordinarily would trigger an update in the original component.
 * @param ref - ref for element to watch for interactions. Note that this must
 * be a real HTML element-- a `Fragment` is not sufficient.
 * @param name - when this ref (or its children) is interacted with, then the
 * shared `lastInteractedWith` value (which is accessible via this hook's return
 * object) will be set to this string.
 */
function useLastInteractedWith(args?: { ref: React.RefObject<HTMLElement>; name: string }): UseInteractedWithResult {
  const { ref, name } = args || { ref: null, name: '' };
  const container = LastInteractedWithContainer.useContainer();
  const watchingElement = useRef<HTMLElement | null>(null);
  const handler = useCallback(() => {
    const { lastInteractedWith, lastInteractionTime } = container;
    console.log(`Interacted with: ${name}`);
    lastInteractionTime.current = Temporal.Now.instant();
    lastInteractedWith.current = name || null;
  }, [name, container]);

  if (ref && !name) {
    throw new Error('Name must be defined to track interactions');
  }

  useEffect(() => {
    return () => {
      if (watchingElement.current) {
        stopWatchingForInteractions(name, watchingElement.current, handler);
        watchingElement.current = null;
      }
    };
  }, [name, watchingElement, handler]);

  useEffect(() => {
    if (ref && ref.current && !watchingElement.current) {
      watchingElement.current = ref.current;
      startWatchingForInteractions(name, watchingElement.current, handler);
    }
  });

  return container;
}

const events: (keyof HTMLElementEventMap)[] = [
  'click',
  'contextmenu',
  'dblclick',
  'drag',
  'drop',
  'focus',
  'gotpointercapture',
  'input',
  'keydown',
  'keypress',
  'keyup',
  'mousedown',
  'mouseup',
  'pointerdown',
  'pointerup',
  'touchstart',
  'touchend',
  'wheel',
];
const eventListenerOptions = {
  capture: true,
  passive: true,
};

function startWatchingForInteractions(name: string, elem: HTMLElement, handler: () => void) {
  console.log(`LastInteractedWith ${name}: adding event listeners`);
  events.forEach(event => elem.addEventListener(event, handler, eventListenerOptions));
}

function stopWatchingForInteractions(name: string, elem: HTMLElement, handler: () => void) {
  console.log(`LastInteractedWith ${name}: removing event listeners`);
  events.forEach(event => elem.removeEventListener(event, handler, eventListenerOptions));
}

function useLastInteractedWithSharedData() {
  const lastInteractedWith = useRef<string | null>(null);
  const lastInteractionTime = useRef<Temporal.Instant | null>(null);
  const result = useMemo(() => ({ lastInteractedWith, lastInteractionTime }), []);
  return result;
}

const LastInteractedWithContainer = createContainer(useLastInteractedWithSharedData);

export { LastInteractedWithContainer, useLastInteractedWith };
