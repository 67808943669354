import { useMemo, useState, useRef, Dispatch, useCallback, useEffect, CSSProperties } from 'react';
import { Temporal } from '@js-temporal/polyfill';
import { ObjectId } from 'bson';

/**
 * Compare two objects with shallow comparison, but consider Date-valued
 * properties equal if the dates have identical time/date values. Adapted from
 * react-window's shallowDiffers function.
 * @param o1 {object} First object to compare
 * @param o2 {object} Other object to compare
 */
export function dateAwareShallowCompare<T extends Record<string, unknown> | CSSProperties>(o1: T, o2: T): boolean {
  if ((!o1 && o2) || (o1 && !o2)) {
    return false;
  }
  if (!o1 && !o2) {
    return true;
  }
  for (const attribute in o1) {
    if (!(attribute in o2)) {
      return false;
    }
  }
  for (const attribute in o2) {
    const prevAttribute = o1[attribute];
    const nextAttribute = o2[attribute];
    const datesEqual =
      prevAttribute instanceof Date &&
      nextAttribute instanceof Date &&
      prevAttribute.getTime() === nextAttribute.getTime();

    if (!datesEqual && prevAttribute !== nextAttribute) {
      return false;
    }
  }
  return true;
}

/**
 * Memoizes a value and uses a custom boolean function to check if the memoized value
 * needs to be changed.  If the function returns true, then the old value is returned.
 * If the function returns false, then the new value (the value parameter) is returned.
 * @param value - value to check
 * @param predicate - `(value1, value2) => boolean` function that returns true
 * if the values are equal
 */
export function useMemoCustom<T>(value: T, predicate: (value1: T, value2: T) => boolean) {
  const ref = useRef(value);
  const old = ref.current;
  if (old === value || predicate(old, value)) {
    return old;
  } else {
    ref.current = value;
    return value;
  }
}

/**
 * Custom hook to ensure that dates with the same value are treated as the same
 * date by React.  Helps prevent re-renders caused by props with the same
 * date/time value.
 * @param date {Temporal.PlainDate} - value to use
 */
export function usePlainDateMemo<T extends Temporal.PlainDate | (Temporal.PlainDate | undefined)>(date: T) {
  const dateString = date ? date.toString() : '';
  // The ESLint rule complains that `date` isn't in the deps list, but the whole
  // point of this hook is to consider two Date instances equivalent if they
  // refer to the same value. So useMemo will depend on `dateString` and not the
  // Temporal.PlainDate object.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => date, [dateString]);
}

/**
 * Custom hook to ensure that times with the same value are treated as the same
 * date by React.  Helps prevent re-renders caused by props with the same
 * date/time value.
 * @param time {Temporal.PlainTime} - value to use
 */
export function usePlainTimeMemo<T extends Temporal.PlainTime | (Temporal.PlainTime | undefined)>(time: T) {
  const timeString = time ? time.toString() : '';
  // The ESLint rule complains that `time` isn't in the deps list, but the whole
  // point of this hook is to consider two Date instances equivalent if they
  // refer to the same  value. So useMemo will depend on `timeString` and not
  // the Temporal.PlainTime object.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => time, [timeString]);
}

/**
 * Custom hook to ensure that MongoDB ObjectId instances with the same value are
 * treated as the same by React.  Helps prevent re-renders caused by props with
 * the same value.
 * @param value {ObjectId} - value to use
 */
export function useObjectIdMemo(value: ObjectId | undefined) {
  const valueString = value ? value.toHexString() : '';
  // The ESLint rule complains that `value` isn't in the deps list, but the
  // whole point of this hook is to consider two Date instances equivalent if
  // they refer to the same  value. So useMemo will depend on `valueString` and
  // not the original object.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => value, [valueString]);
}

/** This hook guarantees that the value passed in its first invocation is always
 * the same (according to Object.is) for subsequent invocations.  It will throw
 * if a different value This can be helpful because known-invariant values can
 * be omitted from hook dependency lists or, when included in dependency lists,
 * are guaranteed never to cause a re-render.
 */
const useInvariantRef = function <T>(initialValue: T) {
  const ref = useRef(initialValue);
  if (initialValue !== ref.current) {
    throw new Error(
      'A supposed-to-be invariant value or function was changed between invocations of a hook. ' +
        "If it's an inline function, consider moving it to module scope, storing it in a ref, " +
        'or wrapping it in `useCallback`.'
    );
  }
  return ref;
};

// wrapper for Object.is that avoids typescript complaints about unbound methods
function is<T>(x: T, y: T) {
  return Object.is(x, y);
}

/**
 * Array-friendly variation of useState. Will memoize the Array so that updates
 * aren't triggered unless the array contains different values from the previous
 * state, even if the array is a different object. Lazy initialization (where
 * initial value is a function) is not supported.
 * @param initialValue {Array} - Initial array to initialize the state
 * @param isElementEqual {Function} - function that returns true if its two
 * parameters are equal, false otherwise
 */
export function useArrayState<T>(
  initialValue: Array<T>,
  isElementEqual: (a: T, b: T) => boolean = is
): [T[], Dispatch<T[]>] {
  const isEqualRef = useInvariantRef(isElementEqual);
  const parameterizedIsEqualRef = useRef((a1: T[], a2: T[]) => areArraysEqual(a1, a2, isEqualRef.current));
  return useObjectState(initialValue, parameterizedIsEqualRef.current);
}

const areArraysEqual = function <T>(a1: Array<T>, a2: Array<T>, isEqual: (a: T, b: T) => boolean = is) {
  if (!a1) {
    throw new Error('The first Array parameter is missing in `areArraysEqual`');
  }
  if (!a2) {
    throw new Error('The second Array parameter is missing in `areArraysEqual`');
  }
  if (a1.length !== a2.length) return false;
  return a1.some((unused, i) => !isEqual(a1[i], a2[i]));
};

/**
 * Temporal variation of useState. Will memoize the Temporal value so that
 * updates aren't triggered unless the date contains a different value than the
 * previous state. Lazy initialization (where initial value is a function) is
 * not supported.
 */
export function useInstantState<T extends Temporal.Instant | null | undefined>(initialValue: T): [T, Dispatch<T>] {
  return useObjectState(initialValue, InstantEquals);
}
const InstantEquals = (d1: Temporal.Instant, d2: Temporal.Instant) => d1.equals(d2);

/**
 * Temporal variation of useState. Will memoize the Temporal value so that
 * updates aren't triggered unless the date contains a different value than the
 * previous state. Lazy initialization (where initial value is a function) is
 * not supported.
 */
export function usePlainDateState<T extends Temporal.PlainDate | null | undefined>(initialValue: T): [T, Dispatch<T>] {
  return useObjectState(initialValue, plainDateEquals);
}
const plainDateEquals = (d1: Temporal.PlainDate, d2: Temporal.PlainDate) => d1.equals(d2);

/**
 * Temporal variation of useState. Will memoize the Temporal value so that
 * updates aren't triggered unless the date contains a different value than the
 * previous state. Lazy initialization (where initial value is a function) is
 * not supported.
 */
export function usePlainTimeState<T extends Temporal.PlainTime | null | undefined>(initialValue: T): [T, Dispatch<T>] {
  return useObjectState(initialValue, plainTimeEquals);
}
const plainTimeEquals = (d1: Temporal.PlainTime, d2: Temporal.PlainTime) => d1.equals(d2);

/**
 * Temporal variation of useState. Will memoize the Temporal value so that
 * updates aren't triggered unless the date contains a different value than the
 * previous state. Lazy initialization (where initial value is a function) is
 * not supported.
 */
export function useZonedDateTimeState<T extends Temporal.ZonedDateTime | null | undefined>(
  initialValue: T
): [T, Dispatch<T>] {
  return useObjectState(initialValue, zdtEquals);
}
const zdtEquals = (d1: Temporal.ZonedDateTime, d2: Temporal.ZonedDateTime) => d1.equals(d2);

/**
 * Object-friendly variation of useState which uses a custom function to
 * determine when the memoized state value should be changed. state updates (and
 * hence re-renders) are triggered only if:
 * 1. The objects are different references (according to `Object.is`)
 * AND
 * 2. Either the old or new value is null or undefined *OR* the custom `isEqual`
 *    function returns true when passed old and new values
 * Unlike `useState`, lazy initialization (where initial value is a function)
 * is not supported.
 * @param initialValue {object | null | undefined} - Initial value for the state
 * @param isEqual {function} - function that returns true if its two parameters
 * are equal, false otherwise
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function useObjectState<T extends object | (object | null) | (object | undefined) | (object | null | undefined)>(
  initialValue: T,
  isEqual: (o1: Exclude<T, null | undefined>, o2: Exclude<T, null | undefined>) => boolean
): [T, Dispatch<T>] {
  const valueRef = useRef(initialValue);
  //  if (typeof isEqual !== 'function') {
  //    throw new Error('`isEqual` must be a function');
  //  }
  const isEqualRef = useInvariantRef(isEqual);
  const [state, setState] = useState(initialValue);

  // TODO: rationalize this approach here with the (maybe?) simpler/better
  // approach used below in `useShallowCompareState`.
  const setter = useCallback(
    (d: T) => {
      if (is(d, valueRef.current)) return;
      if (
        typeof d === 'undefined' ||
        d === null ||
        typeof valueRef.current === 'undefined' ||
        valueRef.current === null ||
        !isEqualRef.current(d as Exclude<T, null | undefined>, valueRef.current as Exclude<T, null | undefined>)
      ) {
        valueRef.current = d;
        setState(d);
      }
    },
    [isEqualRef]
  );
  return [state, setter];
}

/**
 * Variation of useMemo hook for objects. Two objects with the same properties
 * will return only the original object. Objects are compared using shallow
 * comparison of their properties, with a special case for Date instance to make
 * sure that same-valued Date instances are considered equal.
 * TODO: support dependencies instead of just calling the function every time.
 * @param obj {object} - Initial value
 * @returns {[object, function]}
 */
export function useShallowCompareMemo<T extends Record<string | number, unknown> | CSSProperties>(
  value: T | (() => T)
) {
  const ref = useRef<T>(undefined as unknown as T);

  if (typeof value === 'object') {
    if (!ref.current || !dateAwareShallowCompare(value, ref.current)) {
      ref.current = value;
    }
  } else {
    const computedValue = value();
    if (!ref.current || !dateAwareShallowCompare(computedValue, ref.current)) {
      ref.current = computedValue;
    }
  }
  return ref.current;
}

/**
 * Variation of setState hook that will shallow-compare object properties and
 * only update the state (and hence trigger a render) if property values don't
 * match, even if the objects themselves are !==. Comparison has a special case
 * for Date instances to make sure that dates with the same date/time value are
 * considered equal.
 *
 * When you want to apply custom comparison logic (e.g. ignore some properties
 * when comparing), use `useObjectState` instead of this hook.
 * @param obj {object} - Initial value
 * @returns {[object, function]} [stateObject, setStateObject] - Same signature
 * as setState
 */
export function useShallowCompareState<T extends Record<string | number, unknown>>(obj: T) {
  const memoObject = useShallowCompareMemo(obj);
  return useState(memoObject);
}

/** Custom hook that executes a function on the second and subsequent renders
 * after a component is mounted, but which does not execute on the first render.
 * This is useful for responding to resizing/scrolling/focus or other UI events
 * where the event is fired once on mount (when no action needs to be taken) but
 * if the event is fired later, then some action must be taken.
 * @param callback {function} - function to execute on second and later renders
 * */
export function useIgnoreOnce<T extends (...args: unknown[]) => void>(callback: T) {
  const isFirstRender = useRef<boolean>(false);
  return (...args: unknown[]) => {
    if (!isFirstRender.current) {
      isFirstRender.current = true;
    } else {
      callback(...args);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function print(value: unknown) {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  } else if (typeof value === 'function') {
    return '[function]';
  } else {
    return `${value}`; // eslint-disable-line @typescript-eslint/restrict-template-expressions
  }
}

export function useLogIfChanged<T>(name: string, value: T) {
  const previous = useRef(value);
  if (!is(previous.current, value)) {
    console.log(`${name} changed. Old: ${print(previous.current)}, New: ${print(value)} `);
    previous.current = value;
  }
}

export function useLogUnmount(name: string) {
  useEffect(() => {
    return () => console.log(`Unmounting: ${name}`);
  }, [name]);
}

// from: https://github.com/CharlesStover/use-force-update/blob/master/src/use-force-update.ts
// Returning a new object reference guarantees that a before-and-after
//   equivalence check will always be false, resulting in a re-render, even
//   when multiple calls to forceUpdate are batched.

export function useForceUpdate(): () => void {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [, dispatch] = useState<{}>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}
