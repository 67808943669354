export const areSortedArraysEqual = <T extends number | string>(
  arr1: T[],
  arr2: T[],
  predicate?: (val1: T, val2: T) => boolean
) => {
  if (!arr1 || !arr2 || !Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((val, i) => (predicate ? predicate(val, arr2[i]) : val === arr2[i]));
};
