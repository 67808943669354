import { Binary, EJSON, ObjectId, ObjectIdLike } from 'bson';
import { ArgumentError } from './HangleExceptions';
import { Temporal } from '@js-temporal/polyfill';

/*
 * This module converts objects to and from formats that are safely convertible to JSON.
 * This is helpful for communicating between client and server without data loss.
 * We use the MongoDB Extended JSON (EJSON) format for this purpose, although the
 * serialization isn't actually going to/from MongoDB, it's going to/from AWS Lambda!
 */

const isObjectId = (o: unknown): o is ObjectId | ObjectIdLike =>
  typeof o === 'object' && o != null && ObjectId.isValid(o as ObjectId | ObjectIdLike);

const temporalTypes = [
  Temporal.Instant,
  Temporal.ZonedDateTime,
  Temporal.PlainDate,
  Temporal.PlainTime,
  Temporal.PlainDateTime,
  Temporal.PlainYearMonth,
  Temporal.PlainMonthDay,
  Temporal.Duration,
  Temporal.TimeZone,
  Temporal.Calendar,
];
const temporalStringTags = temporalTypes.map(
  t => (t.prototype as { [Symbol.toStringTag]: string })[Symbol.toStringTag]
);

type AnyTemporalType =
  | Temporal.Instant
  | Temporal.ZonedDateTime
  | Temporal.PlainDate
  | Temporal.PlainTime
  | Temporal.PlainDateTime
  | Temporal.PlainYearMonth
  | Temporal.PlainMonthDay
  | Temporal.Duration
  | Temporal.TimeZone
  | Temporal.Calendar;

function isTemporalInstance(o: unknown): o is AnyTemporalType {
  return typeof o === 'object' && temporalStringTags.includes((o as AnyTemporalType)?.[Symbol.toStringTag]);
}
function cloneTemporalInstance(o: unknown): AnyTemporalType {
  if (!isTemporalInstance(o)) throw new TypeError('Temporal instance expeccted');
  return (o.constructor as unknown as { from: (val: AnyTemporalType) => AnyTemporalType }).from(o);
}

/**
 * Clone an object and all its props recursively to get rid of any prop
 * referenced of the original object. Arrays are also cloned. Adapted from
 * https://github.com/mesqueeb/copy-anything
 *
 * @param {T} target
 * @param {Function} mapper - convert one value from one type to another
 * @returns target with replaced values
 * @export
 */
export function deepcopy<T, D = T>(target: T, mapper?: (value: unknown) => unknown): D {
  if (typeof target !== 'object' || target === null) return target as unknown as D;
  if (Array.isArray(target)) return target.map((item: unknown) => deepcopy(item, mapper)) as unknown as D;
  if (mapper) {
    const mapped = mapper(target);
    if (mapped !== undefined) return mapped as D;
  }
  // handle cloning for special object types, e.g. Temporal types and MongoDB ObjectId instances
  // If it's a Temporal type and the mapper didn't map it, then clone the instance
  if (isTemporalInstance(target)) return cloneTemporalInstance(target) as unknown as D;
  if (isObjectId(target)) return ObjectId.createFromHexString(target.toHexString()) as unknown as D;
  if (target instanceof Date) return new Date(target.getTime()) as unknown as D;
  // TODO: Map, Set, etc.

  // if it's an object and the mapper didn't handle it, then recurse
  const props = Object.getOwnPropertyNames(target);
  const result = props.reduce((carry, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const propValue = (target as Record<string, unknown>)[key];
    carry[key] = deepcopy(propValue, mapper);
    return carry;
  }, {} as Record<string, unknown>);
  return result as unknown as D;
}

/** Returns true if the value is an object but not a plain object (aka has a
 * custom prototype) */
// eslint-disable-next-line @typescript-eslint/ban-types
const isNonPlainObject = (o: unknown): o is object =>
  typeof o === 'object' &&
  o != null &&
  !Array.isArray(o) &&
  (o.constructor !== Object || Object.getPrototypeOf(o) !== Object.prototype);

/**
 * If an object is one of a whitelist of JS types, encode it into a MongoDB
 * EJSON-safe object. Encodings:
 * * Temporal.Instant => Date
 * * Temporal.ZonedDateTime => { date: Date, tz: string }
 * * Temporal.Duration => { dur: string }
 *
 * @param value object to serialize
 * @param valueType string name of the type (see type-detect package docs)
 * @returns object decoded object if the input was encoded, or the input object
 * if not
 */
export const typeSerializer = <T>(value: T) => {
  if (isObjectId(value)) return ObjectId.createFromHexString(value.toHexString());
  if (value instanceof Uint8Array || value instanceof Buffer) {
    return new Binary(value);
  }
  if (value instanceof Temporal.Instant) return new Date(value.epochMilliseconds);
  if (value instanceof Temporal.Duration) return { dur: value.toString() } as SerializedDuration;
  if (value instanceof Temporal.ZonedDateTime) {
    return {
      date: new Date(value.epochMilliseconds),
      tz: value.timeZone.toString(),
    } as SerializedZonedDateTime;
  }
  if (value instanceof Temporal.PlainDate) throw new ArgumentError('Temporal.PlainDate not supported');
  if (value instanceof Temporal.PlainTime) throw new ArgumentError('Temporal.PlainTime not supported');
  if (value instanceof Temporal.PlainDateTime) throw new ArgumentError('Temporal.PlainDateTime not supported');
  if (value instanceof Temporal.PlainYearMonth) throw new ArgumentError('Temporal.PlainYearMonth not supported');
  if (value instanceof Temporal.PlainMonthDay) throw new ArgumentError('Temporal.PlainMonthDay not supported');
  if (isNonPlainObject(value)) {
    throw new ArgumentError(`Unexpected object: ${value.toString()}`);
  }
  return undefined;
};

/**
 * If an object represents a JSON-encoded JS type, deserialize the encoded
 * object into a strongly-typed JS instance. This function performs the
 * conversion in reverse order from the list below.
 * * Temporal.Instant => Date
 * * Temporal.ZonedDateTime => { date: Date, tz: string }
 * * Temporal.Duration => { dur: string }
 *
 * @param value object to deserialize
 * @param valueType string name of the type (see type-detect package docs)
 * @returns object decoded object if the input was encoded, or the input object
 * if not
 */
export const typeDeserializer = <T>(value: T) => {
  if (isObjectId(value)) return ObjectId.createFromHexString(value.toHexString());
  if (isSerializedInstant(value)) return Temporal.Instant.fromEpochMilliseconds(value.getTime());
  if (isSerializedZonedDateTime(value)) {
    return Temporal.Instant.fromEpochMilliseconds(value.date.getTime()).toZonedDateTimeISO(value.tz);
  }
  if (isSerializedDuration(value)) return Temporal.Duration.from(value.dur);
  if (value instanceof Binary) {
    return value.buffer as Uint8Array;
  }
  if (isNonPlainObject(value)) {
    throw new ArgumentError(`Unexpected object: ${value.toString()}`);
  }
  return undefined;
};

export type SerializedZonedDateTime = {
  date: Date;
  tz: string;
};

export type SerializedDuration = {
  dur: string;
};

export type SerializedInstant = Date;

const isDate = (o: unknown): o is Date => o != null && typeof o === 'object' && o instanceof Date;

export const isSerializedZonedDateTime = (value: unknown): value is SerializedZonedDateTime =>
  typeof value === 'object' &&
  value !== null &&
  Object.keys(value).length === 2 &&
  isDate((value as { date?: Date }).date) &&
  typeof (value as { tz?: string }).tz === 'string';

export const isSerializedDuration = (value: unknown): value is SerializedDuration =>
  typeof value === 'object' &&
  value !== null &&
  Object.keys(value).length === 1 &&
  typeof (value as { dur?: string }) === 'string';

export const isSerializedInstant = (value: unknown): value is SerializedInstant => value instanceof Date;

/*
type Primitive = string | number | bigint | boolean | null | undefined;

type ReplaceType<T, TReplace, TWith, TKeep = Primitive> = T extends TReplace | TKeep
  ? T extends TReplace
    ? TWith | Exclude<T, TReplace>
    : T
  : {
      [P in keyof T]: ReplaceType<T[P], TReplace, TWith, TKeep>;
    };
*/

// Adapted from http://developingthoughts.co.uk/typescript-recursive-conditional-types/
type AnyFunction = (...args: unknown[]) => unknown;
type NonObject = number | bigint | string | boolean | symbol | undefined | null | void | AnyFunction;
type DontSerialize = NonObject | ObjectId;

export type SerializeType<T> = T extends DontSerialize
  ? T
  : T extends string // e.g. enums
  ? T
  : T extends Uint8Array
  ? Binary
  : T extends Temporal.Instant
  ? SerializedInstant
  : T extends Temporal.ZonedDateTime
  ? SerializedZonedDateTime
  : T extends Temporal.Duration
  ? SerializedDuration
  : T extends Array<infer U>
  ? Array<SerializeType<U>>
  : SerializeRecord<T>;

type SerializeRecord<T> = {
  [K in keyof T]: T[K] extends DontSerialize ? T[K] : SerializeType<T[K]>;
};

export type DeserializeType<T> = T extends DontSerialize
  ? T
  : T extends string // e.g. enums
  ? T
  : T extends Binary
  ? Uint8Array
  : T extends SerializedInstant
  ? Temporal.Instant
  : T extends SerializedZonedDateTime
  ? Temporal.ZonedDateTime
  : T extends SerializedDuration
  ? Temporal.Duration
  : T extends Array<infer U>
  ? Array<DeserializeType<U>>
  : DeserializeRecord<T>;

type DeserializeRecord<T> = {
  [K in keyof T]: T[K] extends DontSerialize ? T[K] : DeserializeType<T[K]>;
};

export const serializeTypes = <T>(o: T): SerializeType<T> => {
  if (typeof o === 'undefined') return o as SerializeType<T>;
  if (o === null) return o as SerializeType<T>;
  if (typeof o !== 'object') throw new ArgumentError(`Invalid type: ${typeof o}`);
  return deepcopy(o, typeSerializer);
};

export const deserializeTypes = <T>(o: T): DeserializeType<T> => {
  if (typeof o === 'undefined') return o as DeserializeType<T>;
  if (o === null) return o as DeserializeType<T>;
  if (typeof o !== 'object') throw new ArgumentError(`Invalid type: ${typeof o}`);
  return deepcopy(o, typeDeserializer);
};

/**
 * Used on both client and back-end to serialize a JS object tree into MongoDB
 * extended JSON: valid JSON that serializes Dates, RegExps, etc. This allows us
 * to, for example, pass native JS Date objects from client code into a Lambda
 * function.
 *
 * @param o - object tree
 * @returns object that can be losslessly converted to JSON
 */
export const serialize = <T extends Record<string, unknown>>(o: T): Record<string, unknown> => {
  if (o == null || typeof o !== 'object') throw new ArgumentError(`Invalid type: ${JSON.stringify(o)}`);
  return EJSON.serialize(o);
};

/**
 * Used only on the client to convert JSON-compatible content (server responses)
 * into its original JS types.
 *
 * @param o - object tree from server response
 * @returns - object with original JS types
 */
export const deserialize = <T extends Record<string, unknown>>(o: T): Record<string, unknown> => {
  if (o == null || typeof o !== 'object') throw new ArgumentError(`Invalid type: ${JSON.stringify(o)}`);
  const result = EJSON.deserialize(o);
  if (Array.isArray(result)) {
    throw new ArgumentError('Unexpected array type');
  }
  if (typeof result !== 'object' || result == null) {
    throw new ArgumentError(`Invalid non-object type: ${typeof result}`);
  }
  return result;
};

// export const serializeAndStringify = (o: Record<string, unknown>): string => EJSON.stringify(o);

/**
 * Used in back end Lambda handler to deserialize input passed from
 * client into JS objects that the Lambda function can use.
 * @param s - serialized input
 * @returns deserialized objects
 */
export const parseAndDeserialize = (s: string): Record<string, unknown> => {
  const original = EJSON.parse(s);
  if (Array.isArray(original)) {
    throw new ArgumentError('Unexpected array type');
  }
  if (typeof original !== 'object' || original == null) {
    throw new ArgumentError(`Invalid non-object type: ${typeof original}`);
  }
  const result = deepcopy(original, typeDeserializer);
  return result;
};

/*

export const serializeToBuffer = (o: Record<string, unknown>): Buffer => BSON.serialize(o);

export const deserializeFromBuffer = (b: Buffer): Record<string, unknown> =>
  BSON.deserialize(b) as Record<string, unknown>;

*/
