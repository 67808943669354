import { CSSProperties } from 'react';
import { imageToDataUrl } from './imageManager';
import { ObjectId } from 'bson';
import { useObjectIdMemo } from './useHooks';
import UserStateContainer from './UserStateContainer';
import { useMemo } from 'react';

interface UserPictureProps {
  name: string | null | undefined;
  pictureUrl: string | null | undefined;
  pictureData?: Uint8Array | null | undefined;
  style?: CSSProperties;
  size?: number;
  userId?: ObjectId;
}

const UserPicture = (props: UserPictureProps) => {
  const { size, style: originalStyle, pictureData, pictureUrl, name, userId: userIdOriginal } = props;
  const userId = useObjectIdMemo(userIdOriginal);
  const { getPictureForUser } = UserStateContainer.useContainer();
  const fetchedPictureUrl = userId && getPictureForUser(userId);
  const style = useMemo(
    () => ({ borderRadius: '5px', marginRight: '15px', ...(originalStyle || {}) }),
    [originalStyle]
  );

  let src: string | undefined;
  if (fetchedPictureUrl) {
    src = fetchedPictureUrl;
  } else if (pictureData) {
    src = imageToDataUrl({ image: pictureData, mimeType: 'image/jpeg' });
  } else if (pictureUrl) {
    src = pictureUrl;
  } else {
    src = undefined;
  }

  return <img alt={name || undefined} src={src} width={size ?? 50} height={size ?? 50} style={style} />;
};

export default UserPicture;
