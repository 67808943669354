import './whyDidYouRender'; // need this first, per https://github.com/welldone-software/why-did-you-render#installation
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ConfigureAWS from './ConfigureAWS';

declare global {
  interface Window {
    LOG_LEVEL: string; // for AWS logs
  }
}
window.LOG_LEVEL = 'INFO';

ConfigureAWS.configure();

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
