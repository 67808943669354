import { useCallback, MouseEventHandler, ReactNode } from 'react';
export default function IgnoreSafariMiddleClicks({ children }: { children: ReactNode }) {
  const onClick = useCallback<MouseEventHandler>(e => {
    if (e.button !== 0) {
      // Prevent middle clicks from being handled by click handlers on Safari
      // browsers, in order to work around this 12-year-old WebKit bug:
      // https://bugs.webkit.org/show_bug.cgi?id=22382
      e.stopPropagation();
    }
  }, []);
  return <div onClickCapture={onClick}>{children}</div>;
}
