import { useState, useEffect, ReactNode } from 'react';

interface LoaderProps {
  effect: () => Promise<void>;
  children?: ReactNode;
}
// adapted from https://github.com/facebook/react/issues/14326#issuecomment-441680293
export default function Loader(props: LoaderProps) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let hasUnmounted = false;
    const loader = async () => {
      if (!loading) {
        setLoading(true);
        await props.effect();
        if (!hasUnmounted) {
          setLoading(false);
        }
      }
    };
    // this is intentionally fire-and-forget, hence the rule disable
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loader();
    return () => {
      hasUnmounted = true;
    };
  }, [loading, props]);
  return <>{props.children}</>;
}
