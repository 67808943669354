import { encode as base64Encode } from 'base64-arraybuffer';
import { Binary } from 'bson';

const longStringShortener = (o: string) => (o.length > 300 ? `${o.slice(0, 300)}...` : o);
type HasToJSON = { toJSON: (key: string | number | symbol | undefined) => string };
function hasToJSON(o: unknown): o is HasToJSON {
  return typeof (o as HasToJSON)?.toJSON === 'function';
}

/**
 * Custom stringifier intended for logging and similar use cases (e.g. error messages)
 *
 * Main goal of this function is to provide concise, readable logs, including shortening long
 * strings and arrays and removing unnecessary quotes.
 * */
export function stringify(o: unknown): string {
  if (typeof o === 'string') return `"${longStringShortener(o)}"`;
  if (typeof o !== 'object' || o === null) return String(o);
  if (o instanceof ArrayBuffer || o instanceof Uint8Array || o instanceof Buffer)
    return `Binary: ${longStringShortener(base64Encode(o))}`;
  if (o instanceof Binary) return `Binary: ${longStringShortener(base64Encode(o.buffer))}`;
  if (hasToJSON(o)) return longStringShortener(o.toJSON(undefined));
  if (Array.isArray(o)) {
    return `[${o
      .slice(0, 20)
      .map(elem => stringify(elem))
      .join(', ')}${o.length > 20 ? ',...' : ''}]`;
  }
  // If we get here, it's a non-array object w/o a custom toJSON().
  // Emit JSON-like output but without quoted keys.
  return `{${Object.keys(o)
    .map(k => `${k}:${stringify((o as Record<string, unknown>)[k])}`)
    .join(', ')}}`;
}
