import { ObjectId } from 'bson';
import { ArgumentError, InternalServerError } from './HangleExceptions';
import { isRunningOnServer } from './isRunningOnServer';

export type NewIdMap = {
  [oldId: string]: ObjectId;
};

export function replaceNewId(currentId: ObjectId, newIdMap: NewIdMap): ObjectId {
  if (!isRunningOnServer) {
    throw new InternalServerError('ReplaceNewId can only be called on the server');
  }
  const replacedId = new ObjectId();
  Object.keys(newIdMap).forEach(oldId => {
    if (currentId.equals(newIdMap[oldId])) {
      throw new InternalServerError(`replaceNewId shouldn't be called on an ID that's already been replaced`);
    }
  });
  newIdMap[currentId.toHexString()] = replacedId;
  return replacedId;
}

/**
 * The HangleId type is essentially the same as a MongoDB ObjectId, but with one
 * important difference: it differentiates a new ID from an existing one.
 *
 * This distinction matters because, for security reasons, we don't want to
 * store unique IDs that were created on the client side. A malicious client
 * could, for example, create IDs that match other existing IDs, which (in
 * theory at least) could cause problems later.
 *
 * Another helpful side effect of having new IDs be differentiated from existing
 * ones is that it prevents a new ID from being used in a place (e.g. an update
 * method) where an existing ID is required, and vice versa. Therefore, we'll
 * have the client create new IDs but those IDs will get replaced on the server
 * with different IDs. If this happens, then the server will return mapping info
 * to help the client know which IDs were replaced.
 */
export default class HangleId extends ObjectId {
  /**
   * Create a new HangleId instance
   * @param {(string|number|ObjectId)} id Can be a 24 byte hex string, 12 byte binary string or a Number.
   */
  constructor(id?: string | number | ObjectId, okForServer = false) {
    super(id);
    this._isNewId = okForServer || id === undefined;
    if (isRunningOnServer && !okForServer) {
      throw new InternalServerError('Cannot use the HangleId class on the server, except when simulating new IDs');
    }
  }
  protected readonly _isNewId: boolean;
  public static isNewId(o: ObjectId) {
    if (!ObjectId.isValid) {
      throw new ArgumentError('Object ID is not valid');
    }
    return o instanceof HangleId && o._isNewId;
  }
  public static cloneIntoNewIdPlaceholder(o: ObjectId) {
    return new HangleId(o, true);
  }
}
