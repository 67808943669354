import { Temporal } from '@js-temporal/polyfill';
import { memo } from 'react';
import { useShallowCompareMemo } from './useHooks';

const dateFormatOptions: {
  [name: string]: Intl.DateTimeFormatOptions;
} = {
  long: { year: 'numeric', month: 'long', day: 'numeric' },
  medium: { year: 'numeric', month: 'short', day: 'numeric' },
  short: { year: 'numeric', month: 'numeric', day: 'numeric' },
};
const toDateString = (d: Temporal.PlainDate, options: Intl.DateTimeFormatOptions) =>
  d.toLocaleString(undefined, options);

const CalendarDateRange = memo(function CalendarDateRange(props: {
  startDate: Temporal.PlainDate;
  endDate?: Temporal.PlainDate;
  onClick?: (date: Temporal.PlainDate) => void;
}) {
  const { onClick } = props;
  const startString = props.startDate.toString();
  const endString = props.endDate && props.endDate.toString();

  const dateStrings = useShallowCompareMemo(() => {
    const startDate = Temporal.PlainDate.from(startString);
    const endDate = endString && Temporal.PlainDate.from(endString);
    const dateStrings = Object.keys(dateFormatOptions).reduce((newObj, name) => {
      const formatOptions = dateFormatOptions[name];
      newObj[name] =
        !endDate || startDate.equals(endDate)
          ? toDateString(startDate, formatOptions)
          : `${toDateString(startDate, formatOptions)} &mdash; ${toDateString(startDate, formatOptions)}`;
      return newObj;
    }, {} as Record<string, string>);

    return dateStrings;
  });

  return (
    <h2 className="calendar-date-range" onClick={() => onClick && onClick(props.startDate)}>
      {Object.entries(dateStrings).map(([name, dateString]) => (
        <span key={name} className={`calendar-date-range-${name}`}>
          {dateString}
        </span>
      ))}
    </h2>
  );
});

export default CalendarDateRange;
