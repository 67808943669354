import { useState, useMemo, useRef, forwardRef, useCallback, CSSProperties } from 'react';
import { HangTime, HangTimeRuntype, addToHangTimes } from './HangTime';
import { Button } from 'reactstrap';
import { Form, Field, FieldMetaState } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import Loader from './Loader';
import { FieldContainer, FieldLabel } from './FormFieldUtils';
import MessageBox from './MessageBox';
import TagEditor, { toTagEditorOption, TagEditorOption, tagOptionsValidator } from './TagEditor';
import { getNewValuePlaceholder, isNewValuePlaceholder } from './newValuePlaceholder';
import TabHeader, { TabHeaderType } from './TabHeader';
import DatePicker, { ReactDatePickerProps } from './DatePicker';
import DurationPicker, {
  DurationPickerProps,
  DurationPickerFieldValue,
  toDurationPickerFieldValue,
} from './DurationPicker';
import TimePicker, { TimePickerProps, TimePickerFieldValue } from './TimePicker';
import { ArgumentError, InternalServerError, NotImplementedError } from './HangleExceptions';
import { toLongDate, formatTimeNoSeconds, DEFAULT_TIME, toYYYYMMDD } from './dateUtils';
import { ModalContainer, ModalCloser } from './ContextModal';
import CalendarTop, { CalendarTopRefHandles } from './CalendarTop';
import { PossibleMatch } from './Notification';
import TagsContainer from './TagsContainer';
import DisplayStateMachine, { DisplayStateDriver } from './DisplayStateMachine';
import UserStateContainer from './UserStateContainer';
import UserPicture from './UserPicture';
import FriendNames from './FriendNames';
import { Friend } from './Friend';
import LoadingTextWithSpinner from './LoadingTextWithSpinner';
import styled from '@emotion/styled';
import { hangTimeFormat } from './HangTime';
import { HangType, Hang, Invite } from './Hang';
import Confetti from './Confetti';
import { Temporal } from '@js-temporal/polyfill';

type OrNull<T> = { [P in keyof T]: T[P] | null };

type HangTimeViewModel = Omit<HangTime, 'startTime' | 'minutes'> & Pick<OrNull<HangTime>, 'startTime' | 'minutes'>;

export interface HangTimeEditProps {
  hangTime: HangTime;
}

const isValidHangTime = (hangTime: HangTimeViewModel): hangTime is HangTime => {
  const validationResult = HangTimeRuntype.validate(hangTime);
  if (!validationResult.success) {
    throw new ArgumentError(validationResult.message);
  }
  return true;
};

// This 3rd-party date picker component has a weird setup where the initial value
// is a Date object, but the value set by the user is a string.
// This confuses the react-final-form library which seems to expect the same type from
// both initial and eventual values of a form field.
// So we wrap the component with props that are more permissive when it comes to types.
interface ReactDatePickerPropsDateOK extends Omit<ReactDatePickerProps, 'value' | 'onChange'> {
  value: Temporal.PlainDate | string | undefined | null;
  // remove [Date, Date] (for range picking) from types to make handler types OK.
  onChange(date: Temporal.PlainDate | null, event: React.SyntheticEvent<unknown> | undefined): void;
}
const DatePickerAdapter = (props: ReactDatePickerPropsDateOK) => {
  const val = props.value;
  let propDate: Temporal.PlainDate | null;
  if (typeof val === 'object' && val instanceof Temporal.PlainDate) {
    propDate = val;
  } else if (val == null) {
    propDate = null;
  } else {
    throw new ArgumentError(`Invalid date: ${val}`);
  }
  if (propDate == null) propDate = Temporal.Now.plainDateISO();
  const legacyDate = new Date(propDate?.toZonedDateTime(Temporal.Now.timeZone()).epochMilliseconds);
  return (
    <DatePicker
      className="form-control custom-select"
      selected={legacyDate}
      onChange={(date: Date | null, event) => {
        const plainDate =
          date instanceof Date
            ? Temporal.Instant.fromEpochMilliseconds(date.getTime())
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDate()
            : null;
        props.onChange(plainDate, event);
      }}
    />
  );
};

type OtherDaysPickerProps = {
  selectedDate: Temporal.PlainDate;
  onChange: (value: Temporal.PlainDate[]) => void;
};

const OtherDaysPickerAdapter = forwardRef((props: OtherDaysPickerProps, ref: React.Ref<CalendarTopRefHandles>) => {
  const { onChange } = props;
  const onMultiSelectChange = useCallback(({ dates }) => onChange(dates), [onChange]);

  return (
    <CalendarTop selectedDate={props.selectedDate} isMulti={true} onMultiSelectChange={onMultiSelectChange} ref={ref} />
  );
});

const TimePickerAdapter = (props: TimePickerProps) => (
  <TimePicker value={props.value} onChange={value => props.onChange(value)} />
);

const DurationPickerAdapter = (props: DurationPickerProps) => (
  <DurationPicker value={props.value} onChange={value => props.onChange(value)} />
);

interface FormValues {
  date: Temporal.PlainDate;
  startTime: TimePickerFieldValue;
  minutes: DurationPickerFieldValue;
  tags: TagEditorOption[];
  otherDays: Temporal.PlainDate[];
}

/**
 * Convert a year-first string to a Temporal.ZonedDateTime. Delimiters can be
 * "/" or "-" between date parts, and " " or "T" between time parts.
 *
 * @param dateString string to parse
 * @returns Temporal.ZonedDateTime object if parsed successfully
 */
function parseDateYearFirst(dateString: string) {
  // Support formats like 2020-12-25T12:34, 2020/12/25 12:34, etc.
  // Transform a "SQL" format like "2020/12/25 12:34" to an ISO-compatible
  // format like 2020-12-25T12:34
  const isoString = dateString.replace(/\//g, '-').replace(/ /g, 'T');
  try {
    const pdt = Temporal.PlainDateTime.from(isoString);
    const zdt = pdt.toZonedDateTime(Temporal.Now.timeZone());
    return zdt;
  } catch (e) {
    return null;
  }
}

const isDateObjectOrStringValid = (value: string | Temporal.PlainDate) => {
  if (!value) return 'Invalid Date';
  const isValid = value instanceof Temporal.PlainDate ? value : parseDateYearFirst(value);
  return isValid ? undefined : 'Invalid Date';
};

export const newHangTimeFromDay = (date: Temporal.PlainDate) => {
  const hangTime: HangTime = {
    h: getNewValuePlaceholder(),
    startTime: date.toZonedDateTime({
      timeZone: Temporal.Now.timeZone(),
      plainTime: DEFAULT_TIME,
    }), // Default to noon. TODO: Make this configurable?
    minutes: 60, // default to an hour. TODO: should we make this user-configurable?
    tags: [],
    name: null,
    hangId: null,
  };
  return hangTime;
};
export const newHangTimeFromDayAndTime = (plainDate: Temporal.PlainDate, plainTime: Temporal.PlainTime) => {
  const noSeconds = plainTime.round({ smallestUnit: 'minute', roundingMode: 'trunc' });
  const startTime = plainDate.toZonedDateTime({ timeZone: Temporal.Now.timeZone(), plainTime: noSeconds });
  const hangTime: HangTime = {
    h: getNewValuePlaceholder(),
    minutes: 60, // default to an hour. TODO: should we make this user-configurable?
    startTime,
    tags: [],
    name: null,
    hangId: null,
  };
  return hangTime;
};

const isStartTimeValid = (value: TimePickerFieldValue) => {
  return value !== null && value.parsed && value.parsed instanceof Temporal.PlainTime
    ? undefined
    : 'Invalid Start Time';
};

const isMinutesValid = (value: DurationPickerFieldValue) =>
  value !== null && typeof value.parsed === 'number' && value.parsed >= 1 && value.parsed <= 60 * 24 * 30
    ? undefined
    : 'Invalid Duration';

function ValidationErrorMessage<FieldValue>(props: { meta: FieldMetaState<FieldValue> }) {
  if (!props.meta.error || !props.meta.touched) {
    return null;
  }
  return <div style={validationErrorMessageStyle}>{props.meta.error}</div>;
}
const validationErrorMessageStyle = { color: 'red', marginTop: 7 } as const;

const useUserState = () => {
  const {
    userState,
    onUpdateHangTime,
    onCreateHangTimes,
    onDeleteHangTime,
    onCreateHang,
    getPossibleMatches,
    getMatches,
  } = UserStateContainer.useContainer();
  return {
    userState,
    onUpdateHangTime,
    onCreateHangTimes,
    onDeleteHangTime,
    onCreateHang,
    getPossibleMatches,
    getMatches,
  };
};

enum HangTimeEditState {
  EDITING,
  SAVING_EDITS,
  GET_POSSIBLE_MATCHES,
  POSSIBLE_MATCHES,
  GET_MATCHES,
  MATCHES,
  SAVING_HANG,
  CLOSE_MODAL,
  DONE,
}

enum MatchDecision {
  ACCEPT,
  DECLINE,
  NEVER,
  // TODO: there are probably other choices
}

const HangTimeEdit = (props: HangTimeEditProps) => {
  const [hangTimeEditState, setHangTimeEditState] = useState(HangTimeEditState.EDITING);
  const hangTime: Readonly<HangTime> = props.hangTime;
  const stateContainer = useUserState();
  const { friends, hangTimes } = stateContainer.userState;
  const isNew = !hangTime.h || isNewValuePlaceholder(hangTime.h);
  const [possibleMatches, setPossibleMatches] = useState<PossibleMatch[]>([]);
  const [matches, setMatches] = useState<Invite[]>([]);
  const { addModal } = ModalContainer.useContainer();

  const [newHangTimes, setNewHangTimes] = useState([] as HangTime[]);

  /** User isn't creating a Hang, so just save the new HangTime(s) */
  const onFinalSaveHangTimes = async () => {
    // If we're closing the dialog but the user has already decided to save the
    // hangtime (meaning they clicked "Save" on the first step of the wizard)
    // then save the hangtime now. We don't save it earlier because we don't
    // want to trigger other users' notifications if this hangtime will
    // immediately be taken by a new Hang. Only if a Hang is not created will we
    // save the hangtimes here. If a Hang is created, then hangtime(s) will be
    // created on the back end when the Hang is created.
    if (newHangTimes) {
      setHangTimeEditState(HangTimeEditState.SAVING_EDITS);
      const saveMethod = isNew
        ? () => stateContainer.onCreateHangTimes(newHangTimes)
        : () => stateContainer.onUpdateHangTime(newHangTimes[0]);
      if (await saveMethod()) {
        setHangTimeEditState(HangTimeEditState.CLOSE_MODAL);
      } else {
        // saving failed, so go back to editor
        setHangTimeEditState(HangTimeEditState.EDITING);
      }
    }
  };

  /** User is creating a Hang, so save it. The user's hangtimes array will get
   * updated as part of Hang creation */
  const onFinalCreateHang = async (hang: Hang) => {
    setHangTimeEditState(HangTimeEditState.SAVING_HANG);
    if (await stateContainer.onCreateHang(hang)) {
      // TODO: when we can save multiple matches per dialog, then need to split
      // the "accept all hangs" case from the "accept only one hang" case.
      // In the latter, we should not switch to the CLOSE_MODAL state.
      onClose();
    } else {
      // saving failed, so go back to viewing matches
      // TODO: consider just saving the hangtime here, so that problems
      // with matching won't prevent users from adding new hangtimes.
      setHangTimeEditState(HangTimeEditState.MATCHES);
    }
  };

  /** Fetch a list of people who might be free at the new or updated hangtime(s) */
  const getPossibleMatches = async (h: HangTime[]) => {
    setNewHangTimes(h);
    setHangTimeEditState(HangTimeEditState.GET_POSSIBLE_MATCHES);
    const result = await stateContainer.getPossibleMatches({ hangTimes: h, maxCount: 20 });
    if (result && result.length) {
      setPossibleMatches(result);
      setHangTimeEditState(HangTimeEditState.POSSIBLE_MATCHES);
    } else {
      // couldn't get any possible matches, so just save the hangtime(s)
      await onFinalSaveHangTimes();
    }
  };

  /** Given the list of possible matches, see if any of them actually match */
  const getMatches = async (possibleMatches: PossibleMatch[]) => {
    setHangTimeEditState(HangTimeEditState.GET_MATCHES);
    const result = await stateContainer.getMatches({ possibleMatches });
    if (result && result.length) {
      setMatches(result);
      setHangTimeEditState(HangTimeEditState.MATCHES);
    } else {
      // couldn't get any matches, so just save the hangtime(s)
      await onFinalSaveHangTimes();
    }
  };

  const onAdd = async (h: HangTime[]) => {
    try {
      // test to make sure that there aren't overlaps or other obvious problems
      addToHangTimes(hangTimes, h, false);
    } catch (e) {
      addModal((e as Error).message);
      return;
    }
    return getPossibleMatches(h);
  };
  const onUpdate = async (h: HangTime) => {
    if (h.hangId) {
      throw new NotImplementedError('Updating hangs is not supported yet');
    }

    try {
      // test to make sure that there aren't overlaps or other obvious problems
      addToHangTimes(hangTimes, [h], false);
    } catch (e) {
      addModal((e as Error).message);
      return;
    }
    return getPossibleMatches([h]);
  };

  const onDelete = async () => {
    // TODO: need Suspense here in case this takes a while!
    setHangTimeEditState(HangTimeEditState.SAVING_EDITS);
    if (await stateContainer.onDeleteHangTime(props.hangTime)) {
      setHangTimeEditState(HangTimeEditState.CLOSE_MODAL);
    } else {
      setHangTimeEditState(HangTimeEditState.EDITING);
    }
  };

  const onClose = () => setHangTimeEditState(HangTimeEditState.CLOSE_MODAL);

  const onMatchDecide = async (invite: Invite, matchDecision: MatchDecision) => {
    if (matchDecision !== MatchDecision.ACCEPT) {
      // User didn't accept the hang, so instead we'll simply save the hangtime
      // that the user "saved" (from the UI's perspective) at the start of this
      // workflow.
      await onFinalSaveHangTimes();
      return;
    }

    const otherFriend = stateContainer.userState.friends.find(f => f.u.equals(invite.sentBy));
    if (!otherFriend) {
      throw new InternalServerError(`Cannot find other friend ${invite.sentBy.toHexString()}`);
    }

    const nameToShow = (person: { firstName: string | null; name: string }) => person.firstName ?? person.name;
    const name = `${nameToShow(stateContainer.userState.profile!)} and ${nameToShow(otherFriend)} hang out`;
    const owners = [stateContainer.userState._id, otherFriend.u];
    const hang: Hang = {
      _id: getNewValuePlaceholder(),
      capacity: null,
      chat: null,
      deleted: false,
      hangType: HangType.AUTO,
      invites: [invite],
      location: {
        name: 'TBD',
        address: null,
        latLong: null,
      },
      minutes: invite.minutes,
      startTime: invite.startTime,
      tags: invite.tags,
      name,
      owners,
      version: 1,
    };

    // finally, save that new hang
    await onFinalCreateHang(hang);
  };

  const acceptPossibleMatches = async (possibleMatches: PossibleMatch[]) => {
    // TODO: start here. Need to turn args into input for the getMatches method above.
    await getMatches(possibleMatches);
  };

  const drivers: DisplayStateDriver<HangTimeEditState>[] = [
    {
      states: HangTimeEditState.EDITING,
      content: (
        <EditorInner
          onAdd={onAdd}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onClose={onClose}
          hangTime={hangTime}
          isNew={isNew}
        />
      ),
      overlayDrivers: {
        states: [HangTimeEditState.SAVING_EDITS, HangTimeEditState.SAVING_HANG],
        content: <LoadingTextWithSpinner text="Saving..." />,
      },
    },
    {
      states: HangTimeEditState.POSSIBLE_MATCHES,
      content: (
        <PossibleMatchesDisplay
          onClose={onClose}
          possibleMatches={possibleMatches}
          friends={friends}
          acceptPossibleMatches={acceptPossibleMatches}
        />
      ),
      overlayDrivers: {
        states: HangTimeEditState.GET_POSSIBLE_MATCHES,
        content: <LoadingTextWithSpinner text="Finding Potential Matches..." />,
      },
    },
    {
      states: HangTimeEditState.MATCHES,
      content: <MatchDisplay onClose={onClose} invites={matches} friends={friends} onMatchDecide={onMatchDecide} />,
      overlayDrivers: {
        states: HangTimeEditState.GET_MATCHES,
        content: <LoadingTextWithSpinner text="Finding Matches..." />,
      },
    },
    {
      states: HangTimeEditState.CLOSE_MODAL,
      content: <ModalCloser />,
    },
    {
      states: HangTimeEditState.DONE,
      content: () => (
        <Redirect to={{ pathname: '/times/day/' + toYYYYMMDD(hangTime.startTime), search: '', hash: '' }} />
      ),
    },
  ];

  return <DisplayStateMachine drivers={drivers} displayState={hangTimeEditState} />;
};

export default HangTimeEdit;

const XButton = styled.button({
  marginLeft: 'auto',
  marginRight: 5,
  backgroundColor: '#0000',
  border: 0,
  webkitAppearance: 'none',
  height: 50,
  width: 50,
  lineHeight: '50px',
  borderRadius: 50,
  transition: 'background-color 0.25s ease-in-out',
  '&:hover': {
    height: 50,
    width: 50,
    backgroundColor: '#eee',
  },
});

const RemoveFriendFromListButton = (props: { onClick: () => void }) => (
  <XButton type="button" onClick={props.onClick} className="close" aria-label="Remove">
    <span aria-hidden="true">×</span>
  </XButton>
);

const FriendRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '140%',
  fontWeight: 'bold',
  color: 'purple',
  padding: '11px 15px',
  borderBottom: '1px solid #e5e5e5',
  lineHeight: 'initial',
});

const FriendMatchContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '11px 15px',
});

enum PossibleMatchDisplayStates {
  SHOWN,
  HIDING,
  HIDDEN,
}

function OneFriendPossibleMatch(props: {
  friend: Friend;
  removeFriendFromMatchList: (friend: Friend) => void;
  style?: CSSProperties;
}) {
  const { friend, removeFriendFromMatchList, style } = props;
  const remover = useCallback(() => {
    removeFriendFromMatchList(friend);
    setDisplayState(PossibleMatchDisplayStates.HIDDEN);
  }, [friend, removeFriendFromMatchList]);
  const [displayState, setDisplayState] = useState<PossibleMatchDisplayStates>(PossibleMatchDisplayStates.SHOWN);
  const drivers: DisplayStateDriver<PossibleMatchDisplayStates>[] = [
    {
      states: PossibleMatchDisplayStates.SHOWN,
      content: () => (
        <FriendRow style={style}>
          <UserPicture name={friend.name} pictureUrl={friend.pictureUrl} userId={friend.u} />
          {friend.name}
          <RemoveFriendFromListButton onClick={remover} />
        </FriendRow>
      ),
    },
    { states: PossibleMatchDisplayStates.HIDDEN, content: null },
  ];
  return <DisplayStateMachine drivers={drivers} displayState={displayState}></DisplayStateMachine>;
}

function PossibleMatchesDisplay(props: {
  onClose: () => void;
  possibleMatches: PossibleMatch[];
  friends: Friend[];
  acceptPossibleMatches: (possibleMatches: PossibleMatch[]) => void;
}) {
  const { onClose, possibleMatches, friends, acceptPossibleMatches } = props;

  // keep track of which friends the user has chosen to omit.
  const friendIdsToExclude = useRef([] as Friend['u'][]);

  // each entry in possibleMatches contains a hangtime and an array of friends
  // who may be free at that hangtime. Let's collapse this into a single
  // deduped list of friends that we can show to the user so they can optionally
  // exclude some (or none) of those friends before proceeding to check for
  // matches.
  const friendsByFriendId = friends.reduce((acc, f) => acc.set(f.u.toHexString(), f), new Map<string, Friend>());
  const friendsToShow = [
    ...possibleMatches.reduce((acc, pm) => {
      pm.friendIds.forEach(f => {
        const friend = friendsByFriendId.get(f.toHexString());
        if (friend) {
          acc.add(friend);
        } else {
          console.log(`User ${f.toHexString()} was sent as a possible match. This user is not your friend. Ignoring.`);
        }
      });
      return acc;
    }, new Set<Friend>()),
  ];

  // TODO: how to deal with the case where there's no matches remaining?
  // Should just save the hangtime in that case.

  /** starting with the possible matches that were passed into this component, 
      strip out all friends that the user has chosen to omit. */
  const filterPossibleMatches = useCallback(
    () =>
      possibleMatches.map(pm => ({
        hangTime: pm.hangTime,
        friendIds: pm.friendIds.filter(f => !friendIdsToExclude.current.find(u => u.equals(f))),
      })),
    [possibleMatches]
  );

  const removeFriendFromMatchList = useCallback((friend: Friend) => friendIdsToExclude.current.push(friend.u), []);

  const oneFriendPossibleMatchContainerStyle = { maxHeight: 500, overflow: 'auto' };
  const fieldContainerStyle = {
    padding: '10px 15px',
    backgroundColor: '#eee',
    marginBottom: 0,
    marginTop: 0,
    borderRadius: '0 0 5px 5px',
  } as const;

  return (
    <>
      <TabHeader type={TabHeaderType.Highlight} isModal={true} closeModal={onClose}>
        These friends may be free at this time.
        <br />
        Remove any you DON'T want to hang with.
      </TabHeader>
      <div>
        <div style={oneFriendPossibleMatchContainerStyle}>
          {friendsToShow.map(friend => (
            <OneFriendPossibleMatch
              friend={friend}
              removeFriendFromMatchList={removeFriendFromMatchList}
              key={friend.f.toHexString()}
            />
          ))}
        </div>
        <FieldContainer isLastInModal style={fieldContainerStyle}>
          <Button color="success" onClick={() => acceptPossibleMatches(filterPossibleMatches())}>
            Save
          </Button>
        </FieldContainer>
      </div>
    </>
  );
}

const FriendMatchContainerInner = styled.div({
  display: 'flex',
  flexDirection: 'column',
});
const FriendMatchContainerText = styled.div({
  lineHeight: '1.5',
  marginBottom: '15px',
});
const UserPictureFlexWrapper = styled.div({
  flex: '1 0 auto',
});

function OneFriendMatch(props: {
  invite: Invite;
  friend: Friend;
  onMatchDecide: (invite: Invite, matchDecision: MatchDecision) => void;
}) {
  const { invite, friend, onMatchDecide } = props;
  const hangTimeText = hangTimeFormat(invite);

  return (
    <FriendMatchContainer>
      <UserPictureFlexWrapper>
        <UserPicture name={friend.name} pictureUrl={friend.pictureUrl} size={75} userId={friend.u} />
      </UserPictureFlexWrapper>
      <FriendMatchContainerInner>
        <FriendMatchContainerText>
          Hang with <FriendNames friends={[friend]} friendIds={[friend.u]} conjunction="and" /> on {hangTimeText}?
        </FriendMatchContainerText>
        <div>
          <Button onClick={() => onMatchDecide(invite, MatchDecision.ACCEPT)}>Yep!</Button>{' '}
          <Button onClick={() => onMatchDecide(invite, MatchDecision.DECLINE)}>Nope</Button>
        </div>
      </FriendMatchContainerInner>
    </FriendMatchContainer>
  );
}

function MatchDisplay(props: {
  onClose: () => void;
  invites: Invite[];
  /** The current user's friend list.  Used to get names, pictures, etc. */
  friends: Friend[];
  onMatchDecide: (invite: Invite, matchDecision: MatchDecision) => void;
}) {
  const { onClose, invites, friends, onMatchDecide } = props;
  if (!invites || invites.length === 0) {
    // parent hasn't populated the matches array yet so show a placeholder UX
    // UX underneath the spinner
    return (
      <>
        <TabHeader type={TabHeaderType.Highlight} isModal={true} closeModal={onClose}>
          Looking for matches...
        </TabHeader>
        <div>
          <div style={spacerStyle}></div>
        </div>
      </>
    );
  }
  if (invites?.length > 1) {
    throw new ArgumentError(`Expected one invite but got ${invites?.length ?? 0}`);
  }
  const invite = invites[0];
  const friend = friends.find(f => f.u.equals(invite.sentBy));
  if (!friend) {
    // TODO: more graceful error to handle this case.  Important to avoid harassment!
    throw new ArgumentError(`This invitation comes from a friend who is not (or no longer) in your friends list`);
  }

  return (
    <Confetti>
      <TabHeader type={TabHeaderType.Highlight} isModal={true} closeModal={onClose}>
        You've been matched! Please confirm your new hang.
      </TabHeader>
      <div>
        <div style={oneFriendMatchWrapperStyle}>
          <OneFriendMatch friend={friend} invite={invite} onMatchDecide={onMatchDecide} />
        </div>
      </div>
    </Confetti>
  );
}
const oneFriendMatchWrapperStyle = { maxHeight: 500, overflow: 'auto' } as const;
const spacerStyle = { maxHeight: 500, minHeight: 100, overflow: 'auto' } as const;

function EditorInner(props: {
  onAdd: (h: HangTime[]) => Promise<void>;
  onUpdate: (h: HangTime) => Promise<void>;
  onDelete: () => Promise<void>;
  onClose: () => void;
  hangTime: HangTime;
  isNew: boolean;
}) {
  const { onAdd, onUpdate, onDelete, onClose, isNew, hangTime } = props;
  const isHang = Boolean(hangTime.hangId);
  const { userTags, refreshUserTagList } = TagsContainer.useContainer();
  const userTagsOptions = userTags?.map(toTagEditorOption);
  const [showRemoveMessageBox, setShowRemoveMessageBox] = useState(false);
  const otherDaysPickerRef = useRef<CalendarTopRefHandles>(null);

  function getInitialValues() {
    if (!hangTime) {
      return;
    }
    const { startTime: startTimeOriginal, minutes: minutesOriginal, tags: tagsOriginal } = hangTime;
    const date = startTimeOriginal.toPlainDate();
    const tags = tagsOriginal ? tagsOriginal.map(toTagEditorOption) : [];
    const time = startTimeOriginal.toPlainTime().round({ smallestUnit: 'minute', roundingMode: 'trunc' });
    const timeString = formatTimeNoSeconds(time);
    const startTime = {
      raw: timeString,
      parsed: time,
      friendly: timeString,
      isUserEntered: false,
    };
    const minutes = toDurationPickerFieldValue(minutesOriginal);
    const otherDays = new Array<Temporal.PlainDate>();
    return { date, startTime, minutes, tags, otherDays };
  }

  const initialValues = useMemo(getInitialValues, [hangTime]);

  const onRemoveHangTime = async () => {
    if (isNewValuePlaceholder(props.hangTime.h)) {
      throw new InternalServerError("Cannot delete a hangtime that hasn't been saved yet");
    }
    setShowRemoveMessageBox(false);
    await onDelete();
  };

  const onSubmit = async (values: FormValues) => {
    const time = values.startTime.parsed;
    if (!time) return;
    const startTime = values.date.toZonedDateTime({ plainTime: time, timeZone: Temporal.Now.timeZone() });
    const parsed = {
      h: props.hangTime.h || getNewValuePlaceholder(),
      startTime,
      minutes: values.minutes.parsed,
      tags: values.tags.map(t => t.value) || [],
      name: null,
      hangId: null,
    };
    if (isValidHangTime(parsed)) {
      if (isNew) {
        if (values.otherDays.length > 0) {
          // adding hangtimes supports bulk adding
          const others = values.otherDays.map(date => ({
            ...parsed,
            h: getNewValuePlaceholder(),
            startTime: date.toZonedDateTime({ plainTime: parsed.startTime, timeZone: Temporal.Now.timeZone() }),
          }));
          others.forEach(o => isValidHangTime(o));
          const { result: deduped } = addToHangTimes([parsed], others, true);
          await onAdd(deduped);
        } else {
          await onAdd([parsed]);
        }
      } else {
        // Update operations only accept a single HangTime. This may change later.
        await onUpdate(parsed);
      }
    }
  };

  const onDatePickerChange = (date: Temporal.PlainDate | null) => {
    if (isNew && date && otherDaysPickerRef.current) {
      const today = Temporal.Now.plainDateISO();
      const dateNotPast = Temporal.PlainDate.compare(date, today) < 0 ? today : date;
      otherDaysPickerRef.current && otherDaysPickerRef.current.ensureDateIsVisible(dateNotPast);
    }
  };

  return (
    <>
      <TabHeader type={TabHeaderType.Highlight} isModal={true} closeModal={onClose}>
        {isHang ? hangTime.name : 'When are you free to hang out?'}
      </TabHeader>
      <Form<FormValues>
        onSubmit={async (values: FormValues) => onSubmit(values)}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values, invalid }) => {
          console.log(`form values: ${JSON.stringify(values)}`);
          // The multi-select control shouldn't show past dates, even if the user foolishly tried
          // to add one!
          const today = Temporal.Now.plainDateISO();
          const multiSelectDate = Temporal.PlainDate.compare(values.date, today) < 0 ? today : values.date;
          return (
            <form onSubmit={handleSubmit} style={formStyle}>
              <FieldContainer>
                <FieldLabel>Date</FieldLabel>
                <Field name="date" validate={isDateObjectOrStringValid}>
                  {({ input, meta }) =>
                    isHang ? (
                      toLongDate(Temporal.PlainDate.from(input.value))
                    ) : (
                      <>
                        <DatePickerAdapter
                          {...input}
                          onChange={(date, event) => {
                            onDatePickerChange(date);
                            input.onChange(date);
                          }}
                        />
                        <ValidationErrorMessage meta={meta} />
                      </>
                    )
                  }
                </Field>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>Start Time</FieldLabel>
                <Field name="startTime" allowNull formatOnBlur validate={isStartTimeValid}>
                  {({ input, meta }) =>
                    isHang ? (
                      input.value.friendly
                    ) : (
                      <>
                        <TimePickerAdapter {...input} />
                        <ValidationErrorMessage meta={meta} />
                      </>
                    )
                  }
                </Field>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>Duration</FieldLabel>
                <Field name="minutes" allowNull formatOnBlur validate={isMinutesValid}>
                  {({ input, meta }) =>
                    isHang ? (
                      input.value.friendly
                    ) : (
                      <>
                        <DurationPickerAdapter {...input} />
                        <ValidationErrorMessage meta={meta} />
                      </>
                    )
                  }
                </Field>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>Tags</FieldLabel>
                <Field name="tags" allowNull validate={tagOptionsValidator}>
                  {({ input, meta }) =>
                    isHang ? (
                      input.value.map(t => `#${t.value}`).join(' ')
                    ) : (
                      <>
                        <Loader effect={refreshUserTagList} />
                        <TagEditor options={userTagsOptions} {...input} />
                        <ValidationErrorMessage meta={meta} />
                      </>
                    )
                  }
                </Field>
              </FieldContainer>
              {isHang ? (
                <></>
              ) : (
                <FieldContainer>
                  <Field name="otherDays" allowNull>
                    {({ input, meta }) => (
                      <>
                        <FieldLabel>
                          I'm Free At the Same Time On <span style={sameTimeFreeStyle}>(optional)</span>
                        </FieldLabel>
                        <OtherDaysPickerAdapter selectedDate={multiSelectDate} {...input} ref={otherDaysPickerRef} />
                        <ValidationErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </FieldContainer>
              )}
              <FieldContainer isLastInModal style={buttonContainerStyle}>
                {isHang ? (
                  <Button color="secondary" onClick={onClose}>
                    Close
                  </Button>
                ) : (
                  <>
                    <Button color="success" type="submit" disabled={pristine || invalid}>
                      {isNew ? 'Add Hang Time' : 'Save'}
                    </Button>
                    <Button color="secondary" style={secondaryButtonStyle} onClick={onClose}>
                      Cancel
                    </Button>
                  </>
                )}

                {!isNew && (
                  <Button
                    color="danger"
                    style={rightJustifyButtonStyle}
                    onClick={() => {
                      console.log('remove clicked');
                      setShowRemoveMessageBox(true);
                    }}
                  >
                    <>{isHang ? 'Cancel Hang' : 'Remove Hang Time'}</>

                    <MessageBox
                      title={isHang ? 'Cancel Hang' : 'Remove Hang Time'}
                      message={
                        isHang
                          ? 'Are you sure you want to cancel this hang?'
                          : "Are you sure you're not free at this time?"
                      }
                      buttons={[
                        {
                          color: 'danger',
                          label: isHang ? 'Cancel Hang' : 'Remove Hang Time',
                          onClick: onRemoveHangTime,
                        },
                        {
                          color: 'secondary',
                          label: isHang ? "Don't Cancel Hang" : "Don't Remove",
                          onClick: () => setShowRemoveMessageBox(false),
                        },
                      ]}
                      isOpen={showRemoveMessageBox}
                      onClose={() => setShowRemoveMessageBox(false)}
                    />
                  </Button>
                )}
              </FieldContainer>
            </form>
          );
        }}
      />
    </>
  );
}
const formStyle = { marginTop: 15 } as const;
const sameTimeFreeStyle = { color: '#808080', fontWeight: 'normal' } as const;
const buttonContainerStyle = { margin: 0, padding: 15, backgroundColor: '#eee', borderRadius: '0 0 5px 5px' } as const;
const secondaryButtonStyle = { marginLeft: '10px' } as const;
const rightJustifyButtonStyle = { float: 'right' } as const;

/*
useEffect(() => {
  return () => {
    console.log(`cleaning up HangTimeEdit (via effect) with done=${done}`);
    props.history.push({ pathname: '/times/day/' + toYYYYmmdd(hangTime.date), search: '', hash: '' });
  };
}, []);

const redirected = useRef(false);
if (done) {
  if (!redirected.current) {
    console.log(`cleaning up HangTimeEdit (via state) with done=${done}`);
    const pathname = '/times/day/' + toYYYYmmdd(hangTime.date);
    if (props.history.location.pathname !== pathname) {
      props.history.push({ pathname: '/times/day/' + toYYYYmmdd(hangTime.date), search: '', hash: '' });
    }
    redirected.current = true;
  }
}
*/
