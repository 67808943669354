/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
  useEffect,
  ReactNode,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  ComponentProps,
  Ref,
  useMemo,
  memo,
  Fragment,
} from 'react';
import { CalendarBottomScrollableProps, CalendarBottomRefHandles } from './CalendarBottomScrollable';
import { groupBy } from 'lodash';
import { getDayIndex, getDateFromDayIndex, formatTimeNoSeconds, DEFAULT_TIME } from './dateUtils';
import { Button } from 'reactstrap';
import CalendarDateRange from './CalendarDateRange';
import { IconButton } from './IconButton';
import { PlusIcon } from './Icon';
import { Sticky, StickyBoundary, StickyViewport, StickyBoundaryProps } from './StickyObserver';
import { useLastInteractedWith } from './LastInteractedWith';
import { useLogIfChanged, usePlainDateMemo, useMemoCustom, useLogUnmount } from './useHooks';
import { HangTime, areHangTimeArraysEqual } from './HangTime';
import { BottomCalendarScrollbarOffsetContainer } from './Calendar';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { Temporal } from '@js-temporal/polyfill';
import { CSSProperties } from 'react';

const EventWrapperInner = styled.li`
  list-style: none;
  line-height: 1.4;
  padding: 7px 10px;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  transition: background-color 250ms;
  background-color: #3174ad;
  &:hover {
    background-color: #20537f;
  }
`;

const EventWrapperInnerHang = styled(EventWrapperInner)`
  background-color: #477946;
  &:hover {
    background-color: #006600;
  }
`;

const EventWrapper = (props: { hangTime: HangTime; onClick: ({ hangTime }: { hangTime: HangTime }) => void }) => {
  const { hangTime, onClick: onClickOriginal } = props;

  const onClick = useCallback(() => {
    onClickOriginal({ hangTime });
  }, [onClickOriginal, hangTime]);

  const { startTime, minutes, tags } = hangTime;
  const endTime = startTime.add({ minutes });

  // TODO: make sure this logic works when an event spans a daylight-savings change
  const allTags = tags ? tags.map(t => '#' + t).join(' ') : '';
  const name = hangTime.name ?? (allTags.length ? `Free Hang Time ${allTags}` : 'Free Hang Time');
  const timeLabel = `${formatTimeNoSeconds(startTime)} — ${formatTimeNoSeconds(endTime)}`;
  const title = `${timeLabel}: ${name}`;
  const WrapperComponent = hangTime.hangId ? EventWrapperInnerHang : EventWrapperInner;

  return (
    <WrapperComponent onClick={onClick}>
      <div title={title}>
        <div>{timeLabel}</div>
        <div>{name}</div>
      </div>
    </WrapperComponent>
  );
};

function DateHeadingWrapper(props: { children: ReactNode; id: string | number }) {
  const { children, id, ...rest } = props;
  return (
    <Sticky
      as="div"
      css={css`
        position: sticky;
        top: 0;
        background-color: #fff;
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 5px;
      `}
      children={children}
      id={id.toString()}
      {...rest}
    />
  );
}

function OneDayListItemWrapper(props: {
  children: ReactNode;
  date: Temporal.PlainDate;
  onStuck: (date: Temporal.PlainDate, edge: 'top' | 'bottom') => void;
}) {
  const { children, date, onStuck: onStuckOriginal, ...rest } = props;
  const onStuck = useCallback<NonNullable<StickyBoundaryProps['onStuck']>>(
    ({ target, isInitial, edge }) => {
      // IntersectionObserver will alert immediately when the observer is initialized
      // for any elements which meet its criteria. When components are re-rendered, this
      // causes lots of false-positive events, so we'll ignore all sticking events right
      // after we start watching.  TODO: is there a better way to solve this?
      if (isInitial) {
        console.log(`Stuck initial: ${JSON.stringify({ edge, element: target.innerText })}`);
      }
      if (onStuckOriginal && !isInitial) {
        onStuckOriginal(date, edge);
      }
    },
    [date, onStuckOriginal]
  );
  return (
    <StickyBoundary
      as="li"
      css={css`
        list-style: none;
        padding: 0;
        margin: 0;
        scroll-snap-align: start;
      `}
      children={children}
      onStuck={onStuck}
      {...rest}
    />
  );
}

const endOfListId = 'bottom-calendar-end-of-list';
const endOfListInnerStyle = { padding: '1rem 1rem 1rem 1rem', fontSize: '1.5rem', lineHeight: 1.3 } as const;
const noWrapStyle = { display: 'inline-block', whiteSpace: 'nowrap' } as const;
const EndOfList = forwardRef(function EndOfList(
  {
    onClick,
    width,
  }: { onClick: (args: { date: Temporal.PlainDate; time: Temporal.PlainTime }) => void; width?: number | string },
  endOfListRef: Ref<HTMLDivElement>
) {
  const currentDate = usePlainDateMemo(Temporal.Now.plainDateISO());
  const onClickCallback = useCallback(() => onClick({ date: currentDate, time: DEFAULT_TIME }), [currentDate, onClick]);
  const endOfListStyle: CSSProperties = useMemo(
    () => ({
      position: 'sticky',
      height: '100vh',
      width: width || '100%',
      textAlign: 'center',
      alignSelf: 'center',
      paddingTop: 60,
    }),
    [width]
  );

  return (
    <div ref={endOfListRef} id={endOfListId} style={endOfListStyle}>
      <div style={endOfListInnerStyle}>
        <span style={noWrapStyle}>No more hang times</span>{' '}
        <span style={noWrapStyle}>
          in your future.{' '}
          <span role="img" aria-label="Sad Face">
            🙁
          </span>
        </span>{' '}
        <span style={noWrapStyle}>Let's fix that!</span>
      </div>
      <Button onClick={onClickCallback}>Add Hang Time</Button>
    </div>
  );
});

const DateClickIconButton = memo(function DateClickIconButton({
  onTimeClick,
  date: dateOriginal,
}: {
  date: Temporal.PlainDate;
  onTimeClick: CalendarBottomScrollableProps['onTimeClick'];
}) {
  const date = usePlainDateMemo(dateOriginal);
  const onClick = useCallback(() => {
    onTimeClick({ date, time: DEFAULT_TIME });
  }, [onTimeClick, date]);

  return <IconButton type={PlusIcon} onClick={onClick} />;
});

interface OneDayListItemProps {
  hangTimes: HangTime[];
  dayIndex: number;
  onClick: (date: Temporal.PlainDate) => void;
  onTimeClick: CalendarBottomScrollableProps['onTimeClick'];
  onEventClick: CalendarBottomScrollableProps['onEventClick'];
  onStuck: (date: Temporal.PlainDate, edge: 'top' | 'bottom') => void;
}

const OneDayListItem = memo(function OneDayListItem(props: OneDayListItemProps) {
  const date = usePlainDateMemo(getDateFromDayIndex(props.dayIndex));
  const groupedByDay = groupByDay(props.hangTimes || []);
  const oneDayHangTimes = useMemoCustom(groupedByDay[props.dayIndex], areHangTimeArraysEqual);
  return <OneDayListItemInner {...props} date={date} hangTimes={oneDayHangTimes} />;
});

interface OneDayListItemInnerProps extends OneDayListItemProps {
  date: Temporal.PlainDate;
}

const oneDayListItemInnerListStyle = { margin: 0, padding: 0, listStyle: 'none' };

const OneDayEventList = memo(function OneDayEventList({
  hangTimes,
  onEventClick,
}: {
  hangTimes: HangTime[];
  onEventClick: CalendarBottomScrollableProps['onEventClick'];
}) {
  return (
    <ul style={oneDayListItemInnerListStyle}>
      {hangTimes.map(hangTime => (
        <EventWrapper key={hangTime.h.toHexString()} hangTime={hangTime} onClick={onEventClick} />
      ))}
    </ul>
  );
});

const OneDayListItemInner = memo(function OneDayListItemInner({
  date,
  dayIndex,
  hangTimes,
  onClick: handleDayClick,
  onTimeClick,
  onStuck: handleStuck,
  onEventClick,
}: OneDayListItemInnerProps) {
  return (
    <OneDayListItemWrapper key={dayIndex} date={date} onStuck={handleStuck}>
      <DateHeadingWrapper id={getDayId(dayIndex)}>
        <CalendarDateRange startDate={date} onClick={handleDayClick} />
        <DateClickIconButton date={date} onTimeClick={onTimeClick} />
      </DateHeadingWrapper>
      <OneDayEventList hangTimes={hangTimes} onEventClick={onEventClick} />
    </OneDayListItemWrapper>
  );
});

const AllDaysListInner = styled.ol`
  padding: 0;
  margin: 0;
  height: auto;
  display: block;
`;

interface AllDaysListProps {
  hangTimes: CalendarBottomScrollableProps['hangTimes'];
  onSelectedDateChange: CalendarBottomScrollableProps['onSelectedDateChange'];
  onTimeClick: CalendarBottomScrollableProps['onTimeClick'];
  onEventClick: CalendarBottomScrollableProps['onEventClick'];
}

const AllDaysList = memo(function AllDaysList({
  hangTimes,
  onSelectedDateChange,
  onTimeClick,
  onEventClick,
}: AllDaysListProps) {
  const lastStuckDate = useRef<Temporal.PlainDate | null>(null);
  const handleStuck = useCallback(
    (date: Temporal.PlainDate, edge: 'top' | 'bottom') => {
      if (lastStuckDate.current && date.equals(lastStuckDate.current)) {
        console.log(`Stuck: (duplicate) ${edge} ${date.toString()}`);
      } else {
        console.log(`Stuck: ${edge} ${date.toString()}`);
        lastStuckDate.current = date;
      }
      onSelectedDateChange({ date, caller: 'bottom' });
    },
    [onSelectedDateChange]
  );

  const handleDayClick = useCallback(
    (date: Temporal.PlainDate) => {
      console.log(`Clicked on date on bottom calendar: ${date.toString()}`);
      onSelectedDateChange({ date, caller: 'bottom', view: 'day' });
    },
    [onSelectedDateChange]
  );

  const groupedByDay = groupByDay(hangTimes || []);

  // When there's a vertical scrollbar that takes up space in the bottom calendar, then
  // we'll need to shift the middle calendar buttons left by the scrollbar width so that
  // the plus icon button in the middle calendar will show up on top of the same plus
  // button in the bottom calendar list.
  // TODO: need to handle the case where resizing the browser causes a scrollbar to appear
  // or disappear. Also should handle the less-common case of the user plugging in a mouse
  // (which causes scrollbars to show up) or unplugging a mouse.
  const innerListRef = useRef<HTMLOListElement>(null);
  const scrollbarOffsetContainer = BottomCalendarScrollbarOffsetContainer.useContainer();
  /*
  const updateScrollbarOffset = useCallback(() => {
    if (innerListRef.current) {
      const innerRight = innerListRef.current.getBoundingClientRect().right;
      const containerRight = innerListRef.current.parentElement!.parentElement!.getBoundingClientRect().right;
      const offset = containerRight - innerRight;
      console.log(`Bottom Calendar offset updater: setting to ${offset}`);
      scrollbarOffsetContainer.setOffset(offset);
    }
  }, [innerListRef, scrollbarOffsetContainer]);

  useLayoutEffect(updateScrollbarOffset);
  */

  const handleResize = useCallback(
    (size: Size) => {
      if (!innerListRef.current) {
        return;
      }

      const innerRight = innerListRef.current.getBoundingClientRect().right;
      const containerRight =
        innerListRef.current.parentElement!.parentElement!.parentElement!.getBoundingClientRect().right;
      const offset = containerRight - innerRight;
      console.log(`Bottom Calendar offset updater: setting to ${offset}`);
      scrollbarOffsetContainer.setOffset(offset);
    },
    [innerListRef, scrollbarOffsetContainer]
  );

  const addDaysListInnerStyle = useCallback((width: number) => ({ width }), []);

  return (
    <AutoSizer onResize={handleResize} disableHeight={true}>
      {size => (
        <Fragment>
          <AllDaysListInner ref={innerListRef} style={addDaysListInnerStyle(size.width)}>
            {Object.keys(groupedByDay).map(dayIndexString => (
              <OneDayListItem
                key={dayIndexString}
                dayIndex={Number.parseInt(dayIndexString)}
                hangTimes={hangTimes}
                onStuck={handleStuck}
                onClick={handleDayClick}
                onEventClick={onEventClick}
                onTimeClick={onTimeClick}
              />
            ))}
          </AllDaysListInner>
          <EndOfList onClick={onTimeClick} width={size.width} />
        </Fragment>
      )}
    </AutoSizer>
  );
});

function getDayId(dayIndex: number) {
  return `day-${dayIndex}`;
}

function groupByDay(hangTimes: HangTime[]) {
  return groupBy(hangTimes, h => getDayIndex(h.startTime));
}

const allDaysListWrapperStyle = { height: '100%' };

type CalendarBottomListProps = Omit<CalendarBottomScrollableProps, 'time'>;

function CalendarBottomList(props: CalendarBottomListProps, componentRef: React.Ref<CalendarBottomRefHandles>) {
  const { hangTimes, onSelectedDateChange, onTimeClick, onEventClick, selectedDate } = props;

  const alreadyScrolled = useRef(false);
  const ensureDateIsVisible = useCallback(
    (date: Temporal.PlainDate, useSmoothScrolling = true) => {
      console.log(`Scrolling to ${date.toString()}`);
      const dayIndex = getDayIndex(date);
      const groupedByDay = groupByDay(hangTimes);
      const matchingDayIndex = Object.keys(groupedByDay).find(i => parseInt(i) >= dayIndex);
      const id = matchingDayIndex == null ? endOfListId : getDayId(parseInt(matchingDayIndex));
      const elem = document.getElementById(id);
      if (elem) {
        if (hangTimes.length) {
          // If we haven't loaded a profile (or if it's a new user) then there's no scrolling to be done.
          // So we'll wait until there is some data before trying to scroll it.
          alreadyScrolled.current = true;
        }
        console.log(`Scrolling bottom calendar list into view of: ${elem.innerText}`);
        elem.scrollIntoView({ block: 'start', behavior: useSmoothScrolling ? 'smooth' : 'auto' }); // Chrome
        elem.scrollIntoView(); // Safari, IE, etc.
      } else {
        console.log(`Couldn't scroll bottom calendar list because it's not mounted yet`);
      }
    },
    [hangTimes]
  );

  // TODO: see if we can prevent this from running every time by knowing better when
  // we can safely scroll after the elements have already been created. I tried
  // useLayoutEffect but the elements hadn't been created then either.
  // TODO: need to figure out why smooth scrolling is not turned off when we initially
  // scroll after changing views.  I suspect the best way to to do it is to have the
  // parent Calendar component be in charge, and pass false when changing views and
  // true otherwise.
  useEffect(() => {
    if (!alreadyScrolled.current) {
      ensureDateIsVisible(selectedDate, false);
    }
  });

  useImperativeHandle(
    componentRef,
    () => ({
      ensureDateIsVisible,
    }),
    [ensureDateIsVisible]
  );

  useLogIfChanged('hangTimes (CalendarBottomList)', hangTimes);
  useLogIfChanged('onSelectedDateChange (CalendarBottomList)', onSelectedDateChange);
  useLogIfChanged('ensureDateIsVisible (CalendarBottomList)', ensureDateIsVisible);
  useLogIfChanged('onTimeClick (CalendarBottomList)', onTimeClick);
  useLogIfChanged('onEventClick (CalendarBottomList)', onEventClick);

  useLogUnmount('Calendar');

  // Track interactions inside this component
  const ref = useRef<HTMLDivElement>(null);
  useLastInteractedWith({ ref, name: 'bottom' });

  const StickyViewportFixType = StickyViewport as ForwardRefExoticComponent<
    PropsWithoutRef<ComponentProps<'div'> & { as: keyof JSX.IntrinsicElements }> & RefAttributes<HTMLDivElement>
  >;

  const eventList = useMemo(
    () => (
      <AllDaysList
        hangTimes={hangTimes}
        onSelectedDateChange={onSelectedDateChange}
        onTimeClick={onTimeClick}
        onEventClick={onEventClick}
      />
    ),
    [hangTimes, onSelectedDateChange, onTimeClick, onEventClick]
  );

  return (
    <StickyViewportFixType as="div" className="bottom-calendar-container" ref={ref}>
      <div style={allDaysListWrapperStyle}>{eventList}</div>
    </StickyViewportFixType>
  );
}

const wrapped = memo(forwardRef(CalendarBottomList));
export default wrapped;
