import { useState, useMemo, CSSProperties } from 'react';
import { Button, Col } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import UserStateContainer from './UserStateContainer';
import { Redirect } from 'react-router-dom';
import { FieldContainer, FieldLabel } from './FormFieldUtils';
import TabHeader, { TabHeaderType } from './TabHeader';
import { ArgumentError } from './HangleExceptions';
import { UserProfile, UserProfileRuntype } from './UserProfile';
import { isValidPhoneNumber, canonicalizePhoneNumberForViewing } from './phoneNumbers';
import { isEmailValid } from './emailAddress';
import UserPicture from './UserPicture';

interface FormValues {
  email: string | null;
  phone: string | null;
}

type OrNull<T> = { [P in keyof T]: T[P] | null };
type ProfileViewModel = Omit<UserProfile, 'email' | 'phone'> & Pick<OrNull<UserProfile>, 'email' | 'phone'>;

export interface ProfileEditProps {
  /*
  editableFields: {
    email: UserProfile['email'];
    phone: UserProfile['phone'];
  },
  */
  profile: UserProfile | null;
}

const isValidProfile = (profile: ProfileViewModel): profile is UserProfile => {
  const validationResult = UserProfileRuntype.validate(profile);
  if (!validationResult.success) {
    throw new ArgumentError(validationResult.message);
  }
  return true;
};

const emailValidator = (value: string) => {
  return isEmailValid(value) ? undefined : 'Valid email is required';
};

const phoneValidator = (value: string) => {
  return !value || isValidPhoneNumber(value) ? undefined : 'Valid mobile phone number is required';
};

const phoneFormatter = (value: unknown, name: string) => {
  if (typeof value !== 'string') {
    return value == null ? '' : value;
  }
  const canonical = canonicalizePhoneNumberForViewing(value);
  return canonical || value;
};

const staticFieldStyle: CSSProperties = { padding: '2px 0' };
const StaticField: React.FC = props => {
  return <div style={staticFieldStyle}>{props.children}&nbsp;</div>;
};

const useUserState = () => {
  const { userState, onUpdateUserProfile } = UserStateContainer.useContainer();
  return { userState, onUpdateUserProfile };
};

const colStyle = { overflowY: 'auto' } as const;
const profileWarningStyle = { color: '#aaa' };
const errorMessageStyle = { color: 'red' };
const formStyle = { marginTop: '15px' };
const secondaryButtonStyle = { marginLeft: '10px' } as const;

const ProfileEdit = (props: ProfileEditProps) => {
  const [done, setDone] = useState(false);
  const { profile } = props;
  const stateContainer = useUserState();
  const closer = () => {
    //    closeModal();
    setDone(true);
  };

  const getInitialValues = () => {
    if (!props.profile) {
      return;
    }
    const { email, phone } = props.profile;
    return { email: email || null, phone: phone || null };
  };

  const initialValues = useMemo(getInitialValues, [props.profile]);

  const onSubmit = async (values: FormValues) => {
    const { email, phone } = values;
    if (!profile) {
      throw new ArgumentError(`Cannot save because no user profile is currently loaded`);
    }
    const newProfile = {
      ...profile,
      email: email || null,
      phone: phone || null,
    };
    const saveMethod = stateContainer.onUpdateUserProfile;

    if (!isValidProfile(newProfile)) {
      // this return is never actually executed because exceptions will have
      // been thrown by the type guard method, but it's needed to satisfy TS.
      // TODO: see if we can remove this with assertions in TS 3.7.
      return;
    }

    const success = await saveMethod(newProfile);

    if (success) {
      closer();
    }
  };

  return done ? (
    // TODO: consider allowing caller to override the redirect URL
    <Redirect to={{ pathname: '/', search: '', hash: '' }} />
  ) : (
    <>
      <TabHeader type={TabHeaderType.Highlight}>My Profile</TabHeader>
      <Col style={colStyle}>
        <Form<FormValues>
          onSubmit={async (values: FormValues) => onSubmit(values)}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, pristine, values, invalid }) => {
            console.log(`form values: ${JSON.stringify(values)}`);
            return (
              <form onSubmit={handleSubmit} style={formStyle}>
                <FieldContainer>
                  <Field name="email" allowNull validate={emailValidator}>
                    {({ input, meta }) => (
                      <>
                        <FieldLabel>Email</FieldLabel>
                        <input className="form-control" type="text" {...input} value={input.value || ''} />
                        {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="phone" formatOnBlur allowNull validate={phoneValidator} format={phoneFormatter}>
                    {({ input, meta }) => (
                      <>
                        <FieldLabel>Mobile Phone</FieldLabel>
                        <input className="form-control" type="text" {...input} value={input.value || ''} />
                        {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <StaticField>
                    <br />
                    <div style={profileWarningStyle}>
                      The fields below came from your Facebook and/or Google account and cannot be edited (yet) in
                      Hangle.
                    </div>
                  </StaticField>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>Name</FieldLabel>
                  <StaticField>{profile && profile.name}</StaticField>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>Picture</FieldLabel>
                  <StaticField>
                    {profile && (
                      <UserPicture name={profile.name} pictureData={profile.picture} pictureUrl={profile.pictureUrl} />
                    )}
                  </StaticField>
                </FieldContainer>
                <FieldContainer>
                  <Button color="success" type="submit" disabled={pristine || invalid}>
                    Save
                  </Button>
                  <Button color="secondary" style={secondaryButtonStyle} onClick={closer}>
                    Cancel
                  </Button>
                </FieldContainer>
              </form>
            );
          }}
        />
      </Col>
    </>
  );
};

export default ProfileEdit;

/* TODO: need to figure out how a user can cancel her account

  const onDeleteHangTime = async () => {
    if (isNewValuePlaceholder(hangTime.h)) {
      throw new InternalServerError("Cannot delete a hangtime that hasn't been saved yet");
    }

    // TODO: need Suspense here in case this takes a while!
    const success = await stateContainer.onDeleteHangTime(hangTime as HangTime);

    if (success) {
      setShowRemoveMessageBox(false);
      closer();
    }
  };
*/

/*
  useEffect(() => {
    return () => {
      console.log(`cleaning up HangTimeEdit (via effect) with done=${done}`);
      props.history.push({ pathname: '/times/day/' + toYYYYmmdd(hangTime.date), search: '', hash: '' });
    };
  }, []);

  const redirected = useRef(false);
  if (done) {
    if (!redirected.current) {
      console.log(`cleaning up HangTimeEdit (via state) with done=${done}`);
      const pathname = '/times/day/' + toYYYYmmdd(hangTime.date);
      if (props.history.location.pathname !== pathname) {
        props.history.push({ pathname: '/times/day/' + toYYYYmmdd(hangTime.date), search: '', hash: '' });
      }
      redirected.current = true;
    }
  }
*/

/*

editable
phone: input.phone,
email: input.email,

read-only
joinedAt: Temporal.Now.instant(),
pictureUrl: input.pictureUrl, (allow editing later)
name: input.name,

firstName: input.firstName,
lastName: input.lastName,
middleName: input.middleName,
nameFormat: input.nameFormat,
shortName: input.shortName,

support-only
facebookUserId: input.facebookUserId,
googleUserId: input.googleUserId,
id
externalUserId,
shareEmailDefault: true,
sharePhoneDefault: true,

*/
