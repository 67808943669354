import { useState } from 'react';
import { Button } from 'reactstrap';
import UserStateContainer from './UserStateContainer';

/**
 * Primitive support for updating DB schema (aka "migration").
 * This is a solved problem by more mature tools, so consider moving to
 * one of those tools instead of rolling our own here.
 */
const Migrations = () => {
  const { runMigration } = UserStateContainer.useContainer();
  const [resultText, setResultText] = useState('');
  const runMigrationWrapper = async () => {
    const result = await runMigration(2);
    setResultText(result ? `Result: ${result.updatedDocumentsCount} documents updated` : 'Failed');
  };
  return (
    <>
      <div style={{ width: '80vw', margin: 20 }}>
        <ul>
          <li>
            Migration 2: Replace `startTime` properties with serialized Temporal.ZonedDateTime{' '}
            <Button onClick={runMigrationWrapper}>Run</Button> <span>{resultText}</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Migrations;
