import { ReactFacebookLoginInfo, facebookLogout } from './facebookLogin';
import { GoogleLoginResponse, googleLogout } from './googleLogin';
import Auth from '@aws-amplify/auth';
import { currentAuthenticatedFederatedUser } from './ConfigureAWS';
// import OriginalAPI from './amplify-fork2'; // req'd to be able to step into Amplify code
import { FederatedUser } from '@aws-amplify/auth/lib/types';
// import AWS from 'aws-sdk';
// import ConfigureAWS from './ConfigureAWS';
import { useApi, API } from './API';
import { UserState, UserStateFactory, UserStateServer, fromUserStateServer } from './UserState';
import { HangTime, addToHangTimes } from './HangTime';
import produce, { Draft } from 'immer';
import {
  CreateUserInput,
  UpdateHangtimeInput,
  UpdateFriendInput,
  HandleFriendRequestInput,
  UpdateUserProfileInput,
  profileFieldsSettableInUI,
  ProfileFieldsSettableInUI,
  CreateHangtimeInput,
  CreateHangInput,
  GetMatchesInput,
  GetPossibleMatchesInput,
  DeleteHangtimeInput,
  PossibleMatches,
  Matches,
  GetUserPicturesInput,
  GetUserPicturesOutput,
} from './HangleCRUD';
// import { addModal } from './ContextModal';
import { Friend } from './Friend';
import { HTTPStatusCodes, InternalServerError, AwsAmplifyError, ArgumentError } from './HangleExceptions';
import { deepcopy } from './Serializer';
import { isNewValuePlaceholder } from './newValuePlaceholder';
import { ObjectId } from 'bson';
import { UserProfile } from './UserProfile';
import { MigrationResult } from './MigrationResult';
import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import { ModalContainer } from './ContextModal';
import { FederatedResponse } from '@aws-amplify/auth/lib-esm/types';
import { Hang } from './Hang';
import { Temporal } from '@js-temporal/polyfill';
import { imageToDataUrl } from './imageManager';
import { useInstantState } from './useHooks';

// import API from './amplify-fork/src';
// OriginalAPI.configure(ConfigureAWS.config);
// ConfigureAWS.configure();

export enum SaveType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}
export enum HangTimeSaveType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
export enum HangSaveType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
export enum UserProfileSaveType {
  UPDATE = 'UPDATE',
}

const isObject = (x: unknown): x is Record<string, unknown> => typeof x === 'object' && x != null;

const isUserState = (o: unknown): o is UserState =>
  isObject(o) &&
  typeof o.profile === 'object' &&
  typeof o.friends === 'object' &&
  typeof o.hangTimes === 'object' &&
  typeof o.notifications === 'object' &&
  typeof o.version === 'number';

const useUserState = () => {
  const [userState, setUserState] = useState(UserStateFactory.create());
  const { addModal } = ModalContainer.useContainer();
  const { callApi, handleApiError } = useApi(userState);

  const callApiAndSetState = useCallback(
    async (action: () => Promise<unknown>, requireLoggedIn = true): Promise<UserState> => {
      const apiResult = await callApi<UserStateServer>(action, requireLoggedIn);
      const result = fromUserStateServer(apiResult);
      if (result && isUserState(result)) {
        // most (all?) API responses will return a new version of the user profile, so we can just update state here.
        // when we get new persisted state, we also clear transient state.
        setStateFromApiResponse(result);
      } else {
        // fool the caller into thinking that this is a server error, for better error handling UX
        const e = new InternalServerError(`Invalid response from server: ${JSON.stringify(result)}`);
        type AmplifyApiException = {
          response: { status: number };
        };
        (e as unknown as AmplifyApiException).response = { status: e.statusCode };
        throw e;
      }
      return result;
    },
    [callApi]
  );

  const setStateFromApiResponse = (newState: UserState): void => {
    setUserState(
      produce((draft: Draft<UserState>) => {
        draft._id = newState._id;
        draft.profile = newState.profile;
        draft.friends = newState.friends;
        draft.hangTimes = newState.hangTimes;
        draft.notifications = newState.notifications;
        draft.version = newState.version;
      })
    );
  };

  const createUser = useCallback(
    async (initialUserInfo: CreateUserInput): Promise<UserState | null> => {
      try {
        return await callApiAndSetState(() => API.post('user', '/prod/user', { body: initialUserInfo }), false);
      } catch (e) {
        const response = (e as AwsAmplifyError).response;
        const message =
          response && response?.status === HTTPStatusCodes.CONFLICT && (response.data as { error: string }).error;
        if (message) {
          addModal(message);
          console.log(`User already exists: ${message}`);
        } else {
          handleApiError('creating user', e as Error);
        }
        return null;
      }

      /* TODO - different user behavior if there's a conflict here, including UI to help user resolve the problem.
    const { status } = e.response;
    if (status === HTTPStatusCodes.CONFLICT) {
        // Another user has the same email address (or, when it's implemented, phone number)
        // For now we'll just fail the user creation. 
        console.log(`API error creating new user: ${formatError(e)}`);
        throw e;
      } else {
        console.log(`API error creating new user: ${formatError(e)}`);
        throw e;
      } 
    */
    },
    [handleApiError, callApiAndSetState, addModal]
  );

  // maps the user ID (as a hex string) to the image URL for that user
  const [userPicturesUrlMap, setUserPicturesUrlMap] = useState({} as { [id: string]: string });

  const getUserPictures = useCallback(
    async (input: GetUserPicturesInput): Promise<GetUserPicturesOutput | null> => {
      try {
        const result = await callApi<GetUserPicturesOutput>(() =>
          API.get('user', '/prod/user/me/picture', { queryStringParameters: input })
        );
        console.log(`API success getting picture for users ${input.userIds.map(u => u.toHexString()).join(',')}`);
        return result;
      } catch (e) {
        handleApiError('getting user pictures', e as Error);
        return null;
      }
    },
    [callApi, handleApiError]
  );

  const [lastFetchedPictures, setLastFetchedPictures] = useInstantState(new Temporal.Instant(BigInt(0)));

  const refreshUserPictures = useCallback(
    async (friends: Friend[]): Promise<void> => {
      console.log(
        `Last refreshed user pix: ${
          lastFetchedPictures.epochMilliseconds === 0
            ? '(never)'
            : `${Temporal.Now.instant().since(lastFetchedPictures).toLocaleString()} ago`
        }`
      );
      if (lastFetchedPictures.until(Temporal.Now.instant(), { largestUnit: 'second' }).seconds < 60) {
        setLastFetchedPictures(Temporal.Now.instant());
        return; // don't fetch too often
      }
      if (!friends) return;
      const input: GetUserPicturesInput = { userIds: friends.map(f => f.u), pictureType: 'profile' };
      console.log(`Refreshing pictures for ${friends.length} friends`);
      const results = await getUserPictures(input);
      if (!results) {
        console.log('No pictures returned');
        return;
      }
      const countType = (type: GetUserPicturesOutput['results'][0]['type']) =>
        results.results.filter(r => r.type === type).length;
      console.log(
        `Fetched ${countType('image')} pictures, ${countType('redirect')} redirects, ${countType('not found')} missing`
      );
      let changeCount = 0;
      const newImageMap = { ...userPicturesUrlMap };

      results.results.forEach(result => {
        let url: string | undefined;
        switch (result.type) {
          case 'image':
            url = imageToDataUrl(result.response);
            break;
          case 'redirect':
            url = result.response.url;
            break;
          case 'not found':
            url = undefined;
            break;
          default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            handleApiError(
              'getting user pictures',
              new ArgumentError(`Invalid response type: ${(result as { type: string }).type}`)
            );
        }
        const userIdString = result.u.toHexString();
        if (url && newImageMap[userIdString] !== url) {
          changeCount++;
          newImageMap[userIdString] = url;
        }
      });

      if (changeCount) {
        console.log(`Updating pictures for ${changeCount} users`);
        setUserPicturesUrlMap(newImageMap);
      }
    },
    [getUserPictures, handleApiError, lastFetchedPictures, setLastFetchedPictures, userPicturesUrlMap]
  );

  const getPictureForUser = useCallback(
    (userId: ObjectId) => {
      return userPicturesUrlMap[userId.toHexString()];
    },
    [userPicturesUrlMap]
  );

  const getOrCreateCurrentUser = useCallback(
    async (initialUserInfo: CreateUserInput): Promise<UserState | null> => {
      try {
        const result = await callApiAndSetState(
          () => API.get('user', '/prod/user/me', { queryStringParameters: initialUserInfo }),
          false
        );
        return result;
      } catch (e) {
        if ((e as AwsAmplifyError).response === undefined) {
          addModal(
            `Oh no!  We can't reach the server. Maybe the server is out to lunch?  ` +
              `Check your connection and be nice to your friendly neighborhood servers!`
          );
          return null;
        }
        const status = (e as AwsAmplifyError).response?.status;
        if (status === HTTPStatusCodes.NOT_FOUND) {
          console.log('User does not exist. Creating new user.');
          return await createUser(initialUserInfo);
        } else {
          handleApiError('fetching current user', e as Error);
          return null;
        }
      }
    },
    [addModal, callApiAndSetState, createUser, handleApiError]
  );

  const onFacebookLoginResponse = useCallback(
    async (response: ReactFacebookLoginInfo) => {
      if (response.name && response.id) {
        const user = {
          name: response.name,
          pictureUrl: `https://graph.facebook.com/${response.id}/picture?type=normal`,
          email: response.email,
          firstName: response.first_name,
          lastName: response.last_name,
          middleName: response.middle_name,
          shortName: response.short_name,
          nameFormat: response.name_format,
          facebookUserId: response.id,
        };

        const login = async () => {
          // See `currentAuthenticatedFederatedUser` code to understand why
          // we can't just call `Auth.currentAuthenticatedUser` here.
          const loggedInUser = await currentAuthenticatedFederatedUser();
          if (loggedInUser) {
            console.log(`Already logged into Cognito: ${JSON.stringify(loggedInUser)}`);
            return;
          }
          const federatedUser: FederatedUser = { name: user.name, email: user.email };
          const federatedResponse: FederatedResponse = {
            token: response.accessToken,
            // facebook returns expiration delta in seconds. Convert to epoch msecs;
            expires_at: response.expiresIn * 1000 + Temporal.Now.instant().epochMilliseconds,
          };

          try {
            await Auth.federatedSignIn('facebook', federatedResponse, federatedUser);
            console.log('Cognito federated Facebook login success');
          } catch (e) {
            console.log(`Cognito federated Facebook login error: ${(e as Error).message}`);
            throw e;
          }
        };

        try {
          await login();
        } catch (e) {
          addModal((e as Error).message);
          return;
        }

        /*
    AWS.config.region = 'us-east-1';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: ConfigureAWS.config.Auth.identityPoolId,
      Logins: {
        'graph.facebook.com': response.accessToken
      }
    });

    const result = await new Promise(resolve => AWS.config.getCredentials(resolve));
    if (result) {
      console.log ("Failed to get Cognito credentials")
      throw result;
    } else {
      console.log ("Cognito got credentials")
    }
*/
        console.log(`Facebook SDK: ${JSON.stringify(window['FB'])}`);

        // The user is authenticated. Let's get the user profile, or create a new user if it doesn't exist.
        // TODO: do we really want to auto-create new user profiles?  Should we
        //       verify with the user that the user is new? once we have Google login
        //       or support changing email address, this may be an issue.
        const initialUserInfo: CreateUserInput = {
          name: user.name,
          lastName: user.lastName,
          firstName: user.firstName,
          middleName: user.middleName ? user.middleName : null,
          shortName: user.shortName,
          nameFormat: user.nameFormat,
          email: user.email,
          pictureUrl: user.pictureUrl,
          facebookUserId: user.facebookUserId,
          facebookToken: response.accessToken,
          googleUserId: null,
          googleToken: null,
          phone: null,
        };

        await getOrCreateCurrentUser(initialUserInfo);
      }
    },
    [addModal, getOrCreateCurrentUser]
  );

  const onGoogleLoginResponse = useCallback(
    async (response: GoogleLoginResponse) => {
      const profile = response.profileObj;

      const user = {
        name: profile.name,
        pictureUrl: profile.imageUrl,
        email: profile.email,
        firstName: profile.givenName,
        lastName: profile.familyName,
        middleName: null,
        shortName: profile.givenName,
        googleUserId: profile.googleId,
        googleUserIdToken: response.tokenId,
      };

      // See `currentAuthenticatedFederatedUser` code to understand why
      // we can't just call `Auth.currentAuthenticatedUser` here.
      const loggedInUser = await currentAuthenticatedFederatedUser();
      if (loggedInUser) {
        console.log(`Already logged into Cognito: ${JSON.stringify(loggedInUser)}`);
      } else {
        const federatedUser: FederatedUser = { name: user.name, email: user.email };
        const federatedResponse: FederatedResponse = {
          token: response.tokenId,
          expires_at: response.expiresAt,
        };

        try {
          await Auth.federatedSignIn('google', federatedResponse, federatedUser);
          console.log('Cognito federated Google login success');
        } catch (e) {
          console.log(`Cognito federated Google login error: ${(e as Error).message}`);
          throw e;
        }
      }

      // The user is authenticated. Let's get the user profile, or create a new user if it doesn't exist.
      // TODO: do we really want to auto-create new user profiles?  Should we verify with the user that the user is new?
      //       once we have Google login or support changing email address, this may be an issue.
      const initialUserInfo: CreateUserInput = {
        name: user.name,
        lastName: user.lastName,
        firstName: user.firstName,
        middleName: user.middleName ? user.middleName : null,
        shortName: user.shortName,
        nameFormat: '', // TODO: should we use a default here?
        email: user.email,
        pictureUrl: user.pictureUrl,
        facebookUserId: null,
        facebookToken: null,
        googleUserId: user.googleUserId,
        googleToken: response.tokenId,
        phone: null,
      };

      await getOrCreateCurrentUser(initialUserInfo);
    },
    [getOrCreateCurrentUser]
  );

  const onLogout = useCallback(async () => {
    // clear Hangle's own state.
    // TODO: need to have un-logged-in UX
    setStateFromApiResponse(UserStateFactory.create());

    // sign out of AWS Cognito Federated Identity Pool
    try {
      await Auth.signOut();
    } catch (e) {
      console.log(`Failed to sign out of Cognito: ${JSON.stringify(e)}`);
    }

    // Finally, sign out of Facebook and/or Google.
    // TODO: make sure that Promise.allSettled is polyfilled for older Safari
    const results = await Promise.allSettled([facebookLogout(), googleLogout()]);
    if (results[0].status === 'rejected') {
      console.log(`Failed to sign out of Facebook: ${JSON.stringify(results[0].reason)}`);
    }
    if (results[1].status === 'rejected') {
      console.log(`Failed to sign out of Google: ${JSON.stringify(results[1].reason)}`);
    }
  }, []);

  const onCreateFriend = async (friend: Friend) => onSaveFriend(friend, SaveType.CREATE);
  const onUpdateFriend = async (friend: Friend) => onSaveFriend(friend, SaveType.UPDATE);
  const onDeleteFriend = async (friend: Friend) => onSaveFriend(friend, SaveType.DELETE);
  const onAcceptFriendRequest = async (friend: Friend) => onSaveFriend(friend, SaveType.ACCEPT);
  const onRejectFriendRequest = async (friend: Friend) => onSaveFriend(friend, SaveType.REJECT);

  const onSaveFriend = async (friend: Friend, saveType: SaveType): Promise<boolean> => {
    const version = userState.version;

    interface UpdateFriendDriver {
      uri: string;
      isNew: boolean;
      preSave: (draft: UserState, oldIndex: number) => void;
      restoreOnError: (draft: UserState, oldIndex: number) => void;
      apiMethod: (apiName: string, path: string, init: Record<string, unknown>) => Promise<UserStateServer>;
      activityName: string;
      input: UpdateFriendInput | HandleFriendRequestInput;
    }

    const driver: { [type in SaveType]: UpdateFriendDriver } = {
      [SaveType.CREATE]: {
        uri: '/prod/user/me/friend',
        isNew: true,
        preSave: (draft, oldIndex) => draft.friends.push(friend),
        restoreOnError: (draft, oldIndex) => (draft.friends = draft.friends.filter(o => !isNewValuePlaceholder(o.f))),
        apiMethod: API.post,
        activityName: 'adding friend',
        input: { version, friend },
      },
      [SaveType.UPDATE]: {
        uri: '/prod/user/me/friend',
        isNew: false,
        preSave: (draft, oldFriendIndex) => (draft.friends[oldFriendIndex] = friend),
        restoreOnError: (draft, oldFriendIndex) => (draft.friends[oldFriendIndex] = oldFriend!),
        apiMethod: API.put,
        activityName: 'saving friend',
        input: { version, friend },
      },
      [SaveType.DELETE]: {
        uri: '/prod/user/me/friend',
        isNew: false,
        preSave: (draft, oldFriendIndex) => draft.friends.splice(oldFriendIndex, 1),
        restoreOnError: (draft, oldFriendIndex) => draft.friends.push(oldFriend!),
        apiMethod: API.del,
        activityName: 'removing friend',
        input: { version, friend },
      },
      [SaveType.ACCEPT]: {
        uri: '/prod/user/me/friendRequest',
        isNew: false,
        preSave: (draft, oldFriendIndex) => (draft.friends[oldFriendIndex] = friend),
        restoreOnError: (draft, oldFriendIndex) => (draft.friends[oldFriendIndex] = oldFriend!),
        apiMethod: API.put,
        activityName: 'accepting friend request',
        input: { version, friend, accepted: true },
      },
      [SaveType.REJECT]: {
        uri: '/prod/user/me/friendRequest',
        isNew: false,
        preSave: (draft, oldFriendIndex) => (draft.friends[oldFriendIndex] = friend),
        restoreOnError: (draft, oldFriendIndex) => (draft.friends[oldFriendIndex] = oldFriend!),
        apiMethod: API.put,
        activityName: 'rejecting friend request',
        input: { version, friend, accepted: false },
      },
    };

    const { uri, isNew, preSave, restoreOnError, apiMethod, activityName, input } = driver[saveType];

    let oldFriend: Friend | null = null;

    // update UI before saving, so users won't wonder what's happening.
    // once we have Suspense, we should probably change this to show a spinner so users won't close app before save complete
    setUserState(
      // TODO: change type below to Draft<UserState> if https://github.com/immerjs/immer/issues/710 is fixed.
      produce((draft: UserState) => {
        const oldFriendIndex = isNew ? -1 : draft.friends.findIndex(o => ObjectId.isValid(o.f) && o.f.equals(friend.f));
        if (!isNew && oldFriendIndex === -1) {
          addModal('This friend does not exist.');
          return;
        }
        oldFriend = isNew ? null : deepcopy(draft.friends[oldFriendIndex]); // save old friend in case we want to restore if saving fails
        preSave(draft, oldFriendIndex);
      })
    );

    try {
      await callApiAndSetState(() => apiMethod.call(API, 'user', uri, { body: input }));
      return true;
    } catch (e) {
      // remove this item from UI because saving failed. Note that the friend list might have changed
      // while the request was executing so we'll re-find it in the friend list.
      setUserState(
        // TODO: change type below to Draft<UserState> if https://github.com/immerjs/immer/issues/710 is fixed.
        produce((draft: UserState) => {
          const oldFriendIndex = isNew
            ? -1
            : draft.friends.findIndex(o => ObjectId.isValid(o.f) && o.f.equals(friend.f));
          restoreOnError(draft, oldFriendIndex);
        })
      );

      handleApiError(activityName, e as Error);
      return false;
    }
  };

  const onCreateHangTimes = async (hangTimes: HangTime[]) => onSaveHangTime(hangTimes, HangTimeSaveType.CREATE);
  const onUpdateHangTime = async (hangTime: HangTime) => onSaveHangTime([hangTime], HangTimeSaveType.UPDATE);
  const onDeleteHangTime = async (hangTime: HangTime) => onSaveHangTime([hangTime], HangTimeSaveType.DELETE);

  const onSaveHangTime = async (hangTimes: HangTime[], saveType: HangTimeSaveType): Promise<UserState | null> => {
    const version = userState.version;

    if (!hangTimes.length) {
      addModal('Hang time is missing');
      return null;
    }
    if (saveType !== HangTimeSaveType.CREATE && hangTimes.length > 1) {
      addModal('Bulk editing of hang times is not supported yet');
      return null;
    }

    interface UpdateHangTimeDriver {
      uri: string;
      isNew: boolean;
      preSave: (draft: UserState, oldIndex: number) => void;
      apiMethod: (apiName: string, path: string, init: Record<string, unknown>) => Promise<UserStateServer>;
      activityName: string;
      input: UpdateHangtimeInput | CreateHangtimeInput | DeleteHangtimeInput;
    }

    let oldHangTimes: HangTime[];

    const driver: { [type in HangTimeSaveType]: UpdateHangTimeDriver } = {
      [HangTimeSaveType.CREATE]: {
        uri: '/prod/user/me/hangtime',
        isNew: true,
        preSave: (draft, oldIndex) => {
          // Merge the new hangtimes into the current list of hangtimes.
          const { result } = addToHangTimes(draft.hangTimes, hangTimes, false);
          draft.hangTimes = result;
        },
        apiMethod: API.post,
        activityName: 'adding hang time',
        input: { version, hangTimes },
      },
      [HangTimeSaveType.UPDATE]: {
        uri: '/prod/user/me/hangtime',
        isNew: false,
        preSave: (draft, oldIndex) => {
          if (hangTimes.length !== 1) {
            throw new InternalServerError(`Expected 1 hang time to update, but received ${draft.hangTimes.length}`);
          }
          // remove the hangtime that we're going to update
          draft.hangTimes.splice(oldIndex, 1);

          // Note: if the updated hangtime is identical to another existing
          // hangtime (same start time, length, tags, etc. but different ID)
          // then we'll silently fail to add the new one and leave the old one.
          const { result } = addToHangTimes(draft.hangTimes, hangTimes, false);
          draft.hangTimes = result;
        },
        apiMethod: API.put,
        activityName: 'saving hang time',
        input: { version, hangTime: hangTimes[0] },
      },
      [HangTimeSaveType.DELETE]: {
        uri: '/prod/user/me/hangtime',
        isNew: false,
        preSave: (draft, oldIndex) => draft.hangTimes.splice(oldIndex, 1),
        apiMethod: API.del,
        activityName: 'removing hang time',
        input: hangTimes[0].hangId
          ? { version, hangTime: hangTimes[0], deleteHangOptions: { keepHangTime: false } }
          : { version, hangTime: hangTimes[0] },
      },
    };

    const { uri, isNew, preSave, apiMethod, activityName, input } = driver[saveType];

    // update UI before saving, so users won't wonder what's happening.
    // once we have Suspense, we should probably change this to show a spinner so users won't close app before save complete
    setUserState(
      // TODO: change type below to Draft<UserState> if https://github.com/immerjs/immer/issues/710 is fixed.
      produce((draft: UserState) => {
        const oldIndex = isNew
          ? -1
          : draft.hangTimes.findIndex(o => ObjectId.isValid(o.h) && o.h.equals(hangTimes[0].h));
        if (!isNew && oldIndex === -1) {
          addModal('This hang time does not exist.');
          return;
        }
        oldHangTimes = deepcopy(draft.hangTimes);
        preSave(draft, oldIndex);
      })
    );

    try {
      const newUserState = await callApiAndSetState(() => apiMethod.call(API, 'user', uri, { body: input }));
      // the user may need to match client-created instances with ones stored on the server,
      // which will have different IDs.
      return newUserState;
    } catch (e) {
      // Undo changes in UI because saving failed.
      // TODO: It's possible for there to be other changes that the user
      // made in the UI while the call was executing. Need to protect
      // against this case... later!
      setUserState(
        produce((draft: Draft<UserState>) => {
          draft.hangTimes = oldHangTimes;
        })
      );

      handleApiError(activityName, e as Error);
      return null;
    }
  };

  const onCreateHang = async (hang: Hang) => onSaveHang(hang, HangSaveType.CREATE);

  const onSaveHang = async (hang: Hang, saveType: HangSaveType): Promise<UserState | null> => {
    const version = userState.version;

    if (!hang) {
      addModal('Hang is missing');
      return null;
    }

    interface UpdateHangDriver {
      apiMethod: (apiName: string, path: string, init: Record<string, unknown>) => Promise<UserStateServer>;
      activityName: string;
      input: CreateHangInput;
    }

    const uri = '/prod/user/me/hang';

    const driver: { [type in HangTimeSaveType]: UpdateHangDriver } = {
      [HangSaveType.CREATE]: {
        apiMethod: API.post,
        activityName: 'adding hang',
        input: { version, hang },
      },
      [HangTimeSaveType.UPDATE]: {
        apiMethod: API.put,
        activityName: 'saving hang',
        input: { version, hang },
      },
      [HangTimeSaveType.DELETE]: {
        apiMethod: API.del,
        activityName: 'removing hang',
        input: { version, hang },
      },
    };

    const { apiMethod, activityName, input } = driver[saveType];

    try {
      const newUserState = await callApiAndSetState(() => apiMethod.call(API, 'user', uri, { body: input }));
      // the user may need to match client-created instances with ones stored on the server,
      // which will have different IDs.
      return newUserState;
    } catch (e) {
      handleApiError(activityName, e as Error);
      return null;
    }
  };

  const onUpdateUserProfile = async (userProfile: UserProfile) =>
    onSaveUserProfile(userProfile, UserProfileSaveType.UPDATE);

  const onSaveUserProfile = async (userProfile: UserProfile, saveType: UserProfileSaveType): Promise<boolean> => {
    const version = userState.version;
    const fields: ProfileFieldsSettableInUI = profileFieldsSettableInUI.reduce((acc, field) => {
      acc[field] = userProfile[field];
      return acc;
    }, {});

    interface UpdateUserProfileDriver {
      uri: string;
      isNew: boolean;
      preSave: (draft: UserState) => void;
      restoreOnError: (draft: UserState, oldProfile: UserProfile) => void;
      apiMethod: (apiName: string, path: string, init: Record<string, unknown>) => Promise<UserStateServer>;
      activityName: string;
      input: UpdateUserProfileInput;
    }

    let oldUserProfile: UserProfile;

    const driver: { [type in UserProfileSaveType]: UpdateUserProfileDriver } = {
      [UserProfileSaveType.UPDATE]: {
        uri: '/prod/user/me/profile',
        isNew: false,
        preSave: draft => {
          if (!draft.profile) {
            addModal(`Profile has not been loaded yet. Wait until profile loads and try again.`);
            return;
          }
          oldUserProfile = deepcopy(draft.profile); // clone to avoid https://github.com/immerjs/immer/issues/281
          draft.profile = { ...draft.profile, ...fields, isProvisional: true };
        },
        restoreOnError: (draft, oldUserProfile) => (draft.profile = oldUserProfile),
        apiMethod: API.put,
        activityName: 'saving user profile',
        input: { version, profile: fields },
      },
    };

    const { uri, preSave, restoreOnError, apiMethod, activityName, input } = driver[saveType];

    // update UI before saving, so users won't wonder what's happening.
    // once we have Suspense, we should probably change this to show a spinner so users won't close app before save complete
    setUserState(
      // TODO: change type below to Draft<UserState> if https://github.com/immerjs/immer/issues/710 is fixed.
      produce((draft: UserState) => {
        preSave(draft);
      })
    );

    try {
      await callApiAndSetState(() => apiMethod.call(API, 'user', uri, { body: input }));
      return true;
    } catch (e) {
      // remove this item from UI because saving failed. Note that the hangTime list might have changed
      // while the request was executing so we'll re-find it in the hangTime list.
      setUserState(
        // TODO: change type below to Draft<UserState> if https://github.com/immerjs/immer/issues/710 is fixed.
        produce((draft: UserState) => {
          restoreOnError(draft, oldUserProfile);
        })
      );

      handleApiError(activityName, e as Error);
      return false;
    }
  };

  const runMigration = async (migrationIndex: number): Promise<MigrationResult | null> => {
    try {
      const result = await callApi<MigrationResult>(() => API.get('user', `/prod/migrations/${migrationIndex}`, {}));
      console.log(`Migration ${migrationIndex} success: ${JSON.stringify(result)}`);
      return result;
    } catch (e) {
      handleApiError('migrating data', e as Error);
      return null;
    }
  };

  const getMatches = async (input: GetMatchesInput): Promise<Matches['matches'] | null> => {
    try {
      const result = await callApi<Matches>(() =>
        API.get('user', '/prod/user/me/matches', { queryStringParameters: input })
      );
      console.log(`API success getting matches: ${JSON.stringify(result.matches)}`);
      return result.matches;
    } catch (e) {
      handleApiError('getting matches', e as Error);
      return null;
    }
  };

  const getPossibleMatches = async (
    input: GetPossibleMatchesInput
  ): Promise<PossibleMatches['possibleMatches'] | null> => {
    try {
      const result = await callApi<PossibleMatches>(() =>
        API.get('user', '/prod/user/me/possibleMatches', { queryStringParameters: input })
      );
      console.log(`API success getting possibleMatches: ${JSON.stringify(result.possibleMatches)}`);
      return result.possibleMatches;
    } catch (e) {
      handleApiError('getting possible matches', e as Error);
      return null;
    }
  };

  return {
    userState,
    onFacebookLoginResponse,
    onGoogleLoginResponse,
    onLogout,
    onCreateFriend,
    onUpdateFriend,
    onDeleteFriend,
    onAcceptFriendRequest,
    onRejectFriendRequest,
    onCreateHang,
    onCreateHangTimes,
    onUpdateHangTime,
    onDeleteHangTime,
    onUpdateUserProfile,
    runMigration,
    getMatches,
    getPossibleMatches,
    getPictureForUser,
    refreshUserPictures,
  };
};

const UserStateContainer = createContainer(useUserState);

export default UserStateContainer;
