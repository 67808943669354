import { ArgumentError } from './HangleExceptions';
import { Redirect, useLocation } from 'react-router';

export interface AuthProps {
  redirectUri: string;
  version: string;
}

function AuthV1(props: AuthProps) {
  const { redirectUri, version } = props;
  if (version !== '1.0') {
    throw new ArgumentError(`Invalid version: ${props.version}`);
  }
  if (!redirectUri) {
    throw new ArgumentError(`Missing redirect URI`);
  }
  if (!redirectUri.startsWith('/')) {
    throw new ArgumentError(`Invalid redirect URI`);
  }
  console.log(`Auth success. Redirecting to: ${redirectUri}`);
  return <Redirect to={redirectUri} />;
}

export function useFacebookStateParam() {
  const location = useLocation();
  const redirectUri = location.pathname + location.search + location.hash;
  const props: AuthProps = {
    version: '1.0',
    redirectUri,
  };
  return JSON.stringify(props);
}

export default AuthV1;
