// The IDs of all main objects (users and hangtimes) have one-letter names for their ID properties because
// our storage capacity, performance, and cost is all based on total size of data (including attribute names!)
// For most fields the size of the name won't matter much (so it's not worth making them very short) but IDs
// are used hundreds of times per user so keeping them short could have non-trivial positive impact. So we'll
// keep them short!

import { ObjectId } from 'bson';
import {
  Boolean,
  Number,
  String,
  Array as RunArray,
  Record,
  Null,
  Static,
  Undefined,
  InstanceOf,
  Guard,
} from 'runtypes';
import { canonicalizePhoneNumberForViewing } from './phoneNumbers';
import { ArgumentError } from './HangleExceptions';
import { Temporal } from '@js-temporal/polyfill';

const objectIdTypeGuard = (o: unknown): o is ObjectId => ObjectId.isValid(o as string | number | ObjectId);
const ObjectIdRuntype = Guard(objectIdTypeGuard, { name: 'ObjectId' });

const FriendRuntypeInternal = Record({
  /** id of this friendship */
  f: ObjectIdRuntype,
  /** id of other user in this friendship (or of own user if it's a friend request) */
  u: ObjectIdRuntype,
  name: String,
  firstName: String.Or(Null),
  lastName: String.Or(Null),
  email: String,
  pictureUrl: String.Or(Null),
  phone: String.Or(Null).Or(Undefined),
  tags: RunArray(String),
  rank: Number.Or(Null).Or(Undefined),
  lastHangTimeId: String.Or(Null).Or(Undefined),
  last: InstanceOf(Temporal.Instant).Or(Null),
  active: Boolean,
  sentAt: InstanceOf(Temporal.Instant),
  sentBy: ObjectIdRuntype,
  acceptedAt: InstanceOf(Temporal.Instant).Or(Null),
  acceptedBy: ObjectIdRuntype.Or(Null),
  removedAt: InstanceOf(Temporal.Instant).Or(Null),
  removedBy: ObjectIdRuntype.Or(Null),
  phoneShared: Boolean,
  emailShared: Boolean,
});

const constraint = (f: Static<typeof FriendRuntypeInternal>): true | string => {
  const { email, tags, phone } = f;

  // TODO: email validation
  if (!email) {
    // TODO: need to allow phone-only friends to be added!
    return 'Email is missing';
  }

  if (!tags || !tags.length || typeof tags[0] !== 'string') {
    return 'Friends must have at least one tag';
  }

  // TODO: refactor tag checking to a more global place
  const sorted = [...tags].sort();
  for (let i = 0; i < tags.length; i++) {
    if (!sorted[i] || !sorted[i].trim()) {
      return `tags cannot be blank`;
    }
    if (sorted[i] !== tags[i]) {
      return 'tags must be sorted in alphabetical order';
    }
    if (i > 0 && sorted[i] === sorted[i - 1]) {
      return `duplicate tag: ${sorted[i]}`;
    }
  }

  // ensure that the phone number was normalized on the client, and fail if it wasn't
  if (phone) {
    const canonicalPhoneNumber = canonicalizePhoneNumberForViewing(phone);
    if (phone !== canonicalPhoneNumber) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `Phone ${phone} was not converted to canonical phone format ${canonicalPhoneNumber}`;
    }
  }

  const nameChecks = {
    name: "Friend's name is missing",
    firstName: "Friend's first name is missing",
    lastName: "Friend's last name is missing",
  };
  for (const [key, msg] of Object.entries(nameChecks)) {
    const value = f[key as keyof typeof nameChecks];
    if (!value || typeof value !== 'string' || value.trim().length === 0) {
      return msg;
    }
  }

  try {
    getFriendshipStatus(f);
  } catch (e) {
    return (e as Error).message;
  }

  return true;
};

export const FriendRuntype = FriendRuntypeInternal.withConstraint(constraint);

export type Friend = Static<typeof FriendRuntype>;

export enum FriendshipStatus {
  ACTIVE = 'A',
  REQUEST = 'R',
  WITHDRAWN = 'W',
  DENIED = 'D',
  UNFRIENDED = 'U',
  INVALID = 'I',
}

//  Active   AcceptedAt/By   RemovedAt/By   State
// ============================================================
//  True     True            False          ACTIVE
//  False    False           False          REQUEST
//  False    False           True           DENIED or WITHDRAWN
//  False    True            True           UNFRIENDED
//
export const getFriendshipStatus = (f: Friend): FriendshipStatus => {
  const propsToShow: Array<keyof Friend> = ['f', 'u', 'active', 'acceptedAt', 'acceptedBy', 'removedAt', 'removedBy'];
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const showProps = () => propsToShow.map(p => `${p as string}: ${f[p]}`).join(', ');

  const currentUser = f.sentBy;
  if (!currentUser) {
    throw new ArgumentError(`Invalid friendship state for f: ${showProps()}`);
  }
  // removed** and accepted** properties must match
  if ((f.removedAt && !f.removedBy) || (f.acceptedAt && !f.acceptedBy)) {
    throw new ArgumentError(`Invalid friendship state for f: ${showProps()}`);
  }

  if (f.active) {
    if (!currentUser || !f.acceptedBy || f.removedBy) {
      throw new ArgumentError(`Invalid friendship state for f: ${showProps()}`);
    }
    return FriendshipStatus.ACTIVE;
  } else if (!f.acceptedBy && !f.removedBy) {
    return FriendshipStatus.REQUEST;
  } else if (f.removedBy) {
    return currentUser.equals(f.removedBy) ? FriendshipStatus.WITHDRAWN : FriendshipStatus.DENIED;
  } else if (f.acceptedBy) {
    return FriendshipStatus.UNFRIENDED;
  } else {
    throw new ArgumentError(`Invalid friendship state for f: ${showProps()}`);
  }
};

/*
export interface Friend {
  f: ObjectId,   // ID of this friend for this user. 
  u?: ObjectId | null,   // if this is a Hangle user, what's the User ID? If null, then it's not a Hangle user (yet!)
  name: string,
  firstName: string | null,
  lastName: string | null,
  email: string,
  phone: string | null,
  tags?: string[] | null,
  rank?: number | null,
  lastHangTimeId?: string | null,
  last?: string | null, // TODO: switch to a real date
  active: boolean,
  sentAt: Temporal.Instant | null,
  acceptedAt: Temporal.Instant | null,
  rejectedAt: Temporal.Instant | null,
  withdrawnAt: Temporal.Instant | null,
  withdrawnBy: ObjectId | null,
}
*/

// TODO: recent activity with this friend?
// TODO: info about how much you want to hang with them?
// TODO: should we create Hangle users for everyone added, that can be merged if the user actually signs up?
