import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { HangleModalProps } from './ContextModal';

export type ReactStrapColors = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link';

export interface MessageBoxButton {
  color: ReactStrapColors | string;
  label: string;
  onClick?: () => Promise<void> | void;
}

export interface MessageBoxProps extends HangleModalProps {
  message: React.ReactNode | string;
  title: string;
  buttons: MessageBoxButton[];
  oppositeSideButtons?: MessageBoxButton[];
  isOpen: boolean | (() => boolean);
  onClose: () => void; // called to close modal to enable "controlled" modals
}

interface RenderButtonsProps {
  buttons: MessageBoxButton[];
  toggle: (e?: React.MouseEvent, onClick?: (e?: React.MouseEvent) => void | Promise<void>) => Promise<void>;
}
const RenderButtons = (props: RenderButtonsProps) => (
  <>
    {props.buttons.map((b, i) => (
      <React.Fragment key={i}>
        {i ? ' ' : ''}
        <Button color={b.color} onClick={async (e: React.MouseEvent) => await props.toggle(e, b.onClick)}>
          {b.label}
        </Button>
      </React.Fragment>
    ))}
  </>
);

const MessageBox = (props: MessageBoxProps) => {
  const { onClose, isOpen: isOpenOriginal, title, message, buttons, oppositeSideButtons, ...rest } = props;
  const isOpen = typeof isOpenOriginal === 'function' ? isOpenOriginal() : !!isOpenOriginal;

  const toggle = async (e?: React.MouseEvent, onClick?: (e?: React.MouseEvent) => void | Promise<void>) => {
    if (onClick) {
      await onClick(e);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={onClose} {...rest}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <RenderButtons buttons={buttons} toggle={toggle} />
        {oppositeSideButtons && (
          <div style={buttonContainerStyle}>
            <RenderButtons buttons={oppositeSideButtons} toggle={toggle} />
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};

const buttonContainerStyle = { float: 'right' } as const;

export default MessageBox;
