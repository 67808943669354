type ReadonlyAndNotUndefined<T> = {
  readonly [P in keyof T]: string;
};

export const {
  REACT_APP_FACEBOOK_APP_SECRET,
  REACT_APP_FACEBOOK_APP_ID,
  REACT_APP_BACKEND,
  REACT_APP_GOOGLE_CLIENT_SECRET,
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env as ReadonlyAndNotUndefined<typeof process.env>;
