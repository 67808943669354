import { useMemo } from 'react';
// import DurationPicker from './DurationPicker';
// import { HangTime } from './HangTime';
import UserStateContainer from './UserStateContainer';
import Calendar from './Calendar';
import { usePlainDateMemo, usePlainDateState, usePlainTimeMemo, useMemoCustom, usePlainTimeState } from './useHooks';
import { areHangTimeArraysEqual } from './HangTime';
import { Temporal } from '@js-temporal/polyfill';

/*
const formatDate = (h: HangTime) => {
  if (h.date === null) {
    throw new Error('date cannot be null');
  } else if (h.minutes === null) {
    throw new Error('minutes cannot be null');
  } else if (h.startTime === null) {
    throw new Error('startTime cannot be null');
  }

  const days = DateTime.fromJSDate(h.date).diffNow('days').days;
  const hour = h.startTime.getHours();
  if (days === 0) {
    const result = hour < 20 ? 'today' : 'tonight';
    return result;
  } else if (days === 1) {
    const result =
      hour < 12
        ? 'tomorrow morning'
        : hour < 18
        ? 'tomorrow afternoon'
        : hour < 20
        ? 'tomorrow evening'
        : 'tomorrow night';
    return result;
  } else {
    return DateTime.fromJSDate(h.date).toFormat('EEE M/d/y');
  }
};

const formatHangtime = (h: HangTime) => {
  if (h.date === null) {
    throw new Error('date cannot be null');
  } else if (h.minutes === null) {
    throw new Error('minutes cannot be null');
  } else if (h.startTime === null) {
    throw new Error('startTime cannot be null');
  }
  const dateString = formatDate(h);
  return `${dateString} @ ${DateTime.fromJSDate(h.startTime).toFormat('h:mm a')} for ${DurationPicker.formatDuration(
    h.minutes
  )}`;
};
*/
export type CalendarViewType = 'day' | 'list'; // | 'week'

export interface HangTimesTabProps {
  date: Temporal.PlainDate;
  time: Temporal.PlainTime;
  view: CalendarViewType;
}

const HangTimesTab = (props: HangTimesTabProps & typeof defaultProps) => {
  const { userState } = UserStateContainer.useContainer();
  const date = usePlainDateMemo(props.date);
  const time = usePlainTimeMemo(props.time);
  const [initialDate /*setInitialDate*/] = usePlainDateState(date);
  const [initialTime /*setInitialTime*/] = usePlainTimeState(time);
  const hangTimes = useMemoCustom(userState.hangTimes, areHangTimeArraysEqual);

  //  const time = useDateMemo(props.time);
  const view = props.view;
  const jsx = useMemo(
    () => <Calendar hangTimes={hangTimes} initialDate={initialDate} initialTime={initialTime} view={view} />,
    [hangTimes, initialDate, initialTime, view]
  );
  return jsx;
};

const defaultProps: HangTimesTabProps = {
  date: Temporal.Now.plainDateISO(),
  time: Temporal.PlainTime.from({ hour: 15 }), // default to 3PM which will make lunch and dinner visible
  view: 'day',
};
HangTimesTab.defaultProps = defaultProps;

export default HangTimesTab;
