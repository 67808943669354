import { AxiosError as OriginalAxiosError } from 'axios';

export type AxiosError<T> = OriginalAxiosError<T>;

// for AWS Amplify errors
export type AwsAmplifyError<T = unknown> = OriginalAxiosError<T>;

// from https://github.com/types/lib-http-status-codes/blob/master/http-status-codes.d.ts
export enum HTTPStatusCodes {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTIPLE_CHOICES = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  USE_PROXY = 305,
  TEMPORARY_REDIRECT = 307,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  REQUEST_ENTITY_TOO_LARGE = 413,
  REQUEST_URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}

export interface HasHttpStatusCode extends Error {
  statusCode: HTTPStatusCodes;
}

// See https://stackoverflow.com/a/48342359/126352
// Set the prototype explicitly. If you skip this, instanceof will not work :-(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resetPrototype = (self: any, target: any): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const actualProto = target.prototype;
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(self, actualProto);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    self.__proto__ = actualProto;
  }
};

export class NotAuthenticatedError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.UNAUTHORIZED;
}

export class LoginRequiredError extends NotAuthenticatedError implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.UNAUTHORIZED;
}

export class ArgumentError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.BAD_REQUEST;
}

export class InternalServerError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.INTERNAL_SERVER_ERROR;
}

export class NotFoundError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.NOT_FOUND;
}

export class NotImplementedError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.NOT_IMPLEMENTED;
}

export class AlreadyExistsError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.CONFLICT;
}

export class RetryNeededError extends Error implements HasHttpStatusCode {
  constructor(m: string) {
    super(m); // 'Error' breaks prototype chain here
    resetPrototype(this, new.target);
  }
  public statusCode = HTTPStatusCodes.CONTINUE;
}
