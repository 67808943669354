// adapted from https://github.com/lifeomic/facebook-test-users/blob/master/src/testUsers.js
import axios from 'axios';
import find from 'lodash/find';
import get from 'lodash/get';
import merge from 'lodash/merge';
import zip from 'lodash/zip';
import FbGraphApi from 'facebook-graph-api';

const GRAPH_API_BASE = 'https://graph.facebook.com/v5.0';

export type FacebookUser = FbGraphApi.FbUser;

/** Get a list of test users associated with an app. From
 * https://developers.facebook.com/docs/graph-api/reference/v5.0/app/accounts/test-users
 * */
export interface TestUserGetResponseFields {
  /** The user ID of the test user. */
  id: string;

  /** The access token for the test user and this app. This field is only
   * visible if the test user has installed the app. */
  access_token?: string;

  /** This URL will allow the test user account to be logged into. The URL will
   * expire one hour after it is generated, or after the first time it is used.
   * */
  login_url: string;
}

/** Create a Facebook test user or associate that user with an app. From
 * https://developers.facebook.com/docs/graph-api/reference/v5.0/app/accounts/test-users
 * */
export interface TestUserCreateRequestFields {
  /** Automatically installs the app for the test user once it is created or
   * associated. */
  installed: boolean;

  /** Comma-separated list of permissions that are automatically granted for the
   * app when this test user is created or associated. */
  permissions: string;

  /** The name for the test user.When left blank, a random name will be
   * automatically generated. */
  name: string;

  /** When associating existing test users with other apps, this is the app
   * access token of any app that is already associated with the test user. The
   * { app-id } in the publishing request in this case should be the app that
   * will is the target to associate with the test user. The API request should
   * also be signed with the app access token of that target app.Required when
   * associating test users, but should not be used when creating new test
   * users. */
  owner_access_token?: string;

  /** ID of an existing test user to associate with another app. Required when
   * associating test users, but should not be used when creating new test users.
   */
  uid?: string;
}

/** Response when creating a Facebook test user or associating the user with an
 * app. From
 * https://developers.facebook.com/docs/graph-api/reference/v5.0/app/accounts/test-users
 * */
export interface TestUserCreateResponseFields {
  /** The new test user ID */
  id: string;

  /** The access token belonging to the test user.Only included when the test user has installed as true. */
  access_token: string;

  /** This URL will allow the test user account to be logged into. The URL will expire one hour after it is generated, or after the first time it is used. */
  login_url: string;

  /** The test user's email address. */
  email: string;

  /** The test user's password. */
  password: string;
}

/*
type UserFieldName =
  | 'id'
  | 'name'
  | 'email'
  | 'about'
  | 'address'
  | 'admin_notes'
  | 'age_range'
  | 'auth_method'
  | 'birthday'
  | 'can_review_measurement_request'
  | 'education'
  | 'favorite_athletes'
  | 'favorite_teams'
  | 'first_name'
  | 'gender'
  | 'hometown'
  | 'inspirational_people'
  | 'install_type'
  | 'installed'
  | 'interested_in'
  | 'is_famedeeplinkinguser'
  | 'is_shared_login'
  | 'labels'
  | 'languages'
  | 'last_name'
  | 'link'
  | 'location'
  | 'meeting_for'
  | 'middle_name'
  | 'name_format'
  | 'payment_pricepoints'
  | 'political'
  | 'profile_pic'
  | 'public_key'
  | 'quotes'
  | 'relationship_status'
  | 'religion'
  | 'security_settings'
  | 'shared_login_upgrade_required_by'
  | 'short_name'
  | 'significant_other'
  | 'sports'
  | 'test_group'
  | 'token_for_business'
  | 'video_upload_limits'
  | 'viewer_can_send_gift'
  | 'website'
  | 'work'
  | 'accounts'
  | 'ad_studies'
  | 'adaccounts'
  | 'albums'
  | 'applications'
  | 'apprequestformerrecipients'
  | 'apprequests'
  | 'assigned_ad_accounts'
  | 'assigned_business_asset_groups'
  | 'assigned_pages'
  | 'assigned_product_catalogs'
  | 'books'
  | 'brand_teams'
  | 'business_users'
  | 'businesses'
  | 'businesssettinglogs'
  | 'checkins'
  | 'commission_splits'
  | 'conversations'
  | 'custom_labels'
  | 'domains'
  | 'events'
  | 'family'
  | 'feed'
  | 'friendlists'
  | 'friendrequests'
  | 'friends'
  | 'games'
  | 'groups'
  | 'home'
  | 'ids_for_apps'
  | 'ids_for_business'
  | 'ids_for_pages'
  | 'inbox'
  | 'insights'
  | 'likes'
  | 'live_encoders'
  | 'live_videos'
  | 'movies'
  | 'music'
  | 'notifications'
  | 'notify_me'
  | 'outbox'
  | 'ownerapps'
  | 'payment.subscriptions'
  | 'payment_transactions'
  | 'payments'
  | 'permissions'
  | 'personal_ad_accounts'
  | 'photos'
  | 'picture'
  | 'platformrequests'
  | 'pokes'
  | 'posts'
  | 'privacy_options'
  | 'promotable_domains'
  | 'promotable_events'
  | 'ratings'
  | 'request_history'
  | 'rich_media_documents'
  | 'screennames'
  | 'session_keys'
  | 'taggable_friends'
  | 'tagged'
  | 'television'
  | 'threads'
  | 'updates'
  | 'videos';
*/

export type Permission =
  | 'public_profile'
  | 'instagram_basic'
  | 'instagram_content_publish'
  | 'instagram_manage_comments'
  | 'instagram_manage_insights'
  | 'publish_video'
  | 'pages_messaging'
  | 'ads_management'
  | 'ads_read'
  | 'business_management'
  | 'leads_retrieval'
  | 'manage_pages'
  | 'pages_manage_cta'
  | 'pages_manage_instant_articles'
  | 'pages_show_list'
  | 'pages_show_list'
  | 'publish_pages'
  | 'read_insights'
  | 'email'
  | 'groups_access_member_info'
  | 'publish_to_groups'
  | 'user_age_range'
  | 'user_birthday'
  | 'user_events'
  | 'user_friends'
  | 'user_gender'
  | 'user_hometown'
  | 'user_likes'
  | 'user_link'
  | 'user_location'
  | 'user_photos'
  | 'user_posts'
  | 'user_tagged_places'
  | 'user_videos';

class Application {
  static async getAccessToken({ appId, appSecret }: { appId: string; appSecret: string }) {
    const response = await axios.get(`${GRAPH_API_BASE}/oauth/access_token`, {
      params: {
        client_id: appId,
        client_secret: appSecret,
        grant_type: 'client_credentials',
      },
    });

    const result = response.data as { access_token: string };
    return result.access_token;
  }

  _appId: string;
  _client: ReturnType<typeof axios.create>;
  _testUsersUrl: string;

  constructor({ accessToken, appId }: { accessToken: string; appId: string }) {
    this._appId = appId;

    this._client = axios.create({
      baseURL: GRAPH_API_BASE,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    this._testUsersUrl = `/${this._appId}/accounts/test-users`;
  }

  async createTestUser(options: TestUserCreateRequestFields) {
    const response = await this._client.post(this._testUsersUrl, options, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data as TestUserCreateResponseFields;
  }

  async deleteTestUser({ id }: { id: string }) {
    await this._client.delete(`/${id}`);
  }

  async findTestUser(fields: Record<keyof FacebookUser, unknown>) {
    const fieldNames = Object.keys(fields) as (keyof FacebookUser)[];
    const users = await this.listTestUsers({ includeFields: fieldNames });
    return find(users, fields) || null;
  }

  async getTestUser({ id, includeFields }: { id: string; includeFields: (keyof FacebookUser)[] }) {
    const encodedFields = encodeURIComponent(includeFields.join(','));
    const response = await this._client.get(`/${id}?fields=${encodedFields}`);
    return response.data as Partial<FacebookUser>;
  }

  async listTestUsers<T extends Partial<FacebookUser>>(options: { includeFields?: (keyof T)[] } = {}) {
    type UserFieldName = keyof Partial<FacebookUser>;
    type ResultType = TestUserGetResponseFields & T;
    const { includeFields } = options;
    const users = await this._getAllPages(this._testUsersUrl);

    if (includeFields) {
      const userFields = await Promise.all(
        users.map(user => this.getTestUser({ id: user.id, includeFields: includeFields as UserFieldName[] }))
      );
      const usersWithFields = zip(users, userFields).map(([user, fields]) => merge(user, fields));
      return usersWithFields as ResultType[];
    }

    return users as ResultType[];
  }

  async _getAllPages(url: string) {
    const client = this._client;
    const results = [] as TestUserGetResponseFields[];

    let next = url;

    while (next) {
      const response = await client.get<{ data: TestUserGetResponseFields[] }>(next);
      results.push(...response.data.data);
      next = get(response, 'data.paging.next') as string;
    }

    return results;
  }
}

export async function createClient({ appId, appSecret }: { appId: string; appSecret: string }) {
  const accessToken = await Application.getAccessToken({ appId, appSecret });
  return new Application({ accessToken, appId });
}
