import { Fragment } from 'react';
import { Friend } from './Friend';
import FriendLink from './FriendLink';

function FriendNames(props: { friendIds: Friend['u'][]; friends: Friend[]; conjunction: string }) {
  const { friendIds, friends, conjunction } = props;
  return (
    <>
      {friendIds.map((friendId, i) => {
        const friend = friends.find(f => f.u.equals(friendId));
        return friend ? (
          <Fragment key={friend.f.toHexString()}>
            {i > 0 ? ` ${conjunction} ` : ''}
            <FriendLink friend={friend} />
          </Fragment>
        ) : null;
      })}
    </>
  );
}
export default FriendNames;
