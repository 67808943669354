const emailRegex = new RegExp(
  "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
  'i'
);

// here's a simpler, alternate regex we could consider
// /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isEmailValid = (value: string) => {
  return emailRegex.test(value);
};
