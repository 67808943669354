import React, { CSSProperties, createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { ModalState } from './ModalState';
import { v4 as uuidv4 } from 'uuid';
import { Modal, ModalProps } from 'reactstrap';
import MessageBox from './MessageBox';
import { createContainer } from 'unstated-next';

export interface ModalsState {
  modals: ModalState[];
}

interface ModalStringContentProps {
  message: string;
  handleClose: () => void;
  modalId: string;
  isOpen: boolean | (() => boolean);
}

// default modal for string content: show an error/warning message dialog
// special behavior to make call stacks readable.
const ModalStringContent: React.FunctionComponent<ModalStringContentProps> = (props: ModalStringContentProps) => {
  const { message, handleClose } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const onClose = () => {
    setIsOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  // special treatment for messages that contain call stacks
  const transformMessage = (s: string) => s.replace(/\\n/g, '\n');
  const hasSpecialChars = (s: string) => s.includes('\\n');
  const messageStyles: (s: string) => CSSProperties | undefined = (s: string) =>
    hasSpecialChars(s)
      ? {
          unicodeBidi: 'embed',
          fontFamily: 'monospace',
          whiteSpace: 'pre-wrap',
        }
      : undefined;

  return (
    <MessageBox
      title="Error"
      message={<div style={messageStyles(message)}>{transformMessage(message)}</div>}
      buttons={[{ color: 'primary', label: 'Close', onClick: onClose }]}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

const closeModalContext = createContext<(callback?: () => void) => void>(() => {
  throw new Error('Cannot change modals outside subtree of a modal context provider');
});

// const useCloseModal = () => useContext(closeModalContext);

/** Render this child component inside a parent modal in order to cause the
 * parent modal to close. It doesn't render anything; it's just a wrapper for
 * useEffect() to close the nearest parent modal. */
function ModalCloser() {
  const closeModal = useContext(closeModalContext);
  useEffect(() => closeModal(), [closeModal]);
  return null;
}

export type HangleModalProps = Omit<ModalProps, 'isOpen' | 'toggle' | 'onClosed'>;

const useModals = () => {
  const [modals, setModals] = useState([] as ModalState[]);

  const closeModal = useCallback(
    (modalId: string) =>
      setModals(currentModals => {
        const target = currentModals.find(m => m.modalId === modalId);
        if (target) {
          // target.isOpen = false;  // TODO: do we need to tell the modal to close itself via a setState to the modal?
          console.log(`Closing modal: ${target.modalId}`);
          return currentModals.filter(m => m !== target);
        } else {
          console.log(`Modal not found: ${modalId}`);
          return currentModals;
        }
        // Currently we're using reactstrap modals. But if we switch back to
        // using plain react-modal, then we'd need to work around a problem with
        // react-modal which is that in order to hide a modal, you need to
        // actually render it (maybe only once?) with isOpen=false. Reactstrap,
        // ironically, *doesn't* if you render modals with isOpen=false. This
        // might be due to how we're implementing modals upstream. In any case,
        // if we go back to react-modal, then we'll need to figure out how to
        // hide them when closed without an infinite list of stale modals
        // hanging around.
      }),
    []
  );

  const addModal = useCallback(
    (content: string | JSX.Element, modalProps?: HangleModalProps) => {
      const newModalId = uuidv4();

      const closer = () => closeModal(newModalId);

      const newModalContent =
        typeof content === 'string' ? (
          <ModalStringContent
            {...{
              message: content,
              handleClose: closer,
              modalId: newModalId,
              isOpen: true,
            }}
          />
        ) : (
          <Modal isOpen={true} toggle={closer} {...modalProps}>
            <closeModalContext.Provider value={closer}>{content}</closeModalContext.Provider>
          </Modal>
        );

      const modal: ModalState = {
        content: newModalContent,
        isOpen: true,
        modalId: newModalId,
      };

      setModals(oldModals => [modal, ...oldModals]);
      // TODO: await-able modals
    },
    [closeModal]
  );

  const result = useMemo(
    () => ({
      addModal,
      modals,
    }),
    [addModal, modals]
  );

  return result;
};

const ModalContainer = createContainer(useModals);

const ModalRoot = () => {
  const { modals } = ModalContainer.useContainer();
  return (
    <>
      {modals.map(modal => {
        console.log(`Rendering modal: ${modal.modalId} (isOpen=${modal.isOpen})`);
        return <React.Fragment key={modal.modalId}>{modal.content}</React.Fragment>;
      })}
    </>
  );
};

export { ModalRoot, ModalCloser, ModalContainer };
