import React from 'react';
import { Container as ReactstrapContainer } from 'reactstrap';
import TopBar from './TopBar';
import { ModalContainer, ModalRoot } from './ContextModal';
import TagsContainer from './TagsContainer';
import UserStateContainer from './UserStateContainer';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Pages';
import BottomBar from './BottomBar';
import 'react-router-tabs/styles/react-router-tabs.css';
import { LastInteractedWithContainer } from './LastInteractedWith';
import IgnoreSafariMiddleClicks from './IgnoreSafariMiddleClicks';

function RealApp() {
  return (
    <ReactstrapContainer fluid={true}>
      <TopBar />
      <div className="main-container">
        <div className="tab-content">
          <Routes />
        </div>
        <BottomBar />
      </div>
    </ReactstrapContainer>
  );
}

function App() {
  return (
    <React.StrictMode>
      <LastInteractedWithContainer.Provider>
        <IgnoreSafariMiddleClicks>
          <Router>
            <ModalContainer.Provider>
              <UserStateContainer.Provider>
                <TagsContainer.Provider>
                  <RealApp />
                  <ModalRoot />
                </TagsContainer.Provider>
              </UserStateContainer.Provider>
            </ModalContainer.Provider>
          </Router>
        </IgnoreSafariMiddleClicks>
      </LastInteractedWithContainer.Provider>
    </React.StrictMode>
  );
}

export default App;
