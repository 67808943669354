import { useRef, useCallback, useMemo, CSSProperties, memo } from 'react';
import { HangTime } from './HangTime';
import { getDayIndex, formatTimeNoSeconds, MINUTES_PER_DAY } from './dateUtils';
import { BOTTOM_CALENDAR_CONTENT_HEIGHT } from './CalendarBottomScrollable';

import './react-big-calendar.css';
// import './react-big-calendar-dnd.css';
// import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { Temporal } from '@js-temporal/polyfill';

export interface CalendarBottomProps {
  hangTimes: HangTime[];
  // onEventDrop: (arg: { hangTime: HangTime; start: Date; end: Date }) => void;
  selectedDate: Temporal.PlainDate;
  time: Temporal.PlainTime; // this time should be in the middle of the view
  height: number;
  onEventClick: (arg: { hangTime: HangTime }) => void;
  onTimeClick: (arg: { time: Temporal.PlainTime; date: Temporal.PlainDate }) => void;
  // onTimeScroll: (arg: { scrollTopTime: Date; date: Date; scrollTop: number; ratio: number }) => void;
}

const hours = new Array<null>(24).fill(null).map((unused, hour) => ({
  hour: hour,
  time: Temporal.PlainTime.from({ hour }),
  formatted: formatTimeNoSeconds(Temporal.PlainTime.from({ hour })),
}));

const TimeGutter = () => {
  return (
    <div className="rbc-time-gutter rbc-time-column">
      {hours.map(h => (
        <div key={h.hour} className="rbc-timeslot-group">
          <div className="rbc-time-slot">
            <span className="rbc-label">{h.formatted}</span>
          </div>
          <div className="rbc-time-slot" />
        </div>
      ))}
    </div>
  );
};

const TimeSlots = () => {
  return (
    <>
      {hours.map(h => (
        <div key={h.hour} className="rbc-timeslot-group">
          <div className="rbc-time-slot" />
          <div className="rbc-time-slot" />
        </div>
      ))}
    </>
  );
};

interface CalendarEventsProps {
  hangTimes: HangTime[];
  date: Temporal.PlainDate;
  onEventClick: CalendarBottomProps['onEventClick'];
}

function getEventStyle(hangTime: HangTime, columns: number, columnIndex: number) {
  const { startTime, minutes } = hangTime;
  const startRatio = ratioFromTime(startTime);
  const heightRatio = Math.min(minutes / MINUTES_PER_DAY, 1.0 - startRatio);
  const style: CSSProperties = {
    ...{
      top: `${startRatio * 100}%`,
      height: `${heightRatio * 100}%`,
      left: `${(columnIndex / columns) * 100}%`, // TODO: columns for double-booked events
      width: `${1.0 / columns}`,
    },
    ...(hangTime.hangId
      ? {
          // TODO: add hover styles for hangs
          // TODO: align presentation code between list and calendar views
          backgroundColor: '#477946',
        }
      : {}),
  };
  return style;
}

interface CalendarOneEventProps {
  hangTime: HangTime;
  onEventClick: CalendarEventsProps['onEventClick'];
}

const CalendarOneEvent = memo(function CalendarOneEvent(props: CalendarOneEventProps) {
  const { hangTime, onEventClick } = props;
  const { startTime, minutes, tags } = hangTime;
  const style = getEventStyle(hangTime, 1, 0);
  const endTime = startTime.add({ minutes });
  // TODO: make sure this logic works when an event spans a daylight-savings change
  const allTags = tags ? tags.map(t => '#' + t).join(' ') : '';
  const name = hangTime.name ?? (allTags.length ? `Free Hang Time ${allTags}` : 'Free Hang Time');
  const timeLabel = `${formatTimeNoSeconds(startTime)} — ${formatTimeNoSeconds(endTime)}`;
  const title = `${timeLabel}: ${name}`;
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // prevent clicks from triggering another click handler (on the underlying timeslot)
      e.preventDefault();
      e.stopPropagation();

      if (onEventClick) {
        onEventClick({ hangTime });
      }
    },
    [onEventClick, hangTime]
  );
  return (
    <div title={title} className="rbc-event" style={style} onClick={onClick}>
      {/*<div className="rbc-event-label">{timeLabel}</div>*/}
      <div className="rbc-event-content">{name}</div>
    </div>
  );
});

const CalendarEvents = (props: CalendarEventsProps) => {
  const { hangTimes, onEventClick } = props;
  return (
    <div className="rbc-events-container">
      {hangTimes.map(h => (
        <CalendarOneEvent key={h.h.toHexString()} hangTime={h} onEventClick={onEventClick} />
      ))}
    </div>
  );
};

/**
 * Convert a percentage to a time-of-day, where 0% is midnight and 100% is
 * midnight of the next day.
 * @param {number} ratio - percentage of the day
 */
export function timeFromRatio(ratio: number) {
  const totalMinutes = Math.round(ratio * MINUTES_PER_DAY);
  const hour = Math.floor(totalMinutes / 60);
  const minute = totalMinutes % 60;
  const time = Temporal.PlainTime.from({ hour, minute });
  return time;
}

/**
 * Converts a time-of-day to a percentage of the day before that time, where 0%
 * is midnight and 100% is midnight of the next day.
 * @param {Temporal.PlainTime | Temporal.ZonedDateTime |
 * Temporal.PlainDateTime} time - time-of-day to convert to a ratio
 */
export function ratioFromTime(time: Temporal.PlainTime | Temporal.ZonedDateTime | Temporal.PlainDateTime) {
  const totalMinutes = time.hour * 60 + time.minute;
  return totalMinutes / MINUTES_PER_DAY;
}

// TODO: if there's an adjacent time
const SLOT_MINUTES = 30;
function nearestTimeSlot(time: Temporal.PlainTime) {
  const { hour } = time;
  const minute = Math.floor(time.minute / SLOT_MINUTES) * SLOT_MINUTES;
  return Temporal.PlainTime.from({ hour, minute });
}

const CalendarBottom = (props: CalendarBottomProps) => {
  const { onTimeClick, onEventClick, hangTimes, height, selectedDate: date } = props;
  const calRef = useRef<HTMLDivElement>(null);
  const hangTimesThisDate = hangTimes.filter(h => h.startTime.toPlainDate().equals(date));

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onTimeClick && calRef.current) {
        const rect = calRef.current.getBoundingClientRect();
        const y = e.clientY - rect.top; //y position within the element.
        const time = timeFromRatio(y / height);
        const start = nearestTimeSlot(time);
        onTimeClick({ date, time: start });
      }
    },
    [onTimeClick, height, date]
  );

  /*
  const initialScrollComplete = useRef(false);
  useLayoutEffect(() => {
    if (calRef.current && !initialScrollComplete.current) {
      initialScrollComplete.current = true;
      if (!areDatesEqual(defaultTime, ZERO_LOCAL_DATE)) {
        const y = ratioFromTime(defaultTime) * height;
        calRef.current.scrollTop = y;
      }
    }
  }, [defaultTime, height]);
  */

  const contentStyle = useMemo(() => ({ height: BOTTOM_CALENDAR_CONTENT_HEIGHT }), []);

  const style = useMemo(() => ({ height }), [height]);

  // const index = getDayIndex(date);
  // console.log(`rendering index ${index}`);

  return (
    <div className="bottom-calendar" ref={calRef} style={style} onClick={onClick}>
      <div
        style={useMemo(
          () => ({ position: 'absolute', right: 18, top: 5, zIndex: 999, color: '#ccc', fontSize: 11 }),
          []
        )}
      >
        {date.toLocaleString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
        })}{' '}
        ({getDayIndex(date)})
      </div>
      <div className="rbc-time-content" style={contentStyle}>
        <TimeGutter />
        <div className="rbc-day-slot rbc-time-column">
          <TimeSlots />
          {hangTimesThisDate.length ? (
            <CalendarEvents hangTimes={hangTimesThisDate} date={date} onEventClick={onEventClick} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CalendarBottom;
