import { CSSProperties } from 'react';
import Select, { Props } from 'react-select/creatable';
import { StylesConfig } from 'react-select/src/styles';
import { Tag } from './Tag';

export interface TagEditorOption {
  value: string;
  label: string;
}

export function tagOptionsValidator(value: TagEditorOption[]) {
  const valid =
    typeof value === 'object' && value instanceof Array && value.length >= 1 && value[0].label.startsWith('#');
  return valid ? undefined : 'At least one tag required';
}

export const toTagEditorOption = (t: Tag | string): TagEditorOption => {
  const tag = typeof t === 'string' ? t : t.tag;
  return {
    label: `#${tag}`,
    value: tag,
  };
};
const menuStyle: CSSProperties = { zIndex: 10 };
const styleFunctions: StylesConfig<TagEditorOption, true> = {
  menu: defaultStyles => ({ ...defaultStyles, ...menuStyle }),
};

const TagEditor = (props: Props<TagEditorOption, true>) => {
  const { input, options, ...rest } = props;
  //  const values = optionsForSelect.filter(o => value && value.includes(o.value));
  return <Select<TagEditorOption> isMulti options={options} styles={styleFunctions} {...input} {...rest} />;
};

export default TagEditor;
