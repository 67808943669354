import { parsePhoneNumberFromString } from 'libphonenumber-js';

// const phoneRegex = new RegExp('^[0-9()-.+ ]{4,15}$', 'i');

export const isValidPhoneNumber = (s: string): boolean => {
  return Boolean(s) && !!canonicalizePhoneNumberForViewing(s);
};

// TODO: canonicalization can change over time. Is there a good way to
// canonicalize a phone number (for searching by phone number) that won't vary
// over time?
// Also want to think about partial matching, e.g. w/o area code for
// the olds who don't live in 9 digits
// Also need to think about how this is internationalized-- how do we know
// which country code to use if the user doesn't input it?
export const canonicalizePhoneNumberForViewing = (s: string): string | null => {
  const parsed = parsePhoneNumberFromString(s, 'US');
  return parsed && parsed.isValid ? parsed.formatInternational() : null;
};
