import React from 'react';
import { Col, Button } from 'reactstrap';
import { Friend, getFriendshipStatus, FriendshipStatus } from './Friend';
import UserStateContainer from './UserStateContainer';
import LinkButton from './LinkButton';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import UserPicture from './UserPicture';
import TabHeaderContainer from './TabHeaderContainer';

const friendSorter = (a: Friend, b: Friend) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
};

const OneFriendWrapper = styled('div')`
  border-bottom: 1px solid #dee2e6;
  padding: 15px;
  position: relative;
  :hover {
    background-color: #f0f0f0;
  }
`;

const TagListWrapper = styled.div`
  display: inline-block;
  color: green;
  line-height: 1.2;
`;

const OneTag = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

const OneFriendInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  & div {
    flex: 1 1;
  }
  & div:first-of-type {
    flex: 0 0; /* don't stretch or shrink the profile picture */
  }
`;

const useUserState = () => {
  const { userState, onAcceptFriendRequest, onRejectFriendRequest, getPictureForUser } =
    UserStateContainer.useContainer();
  return { userState, onAcceptFriendRequest, onRejectFriendRequest, getPictureForUser };
};

const friendLinkNameStyle: React.CSSProperties = {
  display: 'inline-block',
  fontSize: '140%',
  fontWeight: 'bold',
  color: 'purple',
  marginRight: '15px',
  lineHeight: '1.3',
};

const friendLinkPictureStyle: React.CSSProperties = {};

const labelStyle = { fontSize: '14px !important', fontWeight: 'bold', color: '#999' } as const;
const ComingSoonLabel = () => <div style={labelStyle}>Coming Soon (waiting for reply)</div>;
const FriendRequestLabel = () => <div style={labelStyle}>Friend Request</div>;

const FriendsTab = () => {
  const stateContainer = useUserState();
  const activeFriends = stateContainer.userState.friends.filter(f => f.active);
  const friendRequests = stateContainer.userState.friends.filter(
    f => getFriendshipStatus(f) === FriendshipStatus.REQUEST
  );
  const friendRequestsForMe = friendRequests.filter(f => !f.sentBy.equals(stateContainer.userState._id));
  const friendRequestsFromMe = friendRequests.filter(f => f.sentBy.equals(stateContainer.userState._id));
  const friendsToDisplay = [friendRequestsForMe, activeFriends, friendRequestsFromMe]
    .map(list => list.sort(friendSorter))
    .reduce((acc, value) => acc.concat(value), []);

  const renderFriend = (friend: Friend, i: number) => {
    const isFriendRequest = getFriendshipStatus(friend) === FriendshipStatus.REQUEST;
    const isFriendRequestFromMe = isFriendRequest && friend.sentBy.equals(stateContainer.userState._id);
    const isFriendRequestForMe = isFriendRequest && !friend.sentBy.equals(stateContainer.userState._id);
    return (
      <OneFriendWrapper key={friend.f.toHexString()}>
        {isFriendRequestFromMe && <ComingSoonLabel />}
        {isFriendRequestForMe && <FriendRequestLabel />}
        <OneFriendInnerWrapper>
          <div>
            <Link style={friendLinkPictureStyle} to={`/friends/${friend.f.toHexString()}`}>
              <UserPicture name={friend.name} pictureUrl={friend.pictureUrl} userId={friend.u} />
            </Link>
          </div>
          <div>
            <Link style={friendLinkNameStyle} to={`/friends/${friend.f.toHexString()}`}>
              {friend.name}
            </Link>
            {friend.tags && (
              <TagListWrapper>
                {friend.tags.map(tag => (
                  <OneTag key={tag}>{'#' + tag} </OneTag>
                ))}
              </TagListWrapper>
            )}
            {isFriendRequestForMe && (
              <>
                <Button
                  color="primary"
                  style={primaryButtonStyle}
                  onClick={() => stateContainer.onAcceptFriendRequest(friend)}
                >
                  Accept
                </Button>
                <Button
                  color="secondary"
                  style={secondaryButtonStyle}
                  onClick={() => stateContainer.onRejectFriendRequest(friend)}
                >
                  Nope
                </Button>
              </>
            )}
          </div>
        </OneFriendInnerWrapper>
      </OneFriendWrapper>
    );
  };

  return (
    <>
      <TabHeaderContainer>
        <div style={tabHeaderInnerStyle}>
          <div>My Friends</div>
          <div style={friendsListInnerStyle}>
            <LinkButton to={'/friends/new'}>Add Friend</LinkButton>
          </div>
        </div>
      </TabHeaderContainer>
      <Col style={colStyle}>{friendsToDisplay.map(renderFriend)}</Col>
    </>
  );
};

const tabHeaderInnerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } as const;
const friendsListInnerStyle = { justifySelf: 'end' } as const;
const primaryButtonStyle = { marginLeft: '30px' } as const;
const secondaryButtonStyle = { marginLeft: '5px' } as const;
const colStyle = { overflowY: 'auto' } as const;

export default FriendsTab;
