import { CSSProperties } from 'react';
import { Label } from 'reactstrap';

interface HasChildrenProps {
  children: React.ReactNode;
}

interface FieldContainerProps extends HasChildrenProps {
  isLastInModal?: boolean;
  style?: CSSProperties;
}

const containerStyle: CSSProperties = { margin: '0 15px 18px 15px' };
const lastContainerStyle: CSSProperties = { margin: '5px 0 2px 0' };
export const FieldContainer: React.FunctionComponent<FieldContainerProps> = (props: FieldContainerProps) => {
  let style = props.isLastInModal ? lastContainerStyle : containerStyle;
  if (props.style) {
    style = { ...style, ...props.style };
  }
  return <div style={style}>{props.children}</div>;
};

const fieldLabelStyle: CSSProperties = { display: 'block', fontWeight: 'bold', margin: '0 0 5px 1px' };
export const FieldLabel: React.FunctionComponent<HasChildrenProps> = (props: HasChildrenProps) => (
  <Label style={fieldLabelStyle}>{props.children}</Label>
);
