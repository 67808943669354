import { useState } from 'react';
import { Button } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { Friend } from './Friend';
import { Tag } from './Tag';
import UserStateContainer from './UserStateContainer';
import { Redirect } from 'react-router-dom';
import Loader from './Loader';
import { FieldContainer, FieldLabel } from './FormFieldUtils';
import MessageBox from './MessageBox';
import TagEditor, { toTagEditorOption, TagEditorOption, tagOptionsValidator } from './TagEditor';
import { getNewValuePlaceholder } from './newValuePlaceholder';
import { canonicalizePhoneNumberForViewing, isValidPhoneNumber } from './phoneNumbers';
import TabHeader, { TabHeaderType } from './TabHeader';
import { isEmailValid } from './emailAddress';
import TagsContainer from './TagsContainer';
import { Temporal } from '@js-temporal/polyfill';

interface FormValues {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  tags: TagEditorOption[] | null;
  // TODO: rank! For new friends, should we seed a rank with a simple "how much do you want to hang?" slider?
}

export interface FriendEditProps {
  friend?: Friend; // omit to create new, provide to edit existing one
}

export interface FriendEditState {
  readonly tags: Tag[];
  readonly done: boolean;
  readonly showRemoveMessageBox: boolean;
}

type OrNull<T> = { [P in keyof T]: T[P] | null };
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type FriendViewModel = Omit<Friend, 'name' | 'email' | 'tags' | 'pictureUrl'> &
  Pick<OrNull<Friend>, 'name' | 'email' | 'tags' | 'pictureUrl'>;

const getInitialModelState = (): FriendViewModel => {
  return {
    f: getNewValuePlaceholder(), // ID of this friend for this user.
    u: getNewValuePlaceholder(), // if this is a Hangle user, what's the User ID? If "new user", then it's not a Hangle user (yet!)
    name: null,
    firstName: null,
    lastName: null,
    // TODO: short name? name format? middle name? just full name not first/last?
    email: null,
    phone: null,
    pictureUrl: null,
    tags: [],
    rank: null,
    lastHangTimeId: null,
    last: null,
    active: false,
    sentAt: Temporal.Now.instant(),
    sentBy: getNewValuePlaceholder(),
    acceptedAt: null,
    acceptedBy: null,
    removedAt: null,
    removedBy: null,
    emailShared: false,
    phoneShared: false,
  };
};

const firstNameValidator = (value: string) => {
  return value != null ? undefined : 'First name is required';
};

const lastNameValidator = (value: string) => {
  return undefined; // only first name required!
};

// TODO: allow new friends to have either phone or email. Don't require both.
const emailValidator = (value: string) => {
  return isEmailValid(value) ? undefined : 'Valid email is required';
};

const phoneValidator = (value: string) => {
  return !value || isValidPhoneNumber(value) ? undefined : 'Valid mobile phone number is required';
};

const phoneFormatter = (value: unknown, name: string) => {
  if (typeof value !== 'string') {
    return value;
  }
  const canonical = canonicalizePhoneNumberForViewing(value);
  return canonical || value;
};

const useUserState = () => {
  const { userState, onUpdateFriend, onCreateFriend, onDeleteFriend } = UserStateContainer.useContainer();
  return { userState, onUpdateFriend, onCreateFriend, onDeleteFriend };
};

const FriendEdit = (props: FriendEditProps) => {
  const [done, setDone] = useState(false);
  const [showRemoveMessageBox, setShowRemoveMessageBox] = useState(false);
  const stateContainer = useUserState();
  const { userTags, refreshUserTagList } = TagsContainer.useContainer();
  const userTagsOptions = userTags?.map(toTagEditorOption);

  function getInitialValues() {
    if (!props.friend) {
      return;
    }
    const { firstName, lastName, email, phone: phoneOriginal, tags: tagsOriginal } = props.friend;
    const tags = tagsOriginal ? tagsOriginal.map(toTagEditorOption) : [];
    const phone = phoneOriginal || null;
    return { firstName, lastName, email, phone, tags };
  }

  const onSubmit = async (values: FormValues) => {
    const { firstName, lastName, email, phone, tags } = values;
    const existingFriend = props.friend;
    const saveMethod = existingFriend ? stateContainer.onUpdateFriend : stateContainer.onCreateFriend;

    // TODO: validation to make sure there's a name, that there's a phone or email.
    if (phone && (typeof phone !== 'string' || phone.trim().length < 3)) {
      return;
    }
    if (!email || typeof email !== 'string' || email.trim().length < 3) {
      return;
    }

    const updated = {
      ...(existingFriend || getInitialModelState()),
      firstName,
      lastName,
      name: [firstName, lastName].filter(n => n != null).join(' '),
      email,
      phone,
      tags: (tags && tags.map(t => t.value).sort()) || [],
    };

    const success = await saveMethod(updated);

    if (success) {
      setDone(true);
    }
  };

  const onDeleteFriend = async () => {
    const success = await stateContainer.onDeleteFriend(props.friend!); // TODO: need Suspense here in case this takes a while!

    if (success) {
      setShowRemoveMessageBox(false);
      setDone(true);
    }
  };

  return done ? (
    // TODO: consider allowing caller to override the redirect URL
    <Redirect to={{ pathname: '/friends', search: '', hash: '' }} />
  ) : (
    <>
      <TabHeader type={TabHeaderType.Highlight}>Who's your new friend?</TabHeader>
      <Form<FormValues>
        onSubmit={async (values: FormValues) => onSubmit(values)}
        initialValues={getInitialValues()}
        render={({ handleSubmit, form, submitting, pristine, values, invalid }) => (
          <form onSubmit={handleSubmit} style={formStyle}>
            <FieldContainer>
              <Field name="firstName" validate={firstNameValidator}>
                {({ input, meta }) => (
                  <>
                    <FieldLabel>First Name</FieldLabel>
                    <input className="form-control" type="text" {...input} />
                    {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                  </>
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <Field name="lastName" allowNull validate={lastNameValidator}>
                {({ input, meta }) => (
                  <>
                    <FieldLabel>Last Name</FieldLabel>
                    <input className="form-control" type="text" {...input} />
                    {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                  </>
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <Field name="email" allowNull validate={emailValidator}>
                {({ input, meta }) => (
                  <>
                    <FieldLabel>Email</FieldLabel>
                    <input className="form-control" type="text" {...input} />
                    {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                  </>
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <Field name="phone" allowNull formatOnBlur validate={phoneValidator} format={phoneFormatter}>
                {({ input, meta }) => (
                  <>
                    <FieldLabel>Mobile Phone</FieldLabel>
                    <input className="form-control" type="text" {...input} />
                    {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                  </>
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <Field name="tags" allowNull validate={tagOptionsValidator}>
                {({ input, meta }) => (
                  <>
                    <FieldLabel>Tags</FieldLabel>
                    <Loader effect={refreshUserTagList} />
                    <TagEditor options={userTagsOptions} {...input} />
                    {meta.error && meta.touched && <span style={errorMessageStyle}>{meta.error}</span>}
                  </>
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <Button color="success" type="submit" disabled={pristine || invalid}>
                {props.friend ? 'Save' : 'Add Friend'}
              </Button>
              <Button color="secondary" style={cancelButtonStyle} onClick={() => setDone(true)}>
                <>Cancel</>
              </Button>
              {props.friend && (
                <Button
                  color="danger"
                  style={removeFriendButtonStyle}
                  onClick={() => {
                    console.log('remove clicked');
                    setShowRemoveMessageBox(true);
                  }}
                >
                  <>Remove Friend</>
                  <MessageBox
                    title="Remove Friend"
                    message="Are you sure you want to remove this friend?"
                    buttons={[
                      {
                        color: 'danger',
                        label: 'Remove Friend',
                        onClick: onDeleteFriend,
                      },
                      { color: 'secondary', label: "Don't Remove" },
                    ]}
                    isOpen={showRemoveMessageBox}
                    onClose={() => setShowRemoveMessageBox(false)}
                  />
                </Button>
              )}
            </FieldContainer>
          </form>
        )}
      />
    </>
  );
};
const formStyle = { marginTop: '15px' } as const;
const cancelButtonStyle = { marginLeft: '10px' } as const;
const removeFriendButtonStyle = { color: '#fff', float: 'right' } as const;
const errorMessageStyle = { color: 'red' } as const;

export default FriendEdit;
