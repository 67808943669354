// This code is adapted from
// https://github.com/dance2die/blog.sticky_components, which is sample code
// associated with
// https://dev.to/dance2die/react-sticky-event-with-intersection-observer-310h.
// That article provides a React implementation of the techniques in
// https://developers.google.com/web/updates/2017/09/sticky-headers, which
// produce an event when a `position:sticky` element moves into a "stuck" or
// "unstuck" state.

// We use this capability to change the selected date in the top calendar when
// the user scrolls in the list view of the bottom calendar.

// TODO: figure out how to correctly type the `as` parameter in these components
// TODO: typing of refs used in forwardRef
// TODO: do we need to use subscription logic like this? https://gist.github.com/bvaughn/e25397f70e8c65b0ae0d7c90b731b189
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useRef,
  forwardRef,
  useEffect,
  useState,
  useContext,
  createContext,
  ReactNode,
  MutableRefObject,
  CSSProperties,
  useMemo,
  Ref,
  useCallback,
  AllHTMLAttributes,
} from 'react';
import { useLogUnmount } from './useHooks';

let lastTextGlobal = '';

export function logStuck(
  target: HTMLElement,
  entry: IntersectionObserverEntry,
  root: HTMLElement,
  edge: string,
  type: string | undefined,
  isInitial: boolean
) {
  lastTextGlobal = target.innerText;
  console.log(
    `stuck: ${JSON.stringify(
      {
        edge,
        type,
        lastTextGlobal,
        isInitial,
        target: {
          tagName: target.tagName,
          innerText: target.innerText,
          boundingRect: target.getBoundingClientRect(),
        },
        root: {
          tagName: root.tagName,
          innerText: root.innerText,
          boundingRect: root.getBoundingClientRect(),
        },
        entry: {
          boundingClientRect: entry.boundingClientRect,
          intersectionRatio: entry.intersectionRatio,
          intersectionRect: entry.intersectionRect,
          isIntersecting: entry.isIntersecting,
          rootBounds: entry.rootBounds,
          time: new Date(entry.time),
          targetTagName: entry.target && entry.target.tagName,
          targetClassName: entry.target && entry.target.className,
        },
      },
      undefined,
      2
    )}`
  );
}
const sentinelBaseStyle: CSSProperties = {
  position: 'absolute',
  visibility: 'hidden',
  zIndex: -1,
  width: 1,
  left: 0,
  right: 0,
};
const topSentinelHeight = 1;

const styles: { [s: string]: CSSProperties } = {
  stickySection: {
    position: 'relative',
  },
  stickySentinelTop: {
    ...sentinelBaseStyle,
    height: topSentinelHeight,
    top: 0,
  },
  stickySentinelBottom: {
    ...sentinelBaseStyle,
    bottom: 0,
  },
};

interface StickyStateContextType {
  containerRef: MutableRefObject<HTMLElement | null>;
}
const StickyStateContext = createContext(undefined as unknown as StickyStateContextType);

function StickyProvider(props: { children: ReactNode }) {
  const state = {
    containerRef: useRef(null),
  };
  return <StickyStateContext.Provider value={state}>{props.children}</StickyStateContext.Provider>;
}

function useStickyState() {
  const context = useContext(StickyStateContext);
  return context;
}

interface StickySectionContextType {
  setStickyElement: (elem: HTMLElement | null) => void;
}
const StickySectionContext = createContext(undefined as unknown as StickySectionContextType);

function useStickySectionContext() {
  const context = useContext(StickySectionContext);
  return context;
}

export interface StickyProps extends Partial<Omit<AllHTMLAttributes<HTMLElement>, 'children'>> {
  children: ReactNode;
  as: any;
}

// https://stackoverflow.com/questions/40032592/typescript-workaround-for-rest-props-in-react
/**
 * Element with position:sticky (it's up to the caller to ensure this style is applied)
 */
function Sticky({ children, as: Component = 'div', ...rest }: StickyProps) {
  const { setStickyElement } = useStickySectionContext();
  return (
    <Component ref={setStickyElement} {...rest}>
      {children}
    </Component>
  );
}

type SentinelProps = {
  edge: 'top' | 'bottom';
  offsets: SentinelOffsets | null;
};

const Sentinel = forwardRef(function Sentinel({ edge, offsets }: SentinelProps, refForwarded: Ref<HTMLElement | null>) {
  const { topSentinelMarginTop, bottomSentinelHeight } = offsets || {};
  const style = useMemo(() => {
    const defaultStyle = edge === 'top' ? styles.stickySentinelTop : styles.stickySentinelBottom;
    const offsetStyle =
      edge === 'top'
        ? { ...defaultStyle, marginTop: topSentinelMarginTop }
        : { ...defaultStyle, height: bottomSentinelHeight };
    return offsetStyle || defaultStyle;
  }, [edge, topSentinelMarginTop, bottomSentinelHeight]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <div ref={refForwarded as any} style={style}>
      {edge} sentinel
    </div>
  );
});

interface SentinelOffsets {
  bottomSentinelHeight: string;
  topSentinelMarginTop: string;
}

function getSentinelOffsets(stickyElement: HTMLElement): SentinelOffsets {
  const topStyle = window.getComputedStyle(stickyElement);
  const getProp = (name: string) => topStyle.getPropertyValue(name);
  const paddingTop = getProp('padding-top');
  const paddingBottom = getProp('padding-bottom');
  const height = getProp('height');
  const marginTop = getProp('margin-top');
  const bottomSentinelHeight = `calc(${marginTop} +
      ${paddingTop} +
      ${height} +
      ${paddingBottom})`;
  if (styles.stickySentinelTop.height == null) {
    throw new TypeError('Sentinel top height is unexpectedly null or undefined');
  }
  const topSentinelMarginTop = `calc(0px - ${marginTop} - ${styles.stickySentinelTop.height}px)`;
  return { bottomSentinelHeight, topSentinelMarginTop };
}

function observeSentinel({
  edge,
  onChange,
  container,
  stickyElement,
  sentinel,
  isInitialRef,
  isStuckRef,
  offsets,
}: {
  edge: 'top' | 'bottom';
  onChange: NonNullable<StickyBoundaryProps['onChange']>;
  container: HTMLElement;
  sentinel: HTMLElement;
  stickyElement: HTMLElement;
  isInitialRef: React.MutableRefObject<boolean>;
  isStuckRef: React.MutableRefObject<boolean>;
  offsets: SentinelOffsets;
}) {
  const root = container;

  // When observing top sentinels we want to see the first pixel intersecting.
  // For bottom, we wait until the whole sentinel has left or is visible.
  const threshold = edge === 'top' ? 0 : 1;

  // The top sentinel is right above the top of the sticky element. In theory,
  // IntersectionObserver should notify us when the sticky element sticks to
  // the top of the container. In practice, however, we didn't see notifications
  // in this case. But it works fine when we look for intersections in a box that
  // starts one pixel lower than the root element. This seems like it should
  // be unnecessary, but I couldn't get it working without this hack.
  // Sub-pixel rounding (https://bugs.chromium.org/p/chromium/issues/detail?id=963743)
  // may be the cause.  If anyone figures out what's going on here, let me know!
  const rootMargin = `-${topSentinelHeight}px 0px 0px 0px`;

  const firstLine = (s: string) => s.split('\n')[0];
  console.log(`observing ${edge} sentinel for: ${firstLine(sentinel.parentElement!.innerText)}`);
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        const isInitial = isInitialRef.current;
        isInitialRef.current = false;
        const rootBounds = entry.rootBounds;
        if (!rootBounds) {
          throw new Error('rootBoundsInfo is missing');
        }
        const sentinelRect = entry.boundingClientRect;
        // console.log(JSON.stringify({ edge, text: (stickyElement as any).innerText }));
        const stickyRect = stickyElement.getBoundingClientRect();

        const getEventTypeTop = () => {
          if (sentinelRect.bottom < rootBounds.top && Math.abs(stickyRect.top - rootBounds.top) <= 1) {
            return 'stuck';
          } else if (
            sentinelRect.bottom >= rootBounds.top &&
            sentinelRect.bottom < rootBounds.bottom &&
            isStuckRef.current
          ) {
            return 'unstuck';
          } else return undefined;
        };
        const getEventTypeBottom = () => {
          /*
          console.log(
            JSON.stringify({
              text: (stickyElement as any).innerText,
              logic:
                sentinelRect.bottom >= rootBounds.top &&
                entry.intersectionRatio === 1 &&
                Math.abs(stickyRect.top - rootBounds.top) <= 1,
              ratio: entry.intersectionRatio,
              diff: Math.abs(stickyRect.top - rootBounds.top),
              sentinelRect,
              stickyRect,
              rootBounds,
            })
          );
          */
          if (
            sentinelRect.bottom >= rootBounds.top &&
            entry.intersectionRatio === 1 &&
            Math.abs(stickyRect.top - rootBounds.top) <= 1

            //            sentinelRect.top >= rootBounds.top &&
            //            sentinelRect.bottom <= rootBounds.bottom &&
            //            entry.intersectionRatio === 1 &&
            //            stickyRect.top === 0
            //            Math.abs(stickyRect.top - rootBounds.top) < 1
          ) {
            return 'stuck';
          } else if (sentinelRect.top <= rootBounds.top /*&& isStuckRef.current*/) return 'unstuck';
          else return undefined;
        };

        const type = edge === 'top' ? getEventTypeTop() : getEventTypeBottom();
        if (type) {
          if ((type === 'stuck' && !isStuckRef.current) || (type === 'unstuck' && isStuckRef.current)) {
            onChange({ type, target: stickyElement, isInitial, edge });
          } else {
            console.log(
              `Duplicate observer event: ${stickyElement.innerText} ${edge} ${type} ${
                isStuckRef ? '(was stuck)' : '(was unstuck)'
              }${isInitial ? ' initial' : ''}`
            );
          }
          isStuckRef.current = type === 'stuck';
          //          if (type === 'stuck') logStuck(stickyElement, entry, root, edge, type, isInitial);
        }
      });
    },
    { threshold, root, rootMargin }
  );
  observer.observe(sentinel);
  return observer;
}

export interface StickyBoundaryProps {
  children: ReactNode;
  as?: any;
  className?: string;
  style?: CSSProperties;
  onStuck?: (args: { target: HTMLElement; isInitial: boolean; edge: 'top' | 'bottom' }) => void;
  onUnstuck?: (args: { target: HTMLElement; isInitial: boolean; edge: 'top' | 'bottom' }) => void;
  onChange?: (args: { type: string; target: HTMLElement; isInitial: boolean; edge: 'top' | 'bottom' }) => void;
}

/**
 * A section, in which <Sticky /> element element is observed
 */
function StickyBoundary({
  as: Component = 'section',
  style,
  onChange,
  onStuck,
  onUnstuck,
  children,
  ...rest
}: StickyBoundaryProps) {
  const [stickyElement, setStickyElement] = useState<HTMLElement | null>(null);
  const [topSentinel, setTopSentinel] = useState<HTMLElement | null>(null);
  const [bottomSentinel, setBottomSentinel] = useState<HTMLElement | null>(null);
  const topSentinelObserver = useRef<IntersectionObserver | null>(null);
  const bottomSentinelObserver = useRef<IntersectionObserver | null>(null);
  const { containerRef } = useStickyState();
  const [sentinelOffsets, setSentinelOffsets] = useState<SentinelOffsets | null>(null);
  const styleMemo = useMemo(() => (style ? { ...style, ...styles.stickySection } : styles.stickySection), [style]);

  useLogUnmount('StickyBoundary');

  // InteractionObserver sends an initial event immediately after observation
  // starts. This is to observe the initial state of the element. This can be
  // problematic in some React apps because you'll get events firing whenever a
  // re-render happens even if there's been no scrolling or changes. Therefore,
  // we include an `isInitial` prop in the event to help callers in case they
  // want to ignore these initial events or otherwise treat them specially.
  const topIsInitialRef = useRef(true);
  const bottomIsInitialRef = useRef(true);

  // InteractionObserver sends an initial event immediately after observation
  // starts. This is to observe the initial state of the element. This can be
  // problematic in some React apps because you'll get events firing whenever a
  // re-render happens even if there's been no scrolling or changes. Therefore,
  // we include an `isInitial` prop in the event to help callers in case they
  // want to ignore these initial events or otherwise treat them specially.
  const topIsStuck = useRef(false);
  const bottomIsStuck = useRef(false);

  const stopObserving = useCallback(() => {
    if (topSentinelObserver.current) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      //      console.log(`disconnecting top sentinel for ${stickyElement?.innerText}`);
      topSentinelObserver.current.disconnect();
      topSentinelObserver.current = null;
      topIsInitialRef.current = true;
      topIsStuck.current = false;
    }
    if (bottomSentinelObserver.current) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      //      console.log(`disconnecting bottom sentinel for ${stickyElement?.innerText}`);
      bottomSentinelObserver.current.disconnect();
      bottomSentinelObserver.current = null;
      bottomIsInitialRef.current = true;
      bottomIsStuck.current = false;
    }
  }, []);

  type OnChangeParams = Parameters<NonNullable<StickyBoundaryProps['onChange']>>[0];
  const lastEvent = useRef(null as OnChangeParams | null);

  const handleChange = useCallback<NonNullable<StickyBoundaryProps['onChange']>>(
    ({ type, target, isInitial, edge }) => {
      const isDupe = lastEvent.current && type === lastEvent.current.type && isInitial === lastEvent.current.isInitial;
      /*
      const msg = `${isDupe ? 'Duplicate ' : ''}stuck event: ${isInitial ? '(initial)' : ''} ${type} ${edge} (last: ${
        lastEvent.current?.edge
      }) ${target.innerText}`;
      console.log(msg);
      */
      lastEvent.current = { type, target, isInitial, edge };
      if (!isDupe) {
        const handler = type === 'stuck' ? onStuck : onUnstuck;
        handler && handler({ target, isInitial, edge });
        onChange && onChange({ type, target, isInitial, edge });
      }
    },
    [onStuck, onUnstuck, onChange]
  );

  // We require three refs to start observing: a ref each for the bottom and
  // top sentinels, and a ref to the sticky element inside the section.
  // (The latter ref is stored and populated via context in the <Sticky>
  // component implementation.)
  // Below we check if all three refs are ready, and if so we'll start
  // observing the sentinels. However, if all three refs are not present,
  // then we'll clean up.
  useEffect(() => {
    if (stickyElement && topSentinel && bottomSentinel) {
      const container = containerRef.current;
      if (!container) {
        throw new Error('containerRef is missing');
      }

      const offsets = getSentinelOffsets(stickyElement);
      if (
        offsets.bottomSentinelHeight !== sentinelOffsets?.bottomSentinelHeight ||
        offsets.topSentinelMarginTop !== sentinelOffsets.topSentinelMarginTop
      ) {
        setSentinelOffsets(offsets);
      }

      topSentinelObserver.current = observeSentinel({
        edge: 'top',
        container,
        stickyElement,
        sentinel: topSentinel,
        onChange: handleChange,
        isInitialRef: topIsInitialRef,
        isStuckRef: topIsStuck,
        offsets,
      });
      topSentinelObserver.current = observeSentinel({
        edge: 'bottom',
        container,
        stickyElement,
        sentinel: bottomSentinel,
        onChange: handleChange,
        isInitialRef: bottomIsInitialRef,
        isStuckRef: bottomIsStuck,
        offsets,
      });
    } else {
      console.log(`Stopping observing as all three refs are not truthy`);
      stopObserving();
    }

    return stopObserving;
  }, [topSentinel, bottomSentinel, containerRef, stickyElement, stopObserving, handleChange, sentinelOffsets]);

  const value = useMemo(() => ({ setTopSentinel, setBottomSentinel, setStickyElement }), []);

  const topSentinelJsx = useMemo(
    () => <Sentinel ref={setTopSentinel} edge="top" offsets={sentinelOffsets} />,
    [sentinelOffsets]
  );
  const bottomSentinelJsx = useMemo(
    () => <Sentinel ref={setBottomSentinel} edge="bottom" offsets={sentinelOffsets} />,
    [sentinelOffsets]
  );

  return (
    <StickySectionContext.Provider value={value}>
      <Component style={styleMemo} {...rest}>
        {topSentinelJsx}
        {children}
        {bottomSentinelJsx}
      </Component>
    </StickySectionContext.Provider>
  );
}

interface StickyRootProps {
  children: ReactNode;
  as?: any;
}

/**
 * Ref to the sticky viewport
 */
const StickyRoot = forwardRef(function StickyRoot(
  { children, as: Component = 'div', ...rest }: StickyRootProps,
  refForwarded
) {
  const { containerRef } = useStickyState();

  // to enable using the local ref and also forwarding it, we use a callback ref
  // function here instead of a regular ref object
  const addContainerRef = useCallback(
    (elem: HTMLElement) => {
      containerRef.current = elem;
      if (refForwarded) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (refForwarded as unknown as any).current = elem;
      }
    },
    [refForwarded, containerRef]
  );

  return (
    <Component ref={addContainerRef} {...rest}>
      <section style={sectionStyle} />
      {children}
    </Component>
  );
});

const sectionStyle = { zIndex: 1000, position: 'absolute' } as const;

export interface StickyViewportProps extends Partial<Omit<AllHTMLAttributes<HTMLElement>, 'children'>> {
  children: ReactNode;
  as?: any;
}

/**
 * Provides sticky context to the sticky component tree.
 */
const StickyViewport = forwardRef(function StickyViewport({ children, as = 'div', ...rest }: StickyViewportProps, ref) {
  useLogUnmount('StickyViewport');
  return (
    <StickyProvider>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/}
      <StickyRoot as={as} {...rest} ref={ref}>
        {children}
      </StickyRoot>
    </StickyProvider>
  );
});

export { StickyViewport, StickyBoundary, Sticky };
