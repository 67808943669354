import { CSSProperties } from 'react';
import Spinner from './Spinner';

export const defaultLoadingTextStyle: CSSProperties = {
  color: '#558255',
  backgroundColor: '#ffffffa0',
  boxShadow: '0 0 50px 10px #fff',
  padding: 10,
};

export default function LoadingTextWithSpinner(props: { text: string; style?: CSSProperties }) {
  const style = props.style ?? defaultLoadingTextStyle;
  return (
    <>
      <span style={style}>
        {props.text} <Spinner />
      </span>
    </>
  );
}
