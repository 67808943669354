import { CSSProperties, useCallback, useRef, forwardRef, useImperativeHandle, useState, memo, useEffect } from 'react';
import { SHORT_DAYS_OF_WEEK, getWeekIndex, HANG_WEEK_COUNT, FIRST_SUNDAY_PLAIN_DATE } from './dateUtils';
import { useLogIfChanged, useObjectState, useArrayState, useShallowCompareMemo, usePlainDateMemo } from './useHooks';
import { SelectedDateChangeHandler } from './Calendar';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { useLastInteractedWith } from './LastInteractedWith';
import { Temporal } from '@js-temporal/polyfill';

interface VirtualRenderProps<T> {
  offset: number; // slides left / top offset in px
  from: number; // index of first slide required to be rendered
  to: number; // index of last slide required to be rendered
  slides: T[]; // array with slide items to be rendered
}

type RenderSlidesProps = InternalProps & VirtualRenderProps<null>;

export const DEFAULT_WEEKS_TO_SHOW = 2;
export const DEFAULT_DAYS_TO_SHOW = DEFAULT_WEEKS_TO_SHOW * 7;

const WEEK_HEIGHT = 54; // TODO: figure out how to align JS and CSS

export type MultiSelectDateChangeHandler = (arg: { dates: Temporal.PlainDate[] }) => void;

export type CalendarTopProps =
  | {
      /** Date that is initially selected */
      selectedDate: Temporal.PlainDate;
      /** If true, calendar will support multiple selection */
      isMulti: false;
      /** Called when user clicks a date */
      onDateClick: SelectedDateChangeHandler;
      /** Called when user changes selections of dates (multi-select only) */
      readonly onMultiSelectChange?: undefined;
    }
  | {
      /** Date that is initially selected */
      selectedDate: Temporal.PlainDate;
      /** If true, calendar will support multiple selection */
      isMulti: true;
      /** Called when user clicks a date */
      readonly onDateClick?: undefined;
      /** Called when user changes selections of dates (multi-select only) */
      onMultiSelectChange: MultiSelectDateChangeHandler;
    };

type InternalProps =
  | {
      /** Date that is initially selected */
      selectedDate: Temporal.PlainDate;
      /** If true, calendar will support multiple selection */
      isMulti: false;
      /** Called when user clicks a date */
      onDateClick: SelectedDateChangeHandler;
      /** Called when user changes selections of dates (multi-select only) */
      readonly onMultiSelectChange?: undefined;
      /** Which dates are currently selected (multi-select only) */
      readonly multiSelectDates?: undefined;
    }
  | {
      /** Date that is initially selected */
      selectedDate: Temporal.PlainDate;
      /** If true, calendar will support multiple selection */
      isMulti: true;
      /** Called when user clicks a date */
      readonly onDateClick?: undefined;
      /** Called when user changes selections of dates (multi-select only) */
      onMultiSelectChange: MultiSelectDateChangeHandler;
      /** Which dates are currently selected (multi-select only) */
      multiSelectDates: Temporal.PlainDate[];
    };

type RenderOneWeekProps = InternalProps & {
  index: number;
  style: CSSProperties;
};

type RenderOneSlideProps = RenderOneWeekProps;

function isDateSelected(date: Temporal.PlainDate, dates: Temporal.PlainDate[]) {
  return dates.some(d => date.equals(d));
}

const RenderOneWeek = memo(function RenderOneWeek(props: RenderOneWeekProps) {
  const { selectedDate, style, index } = props;
  // console.log(`Rendering week ${props.index} with selected date ${toShortDate(props.selectedDate)}`);
  const today = Temporal.Now.plainDateISO();
  const firstDate = FIRST_SUNDAY_PLAIN_DATE.add({ days: props.index * 7 });
  const onDateClick = (date: Temporal.PlainDate) => {
    if (props.isMulti) {
      if (Temporal.PlainDate.compare(date, today) < 1) {
        return; // can't select past dates for multi-select adding of hangtimes
      }
      const { multiSelectDates } = props;
      const currentlySelected = isDateSelected(date, multiSelectDates);
      const newSelections = currentlySelected
        ? multiSelectDates.filter(d => !d.equals(date))
        : [...multiSelectDates, date];
      props.onMultiSelectChange({ dates: newSelections });
    } else {
      props.onDateClick({ date, caller: 'top' });
    }
  };
  return (
    <ul key={index} style={style} className="top-calendar-row">
      {[0, 1, 2, 3, 4, 5, 6].map(dayIndex => {
        const date = firstDate.add({ days: dayIndex });
        const dateNumber = date.day;
        const classes = ['big-date-day'];
        if (!props.isMulti && date.equals(selectedDate)) {
          classes.push('big-date-selected');
        }
        if (props.isMulti && isDateSelected(date, props.multiSelectDates)) {
          classes.push('big-date-selected');
        }
        if (dateNumber === 1) {
          classes.push('first-day-of-month');
        }
        if (date.equals(today)) {
          classes.push('big-date-today');
        } else if (Temporal.PlainDate.compare(date, today) < 0) {
          classes.push('big-date-past');
        } else {
          // if (date.getTime() < today.getTime()) {
          classes.push('big-date-future');
        }
        return (
          <li
            key={dayIndex}
            className={classes.join(' ')}
            onClick={() => onDateClick(date)}
            title={date.toLocaleString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              weekday: 'long',
            })}
          >
            <div className="big-date">
              {dateNumber === 1 ? (
                // first day of the month gets the month shown above (smaller) "1"
                <div className="big-date-month-name">{date.toLocaleString(undefined, { month: 'short' })}</div>
              ) : null}
              <div className="big-date-text">{dateNumber}</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
});

const rowStyle = {
  height: WEEK_HEIGHT,
  width: '100%',
};

function getSlideId(index: number): string {
  return `topCalendarSlide${index}`;
}

// To prevent slides from being re-rendered every time the List scrolls, we need to:
// 1) move the one-slide component outside of the main component function
// 2) memoize it. I had trouble getting React.memo() to prevent rendering, so
//    using useMemo on child JSX instead.
const RenderOneSlide = memo(function RenderOneSlide(props: RenderOneSlideProps) {
  // console.log(`Rendering day ${props.index} with selected date ${toShortDate(props.selectedDate)}`);
  const { index, style, selectedDate: selectedDateOriginal } = props;
  const selectedDate = usePlainDateMemo(selectedDateOriginal);

  useLogIfChanged('selectedDate (RenderOneSlide)', selectedDate);
  useLogIfChanged('index (RenderOneSlide)', index);
  useLogIfChanged('style (RenderOneSlide)', style);
  useLogIfChanged('isMulti (RenderOneSlide)', props.isMulti);
  useLogIfChanged('props.multiSelectDates (RenderOneSlide)', props.multiSelectDates);
  useLogIfChanged('props.onMultiSelectChange (RenderOneSlide)', props.onMultiSelectChange);
  useLogIfChanged('props.onDateClick (RenderOneSlide)', props.onDateClick);

  return (
    <div className="swiper-slide top-calendar-swiper-slide" key={index} style={style} id={getSlideId(index)}>
      <div className="top-calendar-one-day-wrapper" style={rowStyle}>
        {props.isMulti ? (
          <RenderOneWeek
            key={index}
            index={index}
            style={style}
            onMultiSelectChange={props.onMultiSelectChange}
            selectedDate={selectedDate}
            isMulti={true}
            multiSelectDates={props.multiSelectDates}
          />
        ) : (
          <RenderOneWeek
            key={index}
            index={index}
            style={style}
            onDateClick={props.onDateClick}
            selectedDate={selectedDate}
            isMulti={false}
          />
        )}
      </div>
    </div>
  );
});

const RenderSlides = memo(function RenderSlides(props: RenderSlidesProps) {
  const {
    // Swiper render props
    from,
    offset: top,
    slides,
    // parent component props
    selectedDate,
  } = props;

  // I found that useMemo occasionally was returning different objects
  // despite the same values used as inputs. This caused warnings in the console
  // from Why Did You Render and could affect performance so I'm switching
  // to a memoization strategy that will never be ejected from cache.
  // https://github.com/welldone-software/why-did-you-render/issues/16#issuecomment-565837308
  // const slideStyle = useMemo(() => ({ top, height: WEEK_HEIGHT }), [top]);
  const slideStyle = useShallowCompareMemo({ top, height: WEEK_HEIGHT });
  const refStyle = useRef(slideStyle);
  const refTop = useRef(top);
  if (refStyle.current !== slideStyle && refTop.current === top) {
    console.log('WTF?');
  }
  refStyle.current = slideStyle;
  refTop.current = top;

  const slideStyle2 = useShallowCompareMemo({ top, height: WEEK_HEIGHT });
  const refStyle2 = useRef(slideStyle2);
  const refTop2 = useRef(top);
  if (refStyle2.current !== slideStyle2 && refTop2.current === top) {
    console.log('WTF?');
  }
  refStyle2.current = slideStyle2;
  refTop2.current = top;

  return (
    <>
      {slides.map((unused, i) => {
        const index = from + i;
        console.log(
          `RenderSlides top: ${JSON.stringify({
            index,
            week: FIRST_SUNDAY_PLAIN_DATE.add({ days: index * 7 }).toString(),
            top: slideStyle.top,
            selected: selectedDate?.toString(),
          })}`
        );
        return props.isMulti ? (
          <RenderOneSlide
            key={index}
            index={index}
            style={slideStyle}
            selectedDate={selectedDate}
            isMulti={true}
            onMultiSelectChange={props.onMultiSelectChange}
            multiSelectDates={props.multiSelectDates}
          />
        ) : (
          <RenderOneSlide
            key={index}
            index={index}
            style={slideStyle}
            selectedDate={selectedDate}
            isMulti={false}
            onDateClick={props.onDateClick}
          />
        );
      })}
    </>
  );
});

export interface CalendarTopRefHandles {
  ensureDateIsVisible: (date: Temporal.PlainDate) => void;
  setSmoothScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalendarTopHead = memo(function CalendarTopHead() {
  return (
    <ul className="top-calendar-head">
      {SHORT_DAYS_OF_WEEK.map(day => (
        <li key={day}>
          <span>{day}</span>
        </li>
      ))}
    </ul>
  );
});

const SMOOTH_SCROLLING_STYLES: CSSProperties = { scrollBehavior: 'smooth' };
const ROUGH_SCROLLING_STYLES: CSSProperties = { scrollBehavior: 'auto' };

const swiperContainerStyle = {
  // TODO: should we re-enable this? touchAction: 'pan-y',
  height: DEFAULT_WEEKS_TO_SHOW * WEEK_HEIGHT,
  width: '100%',
};

const areVirtualRenderPropsEqual = (p1: VirtualRenderProps<null>, p2: VirtualRenderProps<null>) =>
  p1.from === p2.from && p1.offset === p2.offset && p1.to === p2.to;

const defaultItemData = { from: 0, to: 0, offset: 0, slides: [] };

/** Two-week view at the top of the calendar tab. Note that this component is also used
 * when editing a hangtime (to allow creating multiple hangtimes at once) so when changing
 * behavior here, make sure the changes work for both places!
 */
function CalendarTop(props: CalendarTopProps, componentRef: React.Ref<CalendarTopRefHandles>) {
  const { selectedDate: selectedDateOriginal, isMulti } = props;
  const selectedDate = usePlainDateMemo(selectedDateOriginal);
  const prevSelectedDate = useRef(selectedDate);
  const startIndex = useRef(getWeekIndex(selectedDate));
  const [smoothScrolling, setSmoothScrolling] = useState(false);
  const [multiSelectDates, setMultiSelectDates] = useArrayState(new Array<Temporal.PlainDate>());
  const [currentItemData, setCurrentItemData] = useObjectState<VirtualRenderProps<null> | undefined>(
    undefined,
    areVirtualRenderPropsEqual
  );
  const swiper = useRef<Swiper>();
  const containerRef = useRef<HTMLDivElement>(null);

  // Track interactions inside this component
  const ref = useRef<HTMLDivElement>(null);
  const { lastInteractedWith } = useLastInteractedWith({ ref: ref, name: 'top' });

  // If the user scrolls the calendar, keep track of the index of the first week
  // that's shown. This is helpful so we'll know how far to scroll a date into
  // view if the selected date is changed.
  const onStartWeekChange = useCallback(() => {
    if (swiper.current) {
      startIndex.current = swiper.current.activeIndex;
    }
  }, []);

  const ensureDateIsVisible = useCallback((date: Temporal.PlainDate) => {
    const index = getWeekIndex(date);
    const start = startIndex.current;
    const end = start + 1; // TODO: change this logic if we move to resizable # of weeks
    if (swiper.current && (index < start || index > end)) {
      // If the current end index is one week lower, then just shift display
      // one row up so it will be visible as the second row. Otherwise, just
      // make the desired date be the first week displayed.
      const newStartIndex = index === end + 1 ? start + 1 : index;
      swiper.current.slideTo(newStartIndex);
    }
  }, []);

  useEffect(() => {
    if (!prevSelectedDate.current.equals(selectedDate)) {
      if (lastInteractedWith.current !== 'top') {
        ensureDateIsVisible(selectedDate);
      }
      prevSelectedDate.current = selectedDate;
    }
  }, [selectedDate, ensureDateIsVisible, lastInteractedWith]);

  useImperativeHandle(
    componentRef,
    () => ({
      ensureDateIsVisible,
      setSmoothScrolling,
    }),
    [ensureDateIsVisible, setSmoothScrolling]
  );

  useEffect(() => {
    if (!swiper.current && containerRef.current) {
      console.log('creating swiper');
      swiper.current = new Swiper(containerRef.current, {
        direction: 'vertical',
        mousewheel: {
          forceToAxis: true,
        },
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: 0.5,
        keyboard: true,
        slidesPerView: 2,
        initialSlide: startIndex.current,
        //          speed: 500,
        on: {
          slideChange: onStartWeekChange,
        },
        virtual: {
          slides: new Array<null>(HANG_WEEK_COUNT).fill(null),
          renderExternal: (data: VirtualRenderProps<null>) => {
            console.log('renderExternal called');
            // assign virtual slides data
            setCurrentItemData(data);
          },
        },
      });
    }
    return () => {
      console.log('cleaning up swiper');
      if (swiper.current) {
        swiper.current.destroy(true, true);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // deps are not specified above because swiper is only created once on mount,
  // so it doesn't need to know about updates to those deps.

  const { onMultiSelectChange } = props;
  const onMultiSelectChangeInternal = useCallback<MultiSelectDateChangeHandler>(
    ({ dates }) => {
      setMultiSelectDates(dates);

      if (onMultiSelectChange) {
        onMultiSelectChange({ dates });
      }
    },
    [onMultiSelectChange, setMultiSelectDates]
  );

  useLogIfChanged('selectedDate (CalendarTop)', selectedDate);
  useLogIfChanged('smoothScrolling (CalendarTop)', smoothScrolling);
  useLogIfChanged('multiSelectDates (CalendarTop)', multiSelectDates);
  useLogIfChanged('ensureDateIsVisible (CalendarTop)', ensureDateIsVisible);
  useLogIfChanged('currentItemData (CalendarTop)', currentItemData);
  useLogIfChanged('onMultiSelectChange (CalendarTop)', onMultiSelectChange);

  const { from, to, offset, slides } = currentItemData || defaultItemData;

  return (
    <div
      className={isMulti ? `top-calendar top-calendar-multi` : `top-calendar top-calendar-single`}
      style={smoothScrolling ? SMOOTH_SCROLLING_STYLES : ROUGH_SCROLLING_STYLES}
      ref={ref}
    >
      <CalendarTopHead />
      <div className="swiper-container big-date-container" style={swiperContainerStyle} ref={containerRef}>
        <div className="swiper-wrapper">
          {currentItemData ? (
            isMulti ? (
              <RenderSlides
                from={from}
                to={to}
                offset={offset}
                slides={slides}
                selectedDate={selectedDate}
                isMulti={isMulti}
                onMultiSelectChange={onMultiSelectChangeInternal}
                multiSelectDates={multiSelectDates}
              />
            ) : (
              <RenderSlides
                from={from}
                to={to}
                offset={offset}
                slides={slides}
                selectedDate={selectedDate}
                isMulti={isMulti}
                onDateClick={props.onDateClick}
              />
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(forwardRef(CalendarTop));

/*
      <List
        height={DEFAULT_WEEKS_TO_SHOW * WEEK_HEIGHT}
        width="100%"
        itemSize={WEEK_HEIGHT}
        initialScrollOffset={WEEK_HEIGHT * weekIndex}
        itemCount={1000000}
        className="big-date-container"
        style={smoothScrolling ? SMOOTH_SCROLLING_STYLES : ROUGH_SCROLLING_STYLES}
        onItemsRendered={onItemsRendered}
        overscanCount={5}
        itemData={itemData}
        ref={listRef}
      >
        {Row}
      </List>
*/

/*
import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
// const VirtualizeSwipeableViews = virtualize(bindKeyboard(SwipeableViews));

<VirtualizeSwipeableViews
        onChangeIndex={index => setWeekIndex(index)}
        index={weekIndex}
        disableLazyLoading={true}
        animateHeight={true}
        slideRenderer={({ index, key }) => (
          <RenderOneWeek key={index} index={index} />
        )}
        axis="y"
        resistance={true}
        enableMouseEvents={true}
        className="top-calendar-body"
        style={ { height: 54, scrollSnapType: 'y mandatory' }}
      />

      <VirtualizeSwipeableViews
        onChangeIndex={index => setWeekIndex(index)}
        index={weekIndex}
        slideRenderer={({ index, key }) => (
          <div key={key} style={ { height: 50 }}>
            This is a line of sample text with a number: {index}
          </div>
        )}
        animateHeight={true}
        axis="y"
        resistance={true}
        enableMouseEvents={true}
        style={ { height: 200, display: 'none' }}
      />
*/
/*
for https://twitter.com/erikras/status/1204148983800696837
can delete the code below.

type Props<T> = T extends { a: string, c: unknown } | { a: unknown, c: string }
  ? never
  : T extends { a: string, b: number } | { b: number, c: string }
  ? T
  : never;

type W = Props<{ a: string, b: number }>
type X = Props<{ c: string, b: number }>
type Y = Props<{ a: string, c: string, b: number }>
type Z = Props<{ a: string }>

const hasA =
  (o: { a?: string, b: number, c?: string }): o is { a: string, b: number,  } => typeof o.a === 'string';

function MyComp<T extends { a?: never, b: number, c: string } | { a: string, b: number, c?: never }>(
  props: Props<T>
): string {
  if (typeof props.a !== 'undefined') {
    return props.a
  } else if (typeof props.a === 'undefined') {
    return props.c
  }
}

// valid 👍
MyComp({ a: 'A', b: 2 })

// valid 👍
MyComp({ b: 2, c: 'C' })

// invalid 👍
MyComp({ a: 'A', b: 2, c: 'C' })
*/
