import { Nav, NavItem, NavLink } from 'reactstrap';

const blankLink = (x: unknown) => '#';

const BottomBar = () => (
  <nav className="navbar navbar-light bottom-bar">
    <Nav className="ml-auto" navbar={true}>
      <NavItem>
        <NavLink onClick={blankLink}>About</NavLink>
      </NavItem>
      <NavItem>
        <NavLink onClick={blankLink}>Contact</NavLink>
      </NavItem>
    </Nav>
  </nav>
);

export default BottomBar;
