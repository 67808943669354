import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { FacebookStatic, StatusResponse } from './FacebookJsSdk';

// TODO: update me to verify all changes from source component that we adapted from
// https://github.com/keppelen/react-facebook-login/commits/master

// This module started as a class component. As an excuse to get to know the code
// (in order to fix a redirect bug), I refactored it to a function component + hooks.
// But then I wanted to replace the render props DX with something easier, so I ported
// again to a custom hook.
// TODO: should we refactor this into an async function instead of a custom hook?

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: FacebookStatic;
  }
}

export interface FacebookLoginProps {
  appId: string;
  onLogin: (userInfo: ReactFacebookLoginInfo) => void;
  onLoginFailure?: (response: ReactFacebookFailureResponse) => void;

  autoLoad?: boolean;
  buttonStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  cookie?: boolean;
  cssClass?: string;
  disableMobileRedirect?: boolean;
  fields?: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  language?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  reAuthenticate?: boolean;
  redirectUri?: string;
  scope?: string;
  size?: 'small' | 'medium' | 'metro';
  textButton?: string;
  typeButton?: string;
  version?: string;
  xfbml?: boolean;
  isMobile?: boolean;
  tag?: Node | React.Component<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  returnScopes?: boolean;
  authType?: 'reauthenticate' | 'reauthorize' | 'rerequest';
  responseType?: string;
  facebookLoginState?: string;

  debug?: boolean; // JG added
}

export interface ReactFacebookFailureResponse {
  status?: string;
}

export interface ReactFacebookLoginInfo {
  id: string;
  accessToken: string;
  name: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  short_name: string;
  name_format: string;
  email: string;
  picture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  };
  userID: string;
  expiresIn: number;
  signedRequest: string;
  reauthorize_required_in: number;
  grantedScopes?: string;
}

function getIsMobile() {
  let isMobile = false;
  // JG add this because window.standalone is missing from built-in type defs
  const navigator = window.navigator as Navigator & { standalone: boolean };

  try {
    isMobile = !!(
      (navigator && navigator.standalone) ||
      /CriOS/.exec(navigator.userAgent) ||
      /mobile/i.exec(navigator.userAgent) ||
      window.matchMedia('(display-mode: standalone)').matches
    ); // see https://stackoverflow.com/a/40932368
  } catch (ex) {
    // continue regardless of error
  }

  return isMobile;
}

function isRedirectedFromFb() {
  const decodeParamForKey = (paramString: string, key: string) => {
    return decodeURIComponent(
      paramString.replace(
        new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
        '$1'
      )
    );
  };

  const params = window.location.search;
  const result = decodeParamForKey(params, 'code') || decodeParamForKey(params, 'granted_scopes');
  return !!result;
}

export async function facebookLogout() {
  return new Promise((resolve, reject) => {
    if (!window.FB) {
      reject(new Error("Not logged into Facebook, so can't log out"));
    }
    window.FB.logout((response: unknown) => {
      console.log(`Facebook logout response: ${JSON.stringify(response)}`);
      resolve(response);
    });
  });
}

function loadFacebookSdk(args: { language: string; isDebug: boolean; fbAsyncInit: () => void }) {
  const { language, isDebug, fbAsyncInit } = args;

  // set a callback function that the SDK will call after it's loaded
  if (window['fbAsyncInit']) {
    throw new Error('Facebook SDK fbAsyncInit is already present, which is unexpected');
  }
  window.fbAsyncInit = fbAsyncInit;

  // Add an fb-root DIV, which I assume is used by the SDK to contain FB-created UI elements.
  if (!document.getElementById('fb-root')) {
    const fbRoot = document.createElement('div');
    fbRoot.id = 'fb-root';
    document.body.appendChild(fbRoot);
  }
  // now create the <script> that will load the SDK
  const id = 'facebook-jssdk';
  const s = 'script';
  const sdkFilename = isDebug ? 'sdk/debug.js' : 'sdk.js';
  if (!document.getElementById(id)) {
    const element: HTMLScriptElement = document.getElementsByTagName(s)[0];
    const js = document.createElement(s);
    js.async = true;
    js.defer = true;
    js.id = id;
    js.src = `https://connect.facebook.net/${language}/${sdkFilename}`;
    element.parentNode!.insertBefore(js, element);
  }
}

function facebookLoginInternal(
  callback: (response: StatusResponse) => void,
  opts: {
    scope: string;
    returnScopes: boolean;
    authType: FacebookLoginProps['authType'];
  }
) {
  const { scope, returnScopes, authType } = opts;
  window.FB.login(callback, { scope, return_scopes: returnScopes, auth_type: authType });
}

export function useFacebookLogin(originalProps: FacebookLoginProps) {
  const props = {
    redirectUri: typeof window !== 'undefined' ? window.location.href : '/',
    scope: 'public_profile,email',
    returnScopes: false,
    xfbml: false,
    cookie: false,
    authType: undefined,
    fields: 'name',
    version: '5.0',
    language: 'en_US',
    disableMobileRedirect: false,
    isMobile: getIsMobile(),
    onLoginFailure: undefined,
    facebookLoginState: 'facebookdirect',
    responseType: 'code',
    debug: true,
    ...originalProps,
  };

  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const isComponentMounted = useRef(false);

  const {
    appId,
    xfbml,
    cookie,
    version,
    autoLoad,
    language,
    fields,
    onLogin,
    onLoginFailure,
    debug,
    scope,
    returnScopes,
    authType,
  } = props;
  const initParams = useMemo(
    () => ({
      version: `v${version}`,
      appId,
      xfbml,
      cookie,
      status: true, // avoids the initial `getLoginStatus()` call
    }),
    [version, appId, xfbml, cookie]
  );

  const getUserProfile = useCallback(
    (response: facebook.StatusResponse) => {
      console.log(`Facebook getLoginStatus (in \`getUserProfile()\`) returned: ${response.status}`);
      if (isComponentMounted.current) {
        setIsProcessing(false);
      }
      if (response.authResponse) {
        window.FB.api('/me', { locale: language, fields }, (me: ReactFacebookLoginInfo) => {
          // For the caller's convenience, we merge the auth response into the user profile.
          // The auth response has this signature:
          // {
          //   accessToken: string;
          //   data_access_expiration_time: number;
          //   expiresIn: number;
          //   signedRequest: string;
          //   userID: string;
          // }
          const profile: ReactFacebookLoginInfo = { ...me, ...response.authResponse };
          onLogin(profile);
        });
      } else {
        if (onLoginFailure) {
          onLoginFailure({ status: response.status });
        } else {
          throw new Error('Facebook login failed without onFailure prop being defined');
        }
      }
    },
    [language, fields, onLogin, onLoginFailure]
  );

  const checkLogin = useCallback(
    (response: facebook.StatusResponse, loginIfNotConnected: boolean) => {
      if (response.status === 'connected') {
        getUserProfile(response);
      } else if (loginIfNotConnected) {
        facebookLoginInternal(getUserProfile, { scope, returnScopes, authType });
      }
    },
    [getUserProfile, authType, scope, returnScopes]
  );

  const checkLoginAfterRefreshOrSignIn = useCallback(
    (response: facebook.StatusResponse) => {
      console.log(`Facebook getLoginStatus (in \`checkLoginAfterRefreshOrSignIn()\`) returned: ${response.status}`);
      checkLogin(response, true);
    },
    [checkLogin]
  );

  const checkLoginAfterInit = useCallback(
    (response: facebook.StatusResponse) => {
      console.log(`Facebook getLoginStatus (in \`checkLoginAfterInit()\`) returned: ${response.status}`);
      window.FB.Event.unsubscribe('auth.statusChange', checkLoginAfterInit);
      checkLogin(response, autoLoad || isRedirectedFromFb());
    },
    [checkLogin, autoLoad]
  );

  useEffect(() => {
    isComponentMounted.current = true;
    if (document.getElementById('facebook-jssdk')) {
      setIsSdkLoaded(true);
    } else {
      const fbAsyncInit = () => {
        window.FB.Event.subscribe('auth.statusChange', checkLoginAfterInit);
        window.FB.init(initParams);
        if (isComponentMounted.current) {
          setIsSdkLoaded(true);
        }
      };
      loadFacebookSdk({ language, isDebug: debug, fbAsyncInit });
    }
    return () => {
      isComponentMounted.current = false;
      window.FB?.Event.unsubscribe('auth.statusChange', checkLoginAfterInit);
    };
  }, [initParams, autoLoad, language, checkLoginAfterInit, debug]);

  // Handle the case where the autoLoad prop is changed after mounting
  const prevAutoLoad = useRef(undefined as boolean | undefined);
  useEffect(() => {
    if (prevAutoLoad !== undefined) {
      if (isSdkLoaded && props.autoLoad && !prevAutoLoad) {
        window.FB.getLoginStatus(checkLoginAfterRefreshOrSignIn);
      }
    }
    prevAutoLoad.current = props.autoLoad;
  });

  function login() {
    if (isProcessing) {
      return; // another login is in progress, so ignore this repeat request.
    }
    if (!isSdkLoaded) {
      throw new Error('Cannot log into Facebook because Facebook SDK not loaded yet. Please try again.');
    }
    if (props.isDisabled) {
      throw new Error('Cannot log into Facebook while login button is disabled. Please try again.');
    }
    setIsProcessing(true); // TODO: is race condition possible here because setState is async?
    const {
      scope,
      appId,
      returnScopes,
      responseType,
      redirectUri,
      disableMobileRedirect,
      authType,
      facebookLoginState,
    } = props;

    if (props.isMobile && !disableMobileRedirect) {
      // Encode object properties to url parameters
      const getParamsFromObject = (paramsObj: Record<string, string | boolean | number | undefined>) =>
        '?' +
        Object.keys(paramsObj)
          .filter(param => paramsObj[param] != null)
          .map(param => `${param}=${encodeURIComponent(paramsObj[param] as string | number | boolean)}`)
          .join('&');
      const params = {
        client_id: appId,
        redirect_uri: redirectUri,
        state: facebookLoginState,
        return_scopes: returnScopes,
        scope,
        response_type: responseType,
        auth_type: authType,
      };
      window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
    } else {
      if (!window.FB) {
        if (props.onLoginFailure) {
          props.onLoginFailure({ status: 'facebookNotLoaded' });
          return;
        } else {
          throw new Error('Facebook click handler failed without onFailure prop being defined');
        }
      }

      window.FB.getLoginStatus(checkLoginAfterRefreshOrSignIn);
    }
  }

  return {
    login,
    isDisabled: !!props.isDisabled,
    isProcessing,
    isSdkLoaded,
  };
}
