import { String, Record, InstanceOf, Static, Boolean, Null, Undefined, Union, Literal } from 'runtypes';
import { isValidPhoneNumber } from './phoneNumbers';
import { isEmailValid } from './emailAddress';
import { Temporal } from '@js-temporal/polyfill';

const UserProfileRuntypeInternal = Record({
  isProvisional: Boolean, // true if the user was added by a friend request; false if the user created their own account
  email: String,
  externalUserId: Union(String, Null, Undefined), // what we get from Cognito including the availability zone e.g. us-east-1:c6f5a448-8b6d-45c1-9616-9f8aaa0ed7ee
  firstName: Union(String, Null),
  lastName: Union(String, Null),
  middleName: Union(String, Null),
  shortName: Union(String, Null),
  nameFormat: Union(String, Null),
  name: String,
  pictureUrl: Union(String, Null),
  picture: Union(InstanceOf(Uint8Array), Null, Undefined),
  phone: Union(String, Null, Undefined),
  createdAt: InstanceOf(Temporal.Instant),
  joinedAt: Union(InstanceOf(Temporal.Instant), Null), // same as createdAt unless previously added as a provisional user
  facebookUserId: Union(String, Null),
  googleUserId: Union(String, Null),
  //  lastLogin: Date,  // TODO: need to figure out how to do out-of-band updates as part of the login process
  shareEmailDefault: Boolean,
  sharePhoneDefault: Boolean,
});

// User profile for a provisional user, meaning this user was created
// by another user adding a friend who's not already on Hangle
const UserProfileProvisionalRuntypeInternal = Record({
  isProvisional: Literal(true), // true if the user was added by a friend request; false if the user created their own account
  email: Union(String, Null),
  externalUserId: Undefined, // what we get from Cognito including the availability zone e.g. us-east-1:c6f5a448-8b6d-45c1-9616-9f8aaa0ed7ee
  firstName: Null,
  lastName: Null,
  middleName: Null,
  shortName: Null,
  nameFormat: Null,
  name: Null,
  pictureUrl: Null,
  picture: Null,
  phone: Undefined,
  createdAt: InstanceOf(Temporal.Instant),
  joinedAt: Null, // same as createdAt unless previously added as a provisional user
  facebookUserId: Null,
  googleUserId: Null,
  shareEmailDefault: Literal(true),
  sharePhoneDefault: Literal(true),
});

export function isExternalUserIdValid(externalUserId: string) {
  return /^us-east-1:([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}$/.test(
    externalUserId
  );
}
export function isFacebookUserIdValid(facebookUserId: string) {
  // no idea what the actual size range of valid IDs should be, so at least here
  // we can verify that the IDs are numeric and a reasonable length
  return /^[0-9]{6,14}$/.test(facebookUserId);
}
export function isGoogleUserIdValid(googleUserId: string) {
  // TODO: fix this for real Google IDs
  // no idea what the actual size range of valid IDs should be, so at least here
  // we can verify that the IDs are numeric and a reasonable length
  return /^[0-9]{6,14}$/.test(googleUserId);
}

const nameProps: { [key in keyof UserProfileProvisionalInternal]?: string } = {
  firstName: 'First name',
  lastName: 'Last name',
  middleName: 'Middle name',
  shortName: 'Short name',
  name: 'Name',
};

const nullPropsProvisional: { [key in keyof UserProfileProvisionalInternal]?: string } = {
  ...nameProps,
  nameFormat: 'Name format',
  pictureUrl: 'Picture URL',
  joinedAt: 'Joined date',
  facebookUserId: 'Facebook user ID',
  googleUserId: 'Google user ID',
};

type UserProfileProvisionalInternal = Static<typeof UserProfileProvisionalRuntypeInternal>;
type UserProfileInternal = Static<typeof UserProfileRuntypeInternal>;

const constraintProvisional = (profile: UserProfileProvisionalInternal): true | string => {
  const {
    isProvisional, // true if the user was added by a friend request; false if the user created their own account
    email,
    externalUserId, // what we get from Cognito including the availability zone e.g. us-east-1:c6f5a448-8b6d-45c1-9616-9f8aaa0ed7ee
    // firstName,
    // lastName,
    // middleName,
    // shortName,
    // nameFormat,
    // name,
    // pictureUrl,
    phone,
    createdAt,
    joinedAt, // same as createdAt unless previously added as a provisional user
    facebookUserId,
    googleUserId,
    shareEmailDefault,
    sharePhoneDefault,
  } = profile;

  if (!isProvisional) {
    return 'Profile was expected to be provisional, but is not';
  }
  if (!email) {
    return 'Email is missing';
  }
  if (!isEmailValid(email)) {
    return 'Email is not valid';
  }
  if (typeof externalUserId !== 'undefined') {
    return 'External user ID cannot be present for provisional users';
  }
  for (const key of Object.keys(nullPropsProvisional) as (keyof UserProfileProvisionalInternal)[]) {
    if (profile[key] !== null) {
      return `${nullPropsProvisional[key] as string} must be null for provisional users`;
    }
  }
  if (phone !== undefined) {
    return 'Phone number should be undefined for provisional users';
  }
  if (!(createdAt instanceof Temporal.Instant)) {
    return 'User created date is invalid';
  }
  if (joinedAt != null) {
    return 'Joined At date must be blank for provisional users';
  }
  if (facebookUserId != null) {
    return 'Facebook User ID cannot be present for provisional users';
  }
  if (googleUserId != null) {
    return 'Google User ID cannot be present for provisional users';
  }
  if (!shareEmailDefault) {
    return 'Property `shareEmailDefault` must be true for provisional users';
  }
  if (!sharePhoneDefault) {
    return 'Property `shareEmailDefault` must be true for provisional users';
  }

  return true;
};

const constraint = (profile: UserProfileInternal): true | string => {
  const {
    isProvisional, // true if the user was added by a friend request; false if the user created their own account
    email,
    externalUserId, // what we get from Cognito including the availability zone e.g. us-east-1:c6f5a448-8b6d-45c1-9616-9f8aaa0ed7ee
    // firstName,
    // lastName,
    // middleName,
    // shortName,
    nameFormat,
    name,
    pictureUrl,
    phone,
    createdAt,
    joinedAt, // same as createdAt unless previously added as a provisional user
    facebookUserId,
    googleUserId,
    shareEmailDefault,
    sharePhoneDefault,
  } = profile;

  if (isProvisional) {
    return 'Profile was expected to be non-provisional, but is provisional';
  }
  if (!email) {
    return 'Email is missing';
  }
  if (!isEmailValid(email)) {
    return 'Email is not valid';
  }
  if (!externalUserId) {
    return 'External user ID is missing';
  }
  if (!isExternalUserIdValid(externalUserId)) {
    return 'External user ID is not valid';
  }
  if (nameFormat) {
    // TODO: validate this!
  }
  // We won't validate the last, first, middle, and short names because we get
  // them from Facebook and so we can't fix them if they're bogus. Despite this,
  // the full "name" prop is so central to the app that we'll fail if it's bad.
  if (name.trim() === '') {
    return 'Name cannot be blank';
  }
  if (pictureUrl && (!pictureUrl.startsWith('http://') || !pictureUrl.startsWith('https://'))) {
    return 'Picture URL is invalid';
  }
  if (phone != null && !isValidPhoneNumber(phone)) {
    return 'Phone number is invalid';
  }
  if (!(createdAt instanceof Temporal.Instant)) {
    return 'User created date is invalid';
  }
  if (!(joinedAt instanceof Temporal.Instant)) {
    return 'Joined At date is invalid';
  }
  if (facebookUserId == null && googleUserId == null) {
    return 'Facebook and/or Google User ID is missing';
  }
  if (facebookUserId != null && !isFacebookUserIdValid(facebookUserId)) {
    return 'Facebook User ID is invalid';
  }
  if (googleUserId != null && !isGoogleUserIdValid(googleUserId)) {
    return 'Google User ID is invalid';
  }
  if (!shareEmailDefault) {
    return 'Property `shareEmailDefault` must be true for provisional users';
  }
  if (!sharePhoneDefault) {
    return 'Property `shareEmailDefault` must be true for provisional users';
  }

  return true;
};

export const UserProfileProvisionalRuntype =
  UserProfileProvisionalRuntypeInternal.withConstraint(constraintProvisional);
export const UserProfileRuntype = UserProfileRuntypeInternal.withConstraint(constraint);

export type UserProfile = Static<typeof UserProfileRuntype>;
export type UserProfileProvisional = Static<typeof UserProfileProvisionalRuntype>;

/*
export interface UserProfile {
  isProvisional: boolean; // true if the user was added by a friend request; false if the user created their own account
  email: string;
  externalUserId: string | null | undefined; // what we get from Cognito including the availability zone e.g. us-east-1:c6f5a448-8b6d-45c1-9616-9f8aaa0ed7ee
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  shortName: string | null;
  nameFormat: string | null;
  name: string;
  pictureUrl: string | null;
  phone: string | null | undefined;
  createdAt: Date;
  joinedAt: Date | null; // same as createdAt unless previously added as a provisional user
  facebookUserId: string | null;
  googleUserId: string | null;
  //  lastLogin: Date,  // TODO: need to figure out how to do out-of-band updates as part of the login process
  shareEmailDefault: boolean;
  sharePhoneDefault: boolean;
}

// User profile for a provisional user, meaning this user was created
// by another user adding a friend who's not already on Hangle
export interface UserProfileProvisional {
  isProvisional: true; // true if the user was added by a friend request; false if the user created their own account
  email: string | null;
  externalUserId: undefined; // what we get from Cognito including the availability zone e.g. us-east-1:c6f5a448-8b6d-45c1-9616-9f8aaa0ed7ee
  firstName: null;
  lastName: null;
  middleName: null;
  shortName: null;
  nameFormat: null;
  name: null;
  pictureUrl: null;
  phone: undefined;
  createdAt: Date;
  joinedAt: null; // same as createdAt unless previously added as a provisional user
  googleUserId: null;
  facebookUserId: null;
  shareEmailDefault: true;
  sharePhoneDefault: true;
}
*/

// TODO: app-wide, need to stop storing nulls in MongoDB. Use `undefined` instead to save storage cost/perf.
