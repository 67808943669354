import { ReactNode, CSSProperties } from 'react';
import { Col } from 'reactstrap';
import TabHeader, { TabHeaderType } from './TabHeader';

const style: CSSProperties = { flexShrink: 0, flexGrow: 0 };

/** Render a tab header when it's being used for a non-modal tab. Use
 * `TabHeader` alone when showing the header of a modal.
 */
export default function TabHeaderContainer({ children }: { children: ReactNode }) {
  return (
    <Col style={style}>
      <TabHeader type={TabHeaderType.Highlight}>{children}</TabHeader>
    </Col>
  );
}
