import { useCallback } from 'react';
import {
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  //  Button
} from 'reactstrap';
import UserStateContainer from './UserStateContainer';
import { Link } from 'react-router-dom';
import { NavTab as UnstyledNavTab } from './NavTabFork';
import styled from '@emotion/styled';
import UserPicture from './UserPicture';
import { TimesIcon, FriendsIcon } from './Icon';
import { ModalContainer } from './ContextModal';
import LoginUi, { AutoLogin } from './Login';
import Loader from './Loader';

const NavTab = styled(UnstyledNavTab)`
  text-align: center;
  color: #666;
  :hover {
    background-color: #d8ecd4;
  }
`;

const userPictureStyle = { marginRight: 0 } as const;
const dropdownStyle = { color: '#666', paddingRight: 0 };

const TopBar = () => {
  const { onLogout, userState, refreshUserPictures } = UserStateContainer.useContainer();
  const { addModal } = ModalContainer.useContainer();

  const friends = userState?.friends;
  const name = userState.profile ? userState.profile.firstName || userState.profile.name : undefined;

  const onLoginClick = useCallback(() => {
    addModal(<LoginUi />, { className: 'login-dialog' });
  }, [addModal]);

  const reloadFriendsPictures = useCallback(() => refreshUserPictures(friends), [friends, refreshUserPictures]);

  return (
    <>
      <nav className="navbar topbar">
        <NavbarBrand tag={Link} to="/" href="/">
          <img alt="Hangle Home" src="/monkeys5.png" />
        </NavbarBrand>
        <ul className="nav nav-tabs justify-content-center align-items-center" role="tablist">
          {/* 
          <NavTab allowClickOnActive exact to="/">
            <HomeIcon />
            <br />
            Home
          </NavTab>
*/}
          <NavTab allowClickOnActive to="/times">
            <TimesIcon />
            <br />
            Times
          </NavTab>
          <NavTab allowClickOnActive to="/friends">
            <FriendsIcon />
            <br />
            Friends
          </NavTab>
        </ul>

        <Nav className="ml-auto login-container">
          <AutoLogin />
          {!userState.profile && (
            <NavItem>
              <Button onClick={onLoginClick}>Login</Button>
            </NavItem>
          )}
          {userState.profile && (
            <>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret style={dropdownStyle}>
                  <UserPicture
                    name={name}
                    pictureUrl={userState.profile.pictureUrl}
                    pictureData={userState.profile.picture}
                    style={userPictureStyle}
                  />
                  {/* Preload all friend pictures for faster display later */}
                  <Loader effect={reloadFriendsPictures} />
                  <span className="user-profile-name"> &nbsp;{name}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink tag={Link} to="/profile">
                      My Profile
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <NavLink onClick={onLogout}>Sign Out</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </Nav>
      </nav>
      <div className="landscape-warning">
        On small screens, Hangle only works in portrait mode. Please rotate your device.
      </div>
    </>
  );
};

export default TopBar;
