import { UserProfile, UserProfileProvisional } from './UserProfile';
import { HangTime } from './HangTime';
import { Friend } from './Friend';
import { ArgumentError } from './HangleExceptions';
import { ObjectId } from 'bson';
import { Notification, AllNotificationTypes } from './Notification';
import HangleId, { NewIdMap } from './HangleId';
import { Temporal } from '@js-temporal/polyfill';
import { deepcopy, SerializeType } from './Serializer';

const template: UserState = {
  _id: new HangleId(undefined, true),
  profile: null,
  friends: new Array<Friend>(),
  hangTimes: new Array<HangTime>(),
  notifications: new Array<Notification>(),
  version: 1,
  newIdMap: {},
};

/**
 * When storing instances in the DB, we don't want to store props that are only
 * used for in-memory use, like `newIdMap`
 * */
export function stripTransientState<T extends { newIdMap?: NewIdMap }>(state: T): T {
  // strip out client-side state
  const { newIdMap: unused, ...result } = state;
  return result as unknown as T;
}

export const UserStateFactory = {
  create: () => deepcopy(template),
  createForServerProvisional: () => {
    const newState = UserStateFactory.create();
    const provisional: UserStateServerProvisional = {
      ...newState,
      profile: {
        isProvisional: true,
        email: null,
        externalUserId: undefined, // Cognito user id is null for users who have never logged in
        firstName: null,
        lastName: null,
        middleName: null,
        shortName: null,
        nameFormat: null,
        name: null,
        pictureUrl: null,
        picture: null,
        phone: undefined,
        createdAt: Temporal.Now.instant(),
        joinedAt: null,
        facebookUserId: null,
        googleUserId: null,
        shareEmailDefault: true,
        sharePhoneDefault: true,
      },
    };
    return provisional;
  },
};

export interface UserState {
  _id: ObjectId;
  profile: UserProfile | null;
  friends: Friend[];
  hangTimes: HangTime[];
  notifications: Notification[];
  version: number; // stored version, for optimistic concurrency
  newIdMap: NewIdMap; // only used for translating new ObjectIds in server results
}

export interface UserStateServerProvisional {
  _id: ObjectId;
  version: number;
  profile: UserProfileProvisional;
  friends: Friend[];
  newIdMap?: NewIdMap; // only used for translating new ObjectIds in server results
}

export type UserStateServerProvisionalResult = SerializeType<UserStateServerProvisional>;

export interface UserStateServer {
  _id: ObjectId;
  version: number;
  profile: UserProfile;
  friends: Friend[];
  hangTimes: HangTime[];
  notifications: AllNotificationTypes[];
  // TODO: add cache of most commonly-used tags?
  newIdMap?: NewIdMap; // only used for translating new ObjectIds in server results
}

/**
 * This is the type actually stored in MongoDB. It's the same as UserStateServer, except
 * that Temporal types are serialized into MongoDB-safe objects.
 */
export type UserStateServerResult = SerializeType<UserStateServer>;

export function toUserStateServer(o: UserState): UserStateServer {
  if (o == null) {
    throw new ArgumentError('UserState missing');
  }

  const { _id, hangTimes, friends, version, profile, notifications, newIdMap } = o;
  if (profile == null) {
    throw new ArgumentError(`Missing profile`);
  }
  const result: UserStateServer = { _id, hangTimes, friends, version, profile, notifications, newIdMap };

  Object.entries(result).forEach(([key, value]) => {
    if (value == null) {
      throw new ArgumentError(`Missing ${key}`);
    }
  });

  return result;
}

export function fromUserStateServer(o: UserStateServer): UserState {
  if (o == null) {
    throw new ArgumentError('persisted UserState missing');
  }

  const { _id, hangTimes, friends, version, profile, notifications, newIdMap } = o;
  if (profile == null) {
    throw new ArgumentError('Profile missing');
  }

  const result = {
    ...UserStateFactory.create(),
    _id,
    hangTimes,
    friends,
    version,
    profile,
    notifications: notifications || [],
    newIdMap: newIdMap || {},
  };
  return result;
}
