import { ArgumentError } from './HangleExceptions';
import { Temporal, Intl } from '@js-temporal/polyfill';

export const MSECS_PER_SECOND = 1000;
export const MSECS_PER_MINUTE = MSECS_PER_SECOND * 60;
export const MSECS_PER_HOUR = MSECS_PER_MINUTE * 60;
export const MSECS_PER_DAY = MSECS_PER_HOUR * 24;
export const MSECS_PER_WEEK = MSECS_PER_DAY * 7;

export const MINUTES_PER_DAY = 24 * 60;

export const ZERO_YEAR = 2017;
export const ZERO_PLAIN_DATE = new Temporal.PlainDate(ZERO_YEAR, 1, 1);
// TODO: localize this to support non-Sunday start of week
export const FIRST_SUNDAY_PLAIN_DATE = ZERO_PLAIN_DATE;

// How far from the zero date can we schedule hangtimes?  This is limited
// for various reasons including the 16M-pixel limit on virtual scroll containers.
export const MAX_HANG_YEARS = 25;

const MAX_HANG_PLAIN_DATE = Temporal.PlainDate.from({ year: ZERO_YEAR + MAX_HANG_YEARS - 1, month: 12, day: 31 });
export const HANG_DAY_COUNT = ZERO_PLAIN_DATE.until(MAX_HANG_PLAIN_DATE, {
  largestUnit: 'day',
  smallestUnit: 'day',
}).days;
export const HANG_WEEK_COUNT = ZERO_PLAIN_DATE.until(MAX_HANG_PLAIN_DATE, {
  largestUnit: 'week',
  smallestUnit: 'week',
}).weeks;

export const DEFAULT_TIME = Temporal.PlainTime.from('12:00');

export const parseDateFromYYYYMMDD = (s: string) => {
  // validate year as 4 digits, month as 01-12, and day as 01-31
  const match = /^(\d{4})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/.exec(s);
  if (!match) {
    return undefined;
  }
  const [year, month, day] = [+match[1], +match[2], +match[3]];
  const date = Temporal.PlainDate.from({ year, month, day });

  // check if month stayed the same (ie that day number is valid)
  if (date.month !== month) {
    return undefined;
  }
  return date;
};

export const parseTimeFromHHMM = (s: string) => {
  // validate time: hour as 0-23, minutes as 0-59
  const match = /^([0-1][0-9]|2[0-3])([0-5][0-9])$/.exec(s);
  if (!match) {
    return undefined;
  }
  const [hour, minute] = [+match[1], +match[2]];
  return Temporal.PlainTime.from({ hour, minute });
};

// TODO: needs unit tests!
export function parseTime(value: string): Temporal.PlainTime | undefined {
  const r = new RegExp('^([0-2]?[0-9]):([0-5][0-9])(?::[0-5][0-9])?\\s*?(AM|PM|am|pm)?$');
  const matches = r.exec(value);
  if (!matches || matches.length < 3 || matches.length > 4) {
    return undefined;
  }
  const hour = +matches[1];
  const minute = +matches[2];
  const meridiem: string | undefined = matches[3];
  const isHourValid = meridiem ? hour > 0 && hour <= 12 : hour < 24;
  if (!isHourValid) {
    return undefined;
  }
  const isPM = meridiem && ['PM', 'pm', 'P', 'p'].some(s => s === meridiem[0]);
  console.log(JSON.stringify({ hour, minute, meridiem, isHourValid, isPM }));
  const newHour = (hour % 12) + (isPM ? 12 : 0);
  return Temporal.PlainTime.from({ hour: newHour, minute });
}

// "17:35:02.545"
export const formatTime24WithMsecs = (time: Temporal.PlainTime) =>
  time.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
    hour12: false,
  } as globalThis.Intl.DateTimeFormatOptions);

// "5:35:02 PM"
export const formatTimeNoMsecs = (time: Temporal.PlainTime) =>
  time.toLocaleString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

// "5:35 PM"
export const formatTimeNoSeconds = (time: Temporal.PlainTime | Temporal.ZonedDateTime | Temporal.PlainDateTime) =>
  time.toLocaleString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

/**
 * Format a time range using fewer characters if possible by omitting minutes if
 * both times are on hour boundaries, and omitting AM or PM from the first time
 * if the second time is the same meridiem.
 *
 * Note that this is only needed in locales like English that don't normally use
 * 24-hour times. If 24-hour times are used, then probably just show the 24-hour
 * format.
 *
 * TODO: update this when it's time to localize!
 *
 * Examples:
 * * 6-8PM
 * * 12:30-2PM
 * * 11:30AM-2PM
 * * 9AM-2PM
 *
 * @param startTime {Temporal.PlainTime}
 * @param endTime {Temporal.PlainTime}
 */
export function formatTimeRangeShort(start: Temporal.PlainTime, end: Temporal.PlainTime) {
  const isStartAM = start.hour < 12;
  const isEndAM = end.hour < 12;
  const isStartHour = start.minute === 0;
  const isEndHour = end.minute === 0;
  const formatter = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  const startParts = formatter.formatToParts(start);
  const endParts = formatter.formatToParts(end);
  /* 
    example:
    0: {type: "hour", value: "2"}
    1: {type: "literal", value: ":"}
    2: {type: "minute", value: "06"}
    3: {type: "literal", value: " "}
    4: {type: "dayPeriod", value: "PM"}
  */
  const partsIndexes = { hour: [0], minute: [1, 2], dayPeriod: [3, 4] };
  const getPart = (partsObject: globalThis.Intl.DateTimeFormatPart[], partName: keyof typeof partsIndexes) =>
    partsIndexes[partName].reduce((s, i) => {
      if (partsObject[i].type !== 'literal' && partsObject[i].type !== partName) {
        throw new ArgumentError('Invalid part name');
      }
      return s.concat(partsObject[i].value);
    }, '');
  const stringFromParts = (
    parts: globalThis.Intl.DateTimeFormatPart[],
    options: { showMinutes: boolean; showDayPeriod: boolean }
  ) => {
    let s = getPart(parts, 'hour');
    if (options.showMinutes) s += getPart(parts, 'minute');
    if (options.showDayPeriod) s += getPart(parts, 'dayPeriod');
    return s;
  };
  const startString = stringFromParts(startParts, {
    showMinutes: !isStartHour,
    showDayPeriod: isStartAM !== isEndAM,
  }).replace(' ', '');
  const endString = stringFromParts(endParts, { showMinutes: !isEndHour, showDayPeriod: true }).replace(' ', '');
  const result = `${startString}-${endString}`;
  return result;
}

export const toYYYYMMDD = (date: Temporal.ZonedDateTime | Temporal.PlainDate | Temporal.PlainDateTime) => {
  const plainDate = date instanceof Temporal.PlainDate ? date : date.toPlainDate();
  return plainDate.toString().replace(/-/g, '').split('[')[0];
};

export const toHHMM = (time: Temporal.PlainTime | Temporal.PlainDateTime | Temporal.ZonedDateTime) =>
  time
    .toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(/:/g, '');

// e.g. 2/18/2021
export const toShortDate = (date: Temporal.ZonedDateTime | Temporal.PlainDate | Temporal.PlainDateTime) =>
  date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

// e.g. Sun 2/18/2021
export const toMediumDate = (date: Temporal.ZonedDateTime | Temporal.PlainDate | Temporal.PlainDateTime) =>
  date
    .toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'short',
    })
    .replace(/,/g, '');

// e.g. Sunday, February 18, 2021
export const toLongDate = (date: Temporal.ZonedDateTime | Temporal.PlainDate | Temporal.PlainDateTime) =>
  date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  });

const getShortDaysOfWeek = () => {
  const dayNames = new Array<string>();
  const formatter = new Intl.DateTimeFormat(undefined, { weekday: 'short' });
  for (let i = 0; i < 7; i++) {
    dayNames[i] = formatter.format(FIRST_SUNDAY_PLAIN_DATE.with({ day: i + 1 }));
  }
  return dayNames;
};

export const SHORT_DAYS_OF_WEEK = getShortDaysOfWeek();

/**
 * Returns the index of the week containing the supplied date, with 0 being the
 * week starting on Sunday, January 1, 2017.
 * @param {string} date - Local date/time to be converted into a week index
 */
export function getWeekIndex(plainDate: Temporal.PlainDate) {
  const diff = plainDate.since(FIRST_SUNDAY_PLAIN_DATE, {
    smallestUnit: 'week',
    largestUnit: 'week',
    roundingMode: 'trunc',
  });
  return diff.weeks;
}

/**
 * Returns the number of days between the specified date and the beginning of
 * the week with week index 0, which is Sunday, January 1, 2017.
 * @param {string} date - Local date/time to be converted into a day index
 */
export function getDayIndex(date: Temporal.PlainDate | Temporal.ZonedDateTime) {
  const plainDate = date instanceof Temporal.PlainDate ? date : date.toPlainDate();
  const diff = plainDate.since(FIRST_SUNDAY_PLAIN_DATE, {
    smallestUnit: 'day',
    largestUnit: 'day',
    roundingMode: 'trunc',
  });
  return diff.days;
}

/**
 * Returns the date (midnight local time) of the provided day index.
 * @param {string} dateIndex - day index, with 0 being Sunday, January 1, 2017.
 */
export function getDateFromDayIndex(dayIndex: number) {
  return FIRST_SUNDAY_PLAIN_DATE.add({ days: dayIndex });
}

type ZonedDateTimeInterval = { start: Temporal.ZonedDateTime; end: Temporal.ZonedDateTime };

/**
 * Check if two intervals overlap
 *
 * @param intervalLeft first interval
 * @param intervalRight second interval
 * @returns true if the intervals overlap
 */
export function areIntervalsOverlapping(intervalLeft: ZonedDateTimeInterval, intervalRight: ZonedDateTimeInterval) {
  if (
    !(
      intervalLeft.start instanceof Temporal.ZonedDateTime &&
      intervalLeft.end instanceof Temporal.ZonedDateTime &&
      intervalRight.start instanceof Temporal.ZonedDateTime &&
      intervalRight.end instanceof Temporal.ZonedDateTime
    )
  ) {
    throw new ArgumentError('Arguments must be Temporal.ZonedDateTime values');
  }
  const [zdtStart1, zdtEnd1] = [intervalLeft.start, intervalLeft.end];
  const [zdtStart2, zdtEnd2] = [intervalRight.start, intervalRight.end];

  if (Temporal.ZonedDateTime.compare(zdtStart1, zdtEnd1) > 0) throw new ArgumentError('Invalid left interval');
  if (Temporal.ZonedDateTime.compare(zdtStart2, zdtEnd2) > 0) throw new ArgumentError('Invalid right interval');

  return (
    Temporal.ZonedDateTime.compare(zdtStart1, zdtEnd2) < 0 && Temporal.ZonedDateTime.compare(zdtStart2, zdtEnd1) < 0
  );
}
