import { ObjectId } from 'bson';
import { Number, String, Array as RunArray, Record, InstanceOf, Static, Null, Undefined } from 'runtypes';
import { toLongDate, toMediumDate, formatTimeRangeShort, ZERO_PLAIN_DATE, areIntervalsOverlapping } from './dateUtils';
import ObjectIdRuntype from './ObjectIdRuntype';
import { isNewValuePlaceholder } from './newValuePlaceholder';
import { isRunningOnServer } from './isRunningOnServer';
import HangleId from './HangleId';
import { ArgumentError, InternalServerError } from './HangleExceptions';
import { areSortedArraysEqual } from './areSortedArraysEqual';
import { Temporal } from '@js-temporal/polyfill';

export function hangTimeFormat(hangTime: HangTimeBase) {
  const { startTime, minutes } = hangTime;
  const endTime = startTime.add({ minutes });
  const text = `${toMediumDate(startTime)} ${formatTimeRangeShort(startTime.toPlainTime(), endTime.toPlainTime())}`;
  return text;
}

/** Core hangtime props without links to a hang. Useful for, for example, including in a Hang type
 * where
 */
const HangTimeBaseRuntypeInternal = Record({
  /** Date and time where this hangtime starts. */
  startTime: InstanceOf(Temporal.ZonedDateTime),

  /** Duration of this hangtime */
  minutes: Number,

  /** Tags for this hangtime */
  tags: RunArray(String),
});

const HangTimeRuntypeInternal = Record({
  /** ID of this hangtime */
  h: ObjectIdRuntype,

  /** If this hangtime is associated with a hang, here's the ID. */
  hangId: ObjectIdRuntype.Or(Undefined).Or(Null),

  /** Text to show in UI to describe this hangtime. Currently only used if this
   * is a hang. */
  name: String.Or(Undefined).Or(Null),
}).And(HangTimeBaseRuntypeInternal);

const constraintBase = (hangTime: Static<typeof HangTimeBaseRuntypeInternal>): true | string => {
  const { startTime, minutes, tags } = hangTime;

  if (!startTime) {
    return 'Start Time is missing';
  }
  if (!(startTime instanceof Temporal.ZonedDateTime)) {
    return 'Start Time is invalid';
  }
  if (startTime.toPlainDate().equals(ZERO_PLAIN_DATE)) {
    return 'Start date is invalid';
  }
  if (Temporal.PlainDate.compare(startTime, ZERO_PLAIN_DATE) < 0) {
    return `Hang times must be after ${toLongDate(ZERO_PLAIN_DATE)}`;
  }
  if (!minutes) {
    return 'Duration is missing';
  }
  if (minutes < 0 || isNaN(minutes)) {
    return 'Duration is invalid';
  }
  if (!tags) {
    return 'Tags are missing';
  }
  if (!tags || !tags.length || typeof tags[0] !== 'string') {
    return 'At least one tag must be provided for a hang time';
  }

  // TODO: refactor tag checking to a more global place
  const sorted = [...tags].sort();
  for (let i = 0; i < tags.length; i++) {
    if (!sorted[i] || !sorted[i].trim()) {
      return `tags cannot be blank`;
    }
    if (sorted[i] !== tags[i]) {
      return 'tags must be sorted in alphabetical order';
    }
    if (i > 0 && sorted[i] === sorted[i - 1]) {
      return `duplicate tag: ${sorted[i]}`;
    }
  }

  return true;
};

const constraintHangTime = (hangTime: Static<typeof HangTimeRuntypeInternal>): true | string => {
  const { h, hangId, name } = hangTime;

  if (!h || !ObjectId.isValid(h)) {
    return 'Hang Time ID is missing or invalid';
  }

  if (name != null && (typeof name !== 'string' || name.length > 100)) {
    return "name, if provided, must be must be text that's not more than 100 characters long";
  }

  if (hangId && !ObjectId.isValid(hangId)) {
    return 'Hang ID is present but invalid';
  }

  return constraintBase(hangTime);
};

export const HangTimeRuntype = HangTimeRuntypeInternal.withConstraint(constraintHangTime);
export const HangTimeBaseRuntype = HangTimeBaseRuntypeInternal.withConstraint(constraintBase);

/** Type to track HangTimes inside each user.  Note that there's a subtype `HangTimeNoId` that's
 * shared between HangTime and Hang types.
 */
export type HangTime = Static<typeof HangTimeRuntype>;

/** Shared subtype type used in both HangTime and Hang types. Differences:
 * - Because Hang is a top-level object in MongoDB, its ID is called `_id` while
 *   HangTime is nested inside UserStateServer so its ID field is named `h`.
 * - a HangTime instance has an optional pointer to a `Hang` (which is obviously
 *   not needed for Hang instances themselves).
 * - The `name` of a HangTime is optional, but it's not optional for a `Hang`
 */
export type HangTimeBase = Static<typeof HangTimeBaseRuntype>;

function compareStrings(s1: string, s2: string) {
  if (s1 < s2) {
    return -1;
  } else if (s1 > s2) {
    return 1;
  } else {
    return 0;
  }
}

export function areHangTimesOverlapping(h1: HangTimeBase, h2: HangTimeBase) {
  if (h1.minutes <= 0 || h2.minutes <= 0) {
    throw new ArgumentError(`Hang time minutes cannot be zero or negative`);
  }
  const range1 = { start: h1.startTime, end: getHangTimeEnd(h1) };
  const range2 = { start: h2.startTime, end: getHangTimeEnd(h2) };
  return areIntervalsOverlapping(range1, range2);
}

export function getHangTimeEnd(h: HangTimeBase) {
  return h.startTime.add({ minutes: h.minutes });
}

/** Comparison function for sorting hangtimes. This is also used for de-duping
 * hangtimes. Hangtimes that sort lower in the array will be retained, while
 * lower-down matching hangtimes will be de-duped away. Note that this is a
 * reverse sort (later hangtimes are earlier in the array) because most Hangle
 * features only care about the latest hangtimes, and the oldest ones may
 * (eventually) be subject to archiving. This means that "before" in the rules
 * below means "lower array index".
 *
 * The rules are:
 * 1) Hangs are sorted before hangtimes w/o hangs. A new hangtime should never
 *    displace an existing hang.
 * 2) Newer hangtimes are sorted before later hangtimes
 * 3) Longer hangtimes are sorted before shorter hangtimes with same start time
 * 4) Tags are sorted by joining the sorted tag list and sorting that result.
 *    There's no meaning behind tag sorting-- we just picked something that was
 *    deterministic.
 */
function compareHangTimesIgnoreId(h1: HangTimeBase, h2: HangTimeBase) {
  const isH1Hang = HangTimeRuntype.guard(h1);
  const isH2Hang = HangTimeRuntype.guard(h2);
  return Math.sign(
    h2.startTime.epochMilliseconds - h1.startTime.epochMilliseconds || // reverse sort; newest first
      h2.minutes - h1.minutes || // reverse sort; longest hangtimes first
      compareStrings(h1.tags.join('|'), h2.tags.join('|')) || // arbitrary determinism
      +isH2Hang - +isH1Hang // hangs are sorted before hangtimes
  );
}

/** Special comparison function used when sorting for a merge operation. This
 * has two differences from normal hangtime comparisons:
 * 1) If there's a tie, then existing items are sorted first. This ensures that
 *    new items will be merged into existing items, not vice versa.
 * 2) Tags are ignored when sorting.  This ensures that before a tag-merging
 *    operation (where two adjacent hangtimes have the same start and end) the
 *    existing item will sort first
 */
function compareHangTimesForMerge(h1: HangTimeBase, h2: HangTimeBase) {
  const isH1Hang = HangTimeRuntype.guard(h1);
  const isH2Hang = HangTimeRuntype.guard(h2);
  const comparison = Math.sign(
    h2.startTime.epochMilliseconds - h1.startTime.epochMilliseconds || // reverse sort; newest first
      h2.minutes - h1.minutes || // reverse sort; longest hangtimes first
      +isH2Hang - +isH1Hang // hangs are sorted before hangtimes
  );
  // When merging, if there's a tie then we'll sort `h1` first because existing
  // items will always be in `h1` while new items will be in `h2`
  return comparison || -1;
}

/** perform a bunch of tests on two HangTime instances to knows how similar they are.
 * Instances can share ranges, tags, IDs, etc.
 *
 * @returns object with props describing relationship of the hangtimes  */
export function howAreHangTimesRelated(h1: HangTimeBase | undefined, h2: HangTimeBase | undefined) {
  if (h1 == null && h2 == null) {
    throw new InternalServerError(`Both h1 and h2 cannot be undefined`);
  }
  if (h1 == null || h2 == null) {
    return {
      overlapping: false,
      sameStart: false,
      sameEnd: false,
      sameStartAndEnd: false,
      sameId: false,
      sameObject: false,
      sameTags: false,
      comparisonForMerge: h1 == null ? 1 : -1, // fake comparison to force the non-missing one to be picked by the caller
      areHangs: [Boolean(HangTimeRuntype.guard(h1)), Boolean(HangTimeRuntype.guard(h1))],
    };
  }

  const overlapping = areHangTimesOverlapping(h1, h2);
  const sameStart = h1.startTime.toInstant().equals(h2.startTime.toInstant());
  const sameEnd = getHangTimeEnd(h1).toInstant().equals(getHangTimeEnd(h2).toInstant());
  const sameStartAndEnd = sameStart && sameEnd;
  const sameId = HangTimeRuntype.guard(h1) && HangTimeRuntype.guard(h2) && h1.h.equals(h2.h);
  const sameObject = h1 === h2;
  const sameTags = areSortedArraysEqual(h1.tags, h2.tags);
  const comparisonForMerge = compareHangTimesForMerge(h1, h2);
  const areHangs = [Boolean(HangTimeRuntype.guard(h1)), Boolean(HangTimeRuntype.guard(h1))];
  return {
    overlapping,
    sameStart,
    sameEnd,
    sameStartAndEnd,
    sameId,
    sameObject,
    sameTags,
    comparisonForMerge,
    areHangs,
  };
}

/** Do two HangTime arrays contain the same values, even if the objects are
 * different? Assumes that the arrays are in the same order. This is used
 * primarily to memoize hangtime arrays in the UI to avoid re-renders.
 * */
export function areHangTimeArraysEqual(a1: HangTime[], a2: HangTime[]) {
  if (Object.is(a1, a2)) {
    return true;
  }
  if (a1.length !== a2.length) {
    return false;
  }
  const firstUnmatching = a1.find((unused, i) => compareHangTimes(a1[i], a2[i]));
  return !firstUnmatching;
}

function compareObjectIds(o1: ObjectId, o2: ObjectId) {
  // The algorithm below sorts newly-created objects sort deterministically
  // lower than existing ones, which makes it easier to strip new ids in a
  // sorted array. Note that this "detect new" trick only works on the client
  // where we create our own "new ID" placeholder values. On the server,
  // client-created new IDs look and act just like regular Object IDs.
  const [isNew1, isNew2] = [isNewValuePlaceholder(o1), isNewValuePlaceholder(o2)];
  if (!isRunningOnServer) {
    if (isNew1 || isNew2) {
      if (!isNew1 && isNew2) {
        return 1;
      } else if (isNew1 && !isNew2) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  return compareStrings(o1.toHexString(), o2.toHexString());
}

export function compareHangTimes(h1: HangTime, h2: HangTime) {
  return compareHangTimesIgnoreId(h1, h2) || compareObjectIds(h1.h, h2.h);
}

export const areHangTimesEqualIgnoreId = (h1: HangTimeBase, h2: HangTimeBase) => compareHangTimesIgnoreId(h1, h2) === 0;
export const areHangTimesEqual = (h1: HangTime, h2: HangTime) => compareHangTimes(h1, h2) === 0;

/**
 * @typedef {Object} AddToHangTimesResult
 */
interface AddToHangTimesResult {
  /** Resulting list of hangtimes, sorted by start time with most recent first.
   * */
  result: HangTime[];
  /** Normally will contain the same elements as `toAdd`, but if any of the
   * elements in `toAdd` were de-duped during merging, then the corresponding
   * element returned here will be the de-duped hangtime (the one returned in
   * the `result` array).
   */
  toAddMapped: HangTime[];
}

// This is not very efficient, but that may not matter given the context.
// Can speed it up if needed later.
function mergeSortedArrays<T>(arr1: T[], arr2: T[]) {
  return [...new Set([...arr1, ...arr2])].sort();
}
/*
const defaultComparer = function<T>(e1: T, e2: T) {
  if (e1 < e2) {
    return -1;
  } else if (e1 > e2) {
    return 1;
  } else {
    return 0;
  }
};

function isArraySorted<T>(arr: T[], comparer?: (e1: T, e2: T) => number, isAscending: boolean = true) {
  if (comparer == null) comparer = defaultComparer;
  for (let i = 1; i < arr.length - 1; i++) {
    const comparison = comparer(arr[i - 1], arr[i]);
    if (isAscending && comparison > 0 || !isAscending && comparison < 0) {
      return false;
    }
  }
  return true;
}

function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`);
  }
}
*/

export function checkForOverlaps(
  h1: HangTime,
  h2: HangTime,
  dupeMap: { [dupeId: string]: HangTime },
  h2IsNew: boolean,
  removeHangOverlaps: boolean
) {
  if (!areHangTimesOverlapping(h1, h2)) {
    // Most common case: no overlaps, so no conflicts and no merging.
    return undefined;
  }
  const { sameStartAndEnd, sameTags, sameId } = howAreHangTimesRelated(h1, h2);

  if (h1.hangId && h2.hangId) {
    throw new ArgumentError(
      `Cannot add a hang (${hangTimeFormat(h2)}) that overlaps with another Hang: ${hangTimeFormat(h1)}`
    );
  }
  // Silently remove hangtimes that overlap with hangs
  if (h1.hangId && !h2.hangId) {
    if (!removeHangOverlaps) {
      throw new InternalServerError(
        `Hang times cannot overlap with a hang. Conflicts are: ${hangTimeFormat(h1)} and ${hangTimeFormat(h2)}`
      );
    }
    dupeMap[h2.h.toHexString()] = h1;
    return h1;
  }
  if (!h1.hangId && h2.hangId) {
    if (!removeHangOverlaps) {
      throw new InternalServerError(
        `Hang times cannot overlap with a hang. Conflicts are: ${hangTimeFormat(h1)} and ${hangTimeFormat(h2)}`
      );
    }
    dupeMap[h1.h.toHexString()] = h2;
    return h2;
  }
  // If we get to here, both h1 and h2 are hangtimes (not hangs)
  if (sameStartAndEnd) {
    // merge tags if the hangtimes are otherwise equal
    // TODO: would it be better to mutate `h1` here?
    const merged = sameTags ? { ...h1 } : { ...h1, tags: mergeSortedArrays(h1.tags, h2.tags) };
    dupeMap[h2.h.toHexString()] = merged;
    dupeMap[h1.h.toHexString()] = merged;
    return merged;
  }
  if (sameId) {
    // This is a pretty weak check-- it catches adjacent items with the same ID, but doesn't prevent
    // same-ID items that aren't next to each other. TODO: consider enhancing later.
    throw new ArgumentError(
      `Cannot add a hang time with the same ID (${h1.h.toHexString()}) as another hang or hangtime`
    );
  }
  // If we get here, there's an overlap between two hang times but it's not an exact overlap. This is not
  // allowed (yet, at least)
  throw new InternalServerError(
    `Overlapping hang times are not yet supported. Conflicts: ${hangTimeFormat(h1)} and ${hangTimeFormat(h2)}`
  );
}

/**
 * If the user tries to add hangtimes that match current ones, we'll allow it
 * and simply de-dupe them. This is helpful because the UI for bulk-adding
 * hangtimes makes it easy to duplicate hangtimes on different dates. One
 * complexity is that if there are any dupes, then the caller needs needs to
 * know which dupes were replaced with a currently-existing element. So, this
 * function will merge 1+ HangTime and/or Hang instances into another array,
 * with the following behavior:
 * - The resulting list will be sorted by start time, with most recent
 *   hangtimes first.  See `compareHangTime` for detailed sort logic.
 * - If two hangtimes vary only by tags, then merge the tags together into one
 *   hangtime.
 * - If there are duplicates (ignoring the IDs) then the instance in the original
 *   array will be retained and the instance in `toAdd` will be thrown out.
 * - Return the merged list
 * @param {HangTime[]} hangTimes - original array of `HangTime` and/or `Hang`
 * instances.  No overlaps (or duplicates, which is a special case of overlaps)
 * are allowed.
 * @param {HangTime[]} toAdd - `HangTime` and/or `Hang` instances to be merged
 * into the `hangTimes` array. Overlaps are permitted between these items and
 * the `hangTimes` array.
 * @returns {AddToHangTimesResult} an object including the merged array and a
 * mapping of `toAdd` to de-duped elements.
 */
export function addToHangTimes(
  hangTimes: HangTime[],
  toAdd: HangTime[],
  removeHangOverlaps: boolean
): AddToHangTimesResult {
  if (toAdd?.length === 0) {
    throw new InternalServerError(`At least one new hang time is required in \`toAdd\``);
  }

  const toAddSorted = [...toAdd].sort(compareHangTimes);
  if (isRunningOnServer) {
    // On the server, we won't know which of the hangtimes are new, because our
    // serializer isn't aware of the HangleId type. Therefore, so we'll convert
    // new hangtimes' IDs to instances of HangleId with the "new" flag set. This
    // will let the comparison function remove new hangtimes that match all
    // props (except ID) with an existing hangtime.
    toAddSorted.forEach(h => {
      h.h = HangleId.cloneIntoNewIdPlaceholder(h.h);
    });
  }

  // Verify that existing items are sorted properly
  for (let i = 1; i < hangTimes.length; i++) {
    const prev = hangTimes[i - 1];
    const current = hangTimes[i];

    const comparison = compareHangTimes(prev, current);
    if (comparison > 0) {
      throw new InternalServerError(
        `The following hang times are not sorted properly: ${hangTimeFormat(
          prev
        )} (${prev.h.toHexString()}) and ${hangTimeFormat(current)}  (${current.h.toHexString()})`
      );
    }
  }

  // Sort comparison, with undefined items sorted last
  const safeCompare = (h1: HangTime | undefined, h2: HangTime | undefined) =>
    h1 && h2 ? compareHangTimesForMerge(h1, h2) : h1 ? -1 : 1;

  // This is the final list of hangtimes that we'll return to the caller
  const result: HangTime[] = [];

  // The caller can (validly) include a hangtime to add that's already present
  // in the list of existing hangtimes. This method will try to merge the lists
  // and remove duplicates. If any hangtimes were de-duped, this object holds a
  // mapping between the ID of the removed item and the merged item that
  // replaced it.
  const dupeMap: { [dupeId: string]: HangTime } = {};

  function addToResults(h: HangTime | undefined, isNew: boolean, removeHangOverlaps: boolean) {
    if (h == null) {
      return;
    }
    if (result.length > 0) {
      const prev = result[result.length - 1];
      const merged = checkForOverlaps(prev, h, dupeMap, isNew, removeHangOverlaps);
      if (merged) {
        // Replace the existing previous item with the merged item. Note that
        // there could be multiple levels of merging, so recurse until there's
        // no more overlap.
        result.pop();
        addToResults(merged, isNew, removeHangOverlaps);
        // If there was a recursive merge, then we'll need to fix up the dupeMap
        // to point to the new merged item.
        const newPrev = result[result.length - 1];
        if (prev !== newPrev) {
          const dupeValues = Object.entries(dupeMap).filter(kv => kv[1] === prev);
          dupeValues.forEach(kv => {
            dupeMap[kv[0]] = newPrev;
          });
        }
        return;
      }
    }
    result.push(h);
  }

  // Now walk through the existing and new hangtimes in sort order, merging the
  // two arrays and inserting the to-be-added items in the right place.  Check
  // for overlaps and handle appropriately depending on the type of overlap:
  // * If a hang overlaps a hangtime, the Hang wins and the hangtime is removed
  // * If two hangtimes are the same except for tags and ID, merge new tags into
  //   the existing one and ignore the new one.
  // * Otherwise, overlaps should throw. Note that overlaps will always involve
  //   a new item, because overlaps in existing items will be prevented by the
  //   loop higher up in this method.
  // TODO: major surgery may be needed to this algorithm once we allow
  //       overlaps e.g. all day hangtime vs. lunch hangtime.
  let iExisting = 0;
  let iNew = 0;
  while (iExisting < hangTimes.length || iNew < toAddSorted.length) {
    const hExisting = iExisting === hangTimes.length ? undefined : hangTimes[iExisting];
    const hNew = iNew === toAddSorted.length ? undefined : toAddSorted[iNew];
    const comparisonIgnoreId = safeCompare(hExisting, hNew);

    if (comparisonIgnoreId > 0) {
      // New item should be inserted first
      if (hNew) {
        addToResults(hNew, true, removeHangOverlaps);
        iNew++;
      }
    } else {
      // If the existing item sorts first, then it should be inserted first.
      // If they have the same sort order, then they'll be merged into the
      // existing one (if both are hangtimes) or will cause an exception (if
      // both are hangs).
      if (hExisting) {
        addToResults(hExisting, false, removeHangOverlaps);
        iExisting++;
      }
    }
  }
  // `toAddMapped` maps all items in `toAdd` to the actual elements in the result.
  // Usually it will match the same item that was input, but in the case where
  // two hangtimes were merged, then we'll point to the merged item.
  const toAddMapped = toAdd.map(h => dupeMap[h.h.toHexString()] || h);
  return { result, toAddMapped };
}

// Note: The IDs of all main objects (users, friends, and hangtimes) have
// one-letter names (u, f, and h, respectively) for their ID properties because
// our storage capacity, performance, and cost is all based on total size of
// data (including attribute names!) For most fields the size of the name won't
// matter much (so it's not worth making them very short) but IDs are used
// hundreds of times per user so keeping them short could have non-trivial
// positive impact. So we'll keep them short!
