import React, { CSSProperties } from 'react';
import { Route, Link, RouteComponentProps } from 'react-router-dom';

// JG: forked from https://raw.githubusercontent.com/chacestew/react-router-tabs/master/src/NavTab.js
// Forked because I wanted to match bootstrap which uses two elements (an LI that wraps an A) instead of a single A element.
// While I was at it, I converted to TypeScript.

/*
    NavTab is just React-Router's <NavLink> with some default props changed + an onClick handler to block clicks on the active tab.
    Forking the code instead of wrapping <NavLink> because it invokes match object we can use in the onClick, saving us doing the same thing higher up.
    Taken from: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/NavLink.js 
*/

import { NavLinkProps } from 'react-router-dom';
import { InternalServerError } from './HangleExceptions';

// Omit taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface NavTabProps extends Omit<NavLinkProps, 'onClick'> {
  disabled?: boolean;
  allowClickOnActive?: boolean;
  containerStyle?: React.CSSProperties;
  containerActiveStyle?: React.CSSProperties;
  containerClassName?: string;
  containerActiveClassName?: string;
}

export const NavTab: React.FunctionComponent<NavTabProps> = ({
  to,
  replace,
  exact,
  strict,
  location,
  activeClassName,
  className,
  activeStyle,
  style,
  isActive: getIsActive,
  'aria-current': ariaCurrent,
  disabled,
  allowClickOnActive,
  containerClassName,
  containerActiveClassName,
  containerStyle,
  containerActiveStyle,
  ...rest
}) => {
  if (typeof to === 'function') {
    throw new InternalServerError(`Can't use function paths for routes using current implementation`);
  }
  const path = typeof to === 'object' ? to.pathname : to;

  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

  return (
    <Route
      exact={exact}
      path={escapedPath}
      strict={strict}
      location={location}
      children={({ location: loc, match }: RouteComponentProps) => {
        const isActive = !!(getIsActive ? getIsActive(match, loc) : match);
        const resolvedStyle = typeof style === 'function' ? style(isActive) : style ?? {};
        const resolvedClassName = typeof className === 'function' ? className(isActive) : className;
        const mergeStyles = (s1: CSSProperties | undefined, s2: CSSProperties | undefined | false): CSSProperties => ({
          ...(s1 ? s1 : {}),
          ...(s2 ? s2 : {}),
        });
        const mergeClassNames = (c1: string | undefined, c2: string | false | undefined) =>
          [c1, c2].filter(i => i).join(' ');

        const onClick = (e: React.MouseEvent<HTMLElement>) => {
          if (disabled || (isActive && !allowClickOnActive)) {
            e.preventDefault();
          }
        };

        // TODO: lots of accessibility work required below!
        // https://diginclusion.com/industry-updates/wai-aria-dark-art-of-accessibility/
        return (
          <li
            onClick={onClick} // Prevent any action when the clicked tab is active or disabled
            className={mergeClassNames(containerClassName, containerActiveClassName)}
            style={mergeStyles(containerStyle, isActive && containerActiveStyle)}
          >
            <Link
              to={to}
              replace={replace} // We usually won't want tabbed navigation appending to browser history
              onClick={onClick} // Prevent any action when the clicked tab is active or disabled
              className={mergeClassNames(resolvedClassName, isActive && activeClassName)}
              style={mergeStyles(resolvedStyle, isActive && activeStyle)}
              aria-current={(isActive && ariaCurrent) || undefined}
              {...rest}
            />
          </li>
        );
      }}
    />
  );
};

NavTab.defaultProps = {
  'aria-current': 'page',
  className: 'nav-link',
  activeClassName: 'active',
  replace: true,
  containerClassName: 'nav-item',
  containerStyle: { cursor: 'pointer' },
};

export default NavTab;
