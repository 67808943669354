// This module defines types created by the client and accepted by the server
// to add/update/remove stored objects.
import { HangTime } from './HangTime';
import { Friend } from './Friend';
import { ObjectId } from 'bson';
import { Tag } from './Tag';
import { UserProfile } from './UserProfile';
import { Hang, Invite } from './Hang';
import { PossibleMatch } from './Notification';
import { Temporal } from '@js-temporal/polyfill';
import { SerializeType } from './Serializer';
import { Image, Redirect } from './imageManager';

export interface HasVersion {
  version: number;
}

export const hasVersion = (o: unknown): o is HasVersion => typeof (o as HasVersion).version !== 'undefined';

/** When deleting a hang, what should we do? */
interface DeleteHangOptions {
  // TODO: implement the reschedule option.
  /** Does the user want to reschedule this hang (true) or cancel it (false) */
  shouldReschedule?: boolean;

  /** If true, we'll convert the hang to a hang time so the user can hang with
   * someone else at this time. If false, we'll delete the hangtime completely.
   * */
  keepHangTime?: boolean;
}

export interface UpdateHangtimeInput extends HasVersion {
  hangTime: HangTime;
}

/** We re-use the same input type for deleting hangtimes and hangs. If it's a hang,
 * use the required option to determine what should happen after deletion. */
export interface DeleteHangtimeInput extends UpdateHangtimeInput {
  deleteHangOptions?: DeleteHangOptions;
}

/** The UI for adding hangtimes supports adding one at a time and optionally adding
 * multiple hangtimes in one call. This type supports both models. We don't (yet, at least)
 * support bulk updating/deleting hangtimes because changing hangtimes is much more
 * complicated because a hangtime can be associated with a Hang, can be shown in an
 * outstanding notification, etc.
 * */
export interface CreateHangtimeInput extends HasVersion {
  hangTimes: HangTime | HangTime[];
}

export interface CreateHangInput extends HasVersion {
  hang: Hang;
}

export interface UpdateFriendInput extends HasVersion {
  friend: Friend;
}

export interface HandleFriendRequestInput extends HasVersion {
  friend: Friend;
  accepted: boolean;
}

interface ProfileFieldsSettableExternally extends PartialNullable<UserProfile> {
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string | null;
  shortName?: string;
  nameFormat?: string;
  pictureUrl?: string;
  facebookUserId?: string;
  googleUserId?: string;
  phone?: string | null;
  shareEmailDefault: boolean;
  sharePhoneDefault: boolean;
}

export interface ProfileFieldsSettableInUI extends Partial<UserProfile> {
  email?: string;
  phone?: string | null;
}
export const profileFieldsSettableInUI: Array<keyof ProfileFieldsSettableInUI> = ['email', `phone`];

// fields below here are only allowed on internal calls, not directly by clients
// when adding new fields here, make sure to also extend the list of internal-only fields below
interface ProfileFieldsSettableInternally extends ProfileFieldsSettableExternally {
  externalUserId?: string;
  isProvisional?: false;
  joinedAt?: Temporal.Instant;
}
export const internalOnlySettableProfileFields: Array<keyof ProfileFieldsSettableInternally> = [
  'externalUserId',
  'isProvisional',
  'joinedAt',
];
export const internalSettableProfileFields: Array<keyof ProfileFieldsSettableInternally> = [
  ...internalOnlySettableProfileFields,
  'email',
  'firstName',
  'lastName',
  'middleName',
  'shortName',
  'nameFormat',
  'name',
  'pictureUrl',
  'phone',
  'createdAt',
  'facebookUserId',
  'googleUserId',
  'shareEmailDefault',
  'sharePhoneDefault',
];

export interface UpdateUserProfileInput extends HasVersion {
  _id?: ObjectId; // if omitted, will use externalUserId from context
  profile: ProfileFieldsSettableInUI;
}

type PartialNullable<T> = { [P in keyof T]?: T[P] | null };

// See note under https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#example-4
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

interface CreateUserInputBase extends PartialNullable<Omit<UserProfile, 'isProvisional'>> {
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  shortName: string;
  nameFormat: string;
  phone: string | null;
}

export type CreateUserInput = CreateUserInputBase &
  (
    | { facebookUserId: string; facebookToken: string; googleUserId: string; googleToken: string }
    | { facebookUserId: null; facebookToken: null; googleUserId: string; googleToken: string }
    | { facebookUserId: string; facebookToken: string; googleUserId: null; googleToken: null }
  );

export interface GetUserInput {
  _id: ObjectId;
}

export interface GetUserPicturesInput {
  userIds: ObjectId[];
  pictureType: 'profile';
}

export interface ImageOutput {
  u: ObjectId;
  type: 'image';
  response: Image;
}
export type ImageResult = SerializeType<ImageOutput>;

export interface RedirectOutput {
  u: ObjectId;
  type: 'redirect';
  response: Redirect;
}
export type RedirectResult = SerializeType<RedirectOutput>;

export interface NotFoundOutput {
  u: ObjectId;
  type: 'not found';
}
export type NotFoundResult = SerializeType<NotFoundOutput>;

export interface GetUserPicturesOutput {
  results: Array<ImageOutput | RedirectOutput | NotFoundOutput>;
}

export type GetUserPicturesResult = SerializeType<GetUserPicturesOutput>;

export interface GetHangInput {
  _id: ObjectId;
}

export interface UserTags {
  tags: Tag[];
}

export type GetCurrentUserTagsResult = SerializeType<UserTags>;

export interface GetPossibleMatchesInput {
  hangTimes: HangTime[];
  maxCount: number;
}
export interface PossibleMatches {
  possibleMatches: PossibleMatch[];
}
export type GetPossibleMatchesResult = SerializeType<PossibleMatches>;

/** Given a list of possible matches, Hangle will return 0+ actual matches.
 * Callers should be prepared for zero matches!
 */
export interface GetMatchesInput {
  possibleMatches: PossibleMatch[];
}
export interface Matches {
  matches: Invite[];
}
export type GetMatchesResult = SerializeType<Matches>;
