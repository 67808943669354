import { CSSProperties, useCallback, useRef, KeyboardEvent } from 'react';
import { parseTime } from './dateUtils';
import ReactSelect, { Props } from 'react-select';
import { StylesConfig } from 'react-select/src/styles';
import { InternalServerError } from './HangleExceptions';
import { Temporal } from '@js-temporal/polyfill';

interface TimePickerDefaultOption {
  raw: string;
  parsed: Temporal.PlainTime;
  friendly: string;
  isUserEntered: boolean;
}

/** Same as regular option except can have null parsed value for user's incomplete/invalid entries */
export type TimePickerFieldValue = Omit<TimePickerDefaultOption, 'parsed'> & { parsed: Temporal.PlainTime | null };

const getOptionLabel = (time: Temporal.PlainTime) =>
  time.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' });

type TimePickerSelectProps = Props<TimePickerFieldValue>;

export interface TimePickerProps extends Omit<TimePickerSelectProps, 'onChange'> {
  value: TimePickerFieldValue;
  onChange: (value: TimePickerFieldValue) => void;
}

export const getOptionFromDate = (time: Temporal.PlainTime, isUserEntered?: boolean): TimePickerDefaultOption => {
  const label = getOptionLabel(time);
  return {
    raw: label,
    parsed: time,
    friendly: label,
    isUserEntered: isUserEntered || false,
  };
};

export const getOptionFromString = (timeString: string, isUserEntered?: boolean): TimePickerFieldValue => {
  const time = parseTime(timeString);
  const normalized = time ? time.round({ smallestUnit: 'minutes', roundingMode: 'trunc' }) : null;
  return {
    raw: timeString,
    parsed: normalized,
    friendly: normalized ? getOptionLabel(normalized) : timeString,
    isUserEntered: isUserEntered || false,
  };
};

const defaultOptions = Array.from({ length: 48 }, (v, k) => {
  const time = Temporal.PlainTime.from({ hour: Math.floor(k / 2), minute: k % 2 === 0 ? 0 : 30 });
  return getOptionFromDate(time);
});

// TODO: extract the styling below (used in multiple dropdowns) into shared code
const menuStyle: CSSProperties = { zIndex: 10 };
// TODO: add type safety to the option type
const styleFunctions: StylesConfig<TimePickerFieldValue, false> = {
  menu: defaultStyles => ({ ...defaultStyles, ...menuStyle }),
};

// If user picked a default option, or if what the user typed isn't valid,
// then just show the default options. Otherwise, insert show what the user
// typed in sorted order.
function getOptions(parsed: Temporal.PlainTime | null) {
  const match = parsed && defaultOptions.find(o => o.parsed.equals(parsed));
  const comparer = (o1: TimePickerDefaultOption, o2: TimePickerDefaultOption) =>
    Temporal.PlainTime.compare(o1.parsed, o2.parsed);
  const options =
    match || !parsed ? defaultOptions : [...defaultOptions, getOptionFromDate(parsed, true)].sort(comparer);
  console.log({ parsed, match, len: options.length });
  return options;
}

export default function TimePicker(props: TimePickerProps) {
  const { value, input, onChange, ...rest } = props;
  const { parsed } = value;
  const select = useRef<ReactSelect<TimePickerFieldValue>>(null);
  const options = getOptions(parsed);
  const index = options.findIndex(o => parsed && o.parsed.equals(parsed));
  const percentAtStart = index / options.length;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  console.log(JSON.stringify({ op: 'render', value, input, rest }));

  const handleMenuClose: TimePickerSelectProps['onMenuClose'] = useCallback(() => {
    select.current?.blur();
  }, [select]);

  const handleChange: TimePickerSelectProps['onChange'] = (selected, action) => {
    console.log(JSON.stringify({ op: 'onChange', selected, action }));
    if (selected == null || Array.isArray(selected)) {
      throw new InternalServerError('Unexpected undefined value or multiple-value selection in time picker');
    }
    props.onChange(selected);
  };

  const handleFocus = useCallback(
    element => {
      console.log(JSON.stringify({ op: 'focus', value }));
      const inputValue = value.friendly || value.raw;
      if (select.current) select.current.setState({ inputValue });
    },
    [value]
  );

  const isOptionSelected: TimePickerSelectProps['isOptionSelected'] = (option, currentSelectedOptions) => {
    if (currentSelectedOptions.length > 1) {
      throw new InternalServerError(`Too many selected items: ${currentSelectedOptions.length}`);
    }
    return !!value.parsed && !!option.parsed && option.parsed.equals(value.parsed);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (!select.current) return;
      if (['Enter', 'Tab'].includes(event.key)) {
        const option = getOptionFromString(select.current.state.inputValue, true);
        const match = options.find(o => option.parsed && o.parsed.equals(option.parsed));
        const newValue = match || option;
        onChange(newValue);
        select.current?.blur();
        event.preventDefault();
      }
    },
    [onChange, options]
  );

  // Set focus to the selected option Feature of focusing selected option when menu is getting opened
  const handleMenuOpen = useCallback(() => {
    setTimeout(() => {
      if (!select.current) return;
      if (!value.parsed) return;
      if (index === -1) return;

      // We'll scroll the selected slot about 1/3 of the way up the window,
      // because users usually tap the start of a time slot so we want to keep
      // room for time below.
      const menuEl = select.current.select.menuListRef as unknown as HTMLElement;
      const menuHeight = menuEl.getBoundingClientRect().height;
      const contentHeight = menuEl.scrollHeight;
      const selectedY = contentHeight * percentAtStart;
      const scrollTop = Math.max(Math.floor(selectedY - menuHeight / 3), 0);
      menuEl.scrollTo({ top: scrollTop });

      /*
      // Setting a focused value as a selected one
      // References:
      // - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L503
      // - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L802
      select.current.select.scrollToFocusedOptionOnUpdate = true;
      select.current.select.inputIsHiddenAfterUpdate = false;
      select.current.select.setState({
        focusedValue: null,
        focusedOption: (value as unknown) as { label: string; value: string },
      });
      */
    });
  }, [value, index, percentAtStart]);

  return (
    <ReactSelect<TimePickerFieldValue>
      ref={select}
      name="startTime"
      blurInputOnSelect={true}
      isClearable={false}
      backspaceRemovesValue={false}
      isSearchable={true}
      onChange={handleChange}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      options={options}
      value={value}
      filterOption={() => true}
      styles={styleFunctions}
      isOptionSelected={isOptionSelected}
      getOptionValue={option => option.parsed?.toString() ?? ''}
      getOptionLabel={option => option.friendly}
      controlShouldRenderValue={true}
    />
  );
}
