import { ReactNode, useRef, useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useRect } from '@reach/rect';
import { useCallback } from 'react';

export interface ConfettiProps {
  children: ReactNode;
}

const outerStyle = { position: 'relative', top: 0 } as const;
const innerStyle = { display: 'flex', flex: '1 0 auto', flexDirection: 'column' } as const;

export default function Confetti(props: ConfettiProps) {
  const { children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);
  const [size, setSize] = useState<{ height: number; width: number } | null>(null);

  useEffect(() => {
    if (rect && (!size || !size.height || !size.width)) {
      setSize({ width: rect.width, height: rect.height });
    }
  }, [rect, size]);

  const confettiStyle = useCallback(
    (height: number) => ({ display: 'block', zIndex: 999, position: 'relative', marginTop: -height } as const),
    []
  );

  return (
    <div style={outerStyle}>
      <div style={innerStyle} ref={ref}>
        {children}
      </div>
      {size && size.height && size.width && (
        <ReactConfetti
          recycle={false} // only show one batch of confetti
          tweenDuration={750} // run shorter than default
          width={size.width}
          height={size.height}
          gravity={0.2} // fall faster than default
          style={confettiStyle(size.height)}
        />
      )}
    </div>
  );
}
