import { Tag } from './Tag';
import { UserTags } from './HangleCRUD';
import { useApi, API } from './API';
import { useState, useCallback, useMemo } from 'react';
import { createContainer } from 'unstated-next';
import UserStateContainer from './UserStateContainer';

const useTags = () => {
  const [userTags, setUserTags] = useState([] as Tag[]);
  const { userState } = UserStateContainer.useContainer();
  const { callApi, handleApiError } = useApi(userState);

  const refreshUserTagList = useCallback(async (): Promise<void> => {
    try {
      const result = await callApi<UserTags>(() => API.get('user', '/prod/user/me/tags', {}));
      console.log(`API success with ${result.tags && result.tags.length} tags`);
      setUserTags(result.tags);
    } catch (e) {
      handleApiError('fetching tags for the current user', e as Error);
    }
  }, [callApi, handleApiError]);
  const result = useMemo(() => ({ refreshUserTagList, userTags }), [refreshUserTagList, userTags]);
  return result;
};

const TagsContainer = createContainer(useTags);

export default TagsContainer;
