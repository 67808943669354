import { isLambdaError, formatLambdaError } from './LambdaError';
import { serialize, deserialize, deserializeTypes, serializeTypes } from './Serializer';
import OriginalAPI from '@aws-amplify/api-rest';
import { InternalServerError, LoginRequiredError, AwsAmplifyError } from './HangleExceptions';
import { ModalContainer } from './ContextModal';
import { UserState } from './UserState';
import { stringify } from './stringify';

type Runner = (apiName: string, path: string, init: Record<string, unknown>) => Promise<Record<string, unknown>>;
const apiWrapper = (runner: Runner) => {
  const wrapped = async <TOutput>(apiName: string, path: string, init: Record<string, unknown>) => {
    // Serialize the function input into MongoDB extended JSON: valid JSON that
    // serializes Dates, RegExps, etc. This allows us to, for example, pass
    // native JS Date objects from client code into a Lambda function
    const serializedInputObject = serialize(serializeTypes(init));

    // If there's a querystring and it's an object, convert to JSON to avoid
    // problems with nested objects not being handled correctly in querystring
    // params. See https://github.com/nodejs/node-v0.x-archive/issues/1665. On
    // the server, we'll reverse this JSON-ification for query strings.
    const qs = serializedInputObject['queryStringParameters'];
    if (typeof qs === 'object') {
      const qsJSON = JSON.stringify(qs);
      serializedInputObject['queryStringParameters'] = { qsJSON };
    }

    // Call the server function with the serialized input. Note that aws-amplify
    // "API" import is actually a class instance, so we need to ensure that its
    // methods are called with the correct value of `this`.
    const resultExtJsonObject = await runner.call(OriginalAPI, apiName, path, serializedInputObject);

    // The server returns a javascript object version of the serialized MongoDB
    // Extended JSON. Convert it to an (extended) JSON string, then use the
    // Extended JSON parser to get back to plain JS objects.
    const resultObject = deserializeTypes(deserialize(resultExtJsonObject));

    return resultObject as unknown as TOutput;
  };
  return wrapped;
};

// The wrapper function retains `this` when calling OriginalAPI, so we can
// safely ignore the ESLint warning about losing `this`.
/* eslint-disable @typescript-eslint/unbound-method */
export const API = {
  get: apiWrapper(OriginalAPI.get),
  put: apiWrapper(OriginalAPI.put),
  del: apiWrapper(OriginalAPI.del),
  post: apiWrapper(OriginalAPI.post),
};
/* eslint-enable @typescript-eslint/unbound-method */

export function formatError<T>(e: AwsAmplifyError<T>) {
  return (e.response && e.response.data && JSON.stringify(e.response.data)) || e.message;
}

export function useApi(userState: UserState) {
  const { addModal } = ModalContainer.useContainer();

  function handleApiError<T extends Record<string, unknown>>(activityName: string, e: Error) {
    const message =
      e instanceof LoginRequiredError
        ? `You're not logged in. Please log in or sign up to use this feature.`
        : `API error ${activityName}: ${formatError(e as AwsAmplifyError<T>)}`;
    console.log(message);
    addModal(message);
  }

  async function callApi<T>(action: () => Promise<unknown>, requireLoggedIn = true): Promise<T> {
    if (requireLoggedIn && userState.profile == null) {
      throw new LoginRequiredError('Login required');
    }
    const apiResult = await action();
    console.log(`API returned: ${stringify(apiResult)}`);
    if (!apiResult) {
      throw new InternalServerError('No results returned from server, usually because the user is not logged in.');
    }
    if (isLambdaError(apiResult)) {
      throw new Error(formatLambdaError(apiResult));
    }
    return apiResult as T;
  }
  return { callApi, handleApiError };
}
