import { HangTimeEditProps } from './HangTimeEdit';
import { toYYYYMMDD, toHHMM } from './dateUtils';
import { HangTimesTabProps } from './HangTimesTab';
import { FriendEditProps } from './FriendEdit';
import { ProfileEditProps } from './ProfileEdit';

/**
 * Pages are split between a base type for parsing and calculating the URLs
 * of a route, and a derived type with the React component that implements
 * that route. Purpose of this split is to enable URL-creation and parsing
 * to be moved into tree-shook shared code without bringing in all of React.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface RouteUrls<Props, UrlProps> {
  urlBuilder: (props: Props) => string;
  path: string | string[];
}

const slashPrefix = (s: string) => (s ? '/' + s : '');

export const hangTimeNewRouteUrls: RouteUrls<
  HangTimeEditProps,
  {
    d: string;
    t: string;
  }
> = {
  path: ['/times/day/:d(\\d{8})/:t(\\d{4})/new(/)?', '/times/day/:d(\\d{8})/new(/)?'],
  urlBuilder: (props: HangTimeEditProps) => {
    const { startTime } = props.hangTime;
    const dateString = toYYYYMMDD(startTime);
    return `/times/day/${dateString}/new`;
  },
};

export const hangTimeEditRouteUrls: RouteUrls<
  HangTimeEditProps,
  {
    d: string;
    h: string;
  }
> = {
  path: ['/times/day/:d(\\d{8})/:h[0-9a-fA-F]{24}', '/times/day/:d(\\d{8})/:t(\\d{4})/:h[0-9a-fA-F]{24}'],
  urlBuilder: (props: HangTimeEditProps) => {
    const { h, startTime, minutes } = props.hangTime;
    const midpointDate = startTime.add({ minutes: Math.floor(minutes / 2) });
    const dateString = toYYYYMMDD(midpointDate);
    const timeString = toHHMM(midpointDate);
    return `/times/day/${dateString}/${timeString}/${h.toHexString()}`;
  },
};

export const hangTimesTabRouteUrls: RouteUrls<
  HangTimesTabProps,
  {
    d?: string;
    t?: string;
    v?: string;
  }
> = {
  path: ['/times/:v(day|list)/:d(\\d{8})/:t(\\d{8})(/)?', '/times/:v(day|list)/:d(\\d{8})(/)?'],
  urlBuilder: (props: HangTimesTabProps) => {
    const { date, time, view } = props;
    const dateString = toYYYYMMDD(date);
    const timeString = toHHMM(time);
    return `/times${slashPrefix(view)}${slashPrefix(dateString)}${slashPrefix(timeString)}`;
  },
};

export const friendEditRouteUrls: RouteUrls<FriendEditProps, { f?: string }> = {
  path: '/friends/:f*',
  urlBuilder: (props: FriendEditProps) => {
    const { friend } = props;
    return `/friends${(friend && slashPrefix(friend.f.toHexString())) || ''}`;
  },
};

export const profileEditRouteUrls: RouteUrls<ProfileEditProps, unknown> = {
  path: '/profile(/)?',
  urlBuilder: () => {
    return `/profile`;
  },
};
