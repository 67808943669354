import UserStateContainer from './UserStateContainer';
import HangTimeEdit, { newHangTimeFromDayAndTime } from './HangTimeEdit';
import { ArgumentError, InternalServerError } from './HangleExceptions';
import { parseDateFromYYYYMMDD, parseTimeFromHHMM, DEFAULT_TIME } from './dateUtils';
import { Route, Switch, RouteComponentProps, useLocation, Redirect } from 'react-router-dom';
// import HomeTab from './HomeTab';
import FriendsTab from './FriendsTab';
import HangTimesTab, { CalendarViewType } from './HangTimesTab';
import FriendEdit from './FriendEdit';
import ProfileEdit from './ProfileEdit';
import Migrations from './Migrations';
import HangleId from './HangleId';
import CreateTestUsers from './dev/CreateTestUsers';
import AuthV1, { AuthProps } from './Auth';
import {
  RouteUrls,
  hangTimeNewRouteUrls,
  friendEditRouteUrls,
  hangTimesTabRouteUrls,
  hangTimeEditRouteUrls,
  profileEditRouteUrls,
} from './RouteUrls';
import { TermsAndConditions, PrivacyPolicy } from './Legal';
import { Temporal } from '@js-temporal/polyfill';
import JobMail from './JobMail';

interface Page<Props, UrlProps> extends RouteUrls<Props, UrlProps> {
  Component: React.FC<RouteComponentProps<UrlProps>>;
}

function implementRoute<Props, UrlProps>(
  urls: RouteUrls<Props, UrlProps>,
  Component: Page<Props, UrlProps>['Component']
) {
  return { ...urls, Component };
}

export const hangTimeNewPage = implementRoute(hangTimeNewRouteUrls, routeProps => {
  const dateInUrl = routeProps.match.params.d;
  const timeInUrl = routeProps.match.params.t;
  const date = parseDateFromYYYYMMDD(dateInUrl) || Temporal.Now.plainDateISO();
  const time = (timeInUrl && parseTimeFromHHMM(timeInUrl)) || DEFAULT_TIME;
  const hangTime = newHangTimeFromDayAndTime(date, time);
  return <HangTimeEdit hangTime={hangTime} />;
});

export const hangTimeEditPage = implementRoute(hangTimeEditRouteUrls, routeProps => {
  const hangTimeId = new HangleId(routeProps.match.params.h);
  const { userState } = UserStateContainer.useContainer();
  const findHangTime = () => {
    const hangTime = userState.hangTimes.find(ht => ht.h.equals(hangTimeId));
    if (!hangTime) {
      // TODO: change to a friendly error page
      throw new ArgumentError('Hang Time not found. Please return to home page.');
    }
    return hangTime;
  };

  return <HangTimeEdit hangTime={findHangTime()} />;
});

export const hangTimesTabPage = implementRoute(hangTimesTabRouteUrls, routeProps => {
  const dayInUrl = routeProps.match.params.d;
  const timeInUrl = routeProps.match.params.t;
  const date = (dayInUrl && parseDateFromYYYYMMDD(dayInUrl)) || Temporal.Now.plainDateISO();
  const time = (timeInUrl && parseTimeFromHHMM(timeInUrl)) || DEFAULT_TIME;
  const view: CalendarViewType = routeProps.match.params.v === 'list' ? 'list' : 'day';
  return <HangTimesTab date={date} time={time} view={view} />;
});

export const friendEditPage = implementRoute(friendEditRouteUrls, routeProps => {
  const friendIdInUrl = new HangleId(routeProps.match.params.f);
  const { userState } = UserStateContainer.useContainer();
  const findFriend = () => userState.friends.find(friend => friend.f.equals(friendIdInUrl));

  return <FriendEdit friend={findFriend()} />;
});

export const profileEditPage = implementRoute(profileEditRouteUrls, routeProps => {
  const { userState } = UserStateContainer.useContainer();
  const profile = userState.profile;
  return <ProfileEdit profile={profile} />;
});

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

function AuthFacebook() {
  const query = useQueryString();
  const facebookState = query.get('state');
  if (!facebookState) {
    throw new InternalServerError(`Invalid Facebook OAuth response state`);
  }
  const facebookStateObject = JSON.parse(facebookState) as AuthProps;
  return <AuthV1 {...facebookStateObject} />;
}

function AuthGoogle() {
  const query = useQueryString();
  const redirectUri = query.get('redirectUri');
  if (!redirectUri) {
    throw new InternalServerError(`Missing redirect URI from Google OAuth response`);
  }
  return <AuthV1 redirectUri={redirectUri} version="1.0" />;
}

export const Routes = () => (
  <Switch>
    {/* Hangtimes home page, e.g. /times. Defaults to today's date. */}
    <Route path="/times(/)?" exact component={HangTimesTab} />

    {/* Create new hangtime for a particular day and optional time, 
            e.g. /times/day/20190926/1800/new or /times/day/20190926/new */}
    <Route path={hangTimeNewPage.path} component={hangTimeNewPage.Component} />

    {/* Edit an existing hangtime, e.g. /times/day/20190926/104c3ad94d984ccfb20e5486ae0cb01e */}
    <Route path={hangTimeEditPage.path} component={hangTimeEditPage.Component} />

    {/* View times on a particular day, e.g. /times/day/20190926 */}
    <Route
      // cast required to avoid optional trailing slash breaking param extraction
      // see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/52914#issuecomment-890100974
      path={'/times/:v(day|list)/:d(\\d{8})(/)?' as '/times/:v/:d'}
      render={(routeProps: RouteComponentProps<{ d: string; v: string }>) => {
        const dayInUrl = routeProps.match.params.d;
        const viewInUrl: CalendarViewType = routeProps.match.params.v === 'list' ? 'list' : 'day';
        const date = parseDateFromYYYYMMDD(dayInUrl) || Temporal.Now.plainDateISO();
        return <HangTimesTab date={date} view={viewInUrl} />;
      }}
    />

    {/* View times on a particular day, optionally scrolled to a particular time, e.g. /times/day/20190926/1400 */}
    <Route
      path={[
        // cast required to avoid optional trailing slash breaking param extraction
        // see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/52914#issuecomment-890100974
        '/times/:v(day|list)/:d(\\d{8})/:t(\\d{8})(/)?' as '/times/:v/:d/:t',
        '/times/:v(day|list)/:d(\\d{8})(/)?' as '/times/:v/:d',
      ]}
      render={(routeProps: RouteComponentProps<{ d: string; t?: string; v: string }>) => {
        const dayInUrl = routeProps.match.params.d;
        const timeInUrl = routeProps.match.params.t;
        const viewInUrl: CalendarViewType = routeProps.match.params.v === 'list' ? 'list' : 'day';
        const date = parseDateFromYYYYMMDD(dayInUrl) || Temporal.Now.plainDateISO();
        const time = (timeInUrl && parseTimeFromHHMM(timeInUrl)) || DEFAULT_TIME;
        return <HangTimesTab date={date} time={time} view={viewInUrl} />;
      }}
    />

    <Route path="/times/new(/)?" exact component={HangTimeEdit} />

    <Route path="/friends/new(/)?" exact component={FriendEdit} />

    <Route path="/friends(/)?" exact component={FriendsTab} />

    {/* Edit a friendship, e.g. /friends/104c3ad94d984ccfb20e5486ae0cb01e*/}
    <Route path={friendEditPage.path} component={friendEditPage.Component} />

    <Route path={profileEditPage.path} component={profileEditPage.Component} />

    <Route exact path="/migrations(/)?" component={Migrations} />

    <Route exact path="/jobmail(/)?" component={JobMail} />

    <Route exact path="/testusers(/)?" component={CreateTestUsers} />

    {/* <Route exact path="/repro" component={Repro} /> */}

    <Route exact path="/auth/facebook/1.0" component={AuthFacebook} />
    <Route exact path="/auth/google/1.0" component={AuthGoogle} />

    <Route path="/terms" component={TermsAndConditions} />

    <Route path="/privacy" component={PrivacyPolicy} />

    <Route exact path="/" render={() => <Redirect to="/times" />} />
  </Switch>
);
