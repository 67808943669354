import { CSSProperties } from 'react';
import Select, { Props } from 'react-select/creatable';
import { InternalServerError } from './HangleExceptions';
import { StylesConfig } from 'react-select/src/styles';

// See https://github.com/microsoft/TypeScript/issues/17002#issuecomment-494937708
declare global {
  interface ArrayConstructor {
    isArray(arg: ReadonlyArray<unknown> | unknown): arg is ReadonlyArray<unknown>;
  }
}

export interface DurationPickerFieldValue {
  raw: string;
  parsed: number | null;
  friendly: string | null;
  isUserEntered: boolean;
}

export interface DurationPickerProps extends Omit<Props<DurationPickerFieldValue, false>, 'onChange'> {
  value: DurationPickerFieldValue;
  onChange: (value: DurationPickerFieldValue) => void;
}

export const toDurationPickerFieldValue = (minutes: number, isUserEntered?: boolean): DurationPickerFieldValue => {
  const asString = formatDuration(minutes);
  return {
    raw: asString,
    parsed: minutes,
    friendly: asString,
    isUserEntered: isUserEntered || false,
  };
};

// adapted from https://github.com/mdibaiee/moment-parse-duration/blob/master/index.js
// I couldn't figure out how to get that extension of moment.js to import correctly,
// so just moving the code here. Also there were several obvious bugs in that code!
const UNITS = {
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
  week: 1000 * 60 * 60 * 24 * 7,
};
function parseDuration(durationString: string, isUserEntered?: boolean) {
  const tokens = durationString
    .split(' ')
    .map((word: string) => {
      const value = parseFloat(word);
      if (!isNaN(value)) {
        return value;
      } else {
        const unit = Object.keys(UNITS).find(u => new RegExp(u, 'i').test(word));
        return unit;
      }
    })
    .filter(a => a) as (string | number)[];

  let totalMsecs;
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (typeof token === 'number') {
      const unit = tokens[i + 1];
      if (unit) {
        const unitMsecs = UNITS[unit] * token;
        totalMsecs = (totalMsecs || 0) + unitMsecs;
      }
    }
  }

  const minutes = totalMsecs == null ? null : Math.round(totalMsecs / (1000 * 60));

  const value: DurationPickerFieldValue = {
    raw: durationString,
    parsed: minutes,
    friendly: minutes == null ? durationString : formatDuration(minutes),
    isUserEntered: isUserEntered || false,
  };
  return value;
}

const COMMON_DURATIONS = [15, 30, 45, 60, 90, 120, 180, 240, 300, 360];
const commonOptions = COMMON_DURATIONS.map(m => toDurationPickerFieldValue(m));
const commonOptionsGroup = { label: 'common durations', options: commonOptions };

function getUserEnteredOptions(value: string) {
  const num = parseFloat(value);
  if (isNaN(num) || num <= 0) {
    return [];
  }
  const relatedOptions =
    num > 5
      ? [toDurationPickerFieldValue(num * 60, true), toDurationPickerFieldValue(num, true)]
      : [toDurationPickerFieldValue(num * 60, true)];
  return relatedOptions;
}

const alwaysTrue = () => true;

function formatDuration(mins: string | number) {
  if (typeof mins === 'string') {
    return mins;
  }
  if (mins < 60) {
    return `${mins} minutes`;
  } else if (mins === 60) {
    return '1 hour';
  } else if (mins < 120) {
    return `${mins} minutes`;
  } else if (mins % 60 === 0) {
    return `${Math.floor(mins / 60)} hours`;
  } else {
    return `${Math.floor(mins / 60)} hours and ${mins % 60} minutes`;
  }
}
/*
const getValue = (value: DurationPickerFieldValue | undefined | null) => {
  if (!value) {
    return '';
  }
  if (value.friendly) {
    return value.friendly;
  }
  if (value.parsed) {
    return formatDuration(value.parsed);
  }
  return '';
};
*/

const style: CSSProperties = { display: 'inline-block', minWidth: 208 };
const menuStyle: CSSProperties = { zIndex: 10 };
const styleFunctions: StylesConfig<DurationPickerFieldValue, false> = {
  container: defaultStyles => ({ ...defaultStyles, ...style }),
  menu: defaultStyles => ({ ...defaultStyles, ...menuStyle }),
};

export default function DurationPicker(props: DurationPickerProps) {
  const { value, onChange } = props;

  // TODO: get input value from a prop (assuming we're a controlled component) ?
  //  const [inputValue, setInputValue] = useState(value.friendly || value.raw);
  const onInputChange: Props<DurationPickerFieldValue, false>['onInputChange'] = (value, action) => {
    console.log(`inputChange: ${JSON.stringify({ value, action })}`);
    if (value === '') {
      console.trace();
    }
    if (action.action === 'input-change') {
      const duration = parseDuration(value, true);
      onChange(duration);
    }
  };

  const onSelect: Props<DurationPickerFieldValue, false>['onChange'] = (selected, action) => {
    console.log(`onChange: ${JSON.stringify({ selected, action })}`);
    //    setInputValue(selected.friendly || '');
    if (selected == null || Array.isArray(selected)) {
      throw new InternalServerError('Unexpected undefined value or multiple-value selection in duration picker');
    }
    onChange(selected);
  };

  // Instead of users having to click a "create" link to add an option that's
  // not already on the list, we'll simply add the option automatically (and
  // remove it automatically if the user's input changes)
  const isValidNewOption = () => false;

  const isOptionSelected: Props<DurationPickerFieldValue, false>['isOptionSelected'] = (
    option,
    currentSelectedOptions
  ) => {
    if (currentSelectedOptions.length > 1) {
      throw new InternalServerError(`Too many selected items: ${currentSelectedOptions.length}`);
    }
    const currentSelectedOption = currentSelectedOptions.length > 0 ? currentSelectedOptions[0] : undefined;
    if (currentSelectedOption && currentSelectedOption.parsed === option.parsed) {
      // if an option is already selected, keep the same one selected.
      return currentSelectedOption.isUserEntered === option.isUserEntered;
    }
    const userEnteredOptions = getUserEnteredOptions(value.friendly || value.raw);
    const isUserEnteredMatch = userEnteredOptions.some(o => o.parsed === value.parsed);
    if (isUserEnteredMatch) {
      // The user has entered a value that matches one of the default durations.
      // Only select the user-entered one, not the default one.
      return option.parsed === value.parsed && option.isUserEntered;
    } else {
      // This value doesn't match any of the defaults, so select it.
      return option.parsed === value.parsed;
    }
  };

  const updatedOptions = [...getUserEnteredOptions(value.friendly || value.raw), commonOptionsGroup];
  return (
    <Select<DurationPickerFieldValue>
      defaultInputValue={value.friendly ? value.friendly : undefined}
      value={value}
      isOptionSelected={isOptionSelected}
      onInputChange={onInputChange}
      onChange={onSelect}
      isClearable={false}
      isSearchable={true}
      options={updatedOptions}
      filterOption={alwaysTrue}
      placeholder={'for how long?'}
      backspaceRemovesValue={false}
      name="duration"
      getOptionValue={option => (option.parsed ? option.parsed.toString() : '')}
      getOptionLabel={option => (option.friendly ? option.friendly : '')}
      isValidNewOption={isValidNewOption}
      controlShouldRenderValue={true}
      styles={styleFunctions}
    />
  );
}
