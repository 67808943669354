import { Dispatch, SetStateAction, useState } from 'react';
import { Button } from 'reactstrap';
import { useApi, API } from './API';
import { UserState } from './UserState';
import { JobMailResult } from './JobMailTypes';

interface MailHtmlDriver {
  name: string;
  html: string;
  result: string;
  setResult: Dispatch<SetStateAction<string>>;
}
function useMailHtml(name: string, html: string): MailHtmlDriver {
  const [result, setResult] = useState('');
  return { name, html, result, setResult };
}

/**
 * Primitive support for updating DB schema (aka "migration").
 * This is a solved problem by more mature tools, so consider moving to
 * one of those tools instead of rolling our own here.
 */
const JobMail = () => {
  const { callApi, handleApiError } = useApi(undefined as unknown as UserState);
  const glassdoor = useMailHtml('Glassdoor', glassdoorHtml);
  const google = useMailHtml('Google', googleHtml);
  const linkedin = useMailHtml('LinkedIn', linkedInHtml);
  const sites = [glassdoor, google, linkedin];

  const testJobMail = async (driver: MailHtmlDriver): Promise<JobMailResult | null> => {
    const { name, html, setResult } = driver;
    try {
      const body = {
        text: name === 'LinkedIn' ? linkedInText : 'this is a test',
        html,
        from: 'Justin Grant',
        subject: 'FW: You look like a good fit for the job at ZEISS Group. Apply now.',
        envelope: JSON.stringify({ to: ['justin@parse.agileg.com'], from: 'justin@justingrant.net' }),
      };
      const result = await callApi<JobMailResult>(() => API.post('user', `/prod/jobmail`, { body }), false);
      setResult(result ? `Result: ${JSON.stringify(result)}` : 'Failed');
      return result;
    } catch (e) {
      handleApiError('testing job mail', e as Error);
      return null;
    }
  };

  return (
    <>
      <div style={{ width: '80vw', margin: 20 }}>
        <ul>
          {sites.map(site => (
            <li style={{ margin: 10 }}>
              Test HTML from {site.name} <Button onClick={() => testJobMail(site)}>Run</Button>{' '}
              <span>{site.result}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default JobMail;

/* eslint-disable no-useless-escape */
/* spell-checker: disable */

export const linkedInHtml = `<html lang="en">
<head>
<meta http-equiv="Content-Type" content="text/html; charset=windows-1256">
<meta content="text/html; charset=utf-8">
<meta name="HandheldFriendly" content="true">
<meta name="viewport" content="width=device-width; initial-scale=0.666667; user-scalable=0">
<meta name="viewport" content="width=device-width">
<style type="text/css">
<!--
-->
</style>
</head>
<body style="padding:0; margin:0 auto; width:100%!important; font-family:Helvetica Neue,Helvetica,Arial,sans-serif">
<strong>
<div><font face="Tahoma" color="#000000" size="2">&nbsp;</font></div>
</strong>
<hr tabindex="-1" style="display:inline-block; width:98%">
<font face="Tahoma" size="2"><b>From:</b> LinkedIn Job Alerts <jobalerts-noreply@linkedin.com><br>
<b>Sent:</b> Tuesday, September 7, 2021 2:48:17 PM (UTC-08:00) Pacific Time (US &amp; Canada)<br>
<b>To:</b> Justin Grant <justin@justingrant.net><br>
<b>Subject:</b> 4 new jobs for 'head of product'<br>
</font><br>
<div></div>
<div>
<div style="overflow:hidden; color:transparent; visibility:hidden; width:0; font-size:0; opacity:0; height:0">
View jobs in San Francisco Bay Area �&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;�&nbsp;
</div>
<table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="100%" bgcolor="#F3F2EF" style="background-color:#F3F2EF; table-layout:fixed">
<tbody>
<tr>
<td align="center" style="padding-top:24px">
<center style="width:100%">
<table role="presentation" border="0" class="mercado-email-container" cellspacing="0" cellpadding="0" width="512" bgcolor="#FFFFFF" style="background-color:#FFFFFF; margin:0 auto; max-width:512px; width:inherit">
<tbody>
<tr>
<td bgcolor="#FFFFFF" style="background-color:#FFFFFF; padding:18px 24px 0 24px">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%!important; min-width:100%!important">
<tbody>
<tr>
<td align="left" valign="middle" style=""><a href="https://www.linkedin.com/comm/feed/?midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-header-33-home&amp;trkEmail=eml-email_job_alert_digest_01-header-33-home-null-2eag8%7Ektaltv99%7E1y-null-neptune%2Ffeed&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none"><img alt="LinkedIn" border="0" src="https://www.linkedin.com/comm/dms/logo?midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-null-41-null&amp;trkEmail=eml-email_job_alert_digest_01-null-41-null-null-2eag8%7Ektaltv99%7E1y-null-comms%7Ebadging%7Edynamic&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D&amp;_sig=1N2g6H26QYP9U1" height="38" style="outline:none; color:#FFFFFF; text-decoration:none"></a></td>
<td valign="middle" width="100%" align="right" style=""><a href="https://www.linkedin.com/comm/in/justingrantjg?midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-header-42-profile&amp;trkEmail=eml-email_job_alert_digest_01-header-42-profile-null-2eag8%7Ektaltv99%7E1y-null-neptune%2Fprofile%7Evanity%2Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="margin:0; color:#0A66C2; display:inline-block; text-decoration:none">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td align="left" valign="middle" style="padding:0 0 0 10px; padding-top:7px"></td>
<td valign="middle" width="40" style="padding-top:7px; padding-left:10px"><img alt="Justin Grant" border="0" height="32" width="32" src="https://media-exp1.licdn.com/dms/image/C4E03AQHolOr00AhnTw/profile-displayphoto-shrink_100_100/0/1587534819870?e=2159024400&amp;v=beta&amp;t=hy-ygilPKpXVWBC2sjy92VSiAxro2IsuoHDaYwIyNnk" style="border-radius:50%; outline:none; color:#FFFFFF; text-decoration:none"></td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="padding:24px 24px 8px">
<h2 style="margin:0; color:#000000; font-weight:400; font-size:24px; line-height:1.333">
<a href="https://www.linkedin.com/comm/jobs/search?geoId=90000084&amp;savedSearchId=1339426806&amp;origin=JOB_ALERT_EMAIL&amp;f_TPR=a1630964890-&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-5-title_jserp_header_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-5-title_jserp_header_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Ejserp%7Esearch&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#242424; display:inline; text-decoration:none">Your
 job alert for <span style="font-weight:700">head of product</span></a></h2>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="padding:0 24px 16px">
<p style="margin:0; color:#000000; font-weight:400; font-size:16px; line-height:1.5">
4 new jobs in San Francisco Bay Area match your preferences.</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="">
<table cellspacing="0" cellpadding="0" style="padding:10px 24px">
<tbody valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tr>
<td width="56" style="width:56px; padding-right:16px"><a href="https://www.linkedin.com/comm/jobs/view/2706563206?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=lPo4ntKU8%2FJPQVA845LLhQ%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-6-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-6-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none"><img src="https://media-exp1.licdn.com/dms/image/C4E0BAQGxsamx2xR-Lg/company-logo_100_100/0/1628051039810?e=2159024400&amp;v=beta&amp;t=xyWtyMd4V5ZE_Qs9PDdq5jrjHyI2cyd2vBEgdm7XkWI" alt="Nas Academy" border="0" width="48" style="outline:none; color:#FFFFFF; text-decoration:none"></a></td>
<td align="left" style="padding-left:0; text-align:left"><a href="https://www.linkedin.com/comm/jobs/view/2706563206?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=lPo4ntKU8%2FJPQVA845LLhQ%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-6-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-6-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tbody>
<tr>
<td style="padding-bottom:4px"><a href="https://www.linkedin.com/comm/jobs/view/2706563206?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=lPo4ntKU8%2FJPQVA845LLhQ%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-6-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-6-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; font-weight:700; text-decoration:none; display:inline-block; font-size:16px">Head
 of Product</a></td>
</tr>
<tr>
<td style="">
<p style="margin:0; color:#000000; font-weight:400; font-size:14px; line-height:1.429">
Nas Academy � San Francisco Bay Area</p>
</td>
</tr>
<tr>
<td width="100%" style="padding-top:8px; width:100%">
<table role="presentation" align="left" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr style="color:black">
<td style="display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td style="white-space:nowrap; display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td width="24" style="display:inline-block; width:24px"><img src="https://static.licdn.com/sc/p/com.linkedin.email-assets-frontend%3Aemail-assets-frontend-static-content%2B__latest__/f/%2Femail-assets-frontend%2Fimages%2Femail%2Ficons%2Ficon_green_radar_screen_24x24.png" border="0" alt="radar icon" width="24" height="24" style="outline:none; color:#FFFFFF; text-decoration:none"></td>
<td width="4" style="display:inline-block; width:4px">&nbsp;</td>
<td style="padding:0 24px 4px 4px; white-space:normal; display:inline-block">
<p style="margin:0; color:#737373; font-weight:400; font-size:12px; line-height:1.333">
Actively recruiting</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
<table cellspacing="0" cellpadding="0" style="padding:10px 24px">
<tbody valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tr>
<td width="56" style="width:56px; padding-right:16px"><a href="https://www.linkedin.com/comm/jobs/view/2705985680?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=ojYn4zWnOPVzP9IyONoAyw%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-7-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-7-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none"><img src="https://media-exp1.licdn.com/dms/image/C4D0BAQE0rsd2g6jl7A/company-logo_100_100/0/1519910334733?e=2159024400&amp;v=beta&amp;t=hVkPDJRSA7CTrIFLzuZGN4ABd1_URzAg62jll7Pgm-E" alt="Stealth Mode" border="0" width="48" style="outline:none; color:#FFFFFF; text-decoration:none"></a></td>
<td align="left" style="padding-left:0; text-align:left"><a href="https://www.linkedin.com/comm/jobs/view/2705985680?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=ojYn4zWnOPVzP9IyONoAyw%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-7-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-7-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tbody>
<tr>
<td style="padding-bottom:4px"><a href="https://www.linkedin.com/comm/jobs/view/2705985680?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=ojYn4zWnOPVzP9IyONoAyw%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-7-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-7-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; font-weight:700; text-decoration:none; display:inline-block; font-size:16px">Head
 Of Product Management</a></td>
</tr>
<tr>
<td style="">
<p style="margin:0; color:#000000; font-weight:400; font-size:14px; line-height:1.429">
Stealth Mode � San Francisco Bay Area</p>
</td>
</tr>
<tr>
<td width="100%" style="padding-top:8px; width:100%">
<table role="presentation" align="left" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr style="color:black">
<td style="display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td style="white-space:nowrap; display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td width="24" style="display:inline-block; width:24px"><img src="https://static.licdn.com/sc/p/com.linkedin.email-assets-frontend%3Aemail-assets-frontend-static-content%2B__latest__/f/%2Femail-assets-frontend%2Fimages%2Femail%2Ficons%2Ficon_green_radar_screen_24x24.png" border="0" alt="radar icon" width="24" height="24" style="outline:none; color:#FFFFFF; text-decoration:none"></td>
<td width="4" style="display:inline-block; width:4px">&nbsp;</td>
<td style="padding:0 24px 4px 4px; white-space:normal; display:inline-block">
<p style="margin:0; color:#737373; font-weight:400; font-size:12px; line-height:1.333">
Actively recruiting</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
<table cellspacing="0" cellpadding="0" style="padding:10px 24px">
<tbody valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tr>
<td width="56" style="width:56px; padding-right:16px"><a href="https://www.linkedin.com/comm/jobs/view/2706527391?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=zd1nh0%2FV5jRWSf34I9nCnw%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-8-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-8-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none"><img src="https://media-exp1.licdn.com/dms/image/C4D0BAQHrxuNzdbseQA/company-logo_100_100/0/1625155796542?e=2159024400&amp;v=beta&amp;t=T2der98CChlsPfKXYEFp7bBzG664jJ-qowPqbWeNsPU" alt="Ripple" border="0" width="48" style="outline:none; color:#FFFFFF; text-decoration:none"></a></td>
<td align="left" style="padding-left:0; text-align:left"><a href="https://www.linkedin.com/comm/jobs/view/2706527391?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=zd1nh0%2FV5jRWSf34I9nCnw%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-8-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-8-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tbody>
<tr>
<td style="padding-bottom:4px"><a href="https://www.linkedin.com/comm/jobs/view/2706527391?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=zd1nh0%2FV5jRWSf34I9nCnw%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-8-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-8-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; font-weight:700; text-decoration:none; display:inline-block; font-size:16px">Director,
 Head of RippleX Product Marketing</a></td>
</tr>
<tr>
<td style="">
<p style="margin:0; color:#000000; font-weight:400; font-size:14px; line-height:1.429">
Ripple � San Francisco, California, United States</p>
</td>
</tr>
<tr>
<td width="100%" style="padding-top:8px; width:100%">
<table role="presentation" align="left" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr style="color:black">
<td style="display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td style="white-space:nowrap; display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td width="24" style="display:inline-block; width:24px"><img src="https://static.licdn.com/sc/p/com.linkedin.email-assets-frontend%3Aemail-assets-frontend-static-content%2B__latest__/f/%2Femail-assets-frontend%2Fimages%2Femail%2Ficons%2Ficon_green_radar_screen_24x24.png" border="0" alt="radar icon" width="24" height="24" style="outline:none; color:#FFFFFF; text-decoration:none"></td>
<td width="4" style="display:inline-block; width:4px">&nbsp;</td>
<td style="padding:0 24px 4px 4px; white-space:normal; display:inline-block">
<p style="margin:0; color:#737373; font-weight:400; font-size:12px; line-height:1.333">
Actively recruiting</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
<table cellspacing="0" cellpadding="0" style="padding:10px 24px">
<tbody valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tr>
<td width="56" style="width:56px; padding-right:16px"><a href="https://www.linkedin.com/comm/jobs/view/2706559436?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=2qe3JcyK%2Fzl6D3cB5%2B%2BdyA%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-9-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-9-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none"><img src="https://media-exp1.licdn.com/dms/image/C560BAQG0Out4AvfiNA/company-logo_100_100/0/1625158724708?e=2159024400&amp;v=beta&amp;t=J3eK2e5NFN_x_iMUIcICimtBE0OnhCQuMpYeFiMkXO8" alt="CaptivateIQ" border="0" width="48" style="outline:none; color:#FFFFFF; text-decoration:none"></a></td>
<td align="left" style="padding-left:0; text-align:left"><a href="https://www.linkedin.com/comm/jobs/view/2706559436?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=2qe3JcyK%2Fzl6D3cB5%2B%2BdyA%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-9-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-9-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; display:inline-block; text-decoration:none">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" valign="top" style="table-layout:fixed; vertical-align:top; width:100%">
<tbody>
<tr>
<td style="padding-bottom:4px"><a href="https://www.linkedin.com/comm/jobs/view/2706559436?alertAction=markasviewed&amp;savedSearchAuthToken=1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNpxNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06GcdXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-axMyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7LToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsbcw0lYx&amp;savedSearchId=1339426806&amp;refId=6efce642-4885-4d38-963c-909fc5c41109&amp;trackingId=2qe3JcyK%2Fzl6D3cB5%2B%2BdyA%3D%3D&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-9-member_details_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-9-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; font-weight:700; text-decoration:none; display:inline-block; font-size:16px">Head
 of Product Design</a></td>
</tr>
<tr>
<td style="">
<p style="margin:0; color:#000000; font-weight:400; font-size:14px; line-height:1.429">
CaptivateIQ � San Francisco Bay Area</p>
</td>
</tr>
<tr>
<td width="100%" style="padding-top:8px; width:100%">
<table role="presentation" align="left" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr style="color:black">
<td style="display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td style="white-space:nowrap; display:inline-block">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="initial" style="">
<tbody>
<tr>
<td width="24" style="display:inline-block; width:24px"><img src="https://static.licdn.com/sc/p/com.linkedin.email-assets-frontend%3Aemail-assets-frontend-static-content%2B__latest__/f/%2Femail-assets-frontend%2Fimages%2Femail%2Ficons%2Ficon_green_radar_screen_24x24.png" border="0" alt="radar icon" width="24" height="24" style="outline:none; color:#FFFFFF; text-decoration:none"></td>
<td width="4" style="display:inline-block; width:4px">&nbsp;</td>
<td style="padding:0 24px 4px 4px; white-space:normal; display:inline-block">
<p style="margin:0; color:#737373; font-weight:400; font-size:12px; line-height:1.333">
Actively recruiting</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="padding:14px 24px 36px">
<table border="0" cellpadding="0" cellspacing="0" role="presentation" align="left" style="display:inline-block">
<tbody>
<tr>
<td align="center" valign="middle" style="font-size:16px"><a href="https://www.linkedin.com/comm/jobs/search?geoId=90000084&amp;savedSearchId=1339426806&amp;origin=JOB_ALERT_EMAIL&amp;f_TPR=a1630964890-&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-4-title_jserp_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-4-title_jserp_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Ejserp%7Esearch&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="word-wrap:normal; color:#0A66C2; word-break:normal; white-space:normal; display:block; text-decoration:none; text-align:center">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td bgcolor="#0A66C2" style="padding:12px 24px; color:#FFFFFF; font-weight:400; display:inline-block; text-decoration:none; font-size:16px; line-height:1.25em; border-color:#0A66C2; background-color:#0A66C2; border-radius:34px; border-width:1px; border-style:solid">
<a href="https://www.linkedin.com/comm/jobs/search?geoId=90000084&amp;savedSearchId=1339426806&amp;origin=JOB_ALERT_EMAIL&amp;f_TPR=a1630964890-&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-job_alert-4-title_jserp_mercado&amp;trkEmail=eml-email_job_alert_digest_01-job_alert-4-title_jserp_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Ejserp%7Esearch&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="word-wrap:normal; color:#FFFFFF; word-break:normal; white-space:normal; display:block; text-decoration:none; text-align:center">See
 all jobs</a></td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td height="3" style="">
<table width="1" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:1px; font-size:1px; line-height:1px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" bgcolor="#F3F2EF" align="left" style="background-color:#F3F2EF; padding-top:16px; color:#000000; text-align:left">
<tbody>
<tr>
<td style="">
<table width="24" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:0px; font-size:0px; line-height:0px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="">
<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td style="">
<table width="1" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:12px; font-size:12px; line-height:12px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="left" style="padding:0; color:#000000; text-align:left">
<p style="margin:0; word-wrap:break-word; color:#000000; word-break:break-word; font-weight:400; font-size:12px; line-height:1.333">
This email was intended for Justin Grant (Head of Product in B2B/SaaS Startups). <a href="https://www.linkedin.com/e/v2?e=2eag8-ktaltv99-1y&;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D&a=customerServiceUrl&midToken=AQFI2cUdOmE73Q&midSig=07AtLQDAAYP9U1&ek=email_job_alert_digest_01&articleId=4788" style="color:#0A66C2; text-decoration:underline; display:inline-block">
Learn why we included this.</a></p>
</td>
</tr>
<tr>
<td style="">
<table width="1" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:12px; font-size:12px; line-height:12px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="left" style="padding:0; color:#000000; text-align:left">
<p style="margin:0; color:#000000; font-weight:400; font-size:12px; line-height:1.333">
You are receiving Job Alert emails.</p>
</td>
</tr>
<tr>
<td align="left" style="padding:16px 0 0 0; text-align:left">
<table role="presentation" align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="">
<tbody>
<tr>
<td valign="middle" align="left" style="padding:0 0 16px 0; vertical-align:middle; text-align:left">
<a href="https://www.linkedin.com/comm/jobs/alerts?midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-footer-11-manage_alert&amp;trkEmail=eml-email_job_alert_digest_01-footer-11-manage_alert-null-2eag8%7Ektaltv99%7E1y-null-job%7Ealert%7Emanager&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; text-decoration:underline; display:inline-block"><span style="color:#0A66C2; font-weight:400; font-size:12px; line-height:1.333">Manage
 job alerts</span></a>&nbsp;&nbsp;�&nbsp;&nbsp;<a href="https://www.linkedin.com/e/v2?e=2eag8-ktaltv99-1y&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D&amp;a=jobs-jserp-search-mirror&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;ek=email_job_alert_digest_01&amp;li=12&amp;m=footer&amp;ts=delete_alert&amp;alertAction=delete&amp;savedSearchId=1339426806" style="color:#0A66C2; text-decoration:underline; display:inline-block">
<span style="color:#0A66C2; font-weight:400; font-size:12px; line-height:1.333">Unsubscribe</span></a>&nbsp;&nbsp;�&nbsp;&nbsp;<a href="https://www.linkedin.com/e/v2?e=2eag8-ktaltv99-1y&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D&amp;a=customerServiceUrl&amp;midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;ek=email_job_alert_digest_01&amp;li=37&amp;m=footer&amp;ts=help&amp;articleId=67" style="color:#0A66C2; text-decoration:underline; display:inline-block">
<span style="color:#0A66C2; font-weight:400; font-size:12px; line-height:1.333">Help</span></a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="left" style="padding:0; text-align:left"><a href="https://www.linkedin.com/comm/feed/?midToken=AQFI2cUdOmE73Q&amp;midSig=07AtLQDAAYP9U1&amp;trk=eml-email_job_alert_digest_01-footer-35-home&amp;trkEmail=eml-email_job_alert_digest_01-footer-35-home-null-2eag8%7Ektaltv99%7E1y-null-neptune%2Ffeed&amp;lipi=urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D" style="color:#0A66C2; text-decoration:underline; display:inline-block"><img alt="LinkedIn" border="0" height="14" src="https://static.licdn.com/sc/p/com.linkedin.email-assets-frontend%3Aemail-assets-frontend-static-content%2B__latest__/f/%2Femail-assets-frontend%2Fimages%2Flogos%2Flogo_linkedin_mercado_blue_112x28.png" width="56" style="outline:none; color:#FFFFFF; display:block; text-decoration:none"></a></td>
</tr>
<tr>
<td style="">
<table width="1" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:12px; font-size:12px; line-height:12px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="left" style="padding:0; color:#000000; text-align:left">
<p style="margin:0; color:#000000; font-weight:400; font-size:12px; line-height:1.333">
� 2021 LinkedIn Corporation, 1000 West Maude Avenue, Sunnyvale, CA 94085. LinkedIn and the LinkedIn logo are registered trademarks of LinkedIn.</p>
</td>
</tr>
<tr>
<td style="">
<table width="1" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:24px; font-size:24px; line-height:24px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
<td style="">
<table width="24" border="0" cellspacing="0" cellpadding="1" style="">
<tbody>
<tr>
<td style="">
<div style="height:0px; font-size:0px; line-height:0px">&nbsp; </div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</center>
</td>
</tr>
</tbody>
</table>
<img alt="" role="presentation" src="http://www.linkedin.com/emimp/ip_TW1WaFp6Z3RhM1JoYkhSMk9Ua3RNWGs9OlpXMWhhV3hmYW05aVgyRnNaWEowWDJScFoyVnpkRjh3TVE9PTo=.gif" style="outline:none; color:#FFFFFF; text-decoration:none; width:1px; height:1px">
</div>
</body>
</html>`;

export const glassdoorHtml = `<html>
<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta content="text/html; charset=utf-8">
<meta name="format-detection" content="telephone=no">
<meta name="format-detection" content="address=no">
<meta name="x-apple-disable-message-reformatting">
<style>
<!--
body
        {margin:0}
.css-uegzbq
        {font-family:Lato,Arial,Helvetica,sans-serif;
        margin:0;
        padding:0;
        font-size:18px;
        font-weight:normal;
        font-stretch:normal;
        font-style:normal;
        line-height:28px;
        letter-spacing:normal;
        color:#505863}
.css-uegzbq, .css-uegzbq *
        {font-family:Lato,Arial,Helvetica,sans-serif}
.css-1kbqv69
        {width:100%;
        background-color:#f5f6f7;
        text-align:center}
.css-l1mraf
        {max-width:500px;
        width:500px;
        margin:0 auto;
        background-color:#fff;
        text-align:left}
.css-1iuszfz
        {padding-top:35px;
        border-top:1px solid transparent;
        border-left:1px solid transparent;
        border-right:1px solid transparent;
        padding-left:24px;
        padding-right:24px;
        padding-bottom:30px;
        width:100%}
.css-9th5vf
        {color:#1861bf}
a.cleanBoldLink
        {font-family:Arial,Helvetica,sans-serif!important;
        font-weight:bold;
        text-decoration:none;
        color:#1861bf}
a.clearLink
        {color:#1861bf;
        text-decoration:none}
.css-1iz8x5j
        {padding-left:24px;
        padding-right:24px;
        padding-top:0px;
        padding-bottom:10px;
        text-align:center;
        vertical-align:top;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-2pliid
        {color:#0d0d0d;
        font-size:20px;
        font-weight:bold;
        line-height:1.3}
.css-1rv4fmk
        {padding-left:24px;
        padding-right:24px;
        padding-top:20px;
        padding-bottom:20px;
        text-align:center;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-ip60z3
        {height:0px;
        border:none;
        margin:0;
        padding:0;
        width:100%;
        border-top:1px solid #dee0e3}
.css-nt1wjc
        {padding-left:24px;
        padding-right:24px;
        padding-top:0px;
        padding-bottom:0px;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-3j8i5w
        {padding-left:0px;
        padding-right:0px;
        padding-top:0px;
        padding-bottom:20px;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.buttonCenterspecialdefault
        {font-family:Arial,Helvetica,sans-serif!important;
        font-weight:bold;
        font-size:15px;
        color:#fff!important}
.css-8bsfb
        {border:1px solid transparent;
        border-radius:3px;
        border-color:transparent;
        font-weight:bold;
        font-size:15px;
        color:#fff!important;
        display:inline-block;
        vertical-align:middle;
        font-family:Lato,Arial,Helvetica,sans-serif;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        min-width:88px;
        text-decoration:none;
        line-height:24px;
        border-style:solid;
        width:327px;
        text-align:center;
        color:#fff!important;
        background-color:#0caa41;
        padding:7px 16px;
        font-size:15px;
        line-height:24px}
.css-1of8vp0
        {border-bottom:1px solid transparent;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-wk1xqm
        {padding:16px 24px;
        font-size:12px;
        background:#20262e}
a.footerLink
        {color:#fff;
        text-decoration:underline;
        white-space:nowrap}
.css-13db5os
        {color:#fff;
        line-height:20px;
        margin:0;
        margin-top:0;
        margin-bottom:0;
        text-align:center}
a.css-1mtfgd0
        {color:#fff;
        text-decoration:underline;
        white-space:nowrap}
.css-b3vx77
        {font-size:16px;
        line-height:1.3}
.css-1nw9wi0 table.jobTable
        {min-width:100%}
.css-1nw9wi0 table.jobTable tr
        {min-width:100%}
.css-1j7t3b3
        {padding-bottom:16px;
        padding-top:16px;
        line-height:20px;
        font-size:15px;
        border-bottom:1px solid #dee0e3}
.css-1ckyvhk
        {padding-right:16px;
        padding-top:2px;
        vertical-align:top;
        min-width:34px;
        width:34px}
.css-1nuvpo1
        {width:32px;
        height:32px;
        border:1px solid #dee0e3}
.css-1979uxh
        {color:#0caa41;
        white-space:nowrap;
        padding-top:4px;
        font-size:12px;
        width:34px}
.css-dgh53j
        {color:#1861bf;
        text-decoration:none}
.css-10qqdaw
        {font-weight:bold;
        color:#1861bf}
.css-forujw
        {color:#20262e}
.css-56kyx5
        {color:#505863}
.css-1af37x6
        {color:#0caa41;
        font-size:12px}
.css-ko2iq
        {color:#7CB228;
        font-size:12px}
.css-kh5y0v
        {font-size:12px;
        color:#20262e;
        line-height:18px}
.css-6rqxwm
        {padding-left:24px;
        padding-right:24px;
        padding-top:20px;
        padding-bottom:10px;
        text-align:center;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-mqvg97
        {line-height:27px;
        font-size:18px;
        font-weight:normal;
        text-decoration:none;
        color:#1861bf}
.css-5gqq3p
        {padding-left:0px;
        padding-right:0px;
        padding-top:40px;
        padding-bottom:20px;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-5yhxj3
        {padding-left:24px;
        padding-right:24px;
        padding-top:10px;
        padding-bottom:0px;
        text-align:center;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-scem3j
        {font-size:20px;
        font-weight:bold;
        color:#0d0d0d;
        line-height:1.3}
.css-1a6rt6g
        {padding-left:24px;
        padding-right:24px;
        padding-top:30px;
        padding-bottom:0px;
        text-align:center;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-n7omje
        {font-size:16px;
        color:#0d0d0d}
.css-1oj56vq
        {text-decoration:none;
        color:#1861bf;
        font-size:18px}
.css-1j0xffn
        {padding-left:24px;
        padding-right:24px;
        padding-top:30px;
        padding-bottom:0px;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.css-8bz5ap
        {padding-left:24px;
        padding-right:24px;
        padding-top:64px;
        padding-bottom:0px;
        border-left:1px solid transparent;
        border-right:1px solid transparent}
.buttonCenterprimarydefault
        {font-family:Arial,Helvetica,sans-serif!important;
        font-weight:bold;
        font-size:15px;
        color:#fff!important}
.buttonCentersecondarydefault
        {font-family:Arial,Helvetica,sans-serif!important;
        font-weight:bold;
        font-size:15px;
        color:#1861bf!important}
.buttonCenterspecialmarketing
        {font-family:Arial,Helvetica,sans-serif!important;
        font-weight:bold;
        font-size:18px;
        color:#fff!important}
.buttonCenterprimarymarketing
        {font-family:Arial,Helvetica,sans-serif!important;
        font-weight:bold;
        font-size:18px;
        color:#fff!important}
.headerTitle
        {white-space:nowrap}
.greenPercent
        {color:#0caa41;
        font-size:20px;
        line-height:1.5}
.redPercent
        {color:#d93e30;
        font-size:20px;
        line-height:1.5}
.kywiSalary
        {font-size:48px}
-->
</style>
</head>
<body id="GDEmail">
<strong>
<div><font face="Tahoma" color="#000000" size="2">&nbsp;</font></div>
</strong>
<hr tabindex="-1" style="display:inline-block; width:98%">
<font face="Tahoma" size="2"><b>From:</b> Glassdoor Jobs <noreply@glassdoor.com><br>
<b>Sent:</b> Tuesday, September 7, 2021 6:22:36 PM (UTC-08:00) Pacific Time (US &amp; Canada)<br>
<b>To:</b> bugalicious@gmail.com <bugalicious@gmail.com><br>
<b>Subject:</b> New jobs in San Francisco, CA. Apply Now.<br>
</font><br>
<div></div>
<div><span style="display:none">4D MOLECULAR THERAPEUTICS is hiring</span>
<div id="GDEmailWrapper" class="css-uegzbq e2c2s610">
<table cellspacing="0" cellpadding="0" color="#f5f6f7" class="css-1kbqv69 e2c2s614">
<tbody>
<tr>
<td>
<table cellspacing="0" cellpadding="0" class="css-l1mraf e2c2s613">
<tbody>
<tr>
<td colspan="3" class="css-1iuszfz e2c2s611" style="text-align:center"><a class="css-9th5vf ep11te10" href="https://www.glassdoor.com" target="_blank"><img src="https://www.glassdoor.com/crs/mail/logos/gdlogoHw_2x.png" alt="Glassdoor" title="Glassdoor" height="30" width="142"></a></td>
</tr>
<tr>
<td colspan="3" class="css-1iz8x5j ep11te12"><span class="css-2pliid e1slim8i11">New jobs for Vice President Product Management</span><br>
<span class="css-b3vx77 e1slim8i12">San Francisco, CA</span></td>
</tr>
<tr>
<td colspan="3" class="css-nt1wjc ep11te12">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-ip60z3 ep11te14" style="padding:0px">
<tbody>
<tr>
<td class="css-ip60z3 ep11te14" style="margin:0px"></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td colspan="3" class="css-nt1wjc ep11te12">
<table cellpadding="0" cellspacing="0" height="100%" width="100%" class="css-1nw9wi0 elptbf70">
<tbody>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=101&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;ea=1&amp;cs=1_55538272&amp;cb=1631064153572&amp;jobListingId=1007281414316&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-d842938b014ec0cd&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos1-1007281414316"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/1359860/hashicorp-squarelogo-1593646465577.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos1-1007281414316" alt="HashiCorp" title="HashiCorp"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6IjdiZWI5YjRhLTBiNDktNDBlNy05ZTFiLWJkMWRjOGFlNmQ3ZSIsImVtcGlkIjoxMzU5ODYwLCJ1aWQiOjI1NjQ4MSwiYWRhdHRyIjp7ImpvYl9saXN0aW5nX2lkIjoiMTAwNzI4MTQxNDMxNiJ9LCJidm5hbWUiOiJqb2ItYWxlcnQtZW1haWwtam9iLWxpc3RpbmciLCJ0bGQiOjEsImJ2dHlwZSI6IkJSQU5EIiwiYnZjaGFuIjoiRU1BSUwifQ.HGYS2FpHsocAhFMK58sEmB2z6h750LW1EQIWthZ9aYXX4zRbYCejTLNIadDy7_L_bzAdCPv2rVR0hfT_r_ghmQ" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">4.0 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=101&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;ea=1&amp;cs=1_55538272&amp;cb=1631064153572&amp;jobListingId=1007281414316&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-d842938b014ec0cd&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos1-1007281414316" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Vice President, Product Management - Terraform Product Line</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">HashiCorp&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">San Francisco, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-ko2iq elptbf711" style="padding:0px">
<tbody>
<tr>
<td class="css-ko2iq elptbf711" style="margin:0px">Easy Apply</td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Work/Life Balance, Culture &amp; Values</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=102&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_5589e3ae&amp;cb=1631064153571&amp;jobListingId=1007283935715&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-1aa5913593403fec&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos2-1007283935715"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/690765/chase-squarelogo-1545241712897.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos2-1007283935715" alt="JPMorgan Chase Bank, N.A." title="JPMorgan Chase Bank, N.A."></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6ImQyY2RhMzQxLTI3MGItNDMwYi05OGE2LTViYTQ2ODEzYTliZiIsImVtcGlkIjo2OTA3NjUsInVpZCI6MjU2NDgxLCJhZGF0dHIiOnsiam9iX2xpc3RpbmdfaWQiOiIxMDA3MjgzOTM1NzE1In0sImJ2bmFtZSI6ImpvYi1hbGVydC1lbWFpbC1qb2ItbGlzdGluZyIsInRsZCI6MSwiYnZ0eXBlIjoiQlJBTkQiLCJidmNoYW4iOiJFTUFJTCJ9.B28jDVOG6hIRgX5ucszsRYSuXeYiZ9mOWLiKvVcxL-HgibnguMx6kghP2gMhd13NakaCgmLqGwGORsDZpitmmw" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">3.7 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=102&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_5589e3ae&amp;cb=1631064153571&amp;jobListingId=1007283935715&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-1aa5913593403fec&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos2-1007283935715" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Merchant Services-Vice President, Product Manager-Safetech Fraud and Risk Management</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">JPMorgan Chase Bank, N.A.&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">San Francisco, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=103&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_c071fdea&amp;cb=1631064153571&amp;jobListingId=1007279782221&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-1722ec7c3ef1ad09&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos3-1007279782221"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/901352/change-healthcare-squarelogo-1492550898788.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos3-1007279782221" alt="Change Healthcare" title="Change Healthcare"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6ImVhYjJhZjU2LThlOTQtNGNiYy1iZDI3LTA5ODkzNzc0ZWZhZiIsImVtcGlkIjo5MDEzNTIsInVpZCI6MjU2NDgxLCJhZGF0dHIiOnsiam9iX2xpc3RpbmdfaWQiOiIxMDA3Mjc5NzgyMjIxIn0sImJ2bmFtZSI6ImpvYi1hbGVydC1lbWFpbC1qb2ItbGlzdGluZyIsInRsZCI6MSwiYnZ0eXBlIjoiQlJBTkQiLCJidmNoYW4iOiJFTUFJTCJ9.3iIBjbG11mkhUkumLlE1eNi1XnrDAR7K9eetNid7X7707dvvJA0WwKIjImT-_GECqfXKmSxVJ7gAwSCetElIHA" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">3.3 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=103&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_c071fdea&amp;cb=1631064153571&amp;jobListingId=1007279782221&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-1722ec7c3ef1ad09&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos3-1007279782221" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Vice President Engineering, Product Development</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">Change Healthcare&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Emeryville, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=104&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_23325b77&amp;cb=1631064153572&amp;jobListingId=1007273584294&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-b0d5790d07882373&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos4-1007273584294"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/34936/blue-shield-of-california-squarelogo-1469735003107.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos4-1007273584294" alt="Blue Shield of California" title="Blue Shield of California"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6IjVkMTIyNjYyLWMwMzctNDNiYi1iYzY1LTY5ODQzYzAzZjZhYyIsImVtcGlkIjozNDkzNiwidWlkIjoyNTY0ODEsImFkYXR0ciI6eyJqb2JfbGlzdGluZ19pZCI6IjEwMDcyNzM1ODQyOTQifSwiYnZuYW1lIjoiam9iLWFsZXJ0LWVtYWlsLWpvYi1saXN0aW5nIiwidGxkIjoxLCJidnR5cGUiOiJCUkFORCIsImJ2Y2hhbiI6IkVNQUlMIn0.P3KWw8LXruirTN6Kr2Wk7JQa1pUxemgRnySi8Qy_5KcXYw413a9PX4ClJ0M5hQxQBogfer_mQ99-ZY3sLMWzyg" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">4.2 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=104&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_23325b77&amp;cb=1631064153572&amp;jobListingId=1007273584294&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-b0d5790d07882373&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos4-1007273584294" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Director, Data Engineering</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">Blue Shield of California&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Oakland, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Career Opportunity, Compensation &amp; Benefits, Work/Life Balance, Culture &amp; Values, Senior Leadership</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=105&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_620a3691&amp;cb=1631064153572&amp;jobListingId=1007281336896&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-3ca8748e795ca1a7&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos5-1007281336896"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/360923/zendesk-squarelogo-1598501764503.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos5-1007281336896" alt="Zendesk" title="Zendesk"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6ImE5NDhjM2IzLTA1YTItNGZkMi05MGE0LTQyZDZiMmYwNmJjYiIsImVtcGlkIjozNjA5MjMsInVpZCI6MjU2NDgxLCJhZGF0dHIiOnsiam9iX2xpc3RpbmdfaWQiOiIxMDA3MjgxMzM2ODk2In0sImJ2bmFtZSI6ImpvYi1hbGVydC1lbWFpbC1qb2ItbGlzdGluZyIsInRsZCI6MSwiYnZ0eXBlIjoiQlJBTkQiLCJidmNoYW4iOiJFTUFJTCJ9.1AdQJrjWExllB9_X7KaHhpZidiXZJwkqOM7HQrFvG_1aSw0arcoeeCA-VzoWHkYB_RM0WR0BBTi_JrY6l0ZeZg" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">4.4 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=105&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_620a3691&amp;cb=1631064153572&amp;jobListingId=1007281336896&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-3ca8748e795ca1a7&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos5-1007281336896" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Senior Director, Workforce Planning &amp; Optimization</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">Zendesk&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">San Francisco, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Career Opportunity, Compensation &amp; Benefits, Work/Life Balance, Culture &amp; Values, Senior Leadership</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=106&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_0f497832&amp;cb=1631064153573&amp;jobListingId=1007285821631&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-76682fc6a85c2275&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos6-1007285821631"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/1889045/4d-molecular-therapeutics-squarelogo-1561122263277.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos6-1007285821631" alt="4D MOLECULAR THERAPEUTICS" title="4D MOLECULAR THERAPEUTICS"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6IjkxN2FlN2RjLWMzNDgtNGYyNi04NGIzLTFmMDdmYTk4OTg3NSIsImVtcGlkIjoxODg5MDQ1LCJ1aWQiOjI1NjQ4MSwiYWRhdHRyIjp7ImpvYl9saXN0aW5nX2lkIjoiMTAwNzI4NTgyMTYzMSJ9LCJidm5hbWUiOiJqb2ItYWxlcnQtZW1haWwtam9iLWxpc3RpbmciLCJ0bGQiOjEsImJ2dHlwZSI6IkJSQU5EIiwiYnZjaGFuIjoiRU1BSUwifQ.fxF5tpPLFNmx2p2JsmF40JhDwpiCmXNeKiH6A6CNpelu3_iZczFX-TI-BZvB6KnS0hnhAHHghY_Zpen7bHbsmw" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">5.0 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=106&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_0f497832&amp;cb=1631064153573&amp;jobListingId=1007285821631&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-76682fc6a85c2275&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos6-1007285821631" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Director of IT Operations &amp; Security</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">4D MOLECULAR THERAPEUTICS&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Emeryville, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Career Opportunity, Compensation &amp; Benefits, Culture &amp; Values, Senior Leadership</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=107&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;ea=1&amp;cs=1_1e3b09f2&amp;cb=1631064153573&amp;jobListingId=1007281925917&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-405bd0f0874f036b&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos7-1007281925917"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/256835/pubmatic-squarelogo-1463001513843.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos7-1007281925917" alt="PubMatic" title="PubMatic"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6ImI1ZWI5Y2EyLTRjZjAtNGM3Ny1hMTU4LTFmOWZmNDA2YzdmYyIsImVtcGlkIjoyNTY4MzUsInVpZCI6MjU2NDgxLCJhZGF0dHIiOnsiam9iX2xpc3RpbmdfaWQiOiIxMDA3MjgxOTI1OTE3In0sImJ2bmFtZSI6ImpvYi1hbGVydC1lbWFpbC1qb2ItbGlzdGluZyIsInRsZCI6MSwiYnZ0eXBlIjoiQlJBTkQiLCJidmNoYW4iOiJFTUFJTCJ9.YgOddoNXuFkKhmMt0MmUKBybG1pNoRl73TSbqWMrbGc5LI9HkAGybqAszJXtWd85XPCasGpCcVB-JLCq8zLNIA" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">4.4 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=107&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;ea=1&amp;cs=1_1e3b09f2&amp;cb=1631064153573&amp;jobListingId=1007281925917&amp;jrtk=2-0-1ff1fvmqf3o7h001-1ff1fvmrhu1m2800-405bd0f0874f036b&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos7-1007281925917" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Senior Manager, Product Marketing, Mobile</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">PubMatic&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Redwood City, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-ko2iq elptbf711" style="padding:0px">
<tbody>
<tr>
<td class="css-ko2iq elptbf711" style="margin:0px">Easy Apply</td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Career Opportunity, Compensation &amp; Benefits, Work/Life Balance, Culture &amp; Values, Senior Leadership</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=108&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_c8ef5a4f&amp;cb=1631064153573&amp;jobListingId=1007276960811&amp;jrtk=2-0-1ff1fvn8o3oic001-1ff1fvn9bu1l9800-233ea451eabac3ea&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos8-1007276960811"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/12830/vmware-squarelogo-1605872987364.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos8-1007276960811" alt="VMware" title="VMware"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6IjNiZjE1OGEwLTllZGUtNDFhNS1iMWViLWEyZDMwMzUxMWRmNSIsImVtcGlkIjoxMjgzMCwidWlkIjoyNTY0ODEsImFkYXR0ciI6eyJqb2JfbGlzdGluZ19pZCI6IjEwMDcyNzY5NjA4MTEifSwiYnZuYW1lIjoiam9iLWFsZXJ0LWVtYWlsLWpvYi1saXN0aW5nIiwidGxkIjoxLCJidnR5cGUiOiJCUkFORCIsImJ2Y2hhbiI6IkVNQUlMIn0.QjZhGUE0RpebBpL6M2JIfSt-rCEnvWIoyYxDvq1oX1ETZ48tqv6gjovlLvod0ERUbYx0P7L2vs1gFv_3ApH2Bw" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">4.3 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=108&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_c8ef5a4f&amp;cb=1631064153573&amp;jobListingId=1007276960811&amp;jrtk=2-0-1ff1fvn8o3oic001-1ff1fvn9bu1l9800-233ea451eabac3ea&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos8-1007276960811" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Vice President of Product Management, SASE - Opportunity for Working Remotely Palo Alto, CA</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">VMware&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Palo Alto, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Career Opportunity, Compensation &amp; Benefits, Work/Life Balance, Culture &amp; Values</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=109&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_599e1a45&amp;cb=1631064153573&amp;jobListingId=1007279084378&amp;jrtk=2-0-1ff1fvn8o3oic001-1ff1fvn9bu1l9800-b1ed5516bd8721ce&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos9-1007279084378"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/30153/saama-squarelogo-1380804661294.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos9-1007279084378" alt="Saama Technologies Inc" title="Saama Technologies Inc"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6ImVmZmE4MjhjLTBhZTYtNGUyNC04MzMxLTBjNTk3M2E0MTk3OSIsImVtcGlkIjozMDE1MywidWlkIjoyNTY0ODEsImFkYXR0ciI6eyJqb2JfbGlzdGluZ19pZCI6IjEwMDcyNzkwODQzNzgifSwiYnZuYW1lIjoiam9iLWFsZXJ0LWVtYWlsLWpvYi1saXN0aW5nIiwidGxkIjoxLCJidnR5cGUiOiJCUkFORCIsImJ2Y2hhbiI6IkVNQUlMIn0.RJ4q4gYp2LuZEvOM2z8UH0IVkbWxajN6FoB4pXYxOs37MQhbR4kNl9iQL-5EVHVUTY1vig1-U3ns1RMmZFl13g" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">3.9 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=109&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_599e1a45&amp;cb=1631064153573&amp;jobListingId=1007279084378&amp;jrtk=2-0-1ff1fvn8o3oic001-1ff1fvn9bu1l9800-b1ed5516bd8721ce&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos9-1007279084378" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Vice President, Product Management (Multiple Openings)</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">Saama Technologies Inc&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Campbell, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Culture &amp; Values</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="css-1j7t3b3 elptbf72">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="jobTable">
<tbody>
<tr>
<td class="css-1ckyvhk elptbf73"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=110&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_7a5d3599&amp;cb=1631064153573&amp;jobListingId=1007285492209&amp;jrtk=2-0-1ff1fvn8o3oic001-1ff1fvn9bu1l9800-0d1270593906ebd1&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos10-1007285492209"><img class="logo css-1nuvpo1 elptbf74" width="32" height="32" src="https://media.glassdoor.com/sql/9079/google-squarelogo-1441130773284.png?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos10-1007285492209" alt="Google" title="Google"></a><img height="0" width="0" src="https://www.glassdoor.com/brand-views?o=brandview-pixel&amp;p=eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MzEwNjQxNTMsImp0aSI6IjAwZjQyOGNlLWYwNGMtNDljNi05ZTkwLTQwZmM1M2E2YWQ3NSIsImVtcGlkIjo5MDc5LCJ1aWQiOjI1NjQ4MSwiYWRhdHRyIjp7ImpvYl9saXN0aW5nX2lkIjoiMTAwNzI4NTQ5MjIwOSJ9LCJidm5hbWUiOiJqb2ItYWxlcnQtZW1haWwtam9iLWxpc3RpbmciLCJ0bGQiOjEsImJ2dHlwZSI6IkJSQU5EIiwiYnZjaGFuIjoiRU1BSUwifQ.vzaEb9A57JLt2ODz4Jok0S8S2UUrxBL_AgRq0m3elFcxYphbY0puAAlo0MrkOgMIlxydIgn9h5t4TfsvdEuYug" alt="">
<table cellpadding="0" cellspacing="0" class="css-1979uxh elptbf71">
<tbody>
<tr>
<td valign="top" align="center">4.5 </td>
</tr>
</tbody>
</table>
</td>
<td valign="top"><a href="https://www.glassdoor.com/partner/jobListing.htm?pos=110&amp;ao=1136043&amp;s=58&amp;guid=0000017bc2ffdadca54bb3a5528cbbd9&amp;src=GD_JOB_AD&amp;t=MJA&amp;vt=e&amp;uido=3F8BFF1D12D2FE45&amp;cs=1_7a5d3599&amp;cb=1631064153573&amp;jobListingId=1007285492209&amp;jrtk=2-0-1ff1fvn8o3oic001-1ff1fvn9bu1l9800-0d1270593906ebd1&amp;ja=163834009&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-jobpos10-1007285492209" class="css-dgh53j elptbf76">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-10qqdaw elptbf77" style="padding:0px">
<tbody>
<tr>
<td class="css-10qqdaw elptbf77" style="margin:0px">Vice President, Engineering, Data Acquisition, Processing and Analysis</td>
</tr>
</tbody>
</table>
<span class="css-forujw elptbf78">Google&nbsp;-&nbsp;</span><span class="css-56kyx5 elptbf79">Sunnyvale, CA</span>
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-1af37x6 elptbf710" style="padding:0px">
<tbody>
<tr>
<td class="css-1af37x6 elptbf710" style="margin:0px"></td>
</tr>
</tbody>
</table>
</a>
<div class="css-kh5y0v elptbf712">Highly Rated:&nbsp;Career Opportunity, Compensation &amp; Benefits, Work/Life Balance, Culture &amp; Values, Senior Leadership</div>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td colspan="3" class="css-1rv4fmk ep11te12">
<div></div>
<a href="https://www.glassdoor.com/Job/san-francisco-vice-president-product-management-jobs-SRCH_IL.0,13_IC1147401_KO14,47_SAJR.htm?ja=163834009&amp;uvk=kghgo:fyy7JRPtt7LEnTwRFhevZQ&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-alljobs-JACTABOTTOM" target="_blank" class="css-8bsfb ep11te11">See
 All Jobs</a></td>
</tr>
<tr>
<td colspan="3" class="css-3j8i5w ep11te12">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-ip60z3 ep11te14" style="padding:0px">
<tbody>
<tr>
<td class="css-ip60z3 ep11te14" style="margin:0px"></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td colspan="3" class="css-1iz8x5j ep11te12"><span class="css-2pliid e1slim8i11">Create related alerts for San Francisco, CA with one click</span></td>
</tr>
<tr>
<td colspan="3" class="css-6rqxwm ep11te12"><a class="css-mqvg97 e1slim8i16" href="https://www.glassdoor.com/job-alert/jobAlertAjax.htm?loc=San Francisco, CA&userValidationKey=kghgo:fyy7JRPtt7LEnTwRFhevZQ&keywords=vp product&JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&source=RELATED_EMAIL_JOB_ALERT&key=hPkgnhh:ONiN6g0XMeMmTlhCrBXcw&oneClick=true&utm_medium=email&utm_source=jobalert&utm_campaign=jobAlertAlert&utm_content=ja-related-jobs-1" target="_blank">+
 vp product</a></td>
</tr>
<tr>
<td colspan="3" class="css-6rqxwm ep11te12"><a class="css-mqvg97 e1slim8i16" href="https://www.glassdoor.com/job-alert/jobAlertAjax.htm?loc=San Francisco, CA&userValidationKey=kghgo:fyy7JRPtt7LEnTwRFhevZQ&keywords=vice president marketing&JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&source=RELATED_EMAIL_JOB_ALERT&key=hPkgnhh:ONiN6g0XMeMmTlhCrBXcw&oneClick=true&utm_medium=email&utm_source=jobalert&utm_campaign=jobAlertAlert&utm_content=ja-related-jobs-2" target="_blank">+
 vice president marketing</a></td>
</tr>
<tr>
<td colspan="3" class="css-6rqxwm ep11te12"><a class="css-mqvg97 e1slim8i16" href="https://www.glassdoor.com/job-alert/jobAlertAjax.htm?loc=San Francisco, CA&userValidationKey=kghgo:fyy7JRPtt7LEnTwRFhevZQ&keywords=product director&JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&source=RELATED_EMAIL_JOB_ALERT&key=hPkgnhh:ONiN6g0XMeMmTlhCrBXcw&oneClick=true&utm_medium=email&utm_source=jobalert&utm_campaign=jobAlertAlert&utm_content=ja-related-jobs-3" target="_blank">+
 product director</a></td>
</tr>
<tr>
<td colspan="3" class="css-6rqxwm ep11te12"><a class="css-mqvg97 e1slim8i16" href="https://www.glassdoor.com/job-alert/jobAlertAjax.htm?loc=San Francisco, CA&userValidationKey=kghgo:fyy7JRPtt7LEnTwRFhevZQ&keywords=chief product officer&JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&source=RELATED_EMAIL_JOB_ALERT&key=hPkgnhh:ONiN6g0XMeMmTlhCrBXcw&oneClick=true&utm_medium=email&utm_source=jobalert&utm_campaign=jobAlertAlert&utm_content=ja-related-jobs-4" target="_blank">+
 chief product officer</a></td>
</tr>
<tr>
<td colspan="3" class="css-5gqq3p ep11te12">
<table cellspacing="0" cellpadding="0" border="0" width="100%" class="css-ip60z3 ep11te14" style="padding:0px">
<tbody>
<tr>
<td class="css-ip60z3 ep11te14" style="margin:0px"></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td colspan="3" class="css-5yhxj3 ep11te12"><span class="css-scem3j e1slim8i20">Email Settings</span></td>
</tr>
<tr>
<td colspan="3" class="css-1a6rt6g ep11te12"><span class="css-n7omje e1slim8i18">Vice President Product Management</span><br>
<a class="css-1oj56vq e1slim8i17" href="https://www.glassdoor.com/profile/editJobAlert_input.htm?JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&amp;key=hPkgnhh:ONiN6g0XMeMmTlhCrBXcw&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-editalert-key" target="_blank">Change
 keywords</a></td>
</tr>
<tr>
<td colspan="3" class="css-1a6rt6g ep11te12"><span class="css-n7omje e1slim8i18">San Francisco, CA</span><br>
<a class="css-1oj56vq e1slim8i17" href="https://www.glassdoor.com/profile/editJobAlert_input.htm?JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&amp;key=hPkgnhh:ONiN6g0XMeMmTlhCrBXcw&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-editalert-key" target="_blank">Change
 location</a></td>
</tr>
<tr>
<td colspan="3" class="css-1a6rt6g ep11te12"><span class="css-n7omje e1slim8i18">Sent Daily</span><br>
<a class="css-1oj56vq e1slim8i17" href="https://www.glassdoor.com/profile/editJobAlert_input.htm?freq=7&amp;JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-editalert-freq" target="_blank">Change
 to weekly</a></td>
</tr>
<tr>
<td colspan="3" class="css-1j0xffn ep11te12"></td>
</tr>
<tr>
<td colspan="3" class="css-8bz5ap ep11te12"></td>
</tr>
<tr>
<td colspan="3" class="css-1of8vp0 e2c2s612">
<table id="Footer" cellpadding="0" cellspacing="0" height="100%" width="100%" class="css-wk1xqm e19qzqau0">
<tbody>
<tr>
<td class="css-13db5os e19qzqau2"><span><span class="">This message was sent to&nbsp; <a class="footerLink" href="mailto:bugalicious@gmail.com" target="_blank">
bugalicious@gmail.com</a>.</span></span><br>
<a target="_blank" href="https://www.glassdoor.com/about/privacy.htm?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-privacy" class="css-1mtfgd0 e19qzqau1">Privacy Policy</a><span> |
</span><a target="_blank" href="https://www.glassdoor.com/member/account/emailSettings_input.htm?JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-email-preference&amp;utm_term=engageJobAlertDefaultTreatment" class="css-1mtfgd0 e19qzqau1">Manage
 Settings</a><span> | </span><a target="_blank" href="https://www.glassdoor.com/profile/unsubscribeEmail.htm?key=kghgo:fyy7JRPtt7LEnTwRFhevZQ&amp;emailType=JOB_ALERT&amp;emailCategory=JOB_ALERT&amp;encryptedUserId=3F8BFF1D12D2FE45&amp;JAK=02cUzyCl_LXo5ItzU9ATSA:G9VCZohu-9VmUgZeV3RNMgO7feel1B4nf_LkIcWrvuX_G_8Ip7wfn_Io6e2imGFQ:sftWgz0btftUXzOxWAKoYDFPtf4UaUKEFteJlz0salc&amp;utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja-unsubscribe&amp;utm_term=engageJobAlertDefaultTreatment" class="css-1mtfgd0 e19qzqau1">Unsubscribe</a><br>
<br>
<span><a target="_blank" href="https://www.glassdoor.com?utm_medium=email&amp;utm_source=jobalert&amp;utm_campaign=jobAlertAlert&amp;utm_content=ja" class="css-1mtfgd0 e19qzqau1">Glassdoor</a></span><br>
300 Mission Street, 16th Floor, San Francisco, CA 94105<br>
<br>
<span>Copyright � 2008-2021, Glassdoor, Inc. &quot;Glassdoor&quot; and logo are registered trademarks of Glassdoor, Inc.</span><br>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</div>
<img src="https://mail8.glassdoor.com/wf/open?upn=BBIYJzoJcQZlMAtzL9sga7LRi6b8NBr-2FXS-2ByrXYcqQxQ19CYzoDX5ARZ3xFEvyidcv39W5ts7-2Bhgj9B-2BtLql4d-2B3Buz1sSGfnDtjI0GXBIBbSe3elEdVNb0xoyrpfY8LWJDFqqKNFWXLpIcpzDGZvgUHDTyINKYOps18nEN6Qk1jEon-2BCFHAPbRl4Q4L23fg1azIYNeqfl6Vohyg4VAh1vFD6-2FfScy4HDPr7h5QUUU6hUCJEgm76bOgz9irEOdnBarvObMr3N0RaYw-2BaTGQATxO-2FU-2FEdG0TlZ7zDA-2B7pLvvozzaBQ00uWHZJF8QKqWxeJCRwsLdB5kB5KWWmz33hYYHRV6LcArF8yHJ9jDx7SlvGzmsEeiSot7FSRMIzDweNIpif38NBqx566jCvwnCN3O8j7bIdik4dym6SK3x1CLZ1Y06NtHzZd9jhMSPKHhPydlfqAblshpu6w0EDafxDb4zOnUDZkdIl4PwAzfZThrZ-2BcgI8AZYshBbIb3w0R2BH" alt="" width="1" height="1" border="0" style="height:1px!important; width:1px!important; border-width:0!important; margin-top:0!important; margin-bottom:0!important; margin-right:0!important; margin-left:0!important; padding-top:0!important; padding-bottom:0!important; padding-right:0!important; padding-left:0!important">
</div>
</body>
</html>`;

export const googleHtml = `<html dir="ltr">
<head>
<meta http-equiv="Content-Type" content="text/html; charset=us-ascii">
<meta content="text/html; charset=utf-8">
<style>
<!--
body
        {margin:0}
-->
</style>
</head>
<body>
<strong>
<div><font face="Tahoma" color="#000000" size="2">&nbsp;</font></div>
</strong>
<hr tabindex="-1" style="display:inline-block; width:98%">
<font face="Tahoma" size="2"><b>From:</b> Job Alerts from Google <notify-noreply@google.com><br>
<b>Sent:</b> Tuesday, September 7, 2021 8:52:06 PM (UTC-08:00) Pacific Time (US &amp; Canada)<br>
<b>To:</b> Justin Grant <justin@justingrant.net><br>
<b>Subject:</b> 10 new jobs for &quot;&amp;quot;Head of Product&amp;quot; OR &amp;quot;VP Product&amp;quot; OR &amp;quot;Vice President, Product&amp;quot; OR &amp;quot;Vice President, Product Management&amp;quot;&quot; - Sep 7<br>
</font><br>
<div></div>
<div><img src="https://notifications.googleapis.com/email/t/AFG8qyUaTaeuBWDJLf6XyAJSyQEWgjyIy31PabuGSAV0muEHQNeMEtZh3pJNv2EKBOk3qivjr43lPztXne0DDUjoZ2I7HAAFpsqwjBHFwG_n6VO0QX0UqrVJsa42X-9yDFOKS8slKkFKtO9sWtAAPBj--ZRyhKzPRIkAY3ODbDAjk96KqlnRvSKBh7AsqM6rWMdaACJcb-8qAk9qccfChftg51SOGL_Tl1pIiRdBM6et8Wou4OHNjsCY6CyIgpgCMkIr3I7wc_zeOUy9DCqR_GaNsX2NDjhH6OcxzlJ2GqDeHGo/a.gif" width="1" height="1">
<div dir="ltr">
<div style="margin-left:auto; margin-right:auto; max-width:680px; min-width:320px">
<table style="background-color:#4285f4; border-collapse:collapse; border-spacing:0; width:100%">
<tbody>
<tr>
<td style="background-color:#4285f4; width:10%; vertical-align:top; padding:0"></td>
<td style="background-color:#4285f4; max-width:545px; min-width:300px; padding:24px 0 20px 17px; font-family:arial,sans-serif-medium,sans-serif!important">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyU8ONXrwRGZgOpe0iK1bkYkiAEcuMy7K8dDIjdZr7hTM4hDAWnGmzCMNyVqsv_-v1DXfC2lPrFcURK6uzROZsVYbrhy9JnHhE3R11S6hfAoNUXLWOS4wv2n45BSsmlBvymOZphM6QrdEAdRuh5QP6rg0jbr1reTC0ykhiCDjqW58GSXncUsUkOD1yfhoG6YcesY_6POqCwpT3DSGIsSU2usybh8oIB0lfqLJNxic17nMtaZ6F85uI_Zhmmh7rlrEMP2HOhF5o8SNIHvV_5-1O1S&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtlexp%26htichips%3Ddate_posted:range_2021-09-06%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r5usRy4tpeKGbtSZ3FpgLlQ" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td><span style="text-decoration:none; color:#FFFFFF; font-size:18px; line-height:1.22em">&quot;Head of Product&quot; OR &quot;VP Product&quot; OR &quot;Vice President, Product&quot; OR &quot;Vice President, Product Management&quot;</span></td>
</tr>
<tr>
<td style="color:#C0D6FB; font-size:14px; line-height:1.14em; padding-top:2px"><span>Near Berkeley, CA</span></td>
</tr>
</tbody>
</table>
</a></td>
<td style="background-color:#4285f4; width:10%; vertical-align:top; padding:0"></td>
</tr>
<tr style="background-color:#EEEEEE">
<td style="background-color:#EEEEEE; vertical-align:top; padding:0">
<table style="border-collapse:collapse; border-spacing:0; table-layout:fixed; width:100%">
<tbody>
<tr>
<td style="height:20px; background-color:#4285f4; padding:0"></td>
</tr>
<tr>
<td style="background-color:#EEEEEE; padding:0"></td>
</tr>
</tbody>
</table>
</td>
<td style="vertical-align:top; padding:0">
<table style="border-collapse:collapse; border-spacing:0; width:100%">
<tbody>
<tr>
<td style="padding:0">
<table style="border-collapse:collapse; border-spacing:0; border:1px solid #E5E5E5; width:100%">
<tbody>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyXGx_lVp5u0Ia-8uCUykNHTuoJrFr1mBc6wJKjq8jcwhCkyPkc0JHcEtF-mE2prqoXEXeNJcHZIYy8W7N8SNxs7WbjQ3a17O1jyWSh0oj9ODV0fRlbM3EKvn57eapuB59OSKDRstXPucc7-SkCn-ellCRvwidn-6K4pcO72wKOA6ZPzBcCdfczva47ojkVQVKi_wUp8DNNs2q_riR4EZvu3E2A4CMHAybUAdRF4CCD_WCmPGf2x32up5YLOReiK0rnDRoHs8sn9p7t5jB4IHYgVeUI&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3DTDSK9CFUX8JyTv9gAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r4EZ-_pjzKS8LUVQYuSOzrF" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t3.gstatic.com/images?q=tbn:ANd9GcRw9CdlHJPn1uf07XMzB7gWuzfrTGw-En1InnxjcMs9CKUCzMMkHbqfQGg2" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">VP, Product Management Platform</span>
<div style="color:black; font-size:14px; line-height:1.43em">Komodo Health</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via LinkedIn</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyV6QINxZLXnktZn3pzeK5QmYPHZJGq33MSllHKwkkbcM5gVpgLpOwWyJVUtqwAinNxuYSVRlOt_rfi7GcBNNMDqgC71u9hQizal76fbGDqzNa5vwWpBkKombZT4mZ_QwtwCUqQ5M9G5UnhfRWGB-C7oToJTFFTlI8uoHwNScpjbechFGtZKqDMzfzIU5FsZzDhyliXE1vpuS6zd2voatH_7QrqVeXOzPW1gR79JFveH-dQKhhpB266wrv9oqS4RsuJum4ppWVvQhsa-rOwB5q1KOIc&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3DRu8SK0tMRTDw3HF4AAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r4sc-dGpCyz6e78JnCupJuK" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t2.gstatic.com/images?q=tbn:ANd9GcQIpInji9xjQvoaLq5Gt7wulmcUAFMZODW6le2UE4eKt0pbglVzcBt9B7o" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">Senior Director/VP, Product Management, Consumer Experiences</span>
<div style="color:black; font-size:14px; line-height:1.43em">Wish</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via LinkedIn</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyVBsf5Twt321KC0JnWnpxzogkyuD7RVFvSVbe1Cf6eCvSUYBIUyoXhUffA_eFVohFgc8X9PH_8YJtvS8UyE3aNxPFzDjicJdVXF8UGrcGamLXgvZ7GVgvM2WtD3nEicHkEKqbGARcFccrQcH_TLBKLiVaCYoaQggtNixHk0GOYEPJdKD4hE4BIApx3jmuHA7hMmpzPUXIZNa5S0ewOVfvpZaiwm97KqHx1PO-8jSexmIRQNB3EFRW0Ql2N1Lgd7Y-8ULLC0TFhSHLWu9Fv8TI5Q2Ss&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3DPZPD8MRl6-C7vltjAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r7hS0rmVjf0oFSXiyCjv07E" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t2.gstatic.com/images?q=tbn:ANd9GcRqx0-DOZcmbA75XPVWjxU4lFD2p26Ky6ZBibpEYHRL5D8unqCiuZWRkNs" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">Head Of Product Management</span>
<div style="color:black; font-size:14px; line-height:1.43em">Stealth Mode</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via LinkedIn</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyWA_2c6Wu3ou4RuCBiKBdIw9S-uqY4-7NW5jf_HvL0Vt9BEvUEfWTQs2JOIk1M-yQiQ8GwPoIvoQCMW17zHENy1gB8bVqyfRUIJjMLQv0eG7S75-yeC5M1SOQrp8oqI_EdSlWTarFJ3XAFypVpix83GFmHgHubbTYa7pWXfBvby0GiUu0oTr6ktyBPu7o8Tj9h_3qAgCYNyDyCwrjOin44PSxihrsBW1_3FH-W528Eg-ye_hg3uDyzBdE5uKELUHDFr30hy-pkfxCBiaeGJFYk3hk8&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3DUUMHM8r2ppDBawJQAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r69nyxKQ1WAfd7AERI80f0_" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t2.gstatic.com/images?q=tbn:ANd9GcQIpInji9xjQvoaLq5Gt7wulmcUAFMZODW6le2UE4eKt0pbglVzcBt9B7o" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">Senior Director/VP, Product Management, Consumer Experiences</span>
<div style="color:black; font-size:14px; line-height:1.43em">Wish</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via SmartRecruiters Jobs</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyVt1avMuXYErN4u81iMmSL-dTMT4gb66EzoLIWoMI0cOdOIL1i8tl5X-PzgV9HM0FpySzPqFlJwQM2bkijN9sCAy2dUxXlyPULV-W7t762Kt2m6QwJEGAKXd_q21Dbhjuq3DD4Q4317KJHNXIEh1gKDWTAPXTicAY2HMlD5_DxwiViWJDXQVDhNcYI8Sm8BVMZnLjfS6qGGPZ8l-uh829HmhqFFD8wx3rFMlr0B-WNsCKG8CFwCxT33zjjUUk3ML-n_88Bczmqvmw0pOMdhi6kDyjM&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3D168IqgRtXd4ISNbSAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r4p3XEPuZD-52lA3iZBiPHg" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t0.gstatic.com/images?q=tbn:ANd9GcRlKObu-5oINagnVC4yyGQyM-P_Bd6Zsf7j92--JO9VyGO2GDC4hWXYehJE" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">Vice President, Product Management</span>
<div style="color:black; font-size:14px; line-height:1.43em">Kiva</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via JobServe</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 6</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyXXqurFmonOCkA68u3EzbDyj3Vrl_az-5Fj_Ergg2w13AcOS61tFkGa2Bot2t6GxofSaXaQ9H_FeUqxqK0-G--fa8d7mpGs2_3J_joGmVEUCuUAoPN6WTaNNgI56yVHEbHfVYA0x5zZZYJCfYWPNafkPUAjCtExvQFj9XXhtSkMbmUFiF8LboGHpGjFYy5HH29MoQXGyA4Zplm5mwX-9vn9n8OFaWctGUSIz2BgjFg4_PGGsGQiyiszvzX5UMwafb6QQm2Jbhkdn4CvdsXiaNdiiJs&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3Dn9lIqLx4LiwJ-WGQAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r6Q5xj9xgVCQVBh9rVFSINL" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t1.gstatic.com/images?q=tbn:ANd9GcTyR-4Hkx0sOpxu5Wx6HMDXY6IGYzLrmv3jZkPyX15REdxy470rGIgMHIB6" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">VP, Product Design &amp; Research</span>
<div style="color:black; font-size:14px; line-height:1.43em">Grand Rounds</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via Uncubed</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 6</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyW2rjlBeTM9xfTdwI52zyWgsljnDMPxpsK4oZ3cV6eL-fIT_WmrQ_NRFy6fbq_iI7WOgvWafhmRPJwBPNlR7BGeghfL17EBDhcyN1tbUebBoOxRffgklN0hz--Fp3UkD9AKy8uI2awskeGQuJDYpXquy_q0hXhHc0OWD2nB5tsPHfrbZAt9TE6ylwkzQErPCaDguATwf3hcU1Ckqik9eM2I51fXlZzIZfQom-2U7Qhx-_kuVGG5fTdCALFbKBabbsFSm_Mk0UAI8N4qu2PYQXcJSiQ&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3Dyc7zrNnJa2PhbRq7AAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r6vhjRgiRPRitMDagcpkGMD" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; min-width:56px; height:56px; font-size:28px; line-height:2em; text-align:center; vertical-align:middle; display:inline-block; background-color:#00897B; color:#FFFFFF">
C</td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">Head of Product Management, Avolin (Remote) - $400,000/year USD</span>
<div style="color:black; font-size:14px; line-height:1.43em">Crossover for Work</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>United States</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via LinkedIn</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyWahlQC9qIks7hLHLo6S6HaTR_W7FxERMYGXBY-CiAADyr0SSifClP9xLM6VWAXa-4-cT3BwxQmxMVo5YkNv_uTMlif6nyIh0et9irLFbhHOpmE_tep-WiJDGdKuklAgFFg_SNdRltIuyP235DhL6z9mPaShJD9aJLdjf66bNyMMdqaz8rPmBEpTxbM_KcROg3kAb8ye_x2YVfADLwsc60Rol3w8o8sdNkJMlngu749C7UVS4qUB6zAy4RrL25H8mXTfbsMhFEP9VSr0KvMUBdVuco&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3Dr-et40b0Y00DALOhAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r6D7GL26jLO9l7vSyNY566j" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t3.gstatic.com/images?q=tbn:ANd9GcTUU1gUYCyvOomeb02Pe2ruNGaLm_M1gl3fcvp74kO95JvGvMo7nBvN6Swq" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">VP Product, Consumer Apps (Remote - US)</span>
<div style="color:black; font-size:14px; line-height:1.43em">DISQO</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via LinkedIn</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyV5vf6-6vkZytWlaMs39lR5L-SSp16-X1lI-sul6hIZas8wX-52U-e_nao5cuwuWRgh3O-9yzR_kO9b3kCHbWfY439J-4_a6aPApwl5mI9ux_GMoXGUIBIzvjVtJ_N3tXpXCDf64EfIw_KbcqI1_dfDFjj66IpLxyrO0hsUbyMTgdLR5F7421Tywc3qjQlVOBK8UsMHMBniwk15FlgCTHX8unxiwNRWyn06whrVznSyxWiZHjn-aTtJJV3Xg95WIH9R6ljtw5ajnGkGxYPuKJ7K-fg&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3DWhKdrMXP5VaQTyOLAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r6ABcSYTX-AtwAw0MU9oMrf" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; height:56px; padding:0; text-align:center"><img alt="Logo" src="http://t0.gstatic.com/images?q=tbn:ANd9GcSmtStvs90jfcqA0DrY0jKBhJQ_KkBVpljwAtV0qwDkDifm7tbkofHo6Rie" style="width:56px; vertical-align:middle; display:inline-block"></td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">Vice President, Product Management</span>
<div style="color:black; font-size:14px; line-height:1.43em">PandaDoc</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>San Francisco, CA, USA</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via BeBee</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 6</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="background-color:white; border-bottom:1px solid #E5E5E5; font-family:arial,sans-serif-medium,sans-serif; padding:0">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyVwX8yZm4Ri6xgvuoa6o49RaAeg1nuu6whtig28weSvxtxvBO8rUp-0zXpcHQ4O2gTNDcd9wq7HaU72uzbiwOAMM4GdpldmPPIGMzI5zwH73-6AW2UPy5hMrh_cB0hM4FzbwI9iVspJCf8FLGP4j53B6rYCCN9KqhcLgZ7d0nbYd8YuiStCdTdKzX-BvD7Z_A7mNTgSWwCCjX9-BykMvEtW3v11H_GOlaPs1pqaAtJehGDLtRy-D8C7FCygZ1t4G1cTWqeSBJ5VajhOuhIFdQeCu7w&;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtldetail%26htichips%3Ddate_posted:range_2021-09-06%26htidocid%3DjfXYa5ah6ge3YJ8fAAAAAA%253D%253D%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&;s=ALHZ2r45M_9y3DU9NTAYib0_lGXO" style="display:inline-block; text-decoration:none; width:100%">
<table style="border-collapse:collapse; border-spacing:0">
<tbody>
<tr>
<td aria-hidden="true" style="border-spacing:0; padding:16px; vertical-align:top">
<table style="border-collapse:collapse; border-spacing:0; width:56px; height:56px; border:1px solid #E5E5E5">
<tbody>
<tr>
<td style="width:56px; min-width:56px; height:56px; font-size:28px; line-height:2em; text-align:center; vertical-align:middle; display:inline-block; background-color:#0097A7; color:#FFFFFF">
G</td>
</tr>
</tbody>
</table>
</td>
<td style="position:static; width:100%">
<div style="display:inline-block; max-width:418px; padding:13px 16px 15px 0"><span style="color:#212121; display:block; text-decoration:none; font-size:16px; line-height:1.43em">VP Product Management - Product to Market, Enterprise, Revenue and Emerging Growth</span>
<div style="color:black; font-size:14px; line-height:1.43em">Gap Inc.</div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em"><span>United States</span></div>
<div style="color:#8A8A8A; font-size:14px; line-height:1.43em">via LinkedIn</div>
<div style="padding-top:12px; line-height:1.5em; max-height:1.5em"><img alt="Time icon" src="https://www.gstatic.com/images/icons/material/system/1x/access_time_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Sep
 7</span><img alt="Work icon" src="https://www.gstatic.com/images/icons/material/system/1x/work_grey600_18dp.png" aria-hidden="true" style="width:18px; height:18px; vertical-align:middle"><span style="color:#8A8A8A; font-size:14px; padding-right:8px; padding-left:4px; vertical-align:middle">Full-time</span></div>
</div>
</td>
</tr>
</tbody>
</table>
</a></td>
</tr>
<tr>
<td style="max-width:560px; background-color:white; border:1px solid #E5E5E5; border-top-width:0px; font-family:arial,sans-serif-medium,sans-serif; vertical-align:middle">
<a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyUoX5SEHuTT0ioCS0Kis7IJHKn-9jV67IcSKBVkHyWZYD6EguZU0EA-oGqZyjkygZjAOY-dgeXQwQzMeRFAdHQwpbr4fIfhN6GKtIoPnU-3JDGqTIoSTHmN7NYqPL5P9BLV9ZRlrGAkkWGG-wqxJKq6UOy7drDJmhGhBfJKVV7NiX9gxIPqRjegC-NQn7lseJMJdnLLAuzW3wAnwxDzqptgYs8UVhad5j1UGDBqCnDey9E9bBoz71EiCCrOHPlf-T_tHysHr0vtPou-LKfqrDcZ&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtlexp%26htichips%3Ddate_posted:range_2021-09-06%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htivrt%3Djobs&amp;s=ALHZ2r7HQle9B_lVJSMwhPdTF00b" style="color:#4285f4; display:inline-block; font-size:16px; padding:16px 0 16px 0; text-decoration:none; vertical-align:middle; width:100%"><img alt="Forward icon" src="https://www.gstatic.com/images/icons/material/system/1x/arrow_forward_googblue_24dp.png" style="height:24px; padding:0 14px 0 16px; vertical-align:middle; width:24px"><span style="vertical-align:middle">Explore
 jobs</span></a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="width:100%; text-align:center; padding-top:60px; padding-bottom:18px; background-color:#EEEEEE">
<table style="border-collapse:collapse; border-spacing:0; font-family:arial,sans-serif-medium,sans-serif; color:#8A8A8A; text-align:center; font-size:14px; width:85%; margin:0 auto">
<tbody>
<tr>
<td>You received this email because you signed up for an alert on Google</td>
</tr>
<tr>
<td style="padding:24px 0; border-bottom:2px solid #BDBDBD">
<table style="border-collapse:collapse; border-spacing:0; width:100%; font-size:14px; text-align:center">
<tbody>
<tr>
<td><a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyVvx0oBLLfdP-Yrx9iPGvF8J-12ZQOKtyfoOIczq3-xwZOqMSnnoC4EkUQdDLXG7vccDZ8nsEoHrOdJ8FMmVn4QiGl-5ZzQecaD_1ACssqNtNDkRLg9y19i2ey9jsGrLlYseK7BvgVeCZEhKmZlaAWJpZEcy-sHFoQ7ITxgPpd9Yyfet6YIwGYLU-Pyhm_9olGOVjs1pAUy2hDfKop4lAXlJSi66L4srOFqQ-FWVbcfBKdltGnqlZy0H2K-8_pkl67RzheiqDvegbYdp7Lqex0tJJs&amp;c=https://www.google.com/search%3Fq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26ibp%3Dhtl%3Bjobs%26rciv%3Djb%26clksrc%3Dalertsemail%26hl%3Den%26gl%3DUS%23fpstate%3Dtlexp%26htichips%3Ddate_posted:range_2021-09-06%26htiq%3D%2522Head%2Bof%2BProduct%2522%2BOR%2B%2522VP%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2522%2BOR%2B%2522Vice%2BPresident,%2BProduct%2BManagement%2522%26htischips%3Ddate_posted%3Brange_2021-09-06%26htitab%3Dalert%26htivrt%3Djobs&amp;s=ALHZ2r5yIWgOGpHSMa_roTuh9a1V" style="padding:0 8px; text-transform:uppercase; text-decoration:none; color:#427fed">Manage
 Alerts</a></td>
<td><a href="https://notifications.googleapis.com/email/redirect?t=AFG8qyUbmNzv-PpO4QmTIHCjTF91bnGHQSZUWKaNcYQ-tqWc78E1BfeaN_MvJvheWmXbBFwb4M8R8dMmUHfb7e0C6cZlmnBOO_c3NVbv_MmssfIBL97bAjnzH3Y-f0SWKL6MHZhMPCCyUq-hOwPp8pDIx-7O5wX8f2OWZAOenIHEJKbdreUATZVAhlDSkUgtShRVJTuMYOyPZDf-L7OXU50Z6N5idZJAS3D0ymvr2pwuy3GLmBM1LWk-AVGGR1NSooVFiHiimrlQV4A9MXuCXYKyRrtcd3VGdeB8bw&amp;c=https://notifications.google.com/emailpreferences/unsubscribe/AIbu_Ft_xXWYOkGTr_nsZThvdj6azSq6Ar4b3-eb4JYiDFiYl3aDeDGsbZxMiahsOxgzlSpj5nFir7AtWVmPc2qOFGp4Aoe6Ex8Bt_GUvDNi9HMoYBTQ4p7M-jfHM4P4xufGfUWvagdF9nd_k0_R8zzOTTcdHgUt59whw78NbTSOCyXXpsg73A0NBs-lpmuBDv0Ereg&amp;s=ALHZ2r7bk7f3liI_hOpGEG8c0T4x" style="text-transform:uppercase; text-decoration:none; color:#427fed">Unsubscribe</a></td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td style="padding:30px 0 5px 0; width:90px; height:31px"><img src="https://www.gstatic.com/alerts/images/google_logo_grey.png" style="width:90px; height:31px"></td>
</tr>
<tr>
<td style="font-size:12px; text-decoration:none; color:#8A8A8A; line-height:1.5em">
Google Inc. 1600 Amphitheatre Parkway<br>
Mountain View, CA 94043, USA<br>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
<td style="background-color:#EEEEEE; vertical-align:top; padding:0">
<table style="border-collapse:collapse; border-spacing:0; table-layout:fixed; width:100%">
<tbody>
<tr>
<td style="height:20px; background-color:#4285f4; padding:0"></td>
</tr>
<tr>
<td style="background-color:#EEEEEE; padding:0"></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</body>
</html>`;

export const linkedInText = `LinkedIn | Your job alert for head of product in San Francisco Bay Area

4 new jobs match your preferences.

---------------------------------------------------------

Head of Product
Nas Academy
San Francisco Bay Area

View job: https://www.linkedin.com/comm/jobs/view/2706563206?alertAction=3D=
markasviewed&savedSearchAuthToken=3D1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNp=
xNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06Gc=
dXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-ax=
MyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7L=
ToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsb=
cw0lYx&savedSearchId=3D1339426806&refId=3D6efce642-4885-4d38-963c-909fc5c41=
109&trackingId=3DlPo4ntKU8%2FJPQVA845LLhQ%3D%3D&midToken=3DAQFI2cUdOmE73Q&m=
idSig=3D07AtLQDAAYP9U1&trk=3Deml-email_job_alert_digest_01-job_alert-6-memb=
er_details_mercado&trkEmail=3Deml-email_job_alert_digest_01-job_alert-6-mem=
ber_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&lipi=3Durn%=
3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%=
3D%3D

---------------------------------------------------------

Head Of Product Management
Stealth Mode
San Francisco Bay Area

View job: https://www.linkedin.com/comm/jobs/view/2705985680?alertAction=3D=
markasviewed&savedSearchAuthToken=3D1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNp=
xNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06Gc=
dXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-ax=
MyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7L=
ToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsb=
cw0lYx&savedSearchId=3D1339426806&refId=3D6efce642-4885-4d38-963c-909fc5c41=
109&trackingId=3DojYn4zWnOPVzP9IyONoAyw%3D%3D&midToken=3DAQFI2cUdOmE73Q&mid=
Sig=3D07AtLQDAAYP9U1&trk=3Deml-email_job_alert_digest_01-job_alert-7-member=
_details_mercado&trkEmail=3Deml-email_job_alert_digest_01-job_alert-7-membe=
r_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&lipi=3Durn%3A=
li%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D=
%3D

---------------------------------------------------------

Director, Head of RippleX Product Marketing
Ripple
San Francisco, California, United States

View job: https://www.linkedin.com/comm/jobs/view/2706527391?alertAction=3D=
markasviewed&savedSearchAuthToken=3D1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNp=
xNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06Gc=
dXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-ax=
MyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7L=
ToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsb=
cw0lYx&savedSearchId=3D1339426806&refId=3D6efce642-4885-4d38-963c-909fc5c41=
109&trackingId=3Dzd1nh0%2FV5jRWSf34I9nCnw%3D%3D&midToken=3DAQFI2cUdOmE73Q&m=
idSig=3D07AtLQDAAYP9U1&trk=3Deml-email_job_alert_digest_01-job_alert-8-memb=
er_details_mercado&trkEmail=3Deml-email_job_alert_digest_01-job_alert-8-mem=
ber_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&lipi=3Durn%=
3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%=
3D%3D

---------------------------------------------------------

Head of Product Design
CaptivateIQ
San Francisco Bay Area

View job: https://www.linkedin.com/comm/jobs/view/2706559436?alertAction=3D=
markasviewed&savedSearchAuthToken=3D1%26AQETsTJrE1qHjwAAAXvCO7Fd9Dg0mO9OaNp=
xNXdjzf3CSt9Jo7nlC8qxjuZS1ILa92AWYQ8IIGgdOxPvB-uRmwsiyCS_gbUiXaRUmp_vY-06Gc=
dXtoACmpFwqacFf4Cx06KZ1eHfQBm7IYFhoTrG1_16HGxTA8jbOVRBF81pzmD6n47pInKrVc-ax=
MyiSedIvyaqwEQfgUTZUPnr5opwXwKQ0Nta7t0tTzSdPB3LMcER8MzRoQHRdlxvh2UszAduYw7L=
ToqW81V_eOaU-XDtsm56m_9_q7sINWdfMBm6hQiZyyr_s9yASQ%26AWUwNpXUd4aeTEf9Wj1rsb=
cw0lYx&savedSearchId=3D1339426806&refId=3D6efce642-4885-4d38-963c-909fc5c41=
109&trackingId=3D2qe3JcyK%2Fzl6D3cB5%2B%2BdyA%3D%3D&midToken=3DAQFI2cUdOmE7=
3Q&midSig=3D07AtLQDAAYP9U1&trk=3Deml-email_job_alert_digest_01-job_alert-9-=
member_details_mercado&trkEmail=3Deml-email_job_alert_digest_01-job_alert-9=
-member_details_mercado-null-2eag8%7Ektaltv99%7E1y-null-jobs%7Eview&lipi=3D=
urn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FD=
lWg%3D%3D

---------------------------------------------------------


---------------------------------------------------------




.....................................

Manage your job alerts: https://www.linkedin.com/comm/jobs/alerts?midToken=
=3DAQFI2cUdOmE73Q&amp;midSig=3D07AtLQDAAYP9U1&amp;trk=3Deml-email_job_alert=
_digest_01-footer-11-manage_alert&amp;trkEmail=3Deml-email_job_alert_digest=
_01-footer-11-manage_alert-null-2eag8%7Ektaltv99%7E1y-null-job%7Ealert%7Ema=
nager&amp;lipi=3Durn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpn=
iYSy%2Bj2xztA%2FDlWg%3D%3D

Unsubscribe: https://www.linkedin.com/e/v2?e=3D2eag8-ktaltv99-1y&amp;lipi=
=3Durn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%=
2FDlWg%3D%3D&amp;a=3Djobs-jserp-search-mirror&amp;midToken=3DAQFI2cUdOmE73Q=
&amp;midSig=3D07AtLQDAAYP9U1&amp;ek=3Demail_job_alert_digest_01&amp;li=3D12=
&amp;m=3Dfooter&amp;ts=3Ddelete_alert&amp;alertAction=3Ddelete&amp;savedSea=
rchId=3D1339426806

Help: https://www.linkedin.com/e/v2?e=3D2eag8-ktaltv99-1y&lipi=3Durn%3Ali%3=
Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%2Bj2xztA%2FDlWg%3D%3D&=
a=3DcustomerServiceUrl&midToken=3DAQFI2cUdOmE73Q&midSig=3D07AtLQDAAYP9U1&ek=
=3Demail_job_alert_digest_01&li=3D37&m=3Dfooter&ts=3Dhelp&articleId=3D67


You are receiving LinkedIn notification emails.

This email was intended for Justin Grant (Head of Product in B2B/SaaS Start=
ups).
Learn why we included this: https://www.linkedin.com/e/v2?e=3D2eag8-ktaltv9=
9-1y&lipi=3Durn%3Ali%3Apage%3Aemail_email_job_alert_digest_01%3B43qhpniYSy%=
2Bj2xztA%2FDlWg%3D%3D&a=3DcustomerServiceUrl&midToken=3DAQFI2cUdOmE73Q&midS=
ig=3D07AtLQDAAYP9U1&ek=3Demail_job_alert_digest_01&articleId=3D4788

=C2=A9 2021 LinkedIn Corporation, 1000 West Maude Avenue, Sunnyvale, CA 940=
85. LinkedIn and the LinkedIn logo are registered trademarks of LinkedIn.`;
