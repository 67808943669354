import { useState } from 'react';
import {
  createClient,
  TestUserCreateRequestFields,
  Permission,
  TestUserCreateResponseFields,
  FacebookUser,
} from './FacebookTestUsers';
import { REACT_APP_FACEBOOK_APP_ID, REACT_APP_FACEBOOK_APP_SECRET } from '../env';
import testUsers from '../testUsers';
import styled from '@emotion/styled';
import { AxiosError } from '../HangleExceptions';

const testUserNames = [
  'Travis Gray',
  'Tracy Cruz',
  'Martha Sims',
  'Dolores Curtis',
  'Elisa Green',
  'Jay Cunningham',
  'Bruce Sparks',
  'Agnes Schneider',
  'Sara Flores',
  'Amber Harmon',
  'Clayton Reese',
  'Pauline Arnold',
  'Cora Richards',
  'Byron Townsend',
  'Shari Baker',
  'Simon Day',
  'Melissa Barton',
  'Essie Hart',
  'Reginald Price',
  'Heidi Summers',
  'Luke Estrada',
  'Mattie Jenkins',
  'June Snyder',
  'April Zimmerman',
  'Kendra Sutton',
  'Bennie Steele',
  'Camille Jacobs',
  'Rolando McDonald',
  'Tammy Barnett',
  'Joyce Walsh',
  'Van Norris',
  'Flora Carson',
  'Meghan Haynes',
  'Ruby Fox',
  'Clay Beck',
  'Gwen Mack',
  'Chester Hall',
  'Clint Freeman',
  'Sheri Hudson',
  'Adrian Castro',
  'Gustavo Briggs',
  'Bryant Neal',
  'Cecil Pratt',
  'Clark Bell',
  'Lynette Chapman',
  'Clinton Holloway',
  'Norma Mills',
  'Rosemary Rodriquez',
  'Keith Simmons',
  'Ron Wise',
  'Jose Gomez',
  'Johnny Webster',
  'Lucy Terry',
  'Joe Clarke',
  'Rodolfo Stevens',
  'Roy Alvarez',
  'Henry Conner',
  'Molly Blair',
  'Celia Holt',
  'Ed Griffin',
  'Samuel Klein',
  'Earnest Salazar',
  'Woodrow Crawford',
  'Marian Wilkins',
  'Dwayne Knight',
  'Edith Burke',
  'Clara Jefferson',
  'Delores Reynolds',
  'Trevor Clark',
  'Alexandra Reeves',
  'Chad Owens',
  'Francisco Jensen',
  'Justin Parsons',
  'Alison Mitchell',
  'Colin Hubbard',
  'Leona Harrington',
  'Forrest Sherman',
  'Joann Hughes',
  'Ramiro Nash',
  'Winifred Castillo',
  'Cody Nguyen',
  'Brandi Long',
  'Anne Montgomery',
  'Myrtle Burgess',
  'Vanessa Santos',
  'Shelley Stephens',
  'Jessie Lamb',
  'Opal Hansen',
  'Genevieve Blake',
  'Tyler Carroll',
  'Lorena Greer',
  'Fred Bush',
  'Duane Barnes',
  'Jill Cohen',
  'Eunice Schwartz',
  'Shelia Peters',
  'Percy Curry',
  'Crystal Lawson',
  'Jackie Adams',
  'Courtney Brady  ',
];

type FacebookApiError = AxiosError<{ error?: { message?: string } }>;

const testUsersMerged = testUsers;

async function createTestUsers() {
  const client = await createClient({
    appId: REACT_APP_FACEBOOK_APP_ID,
    appSecret: REACT_APP_FACEBOOK_APP_SECRET,
  });

  const permissions: Permission[] = ['email', 'public_profile', 'user_friends'];
  const permissionsString = permissions.join(',');

  const promises = testUserNames.map((name, i) => {
    const createOptions: TestUserCreateRequestFields = {
      installed: true,
      permissions: permissionsString,
      name,
    };
    return client.createTestUser(createOptions);
  });

  return await Promise.all(promises);
}

async function listTestUsers() {
  const client = await createClient({
    appId: REACT_APP_FACEBOOK_APP_ID,
    appSecret: REACT_APP_FACEBOOK_APP_SECRET,
  });

  type ExtraFields = { name: string; email: string };
  const includeFields: (keyof ExtraFields)[] = ['name', 'email'];
  const results = await client.listTestUsers<ExtraFields>({ includeFields });
  return results;
}

const StyledTable = styled('table')`
  margin-top: 20;
  width: 600;
  border-spacing: 10;
  & td {
    padding-top: 5px;
    padding-right: 10px;
  }
  & tr:hover {
    background-color: #f0f0f0;
  }
`;

function CreateTestUsers() {
  const [status, setStatus] = useState('(none)');
  const [apiResults, setApiResults] =
    useState<(TestUserCreateResponseFields & Partial<FacebookUser>)[]>(testUsersMerged);

  const onCreate = async () => {
    const mergeUsers = (users: (TestUserCreateResponseFields & { name?: string })[]) => {
      const merged = users.map((u, i) => {
        const { id, email, password, access_token, login_url } = u;
        const u2 = {
          id,
          name: testUserNames[i],
          email,
          password,
          access_token,
          login_url,
        };
        return u2;
      });
      return merged;
    };
    try {
      const results = await createTestUsers();
      const merged = mergeUsers(results);
      setApiResults(merged);
      setStatus('DONE');
    } catch (e) {
      setStatus((e as FacebookApiError)?.response?.data?.error?.message || (e as Error).message);
    }
  };

  const onList = async () => {
    try {
      const results = await listTestUsers();
      const merged = results
        .filter(u => testUsers.find(tu => tu.name === u.name))
        .map((u, i) => {
          const matchingTestUser = testUsers.find(tu => tu.name === u.name);
          const { id, email, access_token, login_url, name } = u;
          const u2 = {
            id,
            name,
            email: email || (matchingTestUser && matchingTestUser.email) || '',
            password: matchingTestUser ? matchingTestUser.password : '',
            access_token: access_token!,
            login_url,
          };
          return u2;
        });
      setApiResults(merged);
      setStatus('DONE');
    } catch (e) {
      setStatus((e as FacebookApiError)?.response?.data?.error?.message || (e as Error).message);
    }
  };

  return (
    <>
      <div style={{ width: 1000, padding: 50 }}>
        {/*
        uncomment this to create more users, which could be somewhat dangerous because
        it'd create same-named users to users who already exist, making it hard to 
        differentiate them.
        */}
        <button style={{ display: 'none' }} onClick={onCreate}>
          Create 100 Test Users
        </button>
        <button onClick={onList}>Fetch Test Users</button>
        <div style={{ marginTop: 30 }}>Status: {status}</div>
        <textarea
          style={{
            height: 200,
            width: 800,
            marginTop: 30,
            marginBottom: 30,
            overflow: 'scroll',
            border: '1px solid #ccc',
            padding: 10,
            whiteSpace: 'normal',
          }}
          value={JSON.stringify(apiResults, null, 2)}
        />
        <div style={{ overflow: 'scroll', height: 200, border: '1px solid #ccc', padding: 10, width: 800 }}>
          <StyledTable>
            <thead>
              <th>Name</th>
              <th>Email</th>
              <th>Login Link</th>
            </thead>
            <tbody>
              {apiResults.map(u => (
                <tr key={u.name}>
                  <td>{u.name}</td>
                  <td>{u.email}</td>
                  <td>
                    <a href={u.login_url}>login</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      </div>
    </>
  );
}

export default CreateTestUsers;
