import { Fragment, CSSProperties } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

// TODO: simplify if https://github.com/Microsoft/TypeScript/pull/26797 is released

type DisplayStateDriverBase<StateType extends number> = {
  content: React.FC | React.ReactElement | React.ReactNode;
  overlayDrivers?: DisplayStateDriver<StateType> | DisplayStateDriver<StateType>[];
  overlayOpacity?: number;
};

/** This driver is for matching states */
type DisplayStateDriverNormal<StateType extends number> = DisplayStateDriverBase<StateType> & {
  states: StateType | StateType[];
};

/** This driver is for all states *except* notStates */
export type DisplayStateDriverNegative<StateType extends number> = DisplayStateDriverBase<StateType> & {
  notStates: StateType | StateType[];
};

export function isDisplayStateDriverNegative<StateType extends number>(
  o: unknown
): o is DisplayStateDriverNegative<StateType> {
  const maybe = o as DisplayStateDriverNegative<StateType>;
  return isDisplayStateDriver(maybe) && maybe.hasOwnProperty('notStates');
}

export function isDisplayStateDriverNormal<StateType extends number>(
  o: unknown
): o is DisplayStateDriverNegative<StateType> {
  const maybe = o as DisplayStateDriverNegative<StateType>;
  return isDisplayStateDriver(maybe) && maybe.hasOwnProperty('states');
}

export function isDisplayStateDriver<StateType extends number>(o: unknown): o is DisplayStateDriver<StateType> {
  const maybe = o as DisplayStateDriver<StateType>;
  return maybe.hasOwnProperty('content') && (maybe.hasOwnProperty('states') || maybe.hasOwnProperty('notStates'));
}

export type DisplayStateDriver<StateType extends number> =
  | DisplayStateDriverNormal<StateType>
  | DisplayStateDriverNegative<StateType>;

function isDisplayStateMatch<T extends number>(states: T | T[], stateToMatch: T) {
  return Array.isArray(states) ? states.includes(stateToMatch) : states === stateToMatch;
}

function isNumberArray<T extends number>(arr: unknown[]): arr is T[] {
  return typeof arr[0] === 'number';
}

function statesAsArray<T extends number>(states: DisplayStateDriver<T> | DisplayStateDriver<T>[]): T[];
function statesAsArray<T extends number>(states: T | T[]): T[];
function statesAsArray<T extends number>(statesOrDriver: T | T[] | DisplayStateDriver<T> | DisplayStateDriver<T>[]) {
  if (isDisplayStateDriver(statesOrDriver)) {
    return statesAsArray(
      isDisplayStateDriverNegative(statesOrDriver) ? statesOrDriver.notStates : statesOrDriver.states
    );
  } else if (Array.isArray(statesOrDriver)) {
    // see https://github.com/microsoft/TypeScript/issues/33591 for why the type guard is needed
    return isNumberArray(statesOrDriver)
      ? statesOrDriver
      : statesOrDriver.reduce<T[]>((arr, s) => arr.concat(statesAsArray(s)), []);
  } else {
    return [statesOrDriver];
  }
}

function asArray<T>(o: T | T[]) {
  return Array.isArray(o) ? o : [o];
}

function isDisplayStateDriverMatch<T extends number>(
  driver: DisplayStateDriver<T> | DisplayStateDriver<T>[],
  stateToMatch: T
): boolean {
  if (Array.isArray(driver)) {
    return driver.some(d => isDisplayStateDriverMatch(d, stateToMatch));
  } else {
    return isDisplayStateDriverNegative(driver)
      ? !isDisplayStateMatch<T>(driver.notStates, stateToMatch)
      : isDisplayStateMatch<T>(driver.states, stateToMatch);
  }
}

function validateDrivers<T extends number>(drivers: DisplayStateDriver<T>[]) {
  const values = new Set<T>();
  for (const d of drivers) {
    if (!isDisplayStateDriverNegative(d)) {
      const states = d.overlayDrivers ? [...statesAsArray(d), ...statesAsArray(d.overlayDrivers)] : statesAsArray(d);
      for (const s of states) {
        if (values.has(s)) {
          throw new Error(`Display state ${s} is repeated in multiple drivers, which is not allowed`);
        } else {
          values.add(s);
        }
      }
    }
  }
}

const overlayStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 999,
};

/*
const loadingTextStyle = {
  color: '#558255',
};
*/

// code below adapted from https://gist.github.com/oncomouse/08b1920f6b42d90a01a0e296980386a4

type Keyframe =
  | 'appear'
  | 'enter'
  | 'exit'
  | 'appear-active'
  | 'enter-active'
  | 'exit-active'
  | 'appear-done'
  | 'enter-done'
  | 'exit-done'
  | 'height';
const keyframes: Keyframe[] = [
  'appear',
  'enter',
  'exit',
  'appear-active',
  'enter-active',
  'exit-active',
  'appear-done',
  'enter-done',
  'exit-done',
  'height',
];

const has = function <T>(key: string, obj: T) {
  return Object.prototype.hasOwnProperty.call(obj, key);
};
const cssNameToJsName = (cssPropertyName: string) =>
  cssPropertyName.replace(/(-[a-z])/g, v => v.slice(1).toUpperCase());

type TransitionsStyles = {
  [k in Keyframe]?: SerializedStyles;
};
interface StyledTransitionProps extends Record<string, unknown> {
  transitions: TransitionsStyles;
  transitionMsecs: number;
  className: string;
}

const StyledTransition = styled(({ transitions, transitionMsecs, className, ...props }: StyledTransitionProps) => (
  <ReactCSSTransitionReplace
    transitionName={className}
    transitionEnterTimeout={transitionMsecs}
    // short leave timeout for faster transition from overlays to next state
    transitionLeaveTimeout={10}
    {...props}
  />
))`
  ${({ transitions }: { transitions: TransitionsStyles }) =>
    keyframes
      .map(keyframe => {
        const objectKey = cssNameToJsName(keyframe);
        return has(objectKey, transitions)
          ? css`
              &-${keyframe} {
                ${transitions[objectKey]}
              }
            `
          : null;
      })
      .filter(x => x !== null)}
`;

const TransitionBoxInner = (props: { transitionMsecs?: number; className?: string }) => {
  const { className, transitionMsecs = 150, ...otherProps } = props;
  return (
    <StyledTransition
      transitions={{
        height: css`
          transition: height ${transitionMsecs}ms ease-in-out;
        `,
      }}
      className={className!}
      transitionMsecs={transitionMsecs}
      {...otherProps}
    />
  );
};

const TransitionBox = styled(TransitionBoxInner)`
  position: relative;
  display: block;
  left: 0;
  top: 0;
`;

const DEFAULT_OVERLAY_OPACITY = 0.3;

export default function DisplayStateMachine<T extends number>(props: {
  drivers: DisplayStateDriver<T>[];
  displayState: T;
}) {
  const { drivers, displayState } = props;
  validateDrivers(drivers);
  const indexedDrivers = drivers.map((d, i) => ({ index: i, driver: d }));

  const matchingDrivers = indexedDrivers.filter(({ driver }) => isDisplayStateDriverMatch(driver, displayState));
  const matchingOverlayDrivers = indexedDrivers.filter(
    ({ driver }) => driver.overlayDrivers && isDisplayStateDriverMatch(driver.overlayDrivers, displayState)
  );
  const totalMatchesCount = matchingDrivers.length + matchingOverlayDrivers.length;
  if (totalMatchesCount > 1) {
    throw new Error(`Display state value ${displayState} is repeated, which is not allowed`);
  } else if (totalMatchesCount === 0) {
    throw new Error(`Display state value ${displayState} is not matched by any drivers`);
  }

  const isOverlay = matchingOverlayDrivers.length > 0;
  const overlaysInside = isOverlay ? asArray(matchingOverlayDrivers[0].driver.overlayDrivers!) : undefined;
  const matchingOverlaysInside = overlaysInside?.filter(d => isDisplayStateDriverMatch(d, displayState));
  const matchingOverlayInside = matchingOverlaysInside?.[0];

  if (matchingOverlaysInside && matchingOverlaysInside.length > 1) {
    throw new Error(`Display state value ${displayState} is repeated in multiple overlay drivers`);
  }

  const { index, Content, OverlayContent, opacity } = isOverlay
    ? {
        index: matchingOverlayDrivers[0].index,
        Content: matchingOverlayDrivers[0].driver.content,
        OverlayContent: matchingOverlayInside!.content,
        opacity: matchingOverlayInside?.overlayOpacity ?? DEFAULT_OVERLAY_OPACITY,
      }
    : {
        index: matchingDrivers[0].index,
        Content: matchingDrivers[0].driver.content,
        OverlayContent: null,
        opacity: undefined,
      };
  const hasContent = Content != null || OverlayContent != null;

  return (
    // TODO: consider replacing fixed timeouts with props
    <TransitionBox>
      <Fragment key={hasContent ? index : -1}>
        {!hasContent && <div></div>}
        {OverlayContent != null && (
          <div style={overlayStyle}>{typeof OverlayContent === 'function' ? <OverlayContent /> : OverlayContent}</div>
        )}
        {hasContent && (
          <div {...(opacity == null ? {} : { style: { opacity } })}>
            {typeof Content === 'function' ? <Content /> : Content}
          </div>
        )}
      </Fragment>
    </TransitionBox>
  );
}

/*
enum States {
  SHOWN,
  LOADING,
  HIDING,
  HIDDEN,
}

export const Test = () => {
  const [displayState, setDisplayState] = useState<States>(States.SHOWN);
  const drivers: DisplayStateDriver<States>[] = [
    {
      states: States.SHOWN,
      content: () => <button onClick={() => setDisplayState(States.HIDING)}>This is shown</button>,
      overlayDriver: {
        states: States.LOADING,
        content: (
          <>
            <span style={loadingTextStyle}>Setting up your hang...</span> <Spinner />
          </>
        ),
      },
    },
    { states: States.HIDING, content: <button onClick={() => setDisplayState(States.HIDDEN)}>This is hiding</button> },
    { states: States.HIDDEN, content: null },
  ];
  return <DisplayStateMachine drivers={drivers} displayState={displayState}></DisplayStateMachine>;
};
*/
