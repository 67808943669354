import { CSSProperties, useCallback, useEffect, useState } from 'react';
import FacebookLoginButton, { FacebookLoginButtonProps } from './FacebookLoginButton';
import GoogleLoginButton, { GoogleLoginButtonProps } from './GoogleLoginButton';
import UserStateContainer from './UserStateContainer';
import { FieldContainer } from './FormFieldUtils';
import { ModalCloser } from './ContextModal';

const hideStyle = { display: 'none' } as const;

export function AutoLogin() {
  const { onFacebookLoginResponse, onGoogleLoginResponse } = UserStateContainer.useContainer();
  return (
    <>
      <GoogleLoginButton onLoginResponse={onGoogleLoginResponse} style={hideStyle} />
      <FacebookLoginButton onLoginResponse={onFacebookLoginResponse} style={hideStyle} />
    </>
  );
}

const containerStyles: CSSProperties = {
  padding: '2rem',
  textAlign: 'center',
};

const headerStyles: CSSProperties = {
  flex: '1 0',
  margin: '0 0 3rem 0',
};

const logoStyle: CSSProperties = {
  width: 'calc(40% + 8rem)',
  marginBottom: '2rem',
};

const titleStyle: CSSProperties = {
  color: '#444',
  fontWeight: 'bold',
  marginTop: '0.75rem',
};
const subtitleStyle: CSSProperties = {};

export default function LoginUi() {
  const { onFacebookLoginResponse, onGoogleLoginResponse, onLogout } = UserStateContainer.useContainer();
  const [showModal, setShowModal] = useState(true);

  // in case there was a previously cached login, we'll log out to clear them
  useEffect(() => {
    void onLogout();
  }, [onLogout]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type FirstParam<T extends (...args: any) => any> = Parameters<T>[0];
  const handleFacebookResponse = useCallback(
    (response: FirstParam<FacebookLoginButtonProps['onLoginResponse']>) => {
      setShowModal(false);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      void onFacebookLoginResponse(response);
    },
    [onFacebookLoginResponse]
  );
  const handleGoogleResponse = useCallback(
    (response: FirstParam<GoogleLoginButtonProps['onLoginResponse']>) => {
      setShowModal(false);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      void onGoogleLoginResponse(response);
    },
    [onGoogleLoginResponse]
  );

  if (!showModal) {
    return <ModalCloser />;
  }
  return (
    <div style={containerStyles}>
      <FieldContainer style={headerStyles}>
        <img alt="Hangle" src="/monkeys5.png" style={logoStyle} />
        <h3 style={titleStyle}>Welcome Back!</h3>
        <h6 style={subtitleStyle}>Your friends will be so happy to see you!</h6>
      </FieldContainer>

      <FieldContainer>
        <FacebookLoginButton onLoginResponse={handleFacebookResponse} />
      </FieldContainer>
      <FieldContainer>
        <GoogleLoginButton onLoginResponse={handleGoogleResponse} />
      </FieldContainer>
    </div>
  );
}
