import { Friend } from './Friend';
import { Link } from 'react-router-dom';
import { friendEditPage } from './Pages';
const FriendLink = (props: { friend: Friend | null | undefined }) => {
  // TODO: add a "friend view" page that's separate from the "friend edit" page that we have now
  const { friend } = props;
  if (!friend) {
    return null;
  }
  return <Link to={friendEditPage.urlBuilder({ friend })}>{friend.name}</Link>;
};
export default FriendLink;
