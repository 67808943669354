import { Button, ButtonProps } from 'reactstrap';
import * as H from 'history'; // the namespace, for global functions
import { Link, LinkProps } from 'react-router-dom';

// removing navigate prop is required per https://github.com/ReactTraining/react-router/issues/6962
const Button2 = (props: ButtonProps) => {
  const { navigate, ...rest } = props;
  return <Button {...rest} />;
};

export interface LinkButtonProps<S = H.LocationState> extends LinkProps<S> {}
const LinkButton = (props: LinkButtonProps) => <Link component={Button2} {...props} />;
export default LinkButton;
