import { useCallback, CSSProperties, memo } from 'react';
import { Button } from 'reactstrap';
import {
  ReactFacebookLoginInfo,
  useFacebookLogin,
  FacebookLoginProps,
  ReactFacebookFailureResponse,
} from './facebookLogin';
import { REACT_APP_FACEBOOK_APP_ID } from './env';
import { ModalContainer } from './ContextModal';
import { useFacebookStateParam } from './Auth';
import { FacebookIcon } from './Icon';

export interface FacebookLoginButtonProps {
  onLoginResponse: (userInfo: ReactFacebookLoginInfo) => void;
  style?: CSSProperties;
  className?: string;
}

const FacebookLoginButton = memo(function FacebookLoginButton(props: FacebookLoginButtonProps) {
  const { onLoginResponse, style, className } = props;
  const { addModal } = ModalContainer.useContainer();

  const loginFailed = useCallback(
    (response: ReactFacebookFailureResponse) => {
      addModal(
        `${
          response.status && response.status !== 'unknown'
            ? `Facebook login failed: ${response.status}`
            : 'Facebook login failed'
        }. Please try again.`
      );
    },
    [addModal]
  );
  const responseFacebook = useCallback(
    (userInfo: ReactFacebookLoginInfo) => {
      console.log(`Facebook login returned: ${JSON.stringify(userInfo)}`);
      onLoginResponse(userInfo);
    },
    [onLoginResponse]
  );
  const facebookLoginState = useFacebookStateParam();
  const facebookLoginProps: FacebookLoginProps = {
    appId: REACT_APP_FACEBOOK_APP_ID,
    autoLoad: true,
    fields: 'name,email,picture,first_name,last_name,middle_name,short_name,name_format',
    onLogin: responseFacebook,
    onLoginFailure: loginFailed,
    redirectUri: window.origin + '/auth/facebook/1.0',
    facebookLoginState,
    isMobile: true, // avoid popups and popup blocker issues
    //    disableMobileRedirect: true,
  };
  const { login, isDisabled, isProcessing, isSdkLoaded } = useFacebookLogin(facebookLoginProps);

  return (
    <Button
      style={style}
      className={className || 'social-login-button facebook-login-button'}
      onClick={login}
      disabled={isDisabled || isProcessing || !isSdkLoaded}
    >
      <FacebookIcon /> Login with Facebook
    </Button>
  );
});

export default FacebookLoginButton;
