import { HangTimeBaseRuntype } from './HangTime';
import {
  Record,
  Array as RunArray,
  String as RunString,
  InstanceOf,
  Boolean as RunBoolean,
  Number as RunNumber,
  Null,
  Guard,
  Static,
} from 'runtypes';
import ObjectIdRuntype from './ObjectIdRuntype';
import NoExtraProps from './NoExtraProps';
import { Temporal } from '@js-temporal/polyfill';
import { SerializeType } from './Serializer';

/*
A “Hang” is a matched Hangtime between users. In addition to the basic info on a Hangtime, a Hang also needs:

Pointers to notifications that triggered creation of this Hang
Name - An optional name for the hang, e.g. “Pat’s 40th Birthday”
Location - where the Hang will (or did) take place
Street Address
Consider an autocomplete solution like https://developers.google.com/places/web-service/autocomplete or https://www.mapbox.com/pricing/#geocode
(optional) Text of name of place, e.g. Moe’s Bar or Maddy’s House - can sometimes get this from autocomplete
Longer-term, consider a “Favorite places” feature to make this easy
(internal-only) - Store the Lat/Long for later geolocation use
Who’s been invited (helpful for “traditional” hangs where the invitees are explicit)
Who’s accepted. Normally this is everyone that’s invited, but there are exceptions:
Traditional hangs where the invitees are specified in advance
If one of the attendees accepts and later declines
If the invite is forwarded but not accepted yet
ChatId - Pointer to the chat for this Hang (when we have “hangchats”)
Capacity - how many people can this hang accommodate? Optional.
Deleted - flag for soft deletion
Owner - The user ID (or IDs) that initiated the hang.

*/

/**
 * There are so many minefields in dealing with postal addresses!  Here's a
 * first attempt, but will certainly need to revise this later. Consider an
 * autocomplete solution like
 * https://developers.google.com/places/web-service/autocomplete or
 * https://www.mapbox.com/pricing/#geocode
 */
export const PostalAddressRuntype = Record({
  rawAddress: RunString,
  address1: RunString,
  address2: RunString.Or(Null),
  address3: RunString.Or(Null),
  city: RunString,
  state: RunString,
  postalCode: RunString,
  country: RunString,
});
export type PostalAddress = Static<typeof PostalAddressRuntype>;

export const LatLongRuntype = Record({
  lat: RunNumber,
  long: RunNumber,
});
export type LatLong = Static<typeof LatLongRuntype>;

/**
 * Tracks the location of a Hang. Later, if/when we do geolocation of users,
 * then presumably we'll use it for that too. Longer-term, consider a“Favorite
 * Places” feature to make this easier.
 */
export interface HangLocation {
  /** Friendly name of the place, e.g. "Moe's Bar" or "Maddy's House" */
  name?: string;
  address: PostalAddress;
  latLong: LatLong | null;
}
export const HangLocationRuntype = Record({
  /** Friendly name of the place, e.g. "Moe's Bar" or "Maddy's House" */
  name: RunString.Or(Null),
  address: PostalAddressRuntype.Or(Null),
  latLong: LatLongRuntype.Or(Null),
});

export enum HangType {
  AUTO = 'AUTO',
  MANUAL = 'MANU',
}
const isHangType = (o: unknown): o is HangType =>
  typeof o === 'string' && ([HangType.AUTO, HangType.MANUAL] as string[]).includes(o);
export const HangTypeRuntype = Guard(isHangType, { name: 'HangType' });

export const InviteRuntype = NoExtraProps(
  HangTimeBaseRuntype.And(
    Record({
      /** Unique ID for this invite */
      i: ObjectIdRuntype,

      /**
       * Is this attendee actually attending?  Normally will be true, but there are
       * exceptions:
       * - Manual Hangs where the invitees are specified in advance
       * - If one of the attendees accepts and later declines or is dis-invited
       * - If the invite is forwarded but not accepted yet
       * Therefore, if you only want confirmed attendees, filter by active===true.
       */
      active: RunBoolean,

      /**
       * Whether this is an Autohang (created by the Hangle matching algorithm) or
       * a manual hang created by users for a specific time and/or set of attendees.
       */
      hangType: HangTypeRuntype,

      /** Optional name for the hang, e.g. "Pat's 40th Birthday" */
      name: RunString.Or(Null),

      /** Which Hangle user is invited? */
      sentTo: ObjectIdRuntype,

      /** When was this invite sent? */
      sentAt: InstanceOf(Temporal.Instant),

      /** Who sent this invite? For AutoHangs, will be the user who OK-ed the
       * notification. */
      sentBy: ObjectIdRuntype,

      /** When the user (sentTo) accepted the invite */
      acceptedAt: InstanceOf(Temporal.Instant).Or(Null),

      /** Set to the time where the user declined the invite or was dis-invited.
       * Otherwise null. */
      removedAt: InstanceOf(Temporal.Instant).Or(Null),

      /** Set to the user who dis-invited this attendee, or to self (sentTo) if the user
       * declined or later decided not to attend.
       */
      removedBy: ObjectIdRuntype.Or(Null),

      // TODO: include reference to notification(s) that caused this invite?
    })
  )
);

export type Invite = Static<typeof InviteRuntype>;

export const HangRuntype = NoExtraProps(
  HangTimeBaseRuntype.And(
    Record({
      /** Unique ID for this Hang */
      _id: ObjectIdRuntype,

      /** Optional name for the hang, e.g. "Pat's 40th Birthday" */
      name: RunString.Or(Null),

      /**
       * Whether this is an Autohang (created by the Hangle matching algorithm) or
       * a manual hang created by users for a specific time and/or set of attendees.
       */
      hangType: HangTypeRuntype,

      /**
       * Where the Hang will (or did) take place
       */
      location: HangLocationRuntype.Or(Null),

      /**
       * Who's been invited to this Hang. Note that not all invitees are attending
       * (filter on `active===true` for that purpose)
       */
      invites: RunArray(InviteRuntype),

      /**
       * Pointer to the chat for this Hang (when we have “hangchats”)
       */
      chat: ObjectIdRuntype.Or(Null),

      /**
       * How many attendees can be accommodated in this Hang
       */
      capacity: RunNumber.Or(Null),

      /**
       * If true, this hang is "soft deleted" by the owner(s)
       */
      deleted: RunBoolean,

      /**
       * The ID(s) of the user(s) who control this hang. Initially this will be the
       * two initial attendees (for Autohangs) or the organizer of the Hang (for
       * Manual Hangs). Owners can add other owners later, and can remove themselves
       * as an owner.
       */
      owners: RunArray(ObjectIdRuntype),

      /** stored version, for optimistic concurrency */
      version: RunNumber,
    })
  )
);

/**
 * A “Hang” is a matched Hangtime between users. In addition to the basic info
 * on a Hangtime, a Hang also needs additional info.
 */
export type Hang = Static<typeof HangRuntype>;

/**
 * This is the type actually stored in MongoDB. It's the same as UserStateServer, except
 * that Temporal types are serialized into MongoDB-safe objects.
 */
export type HangResult = SerializeType<Hang>;
