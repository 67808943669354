import { HTTPStatusCodes } from './HangleExceptions';
import { encode as base64Encode } from 'base64-arraybuffer';

export interface Redirect {
  url: string;
  code: HTTPStatusCodes.FOUND | HTTPStatusCodes.MOVED_PERMANENTLY;
}

export interface Image {
  image: Uint8Array;
  mimeType: 'image/png' | 'image/gif' | 'image/jpeg';
}

export function makeRedirect(url: string, code: HTTPStatusCodes.FOUND | HTTPStatusCodes.MOVED_PERMANENTLY = 302) {
  return { url, code };
}

export function makeImage(image: Uint8Array, mimeType: 'image/png' | 'image/gif' | 'image/jpeg' = 'image/jpeg') {
  return { image, mimeType };
}

export const transparentPixelUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

// There is a convenient Blob object that's useful for making data URLs, but it
// ends up creating a new object for every image, which causes React to re-render
// every time. So we encode as Base64 to a string which React can memoize.
export function imageToDataUrl(image: Image) {
  const encoded = base64Encode(image.image);
  const dataUrl = `data:image/${image.mimeType};base64,${encoded}`;
  return dataUrl;
}
