/// <reference types="@types/gapi" />
/// <reference types="@types/gapi.auth2" />
import { useState, useEffect, useCallback } from 'react';

// Type definitions for react-google-login v2.5.4
// Adapted from https://github.com/anthonyjgrove/react-google-login/master/index.d.ts
// Definitions by: Ruslan Ibragimov <https://github.com/IRus>

export type AuthResponse = gapi.auth2.AuthResponse;
type GoogleUser = gapi.auth2.GoogleUser;

// Based on https://developers.google.com/identity/sign-in/web/reference
// Extends the GoogleUser type (from
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/gapi.auth2/index.d.ts)
// with JS-friendly properties to avoid having to call methods to get profile info.
export interface GoogleLoginResponse extends GoogleUser {
  // google-login.js sez: offer renamed response keys to names that match use
  googleId: string;
  tokenObj: AuthResponse;
  tokenId: string;
  accessToken: string;
  expiresAt: number;
  readonly code?: string; // does not exist but here to satisfy typescript compatibility
  profileObj: {
    googleId: string;
    imageUrl: string;
    email: string;
    name: string;
    givenName: string;
    familyName: string;
  };
}

export interface GoogleLoginResponseOffline {
  readonly code: string;
}

export interface UseGoogleLogoutResponse {
  signOut: () => Promise<void>;
  loaded: boolean;
}

type ClientConfig = gapi.auth2.ClientConfig;

export type GoogleLoginErrorResult = { error: string; details: string };

export interface UseGoogleLogoutProps {
  readonly clientId: string;
  readonly onLogoutSuccess?: () => void;
  readonly onFailure?: (reason: GoogleLoginErrorResult) => void;
  readonly accessType?: string;
  readonly fetchBasicProfile?: boolean;
  readonly cookiePolicy?: string;
  readonly loginHint?: string;
  readonly hostedDomain?: string;
  readonly redirectUri?: string;
  readonly discoveryDocs?: string[];
  readonly scope?: string;
  readonly uxMode?: ClientConfig['ux_mode'];
  readonly debug?: boolean;
}

export interface UseGoogleLoginResponse {
  signIn: () => Promise<void>;
  loaded: boolean;
}

export interface UseGoogleLoginProps {
  readonly onSuccess?: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  readonly onFailure?: (reason: GoogleLoginErrorResult) => void;
  readonly onAutoLoadFinished?: (successLogin: boolean) => void;
  readonly clientId: string;
  readonly onRequest?: () => void;
  readonly scope?: string;
  readonly redirectUri?: string;
  readonly cookiePolicy?: string;
  readonly loginHint?: string;
  readonly hostedDomain?: string;
  readonly prompt?: 'none' | 'login' | 'consent' | 'select_account';
  readonly responseType?: string;
  readonly autoLoad?: boolean;
  readonly uxMode?: ClientConfig['ux_mode'];
  readonly fetchBasicProfile?: boolean;
  readonly isSignedIn?: boolean;
  readonly discoveryDocs?: string[];
  readonly accessType?: string;
  readonly debug?: boolean;
}

// if responseType includes `code` then we'll have to ask for offline access
const isOfflineNeeded = (responseType: string | undefined) => responseType?.split(' ').includes('code');

export function useGoogleLogin({
  onSuccess = () => void 0,
  onAutoLoadFinished = () => void 0,
  onFailure = () => void 0,
  onRequest = () => void 0,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  autoLoad,
  isSignedIn,
  fetchBasicProfile,
  redirectUri,
  discoveryDocs,
  uxMode,
  scope,
  accessType,
  responseType,
  prompt,
  debug = true,
}: UseGoogleLoginProps): UseGoogleLoginResponse {
  const [loaded, setLoaded] = useState(false);

  const handleSigninSuccess = useCallback(
    function handleSigninSuccessInternal(res: GoogleUser) {
      // Add camelCased commonly-used profile props to existing object
      const basicProfile = res.getBasicProfile();
      const authResponse = res.getAuthResponse();
      const result: GoogleLoginResponse = Object.assign(res, {
        googleId: basicProfile.getId(),
        tokenObj: authResponse,
        tokenId: authResponse.id_token,
        accessToken: authResponse.access_token,
        expiresAt: authResponse.expires_at,
        profileObj: {
          googleId: basicProfile.getId(),
          imageUrl: basicProfile.getImageUrl(),
          email: basicProfile.getEmail(),
          name: basicProfile.getName(),
          givenName: basicProfile.getGivenName(),
          familyName: basicProfile.getFamilyName(),
        },
      });
      onSuccess(result);
    },
    [onSuccess]
  );

  const signIn = useCallback(
    async function signInInternal() {
      if (!loaded) return;
      const GoogleAuth = window.gapi.auth2.getAuthInstance();
      onRequest();
      if (isOfflineNeeded(responseType)) {
        if (prompt !== 'consent' && prompt !== 'select_account') {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`Invalid prompt value: ${prompt}`);
        }
        try {
          const res = await GoogleAuth.grantOfflineAccess({ prompt });
          onSuccess(res);
        } catch (err) {
          onFailure(err as GoogleLoginErrorResult);
        }
      } else {
        try {
          const res = await GoogleAuth.signIn({ prompt });
          handleSigninSuccess(res);
        } catch (err) {
          onFailure(err as GoogleLoginErrorResult);
        }
      }
    },
    [handleSigninSuccess, loaded, onFailure, onRequest, onSuccess, prompt, responseType]
  );

  useEffect(() => {
    let unmounted = false;

    const params = {
      client_id: clientId,
      cookie_policy: cookiePolicy,
      login_hint: loginHint,
      hosted_domain: hostedDomain,
      fetch_basic_profile: fetchBasicProfile,
      discoveryDocs,
      ux_mode: uxMode,
      redirect_uri: redirectUri,
      scope,
      access_type: isOfflineNeeded(responseType) ? 'offline' : accessType,
    };

    // TODO: this function is sometimes called several times in a row.
    // I assume this is a race condition that we should eliminate.
    function handleAuth2Loaded() {
      console.log('Google login API loaded');
      const googleAuth = window.gapi.auth2.getAuthInstance();
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (!googleAuth) {
        window.gapi.auth2.init(params).then(
          res => {
            if (!unmounted) {
              setLoaded(true);
              const signedIn = !!isSignedIn && res.isSignedIn.get();
              onAutoLoadFinished(signedIn);
              if (signedIn) {
                handleSigninSuccess(res.currentUser.get());
              }
            }
          },
          err => {
            setLoaded(true);
            onAutoLoadFinished(false);
            onFailure(err);
          }
        );
      } else if (isSignedIn && googleAuth.isSignedIn.get()) {
        setLoaded(true);
        onAutoLoadFinished(true);
        handleSigninSuccess(googleAuth.currentUser.get());
      } else if (!unmounted) {
        setLoaded(true);
        onAutoLoadFinished(false);
      }
    }

    if (window?.gapi?.auth2) {
      handleAuth2Loaded();
    } else {
      const onScriptLoaded = () => {
        console.log('Google login script loaded');
        window.gapi.load('auth2', handleAuth2Loaded);
      };
      loadScript(onScriptLoaded, debug);
    }

    return () => {
      unmounted = true;
    };
  }, [
    accessType,
    clientId,
    cookiePolicy,
    discoveryDocs,
    fetchBasicProfile,
    handleSigninSuccess,
    hostedDomain,
    isSignedIn,
    loginHint,
    onAutoLoadFinished,
    onFailure,
    redirectUri,
    responseType,
    scope,
    uxMode,
    debug,
  ]);

  useEffect(() => {
    if (autoLoad) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      void signIn(); // fire and forget; errors are only handled via callbacks
    }
  }, [autoLoad, loaded, signIn]);

  return { signIn: signIn, loaded };
}

/*
const loadGapiAuth = async (params: gapi.auth2.ClientConfig) => {
  await new Promise(resolve => window.gapi.load('client:auth2', resolve));
  await new Promise(resolve => window.gapi.auth2.init(params).then(resolve));
};
*/

export async function googleLogout() {
  if (!window.gapi) {
    throw new Error("Google SDK not loaded, so you can't log out of Google.");
  }
  const auth2 = window.gapi?.auth2?.getAuthInstance();
  if (auth2 == null) {
    throw new Error('Cannot access GoogleAuth instance in Google SDK. Logout failed.');
  }
  await auth2.signOut();
  // AFAIK disconnect() is not needed for regular logouts.
  // await auth2.disconnect();
}

/*
Commenting out this hook in favor of a simple googleLogout() function above
that assumes that the SDK has already been loaded before logout can be attempted.

export function useGoogleLogout({
  onLogoutSuccess = () => void 0,
  onFailure = () => void 0,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  fetchBasicProfile,
  discoveryDocs,
  uxMode,
  redirectUri,
  scope,
  accessType,
  debug = true,
}: UseGoogleLogoutProps): UseGoogleLogoutResponse {
  const [loaded, setLoaded] = useState(false);

  const signOut = async () => {
    if (!window.gapi) {
      throw new Error("Google SDK not loaded, so you can't log out of Google");
    }
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      await auth2.signOut();
      // AFAIK the disconnect() is not needed for regular logouts, so
      // commenting it out here.
      // await auth2.disconnect();
      onLogoutSuccess();
    }
  };

  useEffect(() => {
    const params = {
      client_id: clientId,
      cookie_policy: cookiePolicy,
      login_hint: loginHint,
      hosted_domain: hostedDomain,
      fetch_basic_profile: fetchBasicProfile,
      discoveryDocs,
      ux_mode: uxMode,
      redirect_uri: redirectUri,
      scope,
      access_type: accessType,
    };

    function handleAuth2Loaded() {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (!window.gapi.auth2.getAuthInstance()) {
        window.gapi.auth2.init(params).then(
          () => setLoaded(true),
          err => onFailure(err)
        );
      } else {
        setLoaded(true);
      }
    }
    if (window?.gapi?.auth2) {
      handleAuth2Loaded();
    } else {
      const onScriptLoaded = () => window.gapi.load('auth2', handleAuth2Loaded);
      loadScript(onScriptLoaded, debug);
    }
  }, [
    accessType,
    clientId,
    cookiePolicy,
    discoveryDocs,
    fetchBasicProfile,
    hostedDomain,
    loginHint,
    onFailure,
    redirectUri,
    scope,
    uxMode,
    debug,
  ]);

  return { signOut, loaded };
}

*/

function loadScript(cb: () => void, isDebug: boolean) {
  const id = 'google-login';
  const s = 'script';
  const sdkFilename = isDebug ? 'client:auth2' : 'client:auth2';
  const element: HTMLScriptElement = document.getElementsByTagName(s)[0];
  const js: HTMLScriptElement = document.createElement(s);
  if (document.getElementById(id)) {
    console.log(
      'Google API script is being unexpectedly reloaded, so ignoring reentrancy and will wait for original callback to run'
    );
    return;
  }
  js.id = id;
  js.src = `https://apis.google.com/js/${sdkFilename}.js`;
  js.async = true;
  js.defer = true;
  js.onload = cb;
  js.onerror = () => {
    console.log(`Could not load Google script: https://apis.google.com/js/${sdkFilename}.js`);
  };
  if (element && element.parentNode) {
    element.parentNode.insertBefore(js, element);
  } else {
    document.head.appendChild(js);
  }
}
