/** @jsxImportSource @emotion/react */
import { CSSObject } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Button } from 'reactstrap';
import { CSSProperties, useMemo, Fragment, memo, NamedExoticComponent } from 'react';
import { useShallowCompareMemo } from './useHooks';

const wrapperStyle: CSSObject = {
  borderBottomStyle: 'solid',
  borderBottomWidth: '3px',
  paddingBottom: '3px',
  transition: 'border-color 100ms ease-out',
  borderBottomColor: 'transparent',
};

const activeWrapperStyle: CSSObject = {
  ...wrapperStyle,
  borderBottomColor: '#aaaaaa',
};

const Wrapper = styled.div(wrapperStyle);
const ActiveWrapper = styled.div(activeWrapperStyle);

const NotActiveButton = styled(Button)`
  background-color: transparent !important;
  color: #333 !important;
  border: 0;
  margin: 0 0.25rem;
  padding: 0.375rem;
  cursor: pointer;
  transition: color 100ms ease-out, border-color 100ms ease-out, box-shadow 100ms ease-out;
  &:hover {
    color: #666 !important;
    background-color: transparent !important;
  }
  &:active {
    color: #666 !important;
    background-color: transparent !important;
  }
`;

const ActiveButton = styled(NotActiveButton)`
  padding-bottom: 0;
`;

export const IconButton = memo(function IconButton(props: {
  type: NamedExoticComponent<Record<string, unknown>> | ((...args: any[]) => JSX.Element); // eslint-disable-line @typescript-eslint/no-explicit-any
  onClick: () => void;
  active?: boolean;
  style?: CSSProperties;
  iconProps?: Record<string, unknown>;
}) {
  const { type, onClick, active, style, iconProps = {} } = props;
  const hasActiveState = props.hasOwnProperty('active');
  const OneIcon = type;
  const memoIconProps = useShallowCompareMemo<CSSProperties>(iconProps || {});
  const memoStyle = useShallowCompareMemo<CSSProperties>(style || {});
  const inner = useMemo(() => {
    const InnerWrapper = hasActiveState ? (active ? ActiveWrapper : Wrapper) : Fragment;
    return (
      <InnerWrapper>
        <OneIcon {...memoIconProps} />
      </InnerWrapper>
    );
  }, [memoIconProps, hasActiveState, active, OneIcon]);
  const ButtonComponent = hasActiveState ? ActiveButton : NotActiveButton;
  return (
    <ButtonComponent onClick={onClick} style={memoStyle} active={active}>
      {inner}
    </ButtonComponent>
  );
});
