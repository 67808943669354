// adapted from https://github.com/kjvalencik/runtypes-filter/blob/master/src/index.ts

import { Runtype, Result, Failure, Reflect, Failcode } from 'runtypes';

// This shouldn't be ever run because it's only used for exhaustiveness
/* istanbul ignore next */
class UnreachableCaseError extends Error {
  constructor(val: unknown) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    super(`Unreachable case: ${val}`);
  }
}

const fail = (msg: string, code: Failcode): Failure => ({ success: false, message: msg, code });

export function validate<T, R extends Runtype<T>>(t: R, x: T): Result<T> {
  function check<T1, R1 extends Runtype<T1>>(r1: R1, y: T1): Result<T1> {
    const r = r1.reflect;

    // First, validate the entire value.  This is inefficient (because we'll be
    // validating everything twice) but doing this guarantees that in the
    // per-runtype `switch` below we can assume that the value is valid. This
    // makes the `switch` code simpler and avoids invalid values sneaking
    // through.
    const initialResult = r1.validate(y);
    if (!initialResult.success) {
      return initialResult;
    }

    // for nestable types (other than InstanceOf, which is treated as a single
    // value here) perform further validation to ensure that no unexpected
    // object properties are present
    switch (r.tag) {
      case 'never':
      case 'literal':
      case 'boolean':
      case 'number':
      case 'string':
      case 'void':
      case 'symbol':
      case 'instanceof':
      case 'function':
      case 'unknown':
        return initialResult;
      case 'array':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (const elem of y as any as unknown[]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result = check(r.element as any, elem as any);
          if (!result.success) return result;
        }
        return { success: true, value: y };
      case 'tuple': {
        for (let i = 0; i < r.components.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
          const elem = (y as any as any[])[i];
          const runtype = r.components[i];
          const result = check(runtype, elem);
          if (!result.success) return result;
        }
        return initialResult;
      }
      case 'dictionary':
        for (const key of Object.keys(y)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
          const result = check(r.value as Runtype<any>, y[key as keyof typeof y]);
          // remove above cast if https://github.com/Microsoft/TypeScript/issues/3500
          if (!result.success) return result;
        }
        return initialResult;
      case 'record': {
        for (const [key, runtype] of Object.entries(r.fields)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const value = (y as any)[key];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result = check(runtype as Runtype<any>, value);
          if (!result.success) return result;
        }
        // check if any props are unexpectedly present
        for (const key of Object.keys(y)) {
          if (!r.fields.hasOwnProperty(key)) {
            return fail(`Expected only valid keys, but got ${key}`, 'PROPERTY_PRESENT');
          }
        }

        return initialResult;
      }
      case 'union':
        for (const runtype of r.alternatives) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result = check(runtype as Runtype<any>, y);
          if (!result.success) return result;
        }
        return initialResult;
      case 'constraint':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return check(r.underlying as Runtype<any>, y);
      case 'brand':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return check(r.entity as Runtype<any>, y);
      case 'intersect':
        // Intersection types are the hardest case because checking
        // can't be delegated to children. Instead, we need to figure out
        // which props are OK for each branch of the intersection, and then
        // make sure that all props are covered by at least one branch.
        // Therefore, we'll only support intersections whose top level are
        // solely `Record` and/or `Partial`. Other combinations will throw.
        const fields = new Set<string>();
        for (const reflect of r.intersectees) {
          const verifyTypes = (rInner: Reflect): boolean => {
            switch (rInner.tag) {
              case 'record':
                Object.keys(rInner.fields).forEach(f => !fields.has(f) && fields.add(f));
                return true;
              case 'constraint':
                return verifyTypes(rInner.underlying);
              default:
                return false;
            }
          };
          if (!verifyTypes(reflect)) {
            return fail(`Expected only Record and/or Partial runtypes, but got ${reflect.tag}`, 'TYPE_INCORRECT');
          }
        }

        // check if any props are unexpectedly present
        for (const key of Object.keys(y)) {
          if (!fields.has(key)) {
            return fail(`Expected only valid keys, but got ${key}`, 'PROPERTY_PRESENT');
          }
        }
        return initialResult;

      // Exhaustiveness checking
      /* istanbul ignore next */
      default:
        throw new UnreachableCaseError(r);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return check(t.reflect as Runtype<any>, x);
}

export default function NoExtraProps<T, R extends Runtype<T>>(t: R) {
  const constraint = (x: T): boolean | string => {
    const result = validate(t, x);
    return result.success ? true : result.message ?? false;
  };
  const wrapped = t.withConstraint(constraint);
  return wrapped;
}
