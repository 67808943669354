/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// JG - I forked this file so I could avoid using AWS Mobile Hub. Going to configure things
// myself, manually.

import API from '@aws-amplify/api-rest';
import { StorageHelper } from '@aws-amplify/core';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { REACT_APP_BACKEND } from './env';

// import OriginalAPI from './amplify-fork2';

/*
// AWS Mobile Hub Project Constants
const rawConfig = {
  'aws_app_analytics': 'enable',
  'aws_cognito_identity_pool_id': 'us-east-2:5878f9fa-0c28-43e1-929f-561783e98e2d',
  'aws_cognito_region': 'us-east-2',
  'aws_content_delivery': 'enable',
  'aws_content_delivery_bucket': 'h-hosting-mobilehub-1097117628',
  'aws_content_delivery_bucket_region': 'us-east-2',
  'aws_content_delivery_cloudfront': 'enable',
  'aws_content_delivery_cloudfront_domain': 'd3v3bay269hnmi.cloudfront.net',
  'aws_mobile_analytics_app_id': '51c416075bc04311aefee30af206cf55',
  'aws_mobile_analytics_app_region': 'us-east-1',
  'aws_project_id': '585bea72-1006-4989-85c5-fcdfb652d931',
  'aws_project_name': 'h3',
  'aws_project_region': 'us-east-2',
  'aws_resource_name_prefix': 'h-mobilehub-1097117628',
  'JG_aws_api_gateway_url': 'https://jrq08ushmh.execute-api.us-east-2.amazonaws.com',
  'JG_aws_api_gateway_region': 'us-east-1',
}
*/

// Need to use custom storage because `currentAuthenticatedFederatedUser()`
// needs to check it and current public API of `Amplify.Auth` doesn't expose
// storage prop.
const storageHelper = new StorageHelper().getStorage();
const storageSync = typeof storageHelper['sync'] === 'function' ? storageHelper['sync']() : Promise.resolve();

/**
 * Get current authenticated federated user. AFAIK there's no way to check to
 * see if the user is already logged in without running into
 * https://github.com/aws-amplify/amplify-js/issues/4001 which shows a console
 * error if there's no Cognito User Pool configured. (We use Cognito Identity
 * Pools, but not User Pools). So we rolled our own solution by copying the code
 * for `Auth.currentAuthenticatedUser()`, removing the user-pool parts, and then
 * monkey-patching it into the Amplify Auth class.
 * TODO: figure out a better solution.
 * @return - A promise resolves to current authenticated CognitoUser if success
 */
export async function currentAuthenticatedFederatedUser() {
  if (!Auth['currentAuthenticatedFederatedUser']) {
    Auth['currentAuthenticatedFederatedUser'] = currentAuthenticatedFederatedUserPatch.bind(Auth);
  }
  const user = await (Auth['currentAuthenticatedFederatedUser'] as typeof currentAuthenticatedFederatedUserPatch)();
  return user;
}

async function currentAuthenticatedFederatedUserPatch(this: typeof Auth): Promise<CognitoUser | null> {
  console.log('getting current authenticated user');
  try {
    await storageSync;
  } catch (e) {
    console.log('Failed to sync cache info into memory', e);
    throw e;
  }

  try {
    const federatedUser = JSON.parse(storageHelper.getItem('aws-amplify-federatedInfo')).user as CognitoUser | null;
    this['user'] = federatedUser;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.log(`got current authenticated federated user: ${federatedUser}`);
    return federatedUser;
  } catch (e) {
    console.log('cannot load federated user from auth storage');
    return null;
  }
}

const IPV4Regex = new RegExp('^d{1,3}.d{1,3}.d{1,3}.d{1,3}$');
const hostname = window.location.hostname;
const isLocal = hostname === 'localhost' || IPV4Regex.test(hostname);
const useLocalAWS = isLocal && REACT_APP_BACKEND !== 'production';

const config = {
  Auth: {
    mandatorySignIn: true,
    region: 'us-east-1',
    identityPoolId: 'us-east-1:9cd6f315-3872-40ff-9865-c0d161e9a143',
    //    storage: storageHelper,
  },
  /*
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
*/

  // TODO: try to enable HTTP keep-alive for faster perf. As discussed here:
  // https://medium.com/predict/lambda-optimization-tip-enable-http-keep-alive-ef7aa7880554
  // Make sure to check to make sure that, when we get around to implementing
  // this, that Amplify hasn't implemented it first!  It's also possible that
  // modern browsers make this optimization unnecessary because they automatically
  // implement keep-alive connections.

  API: {
    endpoints: [
      {
        name: 'user',
        endpoint: useLocalAWS
          ? 'https://localhost:4000' // API gateway URL for local testing
          : 'https://s13271k7ld.execute-api.us-east-1.amazonaws.com', // API gateway URL
        custom_header: async () => {
          const user = await Auth.currentAuthenticatedUser();
          console.log(`will authenticate to AWS with this user: ${JSON.stringify(user)}`);
          return user && user['id'] ? { 'cognito-identity-id': user['id'] } : {};
          // Using Cognito UserPools use this instead: return { (await Auth.currentSession()).idToken.jwtToken }
        },
        region: 'us-east-1',
      },
    ],
  },
  Analytics: {
    disabled: true,
  },
};

const configure = () => {
  // Amplify.configure(config);
  Auth.configure(config);
  API.configure(config);
  //    console.log(JSON.stringify(Amplify['_components']));
};

const ConfigureAWS = { configure, config };
export default ConfigureAWS;
