/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro';
import { memo, CSSProperties } from 'react';
import { getDayIndex, DEFAULT_TIME } from './dateUtils';
import { SelectedDateChangeHandler, BottomCalendarScrollbarOffsetContainer } from './Calendar';
import { CalendarViewType } from './HangTimesTab';
import CalendarDateRange from './CalendarDateRange';
import { IconButton } from './IconButton';
import { Fragment, useRef, useMemo, useCallback } from 'react';
import { useLastInteractedWith } from './LastInteractedWith';
import { usePlainDateMemo } from './useHooks';
import { TodayIcon, DayIcon, ListIcon, PlusIcon } from './Icon';
import { Temporal } from '@js-temporal/polyfill';

export interface CalendarMiddleProps {
  startDate: Temporal.PlainDate;
  endDate: Temporal.PlainDate;
  view: CalendarViewType;
  onSelectedDateChange: SelectedDateChangeHandler;
  onViewChange: (arg: { view: CalendarViewType }) => void;
  onTimeClick: (arg: { time: Temporal.PlainTime; date: Temporal.PlainDate }) => void;
}

const DayIndexLogger = styled.span`
  color: #ccc;
  position: absolute;
  right: 1px;
  top: 0;
  font-size: 9px;
  line-height: 15px;
  @media (max-width: 1000px) {
    display: none;
  }
`;
DayIndexLogger.displayName = 'DayIndexLogger';

const ButtonBarSeparator = styled.span`
  border-left: 1px solid #999;
  vertical-align: middle;
`;
ButtonBarSeparator.displayName = 'ButtonBarSeparator';

const MiddleButtonsWrapper = styled.div`
  white-space: nowrap;
  align-items: center;
`;
MiddleButtonsWrapper.displayName = 'MiddleButtonsWrapper';

const ViewChangeButtonsWrapper = styled.span`
  display: inline-block;
  & button {
    padding-bottom: 0;
  }
`;
ViewChangeButtonsWrapper.displayName = 'ViewChangeButtonsWrapper';

const plusButtonStyle: CSSProperties = { marginLeft: -3 };

const CalendarMiddleButtons = memo(function CalendarMiddleButtons(props: {
  startDate: CalendarMiddleProps['startDate'];
  view: CalendarMiddleProps['view'];
  onSelectedDateChange: CalendarMiddleProps['onSelectedDateChange'];
  onViewChange: CalendarMiddleProps['onViewChange'];
  onTimeClick: CalendarMiddleProps['onTimeClick'];
}) {
  const { onTimeClick, startDate, view, onSelectedDateChange, onViewChange } = props;

  const updateView = useCallback(
    (v: CalendarViewType) => {
      if (view !== v) {
        console.log(`updating view to ${v}`);
        onViewChange({ view: v });
      }
    },
    [onViewChange, view]
  );

  const todayMemo = usePlainDateMemo(Temporal.Now.plainDateISO());
  const todayButtonExtraProps = useMemo(() => ({ date: todayMemo }), [todayMemo]);
  const todayOnClick = useCallback(
    () => onSelectedDateChange({ date: todayMemo, caller: 'middle' }),
    [onSelectedDateChange, todayMemo]
  );

  const switchToDayView = useCallback(() => updateView('day'), [updateView]);
  const switchToListView = useCallback(() => updateView('list'), [updateView]);

  return (
    <MiddleButtonsWrapper>
      <IconButton type={TodayIcon} iconProps={todayButtonExtraProps} onClick={todayOnClick} />
      <ViewChangeButtonsWrapper>
        {(['day', 'list'] as CalendarViewType[]).map((viewName, i) => (
          <Fragment key={viewName}>
            {i > 0 && <ButtonBarSeparator />}
            <IconButton
              type={viewName === 'day' ? DayIcon : ListIcon}
              onClick={viewName === 'day' ? switchToDayView : switchToListView}
              active={view === viewName}
            />
          </Fragment>
        ))}
      </ViewChangeButtonsWrapper>
      <IconButton
        type={PlusIcon}
        style={plusButtonStyle}
        onClick={() => onTimeClick({ date: startDate, time: DEFAULT_TIME })}
      />
    </MiddleButtonsWrapper>
  );
});

const dayViewStyle: CSSProperties = { alignSelf: 'flex-end', marginRight: '0.3125rem' };
const listViewStyle: CSSProperties = {};

function CalendarMiddle(props: CalendarMiddleProps) {
  const { startDate, endDate, view, onTimeClick, onSelectedDateChange, onViewChange } = props;

  // Track interactions inside this component
  const ref = useRef<HTMLDivElement>(null);
  useLastInteractedWith({ ref: ref, name: 'middle' });

  const isList = view === 'list';
  const innerClassName = isList ? 'middle-calendar-list-inner' : '';
  const outerClassName = isList ? 'middle-calendar middle-calendar-list' : 'middle-calendar ';
  const innerStyle = isList ? listViewStyle : dayViewStyle;

  const scrollbarOffsetContainer = BottomCalendarScrollbarOffsetContainer.useContainer();
  const outerStyle: CSSProperties | undefined = useMemo(
    () =>
      view === 'list'
        ? {
            right: scrollbarOffsetContainer.offset,
          }
        : {
            paddingRight: scrollbarOffsetContainer.offset,
            left: 0,
          },
    [view, scrollbarOffsetContainer.offset]
  );

  return (
    <div ref={ref} className={outerClassName} style={outerStyle}>
      {!isList && <CalendarDateRange startDate={startDate} endDate={endDate} />}
      {!isList && <DayIndexLogger>({getDayIndex(startDate)})</DayIndexLogger>}
      <div className={innerClassName} style={innerStyle}>
        <CalendarMiddleButtons
          startDate={startDate}
          view={view}
          onTimeClick={onTimeClick}
          onSelectedDateChange={onSelectedDateChange}
          onViewChange={onViewChange}
        />
      </div>
    </div>
  );
}

export default memo(CalendarMiddle);
