// See https://docs.aws.amazon.com/lambda/latest/dg/nodejs-prog-mode-exceptions.html
export interface LambdaError {
  errorMessage: string;
  errorType: string;
  stackTrace: string[];
  offlineInfo?: string; // from serverless-offline plugin, if present
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLambdaError = (o: unknown): o is LambdaError =>
  (o as LambdaError)?.errorMessage != null && (o as LambdaError)?.errorType != null;
export const formatLambdaError = (e: LambdaError) => {
  const msg =
    `${e.errorType}: ${e.errorMessage}\n` +
    (e.offlineInfo ? `serverless-offline: ${e?.offlineInfo}` : '') +
    e.stackTrace.join('\\n');
  return msg;
};
