import {
  useRef,
  useImperativeHandle,
  forwardRef,
  CSSProperties,
  useCallback,
  MutableRefObject,
  useMemo,
  memo,
  useEffect,
} from 'react';
import { InternalServerError } from './HangleExceptions';
import { getDayIndex, MSECS_PER_DAY, HANG_DAY_COUNT, getDateFromDayIndex } from './dateUtils';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import CalendarBottom, { CalendarBottomProps as CalendarBottomOneDayProps } from './CalendarBottom';
import { usePlainDateMemo, useObjectState, useShallowCompareMemo } from './useHooks';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { SelectedDateChangeHandler } from './Calendar';
import { useLastInteractedWith } from './LastInteractedWith';
import { Temporal } from '@js-temporal/polyfill';

export const BOTTOM_CALENDAR_CONTENT_HEIGHT = 960;

export interface CalendarBottomScrollableProps extends Omit<CalendarBottomOneDayProps, 'height'> {
  onSelectedDateChange: SelectedDateChangeHandler; // end is inclusive // TODO: change to date range
}

interface VirtualRenderProps<T> {
  offset: number; // slides left / top offset in px
  from: number; // index of first slide required to be rendered
  to: number; // index of last slide required to be rendered
  slides: T[]; // array with slide items to be rendered
}

type CalendarBottomScrollableRenderProps = CalendarBottomScrollableProps &
  Size & {
    verticalScroller: React.RefObject<HTMLDivElement>;
  };

const rowStyle = {
  height: BOTTOM_CALENDAR_CONTENT_HEIGHT,
  width: '100%',
};

function getYFromTime(time: Temporal.PlainTime) {
  const msecs = msecsSinceMidnight(time);
  const scrollTop = (BOTTOM_CALENDAR_CONTENT_HEIGHT * msecs) / MSECS_PER_DAY;
  return scrollTop;
}

type RenderSlidesProps = Pick<VirtualRenderProps<null>, 'from' | 'offset' | 'slides'> &
  Pick<CalendarBottomScrollableRenderProps, 'time' | 'onTimeClick' | 'onEventClick' | 'hangTimes'>;

const RenderSlides = memo(function (props: RenderSlidesProps) {
  const {
    // Swiper render props
    from,
    offset,
    slides,
    // parent component props
    time,
    onTimeClick,
    onEventClick,
    //    onEventDrop,
    hangTimes,
  } = props;

  const slideStyle = useShallowCompareMemo(() => ({
    left: offset,
    height: BOTTOM_CALENDAR_CONTENT_HEIGHT,
    width: '100%',
    display: 'inline-block',
    overflow: 'auto',
  }));

  return (
    <>
      {slides.map((unused, i) => {
        const index = from + i;
        // console.log(`RenderSlides bottom: ${JSON.stringify({ index, date: date.toDateString() })}`);
        return (
          <RenderOneSlide
            key={index}
            index={index}
            style={slideStyle}
            time={time}
            onTimeClick={onTimeClick}
            onEventClick={onEventClick}
            // onEventDrop={onEventDrop}
            hangTimes={hangTimes}
            height={BOTTOM_CALENDAR_CONTENT_HEIGHT}
          />
        );
      })}
    </>
  );
});

export interface CalendarBottomRefHandles {
  ensureDateIsVisible: (date: Temporal.PlainDate) => void;
}

interface RenderOneSlideProps
  extends Pick<CalendarBottomOneDayProps, 'time' | 'onTimeClick' | 'onEventClick' | 'hangTimes'> {
  index: number;
  style: CSSProperties;
  height: number;
}

// To prevent rows from being re-rendered every time the List scrolls, we need to:
// 1) move the row component outside of the main component function
// 2) memoize it. I had trouble getting React.memo() to prevent rendering, so
//    using useMemo on child JSX instead.
const RenderOneSlide = memo(function RenderOneSlide(props: RenderOneSlideProps) {
  const {
    time,
    onTimeClick,
    onEventClick,
    // onEventDrop,
    hangTimes,
    style,
    index,
    height,
  } = props;
  const date = usePlainDateMemo(getDateFromDayIndex(index));
  // console.log(`Rendering day ${props.index} (date: ${date.toString()}), back to index: ${getDayIndex(date)}`);

  const childMemo = useMemo(
    () => (
      <CalendarBottom
        selectedDate={date}
        time={time}
        onTimeClick={onTimeClick}
        onEventClick={onEventClick}
        //        onEventDrop={onEventDrop}
        hangTimes={hangTimes}
        height={height}
      />
    ),
    [date, time, onTimeClick, onEventClick, /* onEventDrop,*/ hangTimes, height]
  );

  return (
    <div className="swiper-slide bottom-calendar-swiper-slide" key={index} style={style} id={getSlideId(index)}>
      <div className="bottom-calendar-one-day-wrapper" style={rowStyle}>
        {childMemo}
      </div>
    </div>
  );
});

function msecsSinceMidnight(plainTime: Temporal.PlainTime) {
  return plainTime.since('00:00', { largestUnit: 'milliseconds', smallestUnit: 'milliseconds' }).milliseconds;
}

function getSlideId(index: number): string {
  return `bottomCalendarSlide${index}`;
}

interface SwiperContainerRefHandles {
  swiper: MutableRefObject<Swiper | undefined>;
}

const swiperContainerStyle = {
  // TODO: should we re-enable this? touchAction: 'pan-y',
  height: BOTTOM_CALENDAR_CONTENT_HEIGHT,
  width: '100vw',
};

const areVirtualRenderPropsEqual = (p1: VirtualRenderProps<null>, p2: VirtualRenderProps<null>) =>
  p1.from === p2.from && p1.offset === p2.offset && p1.to === p2.to;

const defaultItemData = { from: 0, to: 0, offset: 0, slides: [] };
const SwiperContainer = forwardRef(function SwiperContainer(
  props: CalendarBottomScrollableRenderProps,
  componentRef: React.Ref<SwiperContainerRefHandles>
) {
  const {
    width,
    height,
    selectedDate: initialDate,
    onSelectedDateChange: originalOnSelectedDateChange,
    time,
    verticalScroller,
  } = props;

  const selectedDate = useRef(initialDate);
  const [currentItemData, setCurrentItemData] = useObjectState<VirtualRenderProps<null> | undefined>(
    undefined,
    areVirtualRenderPropsEqual
  );
  const swiper = useRef<Swiper>();
  const containerRef = useRef<HTMLDivElement>(null);

  const onSelectedDateChange = useCallback(() => {
    const dayIndex = getDayIndex(selectedDate.current);
    if (swiper.current && originalOnSelectedDateChange) {
      const newIndex = swiper.current.activeIndex;
      console.log(`bottom new index: ${newIndex}, oldIndex: ${dayIndex}, current: ${selectedDate.current.toString()}`);
      if (newIndex !== dayIndex) {
        const date = getDateFromDayIndex(newIndex);
        selectedDate.current = date;
        originalOnSelectedDateChange({ date, caller: 'bottom' });
      }
    }
  }, [originalOnSelectedDateChange, selectedDate]);

  useImperativeHandle(
    componentRef,
    () => ({
      swiper,
    }),
    [swiper]
  );

  // const time = useDateMemo(timeOriginal);
  useEffect(() => {
    // Scroll so the requested time prop is visible. Center it, to maximize
    // nearby hours being visible, esp. on smaller devices where only 6-8 hours
    // will be visible.
    if (verticalScroller.current == null) {
      throw new InternalServerError('Vertical scroller is unexpectedly blank');
    }
    const scroller = verticalScroller.current;
    const msecsVisible = MSECS_PER_DAY * (scroller.clientHeight / BOTTOM_CALENDAR_CONTENT_HEIGHT);
    const msecsScrollTop = Math.max(0, msecsSinceMidnight(time) - msecsVisible / 2);
    const timeScrollTop = Temporal.PlainTime.from('00:00')
      .add({ milliseconds: Math.trunc(msecsScrollTop) })
      .round({ smallestUnit: 'minutes', roundingIncrement: 30 });
    const scrollTop = getYFromTime(timeScrollTop);
    if (scrollTop > 0) {
      scroller.scrollTop = scrollTop;
    }

    // create the horizontal scroller
    if (!swiper.current) {
      console.log('creating swiper');
      swiper.current = new Swiper('.bottom-calendar-swiper-container', {
        direction: 'horizontal',
        mousewheel: {
          forceToAxis: true,
          invert: true,
        },
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: 0.5,
        keyboard: true,
        slidesPerView: 1,
        initialSlide: getDayIndex(selectedDate.current),
        //          speed: 500,
        on: {
          slideChange: onSelectedDateChange,
        },
        virtual: {
          slides: new Array<null>(HANG_DAY_COUNT).fill(null),
          renderExternal: (data: VirtualRenderProps<null>) => {
            console.log('renderExternal called');
            // assign virtual slides data
            setCurrentItemData(data);
          },
        },
      });
    }
    return () => {
      console.log('cleaning up swiper');
      if (swiper.current) {
        swiper.current.destroy(true, true);
      }
    };
  }, [setCurrentItemData, onSelectedDateChange, verticalScroller, time]);

  console.log(`Render w/ size: ${JSON.stringify({ width, height })}`);
  console.log(
    `Render w/ currentItemData: ${
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      currentItemData &&
      JSON.stringify({
        from: currentItemData.from,
        to: currentItemData.to,
        offset: currentItemData.offset,
        slides: currentItemData.slides,
      })
    }`
  );

  // setViewportWidth(width);
  const { from, offset, slides } = currentItemData || defaultItemData;

  return (
    <div className="swiper-container bottom-calendar-swiper-container" style={swiperContainerStyle} ref={containerRef}>
      <div className="swiper-wrapper">
        {currentItemData ? (
          <RenderSlides
            from={from}
            offset={offset}
            slides={slides}
            hangTimes={props.hangTimes}
            time={props.time}
            onEventClick={props.onEventClick}
            onTimeClick={props.onTimeClick}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
});

function CalendarBottomScrollable(
  props: CalendarBottomScrollableProps,
  componentRef: React.Ref<CalendarBottomRefHandles>
) {
  const swiperRef = useRef<SwiperContainerRefHandles>(null);

  const ensureDateIsVisible = useCallback((date: Temporal.PlainDate) => {
    const index = getDayIndex(date);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.log(`swiperRef:  ${swiperRef.current}`);
    if (swiperRef.current) {
      try {
        const swiper = swiperRef.current.swiper.current;
        if (swiper) {
          console.log(`scrolling bottom swiper to item: ${index}`);
          swiper.slideTo(index);
        } else {
          console.log(`swiper ref not defined when trying to set a visible date`);
        }
      } catch (e) {
        console.log(`error setting current slide: ${(e as Error).message}`);
      }
    }
  }, []);

  useImperativeHandle(
    componentRef,
    () => ({
      ensureDateIsVisible,
    }),
    [ensureDateIsVisible]
  );

  const verticalScrollerRef = useRef<HTMLDivElement>(null);

  // Track interactions inside this component
  useLastInteractedWith({ ref: verticalScrollerRef, name: 'bottom' });

  return (
    <div className="bottom-calendar-container" ref={verticalScrollerRef}>
      <AutoSizer defaultHeight={500}>
        {size => <SwiperContainer {...props} {...size} verticalScroller={verticalScrollerRef} ref={swiperRef} />}
      </AutoSizer>
    </div>
  );
}

const wrapped = memo(forwardRef(CalendarBottomScrollable));
export default wrapped;
