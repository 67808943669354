import { useCallback, CSSProperties } from 'react';
import { Button } from 'reactstrap';
import { REACT_APP_GOOGLE_CLIENT_ID } from './env';
import { ModalContainer } from './ContextModal';
import {
  useGoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  UseGoogleLoginProps,
  GoogleLoginErrorResult,
} from './googleLogin';
import { GoogleIcon } from './Icon';

export interface GoogleLoginButtonProps {
  onLoginResponse: (response: GoogleLoginResponse) => void;
  style?: CSSProperties;
  className?: string;
}

interface UseGoogleProps {
  readonly onSuccess?: (response: GoogleLoginResponse) => void;
  readonly onFailure?: (reason: GoogleLoginErrorResult) => void;
  //  readonly scope?: string,
  //  readonly responseType?: string,
  //  readonly autoLoad?: boolean;
  //  readonly uxMode?: string;
}

function useGoogleLoginWrapper(props: UseGoogleProps) {
  const { onSuccess, onFailure } = props;
  const input: UseGoogleLoginProps = {
    onSuccess: onSuccess as (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
    onFailure,
    clientId: REACT_APP_GOOGLE_CLIENT_ID,
    fetchBasicProfile: true,
    accessType: 'online',
    prompt: 'select_account',
    cookiePolicy: 'single_host_origin',
    //  onAutoLoadFinished?: (successLogin: boolean) => void,
    //  scope?: string,
    //  loginHint?: string,
    //  responseType?: string, // this might be needed!
    //  autoLoad?: boolean;
    //  uxMode?: string;
    //  isSignedIn?: boolean,
  };
  const result = useGoogleLogin(input);
  return result;
}

const googleLoginErrorMessages = {
  popup_closed_by_user: 'The Google login window was unexpectedly closed',
  access_denied: 'Google permissions that Hangle needs were not approved',
  immediate_failed:
    "No user could be automatically selected without prompting the consent flow. Error raised when using signIn with prompt: 'none' option. This option should not be required to use, as gapi.auth2.init will automatically sign in the user if previously signed in during a previous session.",
};

function GoogleLoginButton(props: GoogleLoginButtonProps) {
  const { onLoginResponse, style, className } = props;
  const { addModal } = ModalContainer.useContainer();

  const handleLoginSuccess = useCallback(
    (userInfo: GoogleLoginResponse) => {
      console.log(`Google login returned: ${JSON.stringify(userInfo)}`);
      onLoginResponse(userInfo);
    },
    [onLoginResponse]
  );

  const handleLoginFailed = useCallback(
    (reason: GoogleLoginErrorResult) => {
      const msg =
        reason.error &&
        (googleLoginErrorMessages[reason.error as keyof typeof googleLoginErrorMessages] ||
          reason.error.replace(/_/g, ' '));

      console.log(`Google login failure: ${msg}`);
      addModal(`${msg ? `Google login failed: ${msg}` : 'Google login failed'}. Please try again.`);
    },
    [addModal]
  );

  const { signIn, loaded } = useGoogleLoginWrapper({
    onSuccess: handleLoginSuccess,
    onFailure: error => handleLoginFailed(error),
  });

  return (
    <Button
      style={style}
      className={className || 'social-login-button google-login-button'}
      onClick={signIn}
      disabled={!loaded}
    >
      <GoogleIcon /> Login with Google
    </Button>
  );
}

export default GoogleLoginButton;
